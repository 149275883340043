import React from "react";
import { Chart } from "primereact/chart";

function ProfitOpportunityChart(props) {
  const chartData = props?.data;

  let basicOptions = {
    maintainAspectRatio: false,
    aspectRatio: 1.9,
    plugins: {
      legend: props.legend,
    },
    scales: props.scales,
  };

  return (
    <>
      <div className="card flex justify-content-center">
        <Chart
          type={props.typeChart}
          data={chartData}
          options={basicOptions}
          style={{ position: "relative", minHeight: "195px", width: "40%" }}
        />
      </div>
    </>
  );
}

export default ProfitOpportunityChart;
