import axios from 'axios'
import authService from '../../components/api-authorization/AuthorizeService';

export class DevelopmentFrameworkService {
	_subscription = null;
	_token = null;
	_subscribeTokenExpiration = null;

	constructor() {
		this._subscription = authService.subscribe(() => {
			this.authorize();
		});

		this._subscribeTokenExpiration = authService.subscribeTokenExpiration((newToken) => {
			if (newToken !== this._token) {
				// console.log('upgrading DEVELOPMENT FRAMEWORK token', newToken);
				this._token = newToken;
			}
		});

		this.authorize();
	}

	authorize() {
		authService.isAuthenticated().then((isAuthenticated) => {
			// console.debug("DevelopmentFrameworkService, isAuthenticated: ", isAuthenticated);
			if (isAuthenticated) {
				authService.getAccessToken().then((token) => {
					this._token = token;
					// console.debug("DevelopmentFrameworkService, received token: ", token);
				});
			} else {
				this._token = null;
			}
		});

		// console.debug("DevelopmentFrameworkService: ", this._token);
	}

	async downloadDevelopmentFrameworkData(parms) {
		if (this._token != null) {
			const config = {
				headers: { Authorization: `Bearer ${this._token}` },
				responseType: 'blob'
			};

			var q = '';
			var init = false;

			if (parms.dateFrom) {
				q = '?from=' + parms.dateFrom;
				init = true;
			}

			if (parms.dateTo) {
				if (init) {
					q += '&to=' + parms.dateTo;
				} else {
					q = '?to=' + parms.dateTo;
				}
			}

			if (parms.selectWorkflowsSamples) {
				if (init) {
					q += '&selectWorkflowsSamples=' + parms.selectWorkflowsSamples;
				} else {
					q = '?selectWorkflowsSamples=' + parms.selectWorkflowsSamples;
				}
			}

			let response = await axios.get('experimentaldata/getexcel/' + q, config);

			if (response.status === 200) {
				// console.debug("downloadDevelopmentFrameworkData() OK");

				// new File([response.data], 'data.xlsx');

				if (response.data) {
					let url = window.URL.createObjectURL(response.data);
					let a = document.createElement('a');
					a.href = url;
					a.download = 'data.xlsx';
					a.click();
				}

			} else {
				let error = response.data;
				console.error(error);
			}
		}
	}

	static get instance() { return developmentFrameworkService; }
}

const developmentFrameworkService = new DevelopmentFrameworkService();

export default developmentFrameworkService;