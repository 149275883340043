import React, { useEffect, useState } from 'react';
import { withTranslation } from 'react-i18next';
import FieldMappings from '../components/Config/FieldMappings';
import ImportData from '../components/Config/ImportData';
import PricingGuardrails from '../components/Config/PricingGuardrails';
// import ConfigActionBtn from '../components/Config/ConfigActionBtn'
// import PricingSchedule from '../components/Config/PricingSchedule';
import { ConfirmDialog } from 'primereact/confirmdialog';
import Scheduler from '../components/Config/Scheduler';
import Users from '../components/Config/Users';
import GeneralConfig from '../components/Config/General';
import ExperimentalData from '../components/Config/ExperimentalData';
import PricingAlgorithmsConfig from '../components/Config/PricingAlgorithms';
import Holidays from '../components/Config/Holidays';
import Customers from '../components/Config/Customers';
import PricingExportSettings from '../components/Config/PricingExportSettings';
import PatternPresets from '../components/Config/PatternsPresets';
import configurationService from '../Api/requests/config';
import authService from '../components/api-authorization/AuthorizeService';
import pricingService from '../Api/requests/pricing';
import { Dialog } from 'primereact/dialog';

const Config = (props) => {
	const { t, tReady } = props;
	const [loading, setLoading] = useState(false);

	const [skus, setSkus] = useState(null);;
	const [currencySymbol, setCurrencySymbol] = useState(null);
	const [user, setUser] = useState(null);
	const [companyName, setCompanyName] = useState("")
	const [lastRepricing, setLastRepricing] = useState(null);
	const [helpVisible, setHelpVisible] = useState(false);
	const [helpText, setHelpText] = useState('');
	const [helpTitle, setHelpTitle] = useState('');

	const onSetLoading = (val) => {
		setLoading(val);
	}

	const showHelp = (titleRes, messageRes) => {
		setHelpText(t(messageRes));
		setHelpTitle(t('ConfigurationDialog.HelpDialog_Title', { Title: t(titleRes) }));
		setHelpVisible(true);
	}

	const loadDefaults = async () => {
		let defaultText = t('ConfigurationDialog.DefaultPricingAlgorithmsConfig');

		var theSkus = [
			{ value: "-1", label: defaultText }
		];

		let skuList = await configurationService.getAllSkus()

		let i = 0;
		for (i = 0; i < skuList.length; i++) {
			theSkus.push({ value: skuList[i], label: skuList[i].toString() });
		}

		setSkus(theSkus);

		let user = await authService.getUser();
		setCurrencySymbol(user.currencySymbol);
		setCompanyName(user.company);
		setUser(user);

		let theLastRepricing = await pricingService.getLastRepricingDateTime();

		if (theLastRepricing != null) {
			setLastRepricing(theLastRepricing);
		} else {
			setLastRepricing(null);
		}
	}

	//useEffect(() => {
	//    loadDefaults();

	//}, []);  // eslint-disable-line react-hooks/exhaustive-deps

	useEffect(() => {
		if (tReady) {
			loadDefaults();
		}
	}, [tReady]);  // eslint-disable-line react-hooks/exhaustive-deps

	const dialogMaskClickEventHandler = (e) => {
		if (e.target.classList.contains('p-dialog-mask')) {
			setHelpVisible(false);
		}
	}

	const dialogHideEventHandler = () => {
		setHelpVisible(false)
	}

	if (user === null) {
		return (<></>);
	} else {
		return (
			<>
				<ConfirmDialog />
				<div id="overlay_panel" style={{ display: (loading ? '' : 'none') }} className="p-overlaypanel-wrapper p-component-overlay p-general-loading-overlay">
				</div>
				<Dialog header={helpTitle} visible={helpVisible} position="center" onMaskClick={dialogMaskClickEventHandler} onHide={dialogHideEventHandler} className="p-config-dialog">
					<p dangerouslySetInnerHTML={{ __html: helpText }}></p>
				</Dialog>

				<div className="grid">
					<div className="col-12 lg:col-3">
						<h3 className="text-primary">{companyName}</h3>
						<h1 className="text-primary">{t('Administration.Header')}</h1>
						<h4 className="text-primary text-subtitle" style={{ display: (lastRepricing == null) ? "none" : "" }}>{t('Administration.LastRepricing', { date: lastRepricing })}</h4>
					</div>
					<div className="col-12 lg:col-12">
						{(user.isDeveloper === true || user.isGlobalAdmin === true || user.isAdmin === true || user.isDirector === true || user.isManager === true) && (
							<div className="config-section-wrapper">
								<h2 className="m-4">{t('Administration.DataImportSection')}</h2>
								<div className="grid config-subsection-wrapper p-4">
									<div className="col-12 xl:col-4">
										<ImportData
											allowed={user.isDeveloper === true || user.isGlobalAdmin === true || user.isAdmin === true || user.isDirector === true || user.isManager === true}
											allowExtendedFunctionalities={user.isGlobalAdmin}
											setLoading={onSetLoading}
											showHelp={showHelp}
										/>
									</div>
									<div className="col-12 xl:col-8">
										<FieldMappings
											allowed={user.isDeveloper === true || user.isGlobalAdmin === true || user.isAdmin === true || user.isDirector === true || user.isManager === true}
											showHelp={showHelp}
											setLoading={onSetLoading} />
									</div>
								</div>
							</div>
						)}

						{(user.isDeveloper === true || user.isGlobalAdmin === true || user.isAdmin === true || user.isDirector === true || user.isManager === true) && (
							<div className="config-section-wrapper mt-4">
								<h2 className="m-4">{t('Administration.ConfigurationSection')}</h2>
								<div className="grid config-subsection-wrapper p-4">
									<div className="col-12 xl:col-6 xxl-4">
										<Holidays
											allowed={user.isDeveloper === true || user.isGlobalAdmin === true || user.isAdmin === true || user.isDirector === true || user.isManager === true}
											showHelp={showHelp}
											setLoading={onSetLoading} />
									</div>

									<div className="col-12 xl:col-6 xxl-8">
										<Scheduler
											allowed={user.isDeveloper === true || user.isGlobalAdmin === true || user.isAdmin === true || user.isDirector === true || user.isManager === true}
											showHelp={showHelp}
											setLoading={onSetLoading} />
									</div>

									<div className="col-12 xl:col-6 xxl-3">
										<PricingExportSettings
											allowed={user.isDeveloper === true || user.isGlobalAdmin === true || user.isAdmin === true || user.isDirector === true || user.isManager === true}
											showHelp={showHelp}
											setLoading={onSetLoading} />
									</div>

									<div className="col-12 xl:col-6 xxl-9">
										<PatternPresets
											allowed={user.isGlobalAdmin === true || user.isAdmin === true || user.isDirector === true || user.isManager === true}
											showHelp={showHelp}
											setLoading={onSetLoading} />
									</div>									
								</div>
							</div>
						)}

						{(user.isDeveloper === true || user.isGlobalAdmin === true || user.isAdmin === true || user.isDirector === true) && (
							<div className="config-section-wrapper mt-4">
								<h2 className="m-4">{t('Administration.AdvancedConfigurationSection')}</h2>
								<div className="grid config-subsection-wrapper p-4">
									<div className="col-12 xl:col-6 xxl-5">
										<GeneralConfig
											allowed={user.isDeveloper === true || user.isGlobalAdmin === true || user.isAdmin === true || user.isDirector === true}
											showHelp={showHelp}
											setLoading={onSetLoading} />
									</div>
									<div className="col-12 xl:col-6 xxl-5">
										<PricingGuardrails
											allowed={user.isDeveloper === true || user.isGlobalAdmin === true || user.isAdmin === true || user.isDirector === true}
											currencySymbol={currencySymbol}
											skus={skus}
											showHelp={showHelp}
											setLoading={onSetLoading} />
									</div>
								</div>
							</div>
						)}

						{(user.isDeveloper === true) && (
							<div className="config-section-wrapper mt-4">
								<h2 className="m-4">{t('Administration.PricingAlgorithmSection')}</h2>
								<div className="grid config-subsection-wrapper p-4">
									<div className="col-12 xl:col-9">
										<PricingAlgorithmsConfig
											skus={skus}
											allowed={user.isDeveloper}
											showHelp={showHelp}
											setLoading={onSetLoading} />
									</div>
								</div>
							</div>
						)}

						{(user.isDeveloper === true || user.isGlobalAdmin === true || user.isAdmin === true || user.isDirector === true) && (
							<div className="config-section-wrapper mt-4">
								<h2 className="m-4">{t('Administration.UsersSection')}</h2>
								<div className="grid config-subsection-wrapper p-4">
									<div className="col-12 xl:col-12">
										<Users
											showHelp={showHelp}
											allowed={user.isDeveloper === true || user.isGlobalAdmin === true || user.isAdmin === true || user.isDirector === true} />
									</div>
								</div>
							</div>
						)}

						{(user.isGlobalAdmin === true || user.isDeveloper === true) && (
							<div className="config-section-wrapper mt-4">
								<h2 className="m-4">{t('Administration.CustomersSection')}</h2>
								<div className="grid config-subsection-wrapper p-4">
									<div className="col-12 xl:col-7 xxl-6">
										<Customers
											allowed={user.isGlobalAdmin || user.isDeveloper}
											showHelp={showHelp} />
									</div>
								</div>
							</div>
						)}

						{(user.isDeveloper === true) && (
							<div className="config-section-wrapper mt-4">
								<h2 className="m-4">{t('Administration.DevelopmentSection')}</h2>
								<div className="grid config-subsection-wrapper p-4">
									<div className="col-12 xl:col-6 xxl-5">
										<ExperimentalData
											allowed={user.isDeveloper}
											showHelp={showHelp}
											setLoading={onSetLoading} />
									</div>
								</div>
							</div>
						)}

					</div>
				</div>
			</>);
	}
}

export default withTranslation()(Config)
