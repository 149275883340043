import { toFont } from 'chart.js/helpers'
import moment from 'moment'

export class ChartUtils {

	getBody(bodyItem) {
		return bodyItem.lines;
	}

	customDemandCurvesPricingPaneTooltips(context) {
		// Tooltip Element
		let tooltipEl = document.getElementById('chartjs-tooltip-panes');

		// Create element on first render
		if (!tooltipEl) {
			tooltipEl = document.createElement('div');
			tooltipEl.id = 'chartjs-tooltip-panes';
			tooltipEl.innerHTML = '<div style="border: 1px solid #004547; margin: 10px; padding: 5px; border-radius: 10px;background-color: #ffffff;"><table></table></div>';
			document.body.appendChild(tooltipEl);
		}

		// Hide if no tooltip
		const tooltipModel = context.tooltip;

		if (tooltipModel.opacity === 0) {
			tooltipEl.style.opacity = 0;
			return;
		}

		// Set caret Position
		tooltipEl.classList.remove('above', 'below', 'no-transform');
		if (tooltipModel.yAlign) {
			tooltipEl.classList.add(tooltipModel.yAlign);
		} else {
			tooltipEl.classList.add('no-transform');
		}

		// Set Text
		if (tooltipModel.body) {
			const titleLines = tooltipModel.title || [];
			const bodyLines = tooltipModel.body.map(this.getBody);

			let innerHtml = '<thead>';

			titleLines.forEach(function (title) {
				innerHtml += '<tr><th style="font-weight: 600;font-size: 13px;color:#004547;text-align: left;">' + title + '</th></tr>';
			});
			innerHtml += '</thead><tbody style="margin-top: 5px;">';

			// console.debug(tooltipModel);

			bodyLines.forEach(function (body, i) {
				const colors = tooltipModel.labelColors[i];
				let style = '; border-color: #004547';
				style += '; border-width: 2px';
				style += '; color: ' + colors.borderColor;
				style += '; font-size: 14px;';
				const span = '<span style="' + style + '">' + body + '</span>';
				const pointStyle = '<div style="width: 10px;height: 10px;display: inline-block;margin-right: 2px; background-color: ' + colors.borderColor + '"></div>';

				innerHtml += '<tr><td>' + pointStyle + span + '</td></tr>';
			});
			innerHtml += '</tbody>';

			let tableRoot = tooltipEl.querySelector('table');
			tableRoot.innerHTML = innerHtml;
		}

		const position = context.chart.canvas.getBoundingClientRect();
		const bodyFont = toFont(tooltipModel.options.bodyFont);

		// Display, position, and set styles for font
		tooltipEl.style.opacity = 1;
		tooltipEl.style.transition = 'opacity .4s ease';	
		tooltipEl.style.position = 'absolute';
		tooltipEl.style.left = position.left + window.pageXOffset + tooltipModel.caretX + 'px';
		tooltipEl.style.top = position.top + window.pageYOffset + tooltipModel.caretY + 'px';
		tooltipEl.style.font = bodyFont.string;
		tooltipEl.style.padding = tooltipModel.padding + 'px ' + tooltipModel.padding + 'px';
		tooltipEl.style.pointerEvents = 'none';
	}

	createTitleDemandCurvesPricingPaneTooltips(item) {
		var theTitle = null;
		const maxPerLine = 6;

		if (item.filter(o => o.raw.label !== undefined).length > 0) {
			theTitle = '';
			for (var i = 0; i < item.length; i++) {
				theTitle += moment(item[i].raw.label).format("DD MMM yy");

				if (i < item.length - 1) {
					if ((i % maxPerLine) === (maxPerLine - 1)) {
						theTitle += "\r\n";
					} else {
						theTitle += " - ";
					}
				}
			}
		}

		return theTitle;
	}
}