import React, { Component } from 'react'
import { Routes, Route } from "react-router-dom";
import { Layout } from './layouts/Layout'
import AuthorizeRoute from './components/api-authorization/AuthorizeRoute';
import { } from 'chart.js';
import AppRoutes from './AppRoutes';

import '../node_modules/primeicons/primeicons.css'
import './assets/scss/main.scss'

export default class App extends Component {
	static displayName = App.name;

	render() {
		return (
			<Layout>
				<Routes>
					{AppRoutes.map((route, index) => {
						const { element, requireAuth, ...rest } = route;
						return <Route key={index} {...rest} element={requireAuth ? <AuthorizeRoute {...rest} element={element} /> : element} />;
					})}
				</Routes>
			</Layout>
		);
	}
}