import React, { useState, useEffect } from 'react'
import { useForm, Controller } from 'react-hook-form';
import { withTranslation } from 'react-i18next';
import ConfigActionBtn from '../Config/ConfigActionBtn'
import { DataTable } from 'primereact/datatable';
import { Dialog } from 'primereact/dialog';
import { Column } from 'primereact/column';
import { confirmDialog } from 'primereact/confirmdialog';
import { Password } from 'primereact/password';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { Dropdown } from 'primereact/dropdown';
import { Checkbox } from 'primereact/checkbox';
import messagesService from '../../Api/client-side/messages'
// import { Message } from 'primereact/message';
import customersService from '../../Api/requests/customers';
import { classNames } from 'primereact/utils';

function Customers(props) {
	const { t } = props;
	const { allowed } = props;
	const { showHelp } = props;

	const [companyDialogVisible, setCompanyDialogVisible] = useState(false)
	const [loading, setLoading] = useState(false);
	const [totalRecords, setTotalRecords] = useState(0);
	const [companies, setCompanies] = useState(null);
	const [showDatabaseCredentials, setShowDatabaseCredentials] = useState(false);
	const [executing, setExecuting] = useState(false);
	const [lazyParams, setLazyParams] = useState({
		first: 0,
		rows: 10,
		page: 1
	});

	let languages = [
		{ value: 'en-US', name: t('Admin_Customers.English') },
		{ value: 'es-ES', name: t('Admin_Customers.Spanish') }
	];

	let defaultCompany = {
		id: null,
		name: "",
		server: "",
		port: 5432,
		userName: "",
		password: "",
		databaseName: "",
		currencyName: "USD",
		currencySymbol: "$",
		language: "en-US",
		usePricimetricsDatabase: true,
		useSSL: true,
		country: "US"
	};

	const [companyData, setCompanyData] = useState(defaultCompany);

	const [countries, setCountries] = useState([]);

	const { control, formState: { errors }, handleSubmit, reset } = useForm({ reValidateMode: 'onChange', defaultValues: defaultCompany });

	useEffect(() => {
		reset(companyData);
	}, [companyData]) // eslint-disable-line react-hooks/exhaustive-deps

	useEffect(() => {
		loadLazyData();
	}, [lazyParams]) // eslint-disable-line react-hooks/exhaustive-deps

	const loadLazyData = async () => {
		setLoading(true);

		let itemsCount = await customersService.getCustomersItemsCount();
		setTotalRecords(itemsCount);
		let items = await customersService.getCustomersItems(lazyParams);
		setCompanies(items);
		let theCountries = await customersService.getCountries();
		setCountries(theCountries);

		setLoading(false);
	}

	const onPage = (event) => {
		let _lazyParams = { ...lazyParams, ...event };
		setLazyParams(_lazyParams);
	}

	const onSort = (event) => {
		let _lazyParams = { ...lazyParams, ...event };
		setLazyParams(_lazyParams);
	}

	const deleteCustomer = async (company) => {
		let ret = await customersService.deleteCustomer(company);

		if (ret == null) {
			messagesService.showInfo(t('Admin_Customers.DeleteSuccessTitle'), t('Admin_Customers.DeleteSuccessMsg'));
			await loadLazyData();
		} else if (ret === 403) {
			messagesService.showError(t('Admin_Customers.DeleteErrorTitle'), t('Admin_Customers.DeleteErrorCannotDeleteCurrent'));
		} else {
			messagesService.showError(t('Admin_Customers.DeleteErrorTitle'), ret);
		}
	}

	const getFormErrorMessage = (name) => {
		return errors[name] && <small className="p-error">{errors[name].message}</small>
	};

	const confirmdeleteCustomer = (company) => {
		confirmDialog({
			message: t('Admin_Customers.ConfirmDeleteMsg'),
			header: t('Admin_Customers.ConfirmDeleteHeader'),
			acceptLabel: t('Admin_Customers.ConfirmDialogYes'),
			rejectLabel: t('Admin_Customers.ConfirmDialogNo'),
			className: 'companies-confirm-panel',
			icon: 'pi pi-exclamation-triangle',
			accept: () => deleteCustomer(company)
		});
	}

	const addCompany = () => {
		// Dialog without initial data
		setCompanyData(defaultCompany);
		reset(defaultCompany);
		setShowDatabaseCredentials(defaultCompany.usePricimetricsDatabase === false);
		showCompanyDialog();
	}

	const editCompany = (company) => {
		// Dialog with initial data
		// console.log(company);
		if (company.password === null) {
			company.password = '';
		}

		/*if (company.phoneNumber === null) {
			company.phoneNumber = '';
		}*/

		setCompanyData(company);
		setShowDatabaseCredentials(company.usePricimetricsDatabase === false);
		showCompanyDialog();
	}

	const deleteBodyTemplate = (rowData) => {
		return (
			<React.Fragment>
				<Button icon="pi pi-trash" className="p-link p-row-editor-init" onClick={() => confirmdeleteCustomer(rowData)} />
			</React.Fragment>
		);
	}

	const editBodyTemplate = (rowData) => {
		return (
			<React.Fragment>
				<Button icon="pi pi-pencil" className="p-link p-row-editor-init" onClick={() => editCompany(rowData)} />
			</React.Fragment>
		);
	}

	const showCompanyDialog = () => {
		setCompanyDialogVisible(true);
	}

	const hideCompanyDialog = () => {
		setCompanyDialogVisible(false);
	}

	const doSaveCompany = async (companyData) => {
		var res = '';

		try {
			setExecuting(true);
			res = await customersService.createCustomer(companyData);

			if (res === null) {
				reset();
				hideCompanyDialog();
				await loadLazyData();
				messagesService.showSuccess(t('Admin_Customers.SuccessTitle'), t('Admin_Customers.SuccessSave'));
			} else {
				messagesService.showError(t('Admin_Customers.ErrorTitle'), t('Admin_Customers.ErrorSave'));
			}
		}
		finally {
			setExecuting(false);
		}
	}

	const onSubmit = async (companyData) => {
		if (companyData.usePricimetricsDatabase === false) {
			let checkDatabaseCredentials = await customersService.checkCredentials(companyData);

			if (checkDatabaseCredentials === null) {
				doSaveCompany(companyData);
			} else {
				messagesService.showError(t('Admin_Customers.ErrorTitle'), t(checkDatabaseCredentials));
			}
		} else {
			doSaveCompany(companyData);
		}
	};

	const saveCompany = () => {
		handleSubmit(onSubmit)();
	}

	const closeCompanyDialog = () => {
		reset();
		hideCompanyDialog();
	};

	const companyDialogFooter = () => (
		<div>
			<Button label={t('Admin_Customers.Button_Save')} icon="pi pi-check" onClick={saveCompany} />
			<Button label={t('Admin_Customers.Button_Cancel')} icon="pi pi-times" onClick={closeCompanyDialog} />
		</div>
	);

	const nameIsUnique = async (name) => {
		// console.log(email);

		var exists;

		if (companyData.id !== '') {
			// Allow same name for this company id
			exists = await customersService.existsCustomer(companyData.id, name);
		} else {
			exists = await customersService.existsCustomer('', name);
		}

		if (exists === true) {
			return t('Admin_Customers.Name_Unique_Error');
		} else {
			return true;
		}
	};

	if (allowed !== true) {
		return (<></>);
	} else {
		return (
			<>
				<Dialog header={t('Admin_Customers.AddCompanyDialogHeader')} className="grid p-config-dialog" visible={companyDialogVisible} style={{ width: '50vw', maxHeight: '100vh', cursor: executing ? "wait" : "auto" }} position="center" footer={companyDialogFooter()} onHide={() => hideCompanyDialog()}>
					<form onSubmit={handleSubmit(onSubmit)} className="grid config-subsection-wrapper p-4" style={{  }}>
						<input type="hidden" name="id" id="id" value={ companyData.id == null ? "" : companyData.id }/>
						<div className="p-fluid p-formgrid grid">
							<div className="field col-12 md:col-6">
								<label htmlFor="name">{t('Admin_Customers.Name')}</label>
								<span className="p-error">{getFormErrorMessage('name')}</span>
								<Controller name="name" control={control} rules={{ required: t('Admin_Customers.Required_Name'), minLength: 1, maxLength: 50, validate: v => nameIsUnique(v) } } render={({ field, fieldState }) => (
									<InputText id={field.name} {...field} autoFocus className={classNames("inputField", "textbox", { 'p-invalid': fieldState.invalid })} />
								)} />
							</div>
							<div className="field col-12 md:col-6">
								<label htmlFor="databaseName">{t('Admin_Customers.DatabaseName')}</label>
								<span className="p-error">{getFormErrorMessage('databaseName')}</span>
								<Controller name="databaseName" control={control} rules={{ required: t('Admin_Customers.Required_DatabaseName'), minLength: 1, maxLength: 50 }} render={({ field, fieldState }) => (
									<InputText id={field.name} {...field} autoFocus className={classNames("inputField", "textbox", { 'p-invalid': fieldState.invalid })} autoComplete="off" />
								)} />
							</div>

							<div className="field col-6 md:col-3">
								<label htmlFor="currencyName">{t('Admin_Customers.CurrencyName')}</label>
								<span className="p-error">{getFormErrorMessage('currencyName')}</span>
								<Controller name="currencyName" control={control} rules={{ required: t('Admin_Customers.Required_CurrencyName'), minLength: 1, maxLength: 10 }} render={({ field, fieldState }) => (
									<InputText id={field.name} {...field} autoFocus className={classNames("inputField", "textbox", { 'p-invalid': fieldState.invalid })} autoComplete="off" />
								)} />
							</div>
							<div className="field col-6 md:col-3">
								<label htmlFor="currencySymbol">{t('Admin_Customers.CurrencySymbol')}</label>
								<span className="p-error">{getFormErrorMessage('currencySymbol')}</span>
								<Controller name="currencySymbol" control={control} rules={{ required: t('Admin_Customers.Required_CurrencySymbol'), minLength: 1, maxLength: 5 }} render={({ field, fieldState }) => (
									<InputText id={field.name} {...field} autoFocus className={classNames("inputField", "textbox", { 'p-invalid': fieldState.invalid })} autoComplete="off" />
								)} />
							</div>
							<div className="field col-12 md:col-6">
								<label htmlFor="country">{t('Admin_Customers.Country')}</label>
								<span className="p-error">{getFormErrorMessage('country')}</span>
								<Controller name="country" control={control} rules={{ required: t('Admin_Customers.Required_Country') }} render={({ field }) => (
									<Dropdown id={field.name} {...field} autoFocus options={countries} optionLabel="name" optionValue="code" placeholder={t('Admin_Customers.SelectCountry')}
										onChange={(e) => field.onChange(e.value)} />
								)} />
							</div>
							<div className="field col-12 md:col-6">
								<label htmlFor="language">{t('Admin_Customers.Language')}</label>
								<span className="p-error">{getFormErrorMessage('language')}</span>
								<Controller name="language" control={control} rules={{ required: t('Admin_Customers.Required_Language') }} render={({ field }) => (
									<Dropdown id={field.name} value={field.value} autoFocus options={languages} optionLabel="name" placeholder={t('Admin_Customers.SelectLanguage')}
										optionValue="value" onChange={(e) => field.onChange(e.value)} />
								)} />
							</div>
							<div className="field col-12 md:col-12">
								<label htmlFor="usePricimetricsDatabase">{t('Admin_Customers.UsePricimetricsDatabase')}</label>
								<Controller name="usePricimetricsDatabase" control={control} render={({field}) => (
									<Checkbox
										inputId={field.name}
										className={classNames("m-2")}
										{...field}
										onChange={(e) => {
											setShowDatabaseCredentials(e.checked === false);
											field.onChange(e.checked);
										}}
										checked={field.value}
									/>
								)} />
							</div>

							{(showDatabaseCredentials && (
								<>
									<div className="field col-12 md:col-6">
										<label htmlFor="server">{t('Admin_Customers.Server')}</label>
										<span className="p-error">{getFormErrorMessage('server')}</span>
										<Controller name="server" control={control} rules={{ required: t('Admin_Customers.Required_Server'), minLength: 1, maxLength: 50 }} render={({ field, fieldState }) => (
											<InputText id={field.name} {...field} autoFocus className={classNames("inputField", "textbox", { 'p-invalid': fieldState.invalid })} />
										)} />
									</div>
									<div className="field col-12 md:col-6">
										<label htmlFor="port">{t('Admin_Customers.Port')}</label>
										<span className="p-error">{getFormErrorMessage('port')}</span>
										<Controller name="port" control={control} rules={{ required: t('Admin_Customers.Required_Port'), minLength: 1, maxLength: 5 }} render={({ field, fieldState }) => (
											<InputText keyfilter="int" id={field.name} {...field} autoFocus className={classNames("inputField", "textbox", { 'p-invalid': fieldState.invalid })} autoComplete="off" />
										)} />
									</div>
									<div className="field col-12 md:col-6">
										<label htmlFor="userName">{t('Admin_Customers.Username')}</label>
										<span className="p-error">{getFormErrorMessage('userName')}</span>
										<Controller name="userName" control={control} rules={{ required: t('Admin_Customers.Required_Username'), minLength: 1, maxLength: 50 }} render={({ field, fieldState }) => (
											<InputText id={field.name} {...field} autoFocus className={classNames("inputField", "textbox", { 'p-invalid': fieldState.invalid })} autoComplete="off" />
										)} />
									</div>
									<div className="field col-12 md:col-6">
										<label htmlFor="password">{t('Admin_Customers.Password')}</label>
										<span className="p-error">{getFormErrorMessage('password')}</span>
										<Controller name="password" control={control} rules={{ required: t('Admin_Customers.Required_Password') }} render={({ field, fieldState }) => (
											<Password id={field.name} {...field} autoFocus toggleMask feedback={false} inputClassName="inputField textbox" autoComplete="off"
												className={classNames({ 'p-invalid': fieldState.invalid })} />
										)} />
									</div>
									<div className="field col-12 md:col-12">
										<label htmlFor="useSSL">{t('Admin_Customers.UseSSL')}</label>
										<Controller name="useSSL" control={control} render={({ field }) => (
											<Checkbox
												inputId={field.name}
												className={classNames("m-2")}
												{...field}
												onChange={field.onChange}
												checked={field.value}
											/>
										)} />
									</div>
								</>
							))}
						</div>
					</form>
				</Dialog>


				<div className="grid mt-3 pl-3 pr-3">
					<h3 className="text-primary pt-3 pb-3 pl-3">
						{t('Admin_Customers.Header')}
						<Button label="" title={t('Admin_Customers.HelpButton')} icon="pi pi-question-circle" className="p-button-config-help p-button-rounded p-button-info" onClick={() => showHelp('Admin_Customers.ImportHelpTitle', 'Admin_Customers.ImportHelpMessage')} />
					</h3>

					<div className="col-12 md:col-12 datatable datatable-responsive">
						<DataTable value={companies} lazy responsiveLayout="scroll"
							className="p-datatable-config" dataKey="id"
							paginatorPosition="top" paginator first={lazyParams.first} rows={10} totalRecords={totalRecords} onPage={onPage}
							onSort={onSort} sortField={lazyParams.sortField} sortOrder={lazyParams.sortOrder}
							loading={loading}>
							<Column field="name" sortable header={t('Admin_Customers.Column_Name')} />
							<Column field="server" sortable header={t('Admin_Customers.Column_Server')} />
							<Column field="databaseName" sortable header={t('Admin_Customers.Column_DatabaseName')} />
							<Column body={editBodyTemplate} style={{ width: '1rem' }}></Column>
							<Column body={deleteBodyTemplate} style={{ width: '1rem' }}></Column>
						</DataTable>
					</div>
				</div>

				<div className="flex justify-content-end mr-3 bottom-buttons">
					<ConfigActionBtn onClick={(e) => addCompany(e)} buttonText={t('Admin_Customers.AddNewCompanyButton')} disabled={executing} />
				</div>
			</>)
	}
}

export default withTranslation()(Customers)
