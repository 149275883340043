import React, { useEffect, useRef, useState } from "react"
import { HashLink } from "react-router-hash-link";
import { withTranslation } from "react-i18next";
import { SelectButton } from "primereact/selectbutton";
import FilterPricing from "../components/Pricing/FilterPricing"
import DataTablePricing from "../components/Pricing/PricingTable"
import { Calculate } from "../Api/client-side/calculate"
import pricingService from "../Api/requests/pricing"
import messagesService from "../Api/client-side/messages"
import PricingTiles from "../components/Pricing/PricingTiles"
import authService from "../components/api-authorization/AuthorizeService";
import { OverlayPanel } from "primereact/overlaypanel";

const Pricing = (props) => {
	const pricingGridSessionStorageSelectedRowsKey = "pricingGridSelectedRows";
	const pricingGridSessionStorageColumnsSelectedKey = "pricingGridSelectedColumns";
	const [rowsPerPage, setRowsPerPage] = useState(10);
	const [search, setSearch] = useState("");
	const [searchOutside, setSearchOutside] = useState(false);
	const [items, setItems] = useState(null);
	const [itemsSearched, setItemsSearched] = useState(null);
	const [itemsFiltered, setItemsFiltered] = useState(null);
	const [currentMaxProfit, setCurrentMaxProfit] = useState(null);
	const [loading, setLoading] = useState(false);
	const [initializing, setInitializing] = useState(true);
	const [totalRecords, setTotalRecords] = useState(0);
	const [checkedItems, setCheckedItems] = useState(null)
	const [selectedItem, setSelectedItem] = useState(null);
	const [currencySymbol, setCurrencySymbol] = useState(null);
	const [companyName, setCompanyName] = useState("");
	const [columns, setColumns] = useState([]);
	const [lastRepricing, setLastRepricing] = useState(null);

	const { t, tReady } = props;

	const [lazyParams, setLazyParams] = useState(null);
	const [filters, setFilters] = useState([]);

	const [canEditPrices, setCanEditPrices] = useState(false);
	const [canViewConfigPage, setCanViewConfigPage] = useState(false);

	const calculate = new Calculate();
	const dt = useRef(null);
	const dtSelected = useRef(null);
	const downloadPanel = useRef(null);

	/**
	 *  Load default session values
	 *  @returns local storage checked items, refreshed from backend
	 */
	const loadDefaults = async () => {
		let user = await authService.getUser();
		setCurrencySymbol(user.currencySymbol);
		setCompanyName(user.company);
		setCanEditPrices(user.isAdmin || user.isDeveloper || user.isGlobalAdmin || user.isDirector || user.isManager || user.isEditor);
		setCanViewConfigPage(user.isAdmin || user.isDeveloper || user.isGlobalAdmin || user.isDirector || user.isManager);

		let theLastRepricing = await pricingService.getLastRepricingDateTime();

		if (theLastRepricing != null) {
			setLastRepricing(theLastRepricing);
		} else {
			setLastRepricing(null);
		}
	};

	/**
	 *  Load checked items from local storage
	 *  @returns local storage checked items, refreshed from backend
	 */
	const loadCheckedItems = async () => {
		try {
			let sessionData = sessionStorage.getItem(pricingGridSessionStorageSelectedRowsKey);

			if (sessionData) {
				let selectedItems = JSON.parse(sessionData);

				// Refresh from backend (all checked items, not only actual page)
				var theLazyParams = { ...lazyParams };

				// Send "null" as count to retrieve everything
				theLazyParams.rows = null;

				var loadedFromBackend = await pricingService.getPricingItems(
					theLazyParams,
					filters,
					search,
					searchOutside,
					selectedItems);

				loadedFromBackend = loadedFromBackend.map((item) => {
					item.changedByUser = false;
					calculate.calculatePricingItem(item);
					item.originalRecommendedPrice = item.newPriceT1; // item.optimalPriceT1;
					item.originalUnitsPeriodT1 = item.unitsPeriodT1;
					return item;
				});

				return loadedFromBackend;
			} else {
				return null;
			}
		} catch (e) {
			return null;
		}
	};

	const exportToCSV = async (columns) => {
		var ret = await pricingService.exportToCSV(
			lazyParams,
			filters,
			search,
			searchOutside,
			columns,
			allItemsActiveButton ? null : (checkedItems ? checkedItems : [])
		);
		if (ret != null) {
			if (ret === "NoExportConfigurationAvailable") {
				messagesService.showWarning(t("PricingPage.WarningHeader"), t("PricingPage.NoExportConfigurationAvailable"));
			} else {
				messagesService.showWarning(t("PricingPage.ErrorHeader"), ret);
			}
		}
	};

	const [allItemsActiveButton, setAllItemsActiveButton] = useState(true)

	useEffect(() => {
		if (tReady) {
			var theFilters = [
				{
					field: "sku",
					header: t("PricingFilters.SKUFilterButton"),
					value: "",
					booleanFilter: false,
				},
				{
					field: "date",
					header: t("PricingFilters.DateFilterButton"),
					value: "",
					booleanFilter: false,
				},
				{
					field: "storeNumber",
					header: t("PricingFilters.StoreFilterButton"),
					value: "",
					booleanFilter: false,
				},
				{
					field: "description",
					header: t("PricingFilters.DescriptionFilterButton"),
					value: "",
					booleanFilter: false,
				},
				{
					field: "pdu",
					header: t("PricingFilters.PDUFilterButton"),
					value: "",
					booleanFilter: true,
					exclusiveWith: ["demandCurve"],
				},
				{
					field: "demandCurve",
					header: t("PricingFilters.DCUFilterButton"),
					value: "",
					booleanFilter: true,
					exclusiveWith: ["pdu"],
				},
				{
					field: "guardrailed",
					header: t("PricingFilters.GuardrailFilterButton"),
					value: "",
					booleanFilter: true,
					exclusiveWith: [],
				}
			];

			setFilters(theFilters);

			setLazyParams({
				first: 0,
				rows: rowsPerPage,
				page: 1,
				includeRelationships: true,
				sortField: "changeInProfit", // "projectedTotalProfit",
				sortOrder: -1,
			});
		}
	}, [tReady]); // eslint-disable-line react-hooks/exhaustive-deps

	useEffect(() => {
		loadDefaults();
	}, []);	// eslint-disable-line react-hooks/exhaustive-deps

	useEffect(() => {
		const lazyParametersChanged = async () => {
			if (currentMaxProfit === null) {
				loadLazyFilters({ filters: filters });
			}
			await loadLazyData();

			if (initializing) {
				// Load checked items from local storage in initialization time
				let theCheckedItems = await loadCheckedItems();
				setCheckedItems(theCheckedItems);
				setInitializing(false);
			}
		}

		if (lazyParams !== null) {
			lazyParametersChanged();
		}
	}, [lazyParams]) // eslint-disable-line react-hooks/exhaustive-deps

	const loadLazyData = async () => {
		setLoading(true);

		// Consume api when no only selected rows is enabled
		//if (allItemsActiveButton) {
		var data = await pricingService.getPricingItems(
			lazyParams,
			filters,
			search,
			searchOutside);

		data = data.map((item) => {
			item.changedByUser = false;
			calculate.calculatePricingItem(item);
			item.originalRecommendedPrice = item.newPriceT1; // item.optimalPriceT1;
			item.originalUnitsPeriodT1 = item.unitsPeriodT1;
			return item;
		});

		setItems(data);
		setItemsSearched(data);

		let _lazyParams = { ...lazyParams, ...{ rows: 100 } } // rows attribute limits the maximum amount of filter items we're going to retrieve
		let theFilterdata = await pricingService.getPricingFilterItems(
			_lazyParams,
			filters,
			search,
			searchOutside);
		setItemsFiltered(theFilterdata);

		setLoading(false);
	}

	const onPage = async (event, grid) => {
		let _lazyParams = { ...lazyParams, ...event }
		setLazyParams(_lazyParams);
		await savePriceChangesQueue(false);
	};

	const onSort = (event) => {
		let _lazyParams = { ...lazyParams, ...event }
		setLazyParams(_lazyParams);
	};

	const onFilter = (event) => {
		let _lazyParams = { ...lazyParams, ...event } // rows attribute required for keeping rows number on filter
		setLazyParams(_lazyParams);
	};

	const fillMaxProfit = async (filters) => {
		let theFilters = filters;
		let theMaxProfit = await pricingService.getPricingMaxProfit(
			theFilters,
			search,
			searchOutside,
			true);
		if (theMaxProfit === 0) {
			theMaxProfit = 1;
		} else {
			theMaxProfit = Math.abs(theMaxProfit);
		}
		setCurrentMaxProfit(theMaxProfit);
	};

	const loadLazyFilters = async (filters) => {
		//pricingService
		//    .filterPricing({ lazyEvent: JSON.stringify({ ...lazyParams, ...filters }) })
		//    .then((data) => {
		//        setItemsFiltered(data.customers)
		//    })

		//if (loadLazyFilterTimeout) {
		//    clearTimeout(loadLazyFilterTimeout)
		//}

		////imitate delay of a backend call
		//loadLazyFilterTimeout = setTimeout(() => {
		//    pricingService
		//        .filterFields({ lazyEvent: JSON.stringify({ ...lazyParams, ...filters }) })
		//        .then((data) => {
		//            setItemsFiltered(data.customers)
		//        })
		//}, Math.random() * 1000 + 250)

		//let _lazyParams = { ...lazyParams, ...{ rows: 100 } } // rows attribute required for keeping rows number on filter
		//let data = await pricingService.getPricingItems(
		//	_lazyParams,
		//	filters.filters,
		//	search,
		//	searchOutside);
	
		//setItemsFiltered(data);

		await fillMaxProfit(filters.filters);

		var totalRecords = await pricingService.getPricingItemsCount(
			filters.filters,
			search,
			searchOutside,
			null,
			true);
		setTotalRecords(totalRecords);
	};

	const tableRowClicked = (data) => {
		setSelectedItem(data);
	};

	useEffect(() => {
		async function doLoad() {
			if (Array.isArray(items)) {
				await loadLazyData();
			}
		}
		doLoad();
	}, [search, searchOutside]); // eslint-disable-line react-hooks/exhaustive-deps

	const onSearched = async (searchTerm, searchOutside, textChanged) => {
		// console.log("term: ", searchTerm, "outside: ", searchOutside);
		setSearchOutside(searchOutside)
		if (textChanged || searchTerm !== "") {
			setSearch(searchTerm)
		}
	};

	const switchAllItemsFilter = async () => {
		await savePriceChangesQueue(true);
		setAllItemsActiveButton(!allItemsActiveButton);
	};

	useEffect(() => {
		if (!initializing) {
			sessionStorage.clear(pricingGridSessionStorageSelectedRowsKey);
			sessionStorage.setItem(pricingGridSessionStorageSelectedRowsKey, JSON.stringify(checkedItems));
		}
	}, [checkedItems]); // eslint-disable-line react-hooks/exhaustive-deps

	const savePriceChangesQueue = async (reloadItems) => {
		if (checkedItems) {
			const changedPrices = checkedItems.filter((item) => {
				return (item.changedByUser === true);
			});

			if (changedPrices.length > 0) {
				var res = await pricingService.saveCurrentPrices(changedPrices);
				if (res == null) {
					messagesService.showSuccess(
						t("PricingPage.SuccessHeader"),
						t("PricingPage.PricingQueueSaved"));

					let updatedItems = [...checkedItems];
					updatedItems.forEach(i => {
						i.changedByUser = false;
						i.originalRecommendedPrice = i.newPriceT1; // i.optimalPriceT1;
						i.originalUnitsPeriodT1 = i.unitsPeriodT1;
					});

					setCheckedItems(updatedItems);

					if (reloadItems) {
						await loadLazyData();
					}

					// Clear selections
					// sessionStorage.clear(pricingGridSessionStorageSelectedRowsKey);
					// setCheckedItems(null);
				} else {
					messagesService.showError(
						t("PricingPage.ErrorHeader"),
						t("PricingPage.PricingQueueSaveError"));
				}
			}
		}
	};

	const visibleGridButtonChange = (e) => {
		switchAllItemsFilter();
	};

	if (lazyParams === null || filters === []) {
		return null;
	} else {
		return (
			<div className="grid">
				<div className="col-12 lg:col-3">
					<h3 className="text-primary">{companyName}</h3>
					<h1 className="text-primary">{t("PricingPage.Title")}</h1>
					<h4 className="text-primary text-subtitle" style={{ display: (lastRepricing == null) ? "none" : "" }}>{t('PricingPage.LastRepricing', { date: lastRepricing })}</h4>

					<FilterPricing
						itemsFiltered={itemsFiltered}
						onFilter={onFilter}
						onSearched={onSearched}
						setFilters={setFilters}
						filters={filters}
						loadLazyFilters={loadLazyFilters}
					/>
					<PricingTiles
						selectedItem={selectedItem}
						setLoading={setLoading}
						currencySymbol={currencySymbol}
					/>
				</div>
				<div className="col-12 lg:col-9 pl-lg-5">
					<div className="flex justify-content-end mb-4">

						{(canEditPrices === true) && (
							<SelectButton
								className="p-buttonset-pricing-grid" style={{ zIndex: 1 }}
								value={
									!allItemsActiveButton
										? `${t("PricingPage.PriceChanges")}`
										: `${t("PricingPage.AllItems")}`
								}
								// disabled={((selectRow && selectRow.length > 0) || (!activeButton)) ? false : true}
								options={
									[
										`${t("PricingPage.AllItems")}`,
										`${t("PricingPage.PriceChanges")}`,
									]
								}
								onChange={(e) => visibleGridButtonChange(e.originalEvent)} />
						)}

						<button
							onClick={(e) => { downloadPanel.current.toggle(e); }}
							type="button"
							className="btn btn-nineth btn-xs btn-rounded-full btn-export"
						>
						</button>
						<OverlayPanel
							ref={downloadPanel}
							id="download_panel"
							className="p-overlaypanel-wrapper p-overlaypanel-rounded-lg"
						>
							<button
								onClick={() => {
									downloadPanel.current.hide();
								}}
								className="modal-close"
							>
							</button>
							<div className="text-center mb-2">
							</div>
							<p className="text-primary text-center text-base mb-3">
								{t("PricingPage.DownloadPriceChanges")}
							</p>
							<div className="text-center">
								<button
									type="button"
									className="btn btn-secondary btn-rounded-full btn-xs mr-2"
									onClick={(e) => {
										downloadPanel.current.hide();
									}}
								>
									{t("PricingTable.CancelButton")}
								</button>
								<button
									type="button"
									className="btn btn-sixth btn-rounded-full btn-xs"
									onClick={(e) => {
										exportToCSV(columns);
									}}
								>
									{t("PricingPage.DownloadCSVButton")}
								</button>
							</div>
							<div className="text-center mt-4 text-sm" style={{ display: canViewConfigPage ? "" : "none" }}>
								<HashLink to="/config#pricingExportSettings">
									{t("PricingPage.ConfigureDownloadFile")}
								</HashLink>
							</div>
						</OverlayPanel>
					</div>
					<DataTablePricing
						checkedItems={checkedItems}
						setCheckedItems={setCheckedItems}
						products={itemsSearched}
						setProducts={setItemsSearched}
						searched={search}
						sessionStorageSelectedRowsKey={pricingGridSessionStorageSelectedRowsKey}
						sessionStorageColumnsSelectedKey={pricingGridSessionStorageColumnsSelectedKey}
						dt={dt}
						dtSelected={dtSelected}
						loading={loading}
						totalRecords={totalRecords}
						lazyParams={lazyParams}
						canEditPrices={canEditPrices}
						onPage={onPage}
						onSort={onSort}
						onColumnsChange={setColumns}
						rowsPerPage={rowsPerPage}
						setRowsPerPage={setRowsPerPage}
						onRowClicked={tableRowClicked}
						currencySymbol={currencySymbol}
						allItemsActiveButton={allItemsActiveButton}
						currentMaxProfit={currentMaxProfit}
						showTiles={true}
						allowCalculatedColumns={true}
						allowGuardrailsPopup={true}
					/>
				</div>
			</div>
		);
	}
};
export default withTranslation()(Pricing);
