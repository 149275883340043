// import ChartJs from 'chart.js/auto';
import React from "react";
import { Chart } from "primereact/chart";
//import { Tooltip } from "chart.js";
import { drawText, splitText /*, getTextHeight */ } from 'canvas-txt'

function MarimekkoChart(props) {
	const chartData = props?.data;
	const { showLabels, showTooltip } = props;
	const { xTitle, yTitle } = props;
	const { t } = props;
	const { rateOfChangeActivated, showFactor3Tooltip } = props;
	const { rawData } = props;

	const fontSizeBlockLabels = 14;
	const xAxisFontSize = 13;
	const yAxisFontSize = 13;
	const colorCategoryFontSize = 11;
	const colorLegendSubtitleFontSize = 15;
	const noDataFontSize = 18;

	const xLabelRotationRads = -Math.PI / 4;

	const isGreenColor = (color) => {
		// debugger;
		if (color && parseInt(color.substring(1, 3), 16) < 30 && parseInt(color.substring(3, 5), 16) > 70) {
			return true;
		} else {
			return false
		}
	}

	const createTooltipContainer = (chart, translateX, translateY) => {
		let tooltipEl = chart.canvas.parentNode.querySelector('div');

		if (!tooltipEl) {
			tooltipEl = document.createElement('div');
			tooltipEl.style.background = '#ffffff';
			tooltipEl.style.borderStyle = 'solid';
			tooltipEl.style.borderColor = '#014548';
			tooltipEl.style.borderWidth = '1px';
			tooltipEl.style.borderRadius = '10px';
			tooltipEl.style.color = '#014548';
			tooltipEl.style.opacity = 1;
			tooltipEl.style.pointerEvents = 'none';
			tooltipEl.style.position = 'absolute';
			// tooltipEl.style.transition = 'all .1s ease';
			tooltipEl.style.minWidth = '300px';

			const table = document.createElement('table');
			table.style.margin = '0px';
			table.style.width = '100%';
			table.style.fontColor = '#014548';
			table.style.fontSize = '14px';
			table.style.fontWeight = 'normal';

			tooltipEl.appendChild(table);
			chart.canvas.parentNode.appendChild(tooltipEl);
		}

		tooltipEl.style.transform = `translate(${translateX}%, ${translateY}%)`;

		return tooltipEl;
	}

	const externalTooltipHandler = (context) => {
		// Tooltip Element
		const { chart, tooltip } = context;
		var tooltipEl = createTooltipContainer(chart, -80, -15);

		// Hide if no tooltip
		if (tooltip.opacity === 0) {
			tooltipEl.style.opacity = 0;
			return;
		}

		// Do not show tooltip if there is no data for this stack
		var pointsSum = context.tooltip.dataPoints.reduce((m, x) => m + x.raw, 0);
		if (pointsSum === 0) {
			return;
		}

		// Set Text
		if (tooltip.body) {
			const titleLines = tooltip.title || [];
			const tableRoot = tooltipEl.querySelector('table');

			// Remove old children
			while (tableRoot.firstChild) {
				tableRoot.firstChild.remove();
			}

			const cellPadding = '5px 5px 5px 5px';

			const tableHead = document.createElement('thead');

			titleLines.forEach(title => {
				var regExp = /(.+?) \(([^)]+)\)/;
				var matches = regExp.exec(title);
				var text, perc;

				const tr = document.createElement('tr');
				tr.style.fontWeight = '700';
				tr.style.borderWidth = 0;

				const th = document.createElement('th');
				th.style.borderWidth = 0;
				th.style.padding = cellPadding;
				th.style.textAlign = "left";

				if (matches) {
					text = document.createTextNode(matches[1]);
					perc = document.createTextNode(matches[2]);

					const th2 = document.createElement('th');
					th2.style.borderWidth = 0;
					th2.style.padding = cellPadding;
					th2.style.textAlign = "right";

					th.appendChild(text);
					th2.appendChild(perc);
					tr.appendChild(th);
					tr.appendChild(th2);
				} else {
					var header = document.createTextNode(title);

					th.style.columnSpan = 2;
					th.appendChild(header);
					tr.appendChild(th);
				}

				tableHead.appendChild(tr);
			});

			tableRoot.appendChild(tableHead);

			const tableBody = document.createElement('tbody');

			// Metric for X axis variable
			context.tooltip.dataPoints.forEach(dataPoint => {
				const tr = document.createElement('tr');
				tr.style.borderWidth = 0;

				const td = document.createElement('td');
				td.style.borderWidth = 0;
				td.style.padding = cellPadding;
				td.style.textAlign = "left";
				td.style.whiteSpace = "nowrap";

				const td2 = document.createElement('td');
				td2.style.borderWidth = 0;
				td2.style.padding = cellPadding;
				td2.style.textAlign = "right";
				td2.style.whiteSpace = "nowrap";

				var text, value;
				text = document.createTextNode(dataPoint.dataset.firstFactorLabel);

				var dataValue = chart.data.firstFactorValues[dataPoint.dataIndex];

				var precission = 0;
				if ((dataValue - Math.trunc(dataValue)) > 0) {
					precission = 2;
				}

				if (dataPoint.dataset.metric1IsCurrency) {
					value = document.createTextNode(`${dataPoint.dataset.currencySymbol}${dataValue.toLocaleString(undefined, { maximumFractionDigits: precission })}`);
				} else {
					value = document.createTextNode(`${dataValue.toLocaleString(undefined, { maximumFractionDigits: precission })}`);
				}

				td.appendChild(text);
				td2.appendChild(value);
				tr.appendChild(td);
				tr.appendChild(td2);
				tableBody.appendChild(tr);
			});

			// First body legend: Factor 2 label only (if present)
			context.tooltip.dataPoints.forEach(dataPoint => {
				if (dataPoint.dataset.twoFactorsLabel != null) {
					const tr = document.createElement('tr');
					tr.style.borderWidth = 0;

					const td = document.createElement('td');
					td.style.borderWidth = 0;
					td.style.padding = cellPadding;
					td.style.textAlign = "left";
					td.style.whiteSpace = "nowrap";

					const td2 = document.createElement('td');
					td2.style.borderWidth = 0;
					td2.style.padding = cellPadding;
					td2.style.textAlign = "right";
					td2.style.whiteSpace = "nowrap";

					var text, value;
					text = document.createTextNode(dataPoint.dataset.twoFactorsName);	

					if (dataPoint.dataset.factor2Category) {
						value = document.createTextNode(dataPoint.dataset.factor2Category);
					}
					else if (dataPoint.dataset.factor2RangeText) {
						value = document.createTextNode(dataPoint.dataset.factor2RangeText);
					}
					else {
						var precission = 0;
						if ((dataPoint.raw - Math.trunc(dataPoint.raw)) > 0) {
							precission = 2;
						}

						if (dataPoint.dataset.metric2IsCurrency) {
							value = document.createTextNode(`${dataPoint.dataset.currencySymbol}${dataPoint.raw.toLocaleString(undefined, { maximumFractionDigits: precission })} (${dataPoint.formattedValue}%)`);
						} else {
							value = document.createTextNode(`${dataPoint.raw.toLocaleString(undefined, { maximumFractionDigits: precission })} (${dataPoint.formattedValue}%)`);
						}
					}

					td.appendChild(text);
					td2.appendChild(value);
					tr.appendChild(td);
					tr.appendChild(td2);
					tableBody.appendChild(tr);
				}
			});


			// Second body legend: Factor 2 range value ONLY when factor 3 is also present
			context.tooltip.dataPoints.forEach(dataPoint => {
				if ((dataPoint.dataset.twoFactorsLabel != null && dataPoint.dataset.thirdFactorName)){
					const tr = document.createElement('tr');
					tr.style.borderWidth = 0;

					const td = document.createElement('td');
					td.style.borderWidth = 0;
					td.style.padding = cellPadding;
					td.style.textAlign = "left";
					td.style.whiteSpace = "nowrap";

					const td2 = document.createElement('td');
					td2.style.borderWidth = 0;
					td2.style.padding = cellPadding;
					td2.style.textAlign = "right";
					td2.style.whiteSpace = "nowrap";

					var text, value;
					text = document.createTextNode(dataPoint.dataset.twoFactorsLabel);

					var rawValue = dataPoint.dataset.aggregatedByFactor2[dataPoint.dataIndex];

					// range = document.createTextNode(dataPoint.formattedValue + '%');
					var precission = 0;
					if ((rawValue - Math.trunc(rawValue)) > 0) {
						precission = 2;
					}

					if (dataPoint.dataset.metric2IsCurrency) {
						value = document.createTextNode(`${dataPoint.dataset.currencySymbol}${rawValue.toLocaleString(undefined, { maximumFractionDigits: precission })}`);
					} else {
						value = document.createTextNode(`${rawValue.toLocaleString(undefined, { maximumFractionDigits: precission })}`);
					}

					td.appendChild(text);
					td2.appendChild(value);
					tr.appendChild(td);
					tr.appendChild(td2);
					tableBody.appendChild(tr);
				}
			});

			// Third body legend: present for 3, 2 and 1 factors
			context.tooltip.dataPoints.forEach(dataPoint => {
				const tr = document.createElement('tr');
				tr.style.borderWidth = 0;

				const td = document.createElement('td');
				td.style.borderWidth = 0;
				td.style.padding = cellPadding;
				td.style.textAlign = "left";
				td.style.whiteSpace = "nowrap";

				const td2 = document.createElement('td');
				td2.style.borderWidth = 0;
				td2.style.padding = cellPadding;
				td2.style.textAlign = "right";
				td2.style.whiteSpace = "nowrap";

				var add = false;
				var text, range;

				if (dataPoint.dataset.tooltipFactor3AggregtedLegends) {
					if (showFactor3Tooltip) {
						text = document.createTextNode(dataPoint.dataset.thirdFactorName);
						range = document.createTextNode(dataPoint.dataset.label);
						add = true;
					} else {
						add = false;
					}
				} else if (dataPoint.dataset.twoFactorsLabel != null) {
					text = document.createTextNode(dataPoint.dataset.twoFactorsLabel);
					range = document.createTextNode(dataPoint.dataset.label);
					add = true;
				} else {
					// text = document.createTextNode(dataPoint.dataset.firstFactorLabel);
					// range = document.createTextNode((dataPoint.dataset.factor1IsCurrency ||  dataPoint.dataset.metric1IsCurrency) ? `${dataPoint.dataset.currencySymbol}${dataPoint.formattedValue}` : dataPoint.formattedValue);
				}

				if (add) {
					td.appendChild(text);
					td2.appendChild(range);
					tr.appendChild(td);
					tr.appendChild(td2);
					tableBody.appendChild(tr);
				}
			});

			tableRoot.appendChild(tableBody);

			// Footer (factor 3 aggregated totals by bar). Only if factor 3 present.
			const tableFooter = document.createElement('tfoot');

			context.tooltip.dataPoints.forEach(dataPoint => {
				if (showFactor3Tooltip) {
					if (dataPoint.dataset.tooltipFactor3AggregtedLegends && dataPoint.dataset.tooltipFactor3AggregtedLegends.length > 0) {
						const tr = document.createElement('tr');
						tr.style.borderWidth = 0;

						const td = document.createElement('td');
						td.style.borderWidth = 0;
						td.style.padding = cellPadding;
						td.style.textAlign = "left";
						td.style.whiteSpace = "nowrap";

						const td2 = document.createElement('td');
						td2.style.borderWidth = 0;
						td2.style.padding = cellPadding;
						td2.style.textAlign = "right";
						td2.style.whiteSpace = "nowrap";

						var text, value;
						text = document.createTextNode(dataPoint.dataset.tooltipFactor3AggregtedLegends[dataPoint.dataIndex]);
						if (dataPoint.dataset.tooltipFactor3AggregatedValues) {
							var rawValueFactor3 = dataPoint.dataset.tooltipFactor3AggregatedValues[dataPoint.dataIndex];
							var precission = 0;
							if ((rawValueFactor3 - Math.trunc(rawValueFactor3)) > 0) {
								precission = 2;
							}
							rawValueFactor3 = rawValueFactor3.toLocaleString(undefined, { maximumFractionDigits: precission });

							if (dataPoint.dataset.metric3IsCurrency) {
								value = document.createTextNode(`${dataPoint.dataset.currencySymbol}${rawValueFactor3} (${dataPoint.formattedValue}%)`);
							} else {
								value = document.createTextNode(rawValueFactor3);
							}

						}

						td.appendChild(text);
						td2.appendChild(value);
						tr.appendChild(td);
						tr.appendChild(td2);
						tableFooter.appendChild(tr);
					}
				}
			});

			// Rate of change special footer
			context.tooltip.dataPoints.forEach(dataPoint => {
				if (rateOfChangeActivated) {
					var text;

					text = document.createTextNode(rawData.rateOfChangeTooltipText);

					const tr = document.createElement('tr');
					tr.style.borderWidth = 0;

					const td = document.createElement('td');
					td.style.borderWidth = 0;
					td.style.padding = cellPadding;
					td.style.textAlign = "left";
					td.style.whiteSpace = "nowrap";

					const td2 = document.createElement('td');
					td2.style.borderWidth = 0;
					td2.style.padding = cellPadding;
					td2.style.textAlign = "right";
					td2.style.whiteSpace = "nowrap";

					var value = 0;

					if (dataPoint.dataset.metric1IsRateOfChange) {
						// Only 1 factor
						value = dataPoint.dataset.data[dataPoint.dataIndex] * 100;	// Show in %
					} else {
						value = dataPoint.dataset.aggregatedByFactor3RateOfChange[dataPoint.dataIndex] * 100;	// Show in %
					}
					value = document.createTextNode(`${value.toLocaleString(undefined, { maximumFractionDigits: 1 })}%`);

					td.appendChild(text);
					td2.appendChild(value);
					tr.appendChild(td);
					tr.appendChild(td2);
					tableFooter.appendChild(tr);
				}
			});

			tableRoot.appendChild(tableFooter);

			const { offsetLeft: positionX, offsetTop: positionY } = chart.canvas;

			// Display, position, and set styles for font
			tooltipEl.style.opacity = 1;
			tooltipEl.style.left = positionX + tooltip.caretX + 'px';
			tooltipEl.style.top = positionY + tooltip.caretY + 'px';
			tooltipEl.style.font = tooltip.options.bodyFont.string;
			tooltipEl.style.padding = tooltip.options.padding + 'px ' + tooltip.options.padding + 'px';

			if (context.tooltip.x < (chart.width / 2)) {
				// Make all bars from left side of the half of the chart to show the tooltip in right side of the position
				tooltipEl.style.transform = `translate(10%, 0)`;
			}
		}
	}

	const showHideLegendDataset = (legendItem, legend) => {
		var index = legendItem.datasetIndex;

		// Range 3 legend that user is showing/hiding
		var label = legendItem.text;

		// Chart instance
		let ci = legend.chart;

		// Show/Hide stack
		var doShow = false;
		if (!ci.getDatasetMeta(index).hidden) {
			ci.hide(index);
			doShow = false;
		} else {
			ci.show(index);
			doShow = true;
		}

		let j = 0;

		// Iterate through all datasets, cap at 200 for safety (it would be always less)
		while (j < 200) {
			const datasetMeta = ci.getDatasetMeta(j);

			if (datasetMeta.type == null) {
				break;
			} else {
				// We have a stack with the same label (and then same color too), so show/hide it 
				if ((datasetMeta.label) === label) {
					if (doShow) {
						ci.show(datasetMeta.index);
					} else {
						ci.hide(datasetMeta.index);
					}
				}
			}

			j++;
		}
	}

	//var defaultLegendClickHandler = ChartJs.defaults.plugins.legend.onClick;

	// const chartData = {
	//   datasets: props?.data,
	// };
	let basicOptions = {
		borderWidth: 1,
		borderSkipped: false,
		categoryPercentage: 1,
		barPercentage: 1,
		responsive: true,
		maintainAspectRatio: false,	// This is not working in ChartJS 4. Instead use a fixed height of 900px for canvas container (see below) and responsive option will handle the auto-width
		aspectRatio: 1.3,
		animation: false,
		devicePixelRatio: 4,
		layout: {
			padding: {
				bottom: 200,	// Determines the maximum height we will have to draw the x axis legends
				right: 15,
				left: 150		// HACK: Add a padding of 150 px to the chart to allow the first label to accomodate into the left padding space
			}
		},
		scales: {
			x: {
				display: false,
				grid: {
					drawOnChartArea: false
				},
				title: {
					display: true,
					text: ''
				},
				ticks: {
					display: false
				}
			},
			y: {
				beginAtZero: true,
				grid: {
					drawOnChartArea: false
				},
				title: {
					display: true,
					text: yTitle,
					color: '#014548',
					font: {
						size: yAxisFontSize,
						font: "'Lato'"
					}
				},
				ticks: {
					color: '#014548',
					font: {
						size: yAxisFontSize,
						font: "'Lato'"
					},
					callback: (label, index, labels) => {
						if (chartData.stacked === true) {
							return label + '%';
						} else {
							return label;
						}
					}
				}
			}
		},
		tooltips: {
			mode: 'label',
			position: 'cursor',
			intersect: true
		},
		plugins: {
			htmlLegend: {
				// ID of the container to put the legend in
				containerID: 'mekko-legend-container',
				display: (props.legend.customNumericDisplay === true)
			},
			legend: {
				...{
					display: true,
					position: 'top',
					onClick: (e, legendItem, legend) => {
						showHideLegendDataset(legendItem, legend);
					}
				}, ...props.legend
			},
			tooltip: {
				enabled: false,
				position: 'followCursor',
				backgroundColor: '#ffffff',
				titleColor: '#014548',
				bodyColor: '#014548',
				footerColor: '#014548',
				animation: false,
				external: (showTooltip === true) ? externalTooltipHandler : (ctx) => { return null }
			},
			stacked100: {
				enable: chartData.stacked ?? true
			}
		}
	};

	const drawEllipsisLabel = (ctx, d, i) => {
		let rawYValue = d._parsed[i].y;
		let yCoord = rawYValue < 0 ? d.data[i].base : d.data[i].y;

		drawText(ctx, '...', {
			x: d.data[i].x,
			y: yCoord,
			width: d.data[i].width,
			height: d.data[i].height,
			fontSize: fontSizeBlockLabels,
			fontWeight: 'bold',
			fontStyle: 'normal',
			font: "'Lato'",
			debug: false
		});
	}

	const drawLabelInContainerMethod1 = (ctx, d, i, text) => {
		// Workaround: draw the text with opacity = 0 to check the real rendering height for our box
		//			   later check how many lines fit the real box height because the canvas-txt library doesn't do a crop by height
		ctx.fillStyle = 'rgb(0,0,0,0)';

		const MIN_WIDTH = 15;
		const MIN_HEIGHT_FOR_ELLIPSIS = 10;

		if (d.data[i].width > MIN_WIDTH) {
			// HACK: When negative Y value is set, the d.data[i].y is not the real y point coordinate where the block has been rendered.
			//		 Use d.data[i].base instead
			let rawYValue = d._parsed[i].y;
			let yCoord = rawYValue < 0 ? d.data[i].base : d.data[i].y;

			// let lineHeight = getTextHeight({ ctx: ctx, text: text, style: `${fontSizeBlockLabels}px "Lato"` });

			// This doesn't have "fillStyle" set, so the render will be transparent and allow us to measure render
			const renderResult = drawText(ctx, text, {
				x: d.data[i].x,
				y: yCoord,
				width: d.data[i].width,
				height: d.data[i].height,
				fontSize: fontSizeBlockLabels,
				fontWeight: 'bold',
				fontStyle: 'normal',
				font: "'Lato'"
			});

			// Must translate here because the canvas is centered horizontally
			ctx.translate(-d.data[i].width / 2, 0);

			var theBackColor = d._dataset.backgroundColor;
			if (Array.isArray(d._dataset.backgroundColor)) {
				theBackColor = d._dataset.backgroundColor[i];
			}

			if (renderResult.height > d.data[i].height) {
				ctx.fillStyle = '#014548';

				if (isGreenColor(theBackColor)) {
					ctx.fillStyle = '#eeeeee';
				} else {
					ctx.fillStyle = '#014548';
				}

				if (d.data[i].height >= MIN_HEIGHT_FOR_ELLIPSIS) {
					// We have enough space for at least write a "..." ellipsis
					drawEllipsisLabel(ctx, d, i);
				}
			} else {

				ctx.fillStyle = '#014548';
				if (isGreenColor(theBackColor)) {
					ctx.fillStyle = '#eeeeee';
				} else {
					ctx.fillStyle = '#014548';
				}

				if (renderResult.height <= d.data[i].height) {
					// PRIC-456: Remove text in Mosaic chart columns when 'scrunched'
					// We have enough space to write the text, now we want to be sure it's not 'scrunched'
					let splitted = splitText({ ctx: ctx, text: text, justify: false, width: d.data[i].width });

					var numberOfSpaces = (text.split(" ").length - 1);
					var numberOfWords = numberOfSpaces + 1;

					if (numberOfWords < splitted.length) {
						// We have word wrap, write "..." instead
						drawEllipsisLabel(ctx, d, i);
					} else {
						// We have enough space to write the full text, let's do it!
						drawText(ctx, text, {
							x: d.data[i].x,
							y: yCoord,
							width: d.data[i].width,
							height: d.data[i].height,
							fontSize: fontSizeBlockLabels,
							fontWeight: 'bold',
							fontStyle: 'normal',
							font: "'Lato'",
							debug: false
						});
					}
				} else {
					// We don't have enough height to write all the text, let's write only "..."
					drawEllipsisLabel(ctx, d, i);

					/*
					
					// We don't have enough height to write all the text, try to draw at least a portion
					let lineHeight = getTextHeight({ ctx: ctx, text: text, style: `${fontSizeBlockLabels}px "Lato"` });
					let splitted = splitText({ ctx: ctx, text: text, justify: false, width: d.data[i].width });

					console.log(splitted);

					let linesAllowed = Math.floor(d.data[i].height / lineHeight);

					if (linesAllowed > 0) {
						let cropText = '';
						for (var idxLine = 0; idxLine < Math.min(linesAllowed, splitted.length); idxLine++) {
							cropText += splitted[idxLine];
						}

						drawText(ctx, cropText, {
							x: d.data[i].x,
							y: yCoord,
							width: d.data[i].width,
							height: d.data[i].height,
							fontSize: fontSizeBlockLabels,
							fontWeight: 'bold',
							fontStyle: 'normal',
							font: "'Lato'",
							debug: false,
							// lineHeight: 14
						});
					}
					*/
				}
			}
		}
	}

	/*
	const drawLabelInContainerMethod2 = (ctx, d, i, text) => {
		// let lineHeight = getTextHeight({ ctx: ctx, text: text, style: `${fontSizeBlockLabels}px "Lato"` });
		let lineHeight = 18;
		let splitted = splitText({ ctx: ctx, text: text, justify: false, width: d.data[i].width });

		let linesAllowed = Math.floor(d.data[i].height / lineHeight);

		if (linesAllowed > 0) {
			// Must translate here because the canvas is centered horizontally
			ctx.translate(-d.data[i].width / 2, 0);

			let cropText = '';
			for (var idxLine = 0; idxLine < Math.min(linesAllowed, splitted.length); idxLine++) {
				cropText += splitted[idxLine];
			}

			drawText(ctx, cropText, {
				x: d.data[i].x,
				y: d.data[i].y - d.data[i].base,
				width: d.data[i].width,
				height: d.data[i].height,
				fontSize: fontSizeBlockLabels,
				fontWeight: 'bold',
				fontStyle: 'normal',
				font: "'Lato'",
				debug: false,
				lineHeight: 14
			});
		}
	}
	*/

	const marimekkoDataLabels = {
		id: 'marimekkoDataLabels',
		afterDatasetDraw: (chart, args, pluginOptions) => {
			const { ctx /*, data, chartArea: { left, right, top, bottom }, scales: { x, y }*/ } = chart;
			if (showLabels === true) {

				let metaData = args.meta;

				if (metaData.hidden !== true) {
					for (let i = 0; i < metaData.data.length; i++) {
						if (metaData.data[i].height > 14) {
							// const text = data.datasets[index].label;
							var text = null;
							if (metaData._dataset.factor2Category) {
								text = metaData._dataset.factor2Category;
							} else if (metaData._dataset.factor2RangeText) {
								text = metaData._dataset.factor2RangeText;
							} else if (metaData._dataset.label) {
								text = metaData._dataset.label;
							}

							if (text != null) {
								// if (text == '$375 to $500') debugger;

								ctx.save();
								drawLabelInContainerMethod1(ctx, metaData, i, text);
								ctx.restore();
							}
						}
					}
				}
			}
		},
		beforeDraw: function (chart) {
			var ctx = chart.ctx;

			var xAxis = chart.scales.x;
			xAxis.options.ticks.fontColor = 'transparent'; // hide original tick
			// loop through ticks array

			var totalWidth = xAxis.width;
			var total = 0;
			var mekkoThickness = chart.data.mekkoThickness;
			if (mekkoThickness) {
				total = mekkoThickness.reduce((m, x) => m + x, 0);
			}

			var currentLeft = xAxis._startPixel;

			var yPadding_labels = 10;
			var yPadding_xAxisTitle = 100;

			if (xTitle) {
				var center = (xAxis.right - xAxis.left) / 2;
				//center += 35;

				ctx.save();
				ctx.textBaseline = 'middle';
				ctx.textAlign = 'center';
				ctx.font = `${xAxisFontSize}px "Lato"`;
				ctx.fillStyle = '#014548';
				ctx.fillText(xTitle, center, xAxis.bottom + yPadding_xAxisTitle);
				ctx.restore();
			}

			xAxis.ticks.forEach((tick, index) => {
				var perc = mekkoThickness[index] / total;

				var thisLabelWidth = totalWidth * perc;

				// var textWidth = ctx.measureText(tick.label).width;	
				var tickGap = 0;
				var xPos = currentLeft + tickGap + (thisLabelWidth / 2);
				var yPos = xAxis.bottom;

				currentLeft += totalWidth * perc;			

				// draw tick
				ctx.save();
				ctx.textBaseline = 'middle';
				ctx.textAlign = 'right';
				ctx.fillStyle = "#014548";
				ctx.font = `${xAxisFontSize}px "Lato"`;
				ctx.translate(xPos, yPos + yPadding_labels);
				ctx.rotate(xLabelRotationRads);
				ctx.fillText(tick.label, 0, 0);
				ctx.restore();
			});
		}
	}
	const getOrCreateLegendList = (chart, id) => {
		const legendContainer = document.getElementById(id);
		let listContainer = legendContainer.querySelector('ul');

		if (!listContainer) {
			listContainer = document.createElement('ul');
			// listContainer.style.display = 'flex';
			// listContainer.style.flexDirection = 'row';
			listContainer.style.display = 'inline-block';
			listContainer.style.margin = 0;
			listContainer.style.padding = 0;

			legendContainer.appendChild(listContainer);
		}

		return listContainer;
	};

	const createOrRemoveLegendTitle = (id, display, text) => {
		const legendContainer = document.getElementById(id);

		var titleSpan = legendContainer.querySelector('span');

		if (display === true) {
			if (!titleSpan) {
				titleSpan = document.createElement('span');
				legendContainer.appendChild(titleSpan);
			}

			titleSpan.style.color = '#014548';
			titleSpan.style.fontSize = `${colorLegendSubtitleFontSize}px`;
			titleSpan.style.display = 'inline-block';
			titleSpan.style.textAlign = 'center';
			titleSpan.style.width = '100%';
			titleSpan.innerText = text;
			titleSpan.style.float = 'left';
			titleSpan.style.clear = 'both';
			titleSpan.style.marginTop = '5px';
			titleSpan.style.marginBottom = '5px';
		} else if (titleSpan) {
			legendContainer.removeChild(titleSpan);
		}
	}

	const createColorLegendBox = (ul, text, fillStyle, strokeStyle, lineWidth, hidden, datasetIndex, onItemClick) => {
		const li = document.createElement('li');
		li.style.alignItems = 'center';
		li.style.cursor = 'pointer';
		// li.style.display = 'flex';
		// li.style.flexDirection = 'row';
		li.style.display = 'inline-block';
		li.style.marginLeft = '0px';

		if (onItemClick) {
			li.onclick = () => {
				onItemClick();
			};
		}

		// Color box
		const boxSpan = document.createElement('span');
		boxSpan.style.background = fillStyle;
		boxSpan.style.borderColor = strokeStyle;
		boxSpan.style.borderWidth = lineWidth + 'px';
		boxSpan.style.display = 'inline-block';
		boxSpan.style.height = '20px';
		boxSpan.style.marginRight = '0px';
		boxSpan.className = 'legend-block';
		boxSpan.title = text;
		boxSpan.style.float = 'left';
		boxSpan.style.clear = 'both';

		if (hidden) {
			const textHidden = document.createTextNode('X');
			boxSpan.appendChild(textHidden);
			boxSpan.style.float = 'left';
			boxSpan.style.paddingTop = '1px';

			if (datasetIndex < 5) {
				// First colors are green, put X in white
				boxSpan.style.color = '#ffffff';
			}
		}

		const textLabelSpan = document.createElement('span');
		textLabelSpan.style.display = 'inline-block';
		textLabelSpan.style.float = 'left';
		textLabelSpan.style.clear = 'both';
		textLabelSpan.style.fontSize = `${colorCategoryFontSize}px`;
		textLabelSpan.style.height = '20px';
		textLabelSpan.style.marginRight = '0px';
		textLabelSpan.style.textAlign = 'left';
		textLabelSpan.style.whiteSpace = 'nowrap';
		textLabelSpan.style.transform = 'translate(15px, -15px) rotate(-40deg)';
		textLabelSpan.style.color = '#014548';
		textLabelSpan.className = 'legend-block-text';
		textLabelSpan.style.textDecoration = hidden ? 'line-through' : '';
		const labelSpanText = document.createTextNode(text); // txt[txt.length - 1]);

		textLabelSpan.appendChild(labelSpanText);

		li.appendChild(textLabelSpan);
		li.appendChild(boxSpan);
		ul.appendChild(li);
	}

	const noDataCheckPlugin = {
		id: 'noDataCheckPlugin',
		afterDraw(chart, args, options) {
			const { datasets } = chart.data;
			
			if (datasets.length > 0) {
				let hasData = false;
			
				// Check bar widths
				if (chartData.mekkoThickness) {
					if (chartData.mekkoThickness.length > 0 && chartData.mekkoThickness.some(item => item !== 0)) {
						hasData = true;
					}
				}

				if (hasData) {
					hasData = false;

					for (let dataset of chartData.datasets) {
						if (dataset.data.length > 0 && dataset.data.some(item => item !== 0)) {
							hasData = true;
							break;
						}
					}
				}

				if (!hasData) {
					const { chartArea: { left, top, right, bottom }, ctx } = chart;
					const centerX = (left + right) / 2;
					const centerY = (top + bottom - 100) / 2;	// Do not use exact middle on Y axis and accomodate the "no data" label a little up to show it better in patterns page

					// Clear chart, we don't wany any garbage because we don't have data
					chart.clear();
					
					// We have no data, so hide Color Axis legend too!
					document.getElementById('mekko-legend-container').style.display = 'none';

					ctx.save();
					ctx.textAlign = 'center';
					ctx.textBaseline = 'middle';
					ctx.font = `bold ${noDataFontSize}px "Lato"`;
					ctx.fillStyle = '#014548';
					ctx.fillText(t('Patterns.ChartNoData'), centerX, centerY);
					ctx.restore();
				} else {					
					// If we come from a "no data" scenario, and now we have data, reset Color Axis legend visibility
					document.getElementById('mekko-legend-container').style.display = '';	
				}
			}
		}
	}

	const htmlLegendPlugin = {
		id: 'htmlLegend',
		afterUpdate(chart, args, options) {

			const ul = getOrCreateLegendList(chart, options.containerID);

			// Remove old legend items
			while (ul.firstChild) {
				ul.firstChild.remove();
			}

			// Add axis title
			createOrRemoveLegendTitle(options.containerID, options.display, chart.options.plugins.legend.title.text);

			if (options.display) {

				if (rateOfChangeActivated && rawData.rangesFactor3ForRateOfChange) {
					var currentFrom = `${(rawData.rangesFactor3ForRateOfChange[0] * 100).toLocaleString(undefined, { maximumFractionDigits: 1 })}%`;	// put in %

					for (var idxRange = 1; idxRange < rawData.rangesFactor3ForRateOfChange.length; idxRange++) {
						var currentTo = `${(rawData.rangesFactor3ForRateOfChange[idxRange] * 100).toLocaleString(undefined, { maximumFractionDigits: 1 })}%`;	// put in %
						let text = t("Patterns.PriceVsProfitMekko_ColorRangeLegend", { from: currentFrom, to: currentTo });
						let color = rawData.rateOfChangeColors[idxRange - 1];

						currentFrom = `${(rawData.rangesFactor3ForRateOfChange[idxRange] * 100).toLocaleString(undefined, { maximumFractionDigits: 1 })}%`;	// put in %

						createColorLegendBox(ul, text, color, null, 0, false, -1, null);
					}
				} else {
					// Reuse the built-in legendItems generator
					const items = chart.options.plugins.legend.labels.generateLabels(chart);

					items.forEach(item => {
						var show = true;

						if (chart.options.plugins.legend.labels.filter) {
							show = (chart.options.plugins.legend.labels.filter(item, chart.data) !== false);
						}

						if (show) {
							createColorLegendBox(ul, item.text, item.fillStyle, item.strokeStyle, item.lineWidth, item.hidden, item.datasetIndex,
								() => { showHideLegendDataset(item, { chart: chart }); });
						}
					});
				}

				if (ul.childNodes.length <= 20) {
					// Workaround: Hardcode width to 40px when colors are less or equal than 20, since they're visible for 1280x720 resolutions (the lower we support)
					ul.childNodes.forEach(li => {
						li.childNodes.forEach(span => {
							span.style.width = '40px';
						})						
					});
				}
			}
		}
	};

	return (
		<>
			{
				// margin left of -150px must match the padding left of the chart (see basic basicOptions.layout.padding above) so we keep this as empty space for x axis legends
			}
			<div id="mekko-legend-container" className="text-center print-background patterns-legend-container">
				
			</div>
			<div className="patterns-chart-container card flex justify-content-center"> 
				<div style={ { width: '100%' } }>
					<Chart
						type="mekko"
						data={chartData}
						options={{ ...basicOptions }}
						plugins={[marimekkoDataLabels, htmlLegendPlugin, noDataCheckPlugin]}
						style={{ position: "relative", width: "99%", height: "900px" }}
						/>
				</div>
			</div>
		</>
	);
}

export default MarimekkoChart;
