import React, { useState, useEffect, useRef } from 'react'
import { Checkbox } from 'primereact/checkbox';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import { withTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';
import SignaturePad from 'react-signature-pad-wrapper'
import { contextualConfirmDialog } from '../User/ContextualConfirmDialog'
import authService from '../api-authorization/AuthorizeService';
import userDataService from '../../Api/requests/users';
import messagesService from '../../Api/client-side/messages';

function UserAgreement(props) {
	const { t } = props;
	const [dialogVisible, setDialogVisible] = useState(false);
	const [terms, setTerms] = useState(false);
	const [privacy, setPrivacy] = useState(false);
	const [userAgreement, setUserAgreement] = useState(false);
	const [signatureInitialized, setSignatureInitialized] = useState(false);
	const [userName, setUserName] = useState('');
	const [user, setUser] = useState(null);

	const signature = useRef(-1);

	const authorization = async () => {
		let user = await authService.getUser();
		setUser(user);
		// console.log(user);
		if (user) {
			var agreed = await userDataService.agreementUpToDate(user.sub);

			if (!agreed) {
				setDialogVisible(true);
			}
		}
	}

	useEffect(() => {
		async function startup() {
			await authorization();
		}

		startup();
	}, []); // eslint-disable-line react-hooks/exhaustive-deps

	const showWarning = (e) => {
		// Do nothing for now
	}

	const dialogMaskClickEventHandler = (e) => {
		if (e.target.classList.contains('p-dialog-mask')) {
			showWarning(e);
		}
	}

	const dialogHideEventHandler = (e) => {
		showWarning(e);
	}

	const doAgree = async (e) => {
		if (signature.current.isEmpty()) {
			contextualConfirmDialog({
				message: t('UserAgreement.SignatureRequiredMessage'),
				header: "",
				hideRejectButton: true,
				acceptLabel: t('UserAgreement.OkButton'),
				accept: () => {
					// DO NOTHING
				},
				contextEvent: e,
				// targetElement: document.getElementById('signatureContainer')
			});
		} else {
			// Send agreement signature to backend
			try {
				var ret = await userDataService.aceptAgreement(user.sub, signature.current.toDataURL());
				if (ret === null) {
					messagesService.showSuccess(t('UserAgreement.UserAgreementSuccessTitle'), t('UserAgreement.UserAgreementSuccessMessage'));
					setDialogVisible(false);
				} else {
					console.log(ret);
					messagesService.showError(t('UserAgreement.UserAgreementErrorTitle'), ret);
				}
			} catch (e) {
				console.error(e);
				messagesService.showError(t('UserAgreement.UserAgreementErrorTitle'), t('UserAgreement.UserAgreementErrorMessage'));
			}
		}
	}

	useEffect(() => {
		if (signature.current !== -1 && !signatureInitialized) {
			setSignatureInitialized(true);
			// When any checkbox get checked, try to reset the signature panel for the first time
			signature.current.clear();
		}
	}, [terms, privacy, userAgreement]); // eslint-disable-line react-hooks/exhaustive-deps

	const isValid = () => {
		return (userAgreement
			&& privacy
			&& terms
			&& userName !== '');
	}

	return (<Dialog header="User Agreement" visible={dialogVisible} position="center" onMaskClick={dialogMaskClickEventHandler} onHide={dialogHideEventHandler} className="p-config-dialog" closable={false}>
			<p className="pb-3">
				I agree to the Pricimetrics: <NavLink to="/useragreement?standalone=true" target="_blank">SaaS User Agreement</NavLink>, the <NavLink to="/privacy?standalone=true" target="_blank">Privacy Policy</NavLink> and the <NavLink to="/terms?standalone=true" target="_blank">Terms and Conditions</NavLink>
			</p>

			<div className="pb-2 pt-2">
				<div className="inline-block pr-2" style={{ width: "180px" }}>
					<label htmlFor="checkUserAgreement">SaaS User Agreement</label>
				</div>
				<div className="inline-block">
					<Checkbox checked={userAgreement} inputId="checkUserAgreement" onChange={(e) => setUserAgreement(e.checked)} />
				</div>
			</div>

			<div className="pb-2 pt-2">
				<div className="inline-block pr-2" style={{ width: "180px" }}>
					<label htmlFor="checkTerms">Terms and Conditions</label>
				</div>
				<div className="inline-block">
					<Checkbox checked={terms}  inputId="checkTerms" onChange={(e) => setTerms(e.checked)} />
				</div>
			</div>

			<div className="pb-3 pt-2">
				<div className="inline-block pr-2" style={ { width:"180px"} }>
					<label htmlFor="checkPrivacy">Privacy Policy</label>
				</div>
				<div className="inline-block">
					<Checkbox checked={privacy} inputId="checkPrivacy" onChange={(e) => setPrivacy(e.checked)} />
				</div>
			</div>

			<div className="grid config-subsection-wrapper">
				<div className="col-12 field mb-0">
					<div className="w-full border-1 border-fourth overflow-hidden user-agreement-signature-field" id="signatureContainer">
						<SignaturePad ref={signature} options={{ minWidth: 0.5, maxWidth: 2.5, penColor: '#004547' }} redrawOnResize />
					</div>
				</div>
				<div className="col-12 field">
					<InputText type="text" value={userName} className="inputfield textbox" placeholder="Your Name" onChange={(e) => setUserName(e.target.value)} />
				</div>
			</div>

			<div className="flex justify-content-center mr-3 bottom-buttons">
				<button
					onClick={(e) => doAgree(e)}
					type="button"
					className="btn btn-tertiary btn-md btn-rounded-full btn-inline-block"
					disabled={!isValid()}>
					Ok
				</button>
			</div>
		</Dialog>);
}

export default withTranslation()(UserAgreement)
