import React, { useState } from "react";
import { TabView, TabPanel } from "primereact/tabview";
import ProfitOpportunity from "./ProfitOpportunity";

const ProfitTab = ({ data }) => {
	const [activeTab, setActiveTab] = useState(0);

	return (
		<>
			<div className="profitOpportunityChart">
				<TabView
					activeIndex={activeTab}
					onTabChange={(e) => setActiveTab(e.index)}
				>
					{data.map((tab, i) => {
						return (
							<TabPanel header={tab.name} key={i}>
								<ProfitOpportunity data={data[i]} />
							</TabPanel>
						);
					})}
				</TabView>
			</div>
		</>
	);
};
export default ProfitTab;
