import axios from 'axios'
import authService from '../../components/api-authorization/AuthorizeService';

export class CustomersService {
	_subscription = null;
	_token = null;

	constructor() {
		this._subscription = authService.subscribe(() => {
			this.authorize();
		});

		this._subscribeTokenExpiration = authService.subscribeTokenExpiration((newToken) => {
			if (newToken !== this._token) {
				// console.log('upgrading CUSTOMERS token', newToken);
				this._token = newToken;
			}
		});

		this.authorize();
	}

	authorize() {
		authService.isAuthenticated().then((isAuthenticated) => {
			// console.debug("ConfigurationService, isAuthenticated: ", isAuthenticated);
			if (isAuthenticated) {
				authService.getAccessToken().then((token) => {
					this._token = token;
					// console.debug("ConfigurationService, received token: ", token);
				});
			} else {
				this._token = null;
			}
		});

		// console.debug("ConfigurationService: ", this._token);
	}

	async existsCustomer(id, name) {
		let ret = null;

		if (this._token != null) {
			const config = {
				headers: { Authorization: `Bearer ${this._token}` }
			};

			try {
				let response = await axios.post('customers/exists', { id: id, name: name }, config);

				if (response.status === 200) {
					ret = response.data;
					// console.debug("createCustomer() OK");
				} else {
					let error = response.data;
					console.error(error);
					ret = error;
				}
			} catch (e) {
				let error = e.response.data;
				console.error(error);
				ret = error;
			}
		}

		return ret;
	}

	async checkCredentials(data) {
		let ret = null;

		if (this._token != null) {
			const config = {
				headers: { Authorization: `Bearer ${this._token}` }
			};

			try {
				let response = await axios.post('customers/checkCredentials', data, config);

				if (response.status === 200) {
					// console.debug("createCustomer() OK");
					if (response.data === 1) {
						ret = "Admin_Customers.CannotConnectToDatabase";
					} else if (response.data === 2) {
						ret = "Admin_Customers.DatabaseNotAvailable";
					}
				} else {
					let error = response.data;
					console.error(error);
					ret = error;
				}
			} catch (e) {
				let error = e.response.data;
				console.error(error);
				ret = error;
			}
		}

		return ret;
	}

	async createCustomer(data) {
		let ret = null;

		if (this._token != null) {
			const config = {
				headers: { Authorization: `Bearer ${this._token}` }
			};

			try {
				let response = await axios.put('customers', data, config);

				if (response.status === 200) {
					// console.debug("createCustomer() OK");
				} else {
					let error = response.data;
					console.error(error);
					ret = error;
				}
			} catch (e) {
				let error = e.response.data;
				console.error(error);
				ret = error;
			}
		}

		return ret;
	}

	async deleteCustomer(data) {
		var ret = null;

		if (this._token != null) {
			const config = {
				headers: {
					Authorization: `Bearer ${this._token}`,
					"X-XSRF-TOKEN": this.getAntiForgeryToken()
				}
			};

			let response = await axios.delete('customers/' + data.id, config);

			if (response.status === 200) {
				// console.debug("deleteCustomer() OK");
				ret = null;
			} else if (response.status === 400) {
				ret = 400;
				console.error(ret);
			} else {
				let error = response.data;
				ret = error;
				console.error(error);
			}
		}

		return ret;
	}

	async getCustomersItemsCount() {
		let ret = 0;

		if (this._token != null) {
			const config = {
				headers: { Authorization: `Bearer ${this._token}` }
			};

			const parms = {
				sortField: null,
				sortOrder: -1
			};

			let response = await axios.post('customers/count', parms, config);

			if (response.status === 200) {
				ret = response.data;
				// console.debug(`getUsersItemsCount() OK, res=${ret}`);
			} else {
				var error = response.data;
				console.error(error);
			}
		}

		return ret;
	}

	async getCustomersItems(parameters) {
		let ret = [];
		if (this._token != null) {
			const config = {
				headers: { Authorization: `Bearer ${this._token}` }
			};

			const parms = {
				from: parameters.first,
				count: parameters.rows,
				sortField: parameters.sortField,
				sortOrder: parameters.sortOrder ? parameters.sortOrder : 0
			};

			let response = await axios.post('customers', parms, config);

			if (response.status === 200) {
				ret = response.data;
				// console.debug(`getPricingItems() OK, res=${ret}`);
			} else {
				var error = response.data;
				console.error(error);
			}
		}

		return ret;
	}

	async getCountries() {
		let ret = null;

		if (this._token != null) {
			const config = {
				headers: { Authorization: `Bearer ${this._token}` }
			};

			try {
				let response = await axios.get('customers/countries', config);

				if (response.status === 200) {
					ret = response.data;
					// console.debug("createCustomer() OK");
				} else {
					let error = response.data;
					console.error(error);
					ret = error;
				}
			} catch (e) {
				let error = e.response.data;
				console.error(error);
				ret = error;
			}
		}

		return ret;
	}

	getAntiForgeryToken() {
		const xsrfToken = document.cookie
			.split("; ")
			.find(row => row.startsWith("XSRF-TOKEN="))
			.split("=")[1];

		return xsrfToken;
	}

	static get instance() { return customersService; }
}

const customersService = new CustomersService();

export default customersService;