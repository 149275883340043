import React, { useEffect, useState } from 'react'
import ViewMessages from '../components/UserMessages/ViewMessages'
import ConfigActionBtn from '../components/Config/ConfigActionBtn'
import { contextualConfirmDialog } from '../components/User/ContextualConfirmDialog'
import authService from '../components/api-authorization/AuthorizeService';
import realTimeMessagingServices from '../Api/requests/realtimemessaging'
import { withTranslation } from 'react-i18next'

function MessagesPage(props) {
	const { t } = props;
	const [companyName, setCompanyName] = useState("");
	const [messagesAvailable, setMessagesAvailable] = useState(false);

	useEffect(() => {
		const setUserData = async () => {
			let user = await authService.getUser();
			setCompanyName(user.company);
		}

		setUserData();
	}, []); // eslint-disable-line react-hooks/exhaustive-deps

	const deleteAll = (event) => {
		contextualConfirmDialog({
			message: t('UserMessages.ConfirmDeleteAllMessage'),
			header: t('UserMessages.ConfirmDeleteAllHeader'),
			acceptLabel: t('UserMessages.ConfirmDialogYes'),
			rejectLabel: t('UserMessages.ConfirmDialogNo'),
			icon: 'pi pi-contextDialog-warning',
			accept: async () => {
				await realTimeMessagingServices.deleteAllMessages();
			},
			contextEvent: event
		});
	}

	return (
		<div className="grid">
			<div className="col-12 lg:col-6">
				<h3 className="text-primary">{companyName}</h3>
				<h1 className="text-primary">{t('UserMessages.Header')}</h1>
			</div>
			<div className="col-12 lg:col-6 pl-lg-5">
				<div className="flex justify-content-end">
					<div className="mr-2"><ConfigActionBtn onClick={(e) => deleteAll(e)} buttonText={t('UserMessages.DeleteAll')} disabled={!messagesAvailable} /></div>
				</div>
			</div>
			<div className="col-12 lg:col-12">
				<ViewMessages
					OnMessagesLoaded={(available) => {
						setMessagesAvailable(available);
					}}
				></ViewMessages>
			</div>
		</div>
	)
}

export default withTranslation()(MessagesPage)
