import Chart from 'chart.js/auto';
import { Tooltip } from 'chart.js';
// import { valueOrDefault, isNullOrUndef } from 'chart.js/helpers'
// import { BarController, DatasetController } from 'chart.js'
import { isNullOrUndef } from 'chart.js/helpers'
import { BarController } from 'chart.js'
import annotationPlugin from 'chartjs-plugin-annotation';
import chartPluginStacked100 from 'chartjs-plugin-stacked100';

Chart.register(annotationPlugin);
Chart.register(chartPluginStacked100);

Tooltip.positioners.followCursor = function (elements, eventPosition) {
    // var tooltip = this;
    // console.debug(elements);

    eventPosition.x -= 20;
    eventPosition.y -= 80;
    return eventPosition;
};

class Mekko extends BarController {
    computeFlexCategoryTraits(index, ruler, options, stackCount) {
        const pixels = ruler.pixels;
        const curr = pixels[index];
        let prev = index > 0 ? pixels[index - 1] : null;
        let next = index < pixels.length - 1 ? pixels[index + 1] : null;
        const percent = options.categoryPercentage;
        if (prev === null) {
            prev = curr - (next === null ? ruler.end - ruler.start : next - curr);
        }
        if (next === null) {
            next = curr + curr - prev;
        }
        const start = curr - (curr - Math.min(prev, next)) / 2 * percent;
        const size = Math.abs(next - prev) / 2 * percent;
        return {
            chunk: size / stackCount,
            ratio: options.barPercentage,
            start
        };
    }

    computeFitCategoryTraits(index, ruler, options, stackCount) {
        const thickness = options.barThickness;
        let size, ratio;
        if (isNullOrUndef(thickness)) {
            size = ruler.min * options.categoryPercentage;
            ratio = options.barPercentage;
        } else {
            size = thickness * stackCount;
            ratio = 1;
        }
        return {
            chunk: size / stackCount,
            ratio,
            start: ruler.pixels[index] - (size / 2)
        };
    }

    /**
     * @private
     */
    _calculateBarIndexPixels(index, ruler) {
        const options = this.options;
        var datasetIndex = this.index;
        const skipNull = options.skipNull;

        const stackCount = skipNull ? this._getStackCount(index) : ruler.stackCount;

        const range = options.barThickness === 'flex'
            ? this.computeFlexCategoryTraits(index, ruler, options, stackCount)
            : this.computeFitCategoryTraits(index, ruler, options, stackCount);

        const barSize = range.chunk;

        const stackIndex = this._getStackIndex(datasetIndex, this.getMeta().stack);

        let center = range.start + range.chunk * stackIndex + range.chunk / 2;
        let size = range.chunk * range.ratio;

        let start = range.start;

        let mekkoThickness = this.chart.data.mekkoThickness;

        if (mekkoThickness) {
            var total = mekkoThickness.reduce((m, x) => m + x, 0);
            var perc = mekkoThickness[index] / total;

            //the max weight should be one
            const meta = this._cachedMeta;
            size = barSize * (meta.data.length) * perc;
            const lastModel = index > 0 ? meta.data[index - 1] : null;

            //last column takes the full bar
            if (lastModel) {
                //start could be last center plus half of last column width
                start = lastModel.x + lastModel.width / 2;
            }
            center = start + size * stackIndex + size / 2;
        }

        return {
            size: size,
            base: center - size / 2,
            head: center + size / 2,
            center: center
        };
    }
};

Mekko.id = 'mekko';
Mekko.defaults = BarController.defaults;

Chart.register(Mekko);