import React, { Component } from 'react';
import Header from '../components/Header'
import ContentFooter from '../components/ContentFooter'
import { Toast } from 'primereact/toast';
import RealTimeMessages from '../components/UserMessages/RealTimeMessaging'
import messagesService from '../Api/client-side/messages';
import UserAgreement from '../components/Legal/UserAgreement';

// We must add ALL the supported languages we want to use for Moment.js here
import 'moment/locale/es';

export class Layout extends Component {
	static displayName = Layout.name;

	static myToast = null;

	constructor(props) {
		super(props);

		this.state = {
			showChild: true
		}

		this.reloadLayout = this.reloadLayout.bind(this);
	}

	componentDidMount() {
	}

	componentWillUnmount() {
	}

	reloadLayout() {
		// Use the "showChild" state to "hack" the render, so we force the whole main contents to be refreshed.
		this.setState({
			showChild: false
		})

		setTimeout(() => {
			this.setState({
				showChild: true
			})
		}, 100);
	}

	render() {
		let search = window.location.search;
		let params = new URLSearchParams(search);
		let standalone = params.get('standalone');

		if (standalone === "true") {
			return (<main className="p-no-header">
				<Toast ref={(t) => {
					this.myToast = t;
					if (this.myToast != null) {
						messagesService.attachToast(this.myToast);
					}
				}} position="top-right" />
				{this.props.children}
			</main>);
		} else {
			return (<>
				<Header reloadLayout={this.reloadLayout} />

				{(this.state.showChild) ?
					<main>
						{this.props.children}
						<ContentFooter />
					</main>
					: null
				}

				<Toast ref={(t) => {
					this.myToast = t;
					if (this.myToast != null) {
						messagesService.attachToast(this.myToast);
					}
				}} position="top-right" />
				<RealTimeMessages />
				<UserAgreement />
			</>);
		}
	}
}