import React, {useEffect, useState } from 'react'
import Collapse from '../Collapse'
// import PotentialProfitChart from './Charts/PotentialProfitChart'
// import ContributionChart from './Charts/ContributionChart'
import DemandCurve from './Charts/DemandCurve'
import NetProfitCurve from './Charts/NetProfitCurve'
import SKUDetails from './Tiles/SKUDetails'
import UnitPriceHistory from './Charts/UnitPriceHistory'
import { withTranslation } from 'react-i18next'
import pricingService from '../../Api/requests/pricing'
import { classNames } from 'primereact/utils';

function PricingTiles(props) {
	const { selectedItem, currencySymbol, t, tReady, setLoading } = props

	const [tilesSet, setTilesSet] = useState([])
	const [productInfo, setProductInfo] = useState(null)

	const skuDetail = (selectedItem) => {
		return (<SKUDetails selectedItem={selectedItem} currencySymbol={currencySymbol}></SKUDetails>);
	}

	const demandCurve = (selectedItem) => {
		let showModelOverride = (selectedItem && selectedItem.invalidSlope === true);

		return (
			<>
				<DemandCurve selectedItem={selectedItem} ></DemandCurve>
				<span
					hidden={!showModelOverride}
					className={classNames(showModelOverride ? "inline-block" : "", "pt-4", "text-primary", "text-sm")}>
					{t('UnitPriceHistoryTile.DemandCurveModelOverrideActivated')}
				</span>
			</>
		);
	}

	const profitCurve = (selectedItem) => {
		let showModelOverride = (selectedItem && selectedItem.invalidSlope === true);

		return (
			<>
				<NetProfitCurve selectedItem={selectedItem} ></NetProfitCurve>
				<span
					hidden={!showModelOverride}
					className={classNames(showModelOverride ? "inline-block" : "", "pt-4", "text-primary", "text-sm")}>
					{t('UnitPriceHistoryTile.DemandCurveModelOverrideActivated')}
				</span>
			</>
		);
	}

	const unitPriceHistory = (selectedItem, productInfo) => {
		return (<UnitPriceHistory selectedItem={selectedItem} productInfo={productInfo}></UnitPriceHistory>);
	}

	useEffect(() => {
		if (tReady) {
			var tiles = [
				//{
				//	"title": "Potential Profit Realized",
				//	"isActive": "",
				//	"template": <PotentialProfitChart></PotentialProfitChart>,
				//},
				//{
				//	"title": "Contribution to Gain by Sku",
				//	"isActive": "",
				//	"template": <ContributionChart></ContributionChart>,
				//},
				//{
				//	"title": "Profit Opportunity by Category",
				//	"isActive": "",
				//	"template": <p className="text-md text-primary">lorem ipsum dolor sit amet</p>,
				//},
				//{
				//	"title": "Alerts",
				//	"isActive": "active",
				//	"template": <p className="text-md text-primary">lorem ipsum dolor sit amet</p>,
				//},
				{
					"title": t('SKUDetailsTile.Title'),
					"isActive": "",
					"template": skuDetail,
					closeOnClick: true
				},
				{
					"title": t('DemandCurveTile.Title'),
					"isActive": "",
					"template": demandCurve,
					closeOnClick: false
				},
				{
					"title": t('PriceVsNetProfitTile.Title'),
					"isActive": "",
					"template": profitCurve,
					closeOnClick: false
				},
				{
					"title": t('UnitPriceHistoryTile.Title'),
					"isActive": "",
					"template": unitPriceHistory,
					closeOnClick: false
				}
			];
			setTilesSet(tiles);
		}
	}, [tReady]); // eslint-disable-line react-hooks/exhaustive-deps

	const loadProductInfo = async () => {
		let pInfo = await pricingService.getProductInfo(selectedItem.observationId, false);
		setProductInfo(pInfo);
	};

	useEffect(() => {
		if (selectedItem !== null) {
			setLoading(true);
			loadProductInfo();
			setLoading(false);
		}
	}, [selectedItem]);  // eslint-disable-line react-hooks/exhaustive-deps

	const onToogled = (title, newState) => {
		let updatedTilesSet = [...tilesSet]
		let index = updatedTilesSet.findIndex((item) => item.title === title)
		updatedTilesSet[index].isActive = newState
		setTilesSet(updatedTilesSet)
	}

	return (
		<div className="filter-wrapper">
			{/* Tiles */}
			<h4 className="text-primary text-md mb-3">{t('SKUDetailsTile.TilesTitle')}</h4>
			{
				tilesSet
					.sort((a, b) => {
						let stringA = a.isActive.toLowerCase(),
							stringB = b.isActive.toLowerCase();

						if (stringA === stringB) {
							let titleA = a.title.toLowerCase(),
								titleB = b.title.toLowerCase();

							if (titleA < titleB) {
								return -1;
							}
							if (titleA > titleB) {
								return 1;
							}
						}

						if (stringA > stringB) {
							return -1;
						}
						if (stringA < stringB) {
							return 1;
						}

						return 0;
					})
					.map(item => (
						<Collapse
							key={item.title}
							className="collapse-fiveth collapse-rounded-md"
							title={item.title}
							collapse={item.isActive}
							onToogled={onToogled}
							closeOnClick={item.closeOnClick}
						>
							{item.template(selectedItem, productInfo)}
						</Collapse>
					)
					)
			}
			{/* end Tiles */}
		</div>
	)
}

export default withTranslation()(PricingTiles)
