import React, { useState } from "react";
import { withTranslation } from "react-i18next";
import { TabView, TabPanel } from "primereact/tabview";

const StateOfBusiness = (props) => {
    const { data, currencySymbol, t } = props

    const [activeTab, setActiveTab] = useState(0);

    const getNumberFormatForValues = (value) => {
        const minValueToApplyMultipliers = 100000;

        if (value < minValueToApplyMultipliers) {
            if (value < 1000) {
                return value.toFixed(2);
            } else {
                if ((value - Math.trunc(value)) > 0.1) {
                    return value.toFixed(2);
                } else {
                    return value.toFixed(0);
                }
            }
        } else {
            var newValue = (value / 1e6);
            var multiplier = 'M';

            if (value < 1e6) {
                newValue = value / 1e3;
                multiplier = 'K';
            }

            if ((newValue - Math.trunc(newValue)) > 0.1) {
                if (newValue < 1000) {
                    // Lower than 1000k/1000m, use 2 decimal points
                    return `${newValue.toFixed(3)}${multiplier}`;
                } else {
                    // Greater than 1000k/1000m, use 1 decimal point
                    return `${newValue.toFixed(2)}${multiplier}`;
                }
            } else {
                return `${newValue.toFixed(1)}${multiplier}`;
            }
        }
    }

  return (
    (data !== null) ? (<>
      <div className="StateOfBusinessCard">
        <TabView
          activeIndex={activeTab}
          onTabChange={(e) => setActiveTab(e.index)}>
            <TabPanel header={t('Dashboard_StateOfBusiness.StateOfBusiness_Tab')} key={0}>
                <table>
                    <thead>
                        <tr>
                            <th>&nbsp;</th>
                            <th>{t('Dashboard_StateOfBusiness.StateOfBusiness_WeekBefore')} {(data.interpolated[0] && (<>*</>))}</th>
                            <th>{t('Dashboard_StateOfBusiness.StateOfBusiness_LastWeek')} {(data.interpolated[1] && (<>*</>))}</th>
                            <th>{t('Dashboard_StateOfBusiness.StateOfBusiness_Predicted')} {(data.interpolated[2] && (<>*</>))}</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>{t('Dashboard_StateOfBusiness.StateOfBusiness_AvgPrice')}</td>
                            <td>{currencySymbol} {getNumberFormatForValues(data.avgPrice[0])}</td>
                            <td>{currencySymbol} {getNumberFormatForValues(data.avgPrice[1])}</td>
                            <td>{currencySymbol} {getNumberFormatForValues(data.avgPrice[2])}</td>
                        </tr>
                        <tr>
                            <td>{t('Dashboard_StateOfBusiness.StateOfBusiness_Units')}</td>
                            <td>{getNumberFormatForValues(data.units[0])}</td>
                            <td>{getNumberFormatForValues(data.units[1])}</td>
                            <td>{getNumberFormatForValues(data.units[2])}</td>
                        </tr>
                        <tr>
                            <td>{t('Dashboard_StateOfBusiness.StateOfBusiness_Revenue')}</td>
                            <td>{currencySymbol} {getNumberFormatForValues(data.revenue[0])}</td>
                            <td>{currencySymbol} {getNumberFormatForValues(data.revenue[1])}</td>
                            <td>{currencySymbol} {getNumberFormatForValues(data.revenue[2])}</td>
                        </tr>
                        <tr>
                            <td>{t('Dashboard_StateOfBusiness.StateOfBusiness_Profit')}</td>
                            <td>{currencySymbol} {getNumberFormatForValues(data.profit[0])}</td>
                            <td>{currencySymbol} {getNumberFormatForValues(data.profit[1])}</td>
                            <td>{currencySymbol} {getNumberFormatForValues(data.profit[2])}</td>
                        </tr>
                    </tbody>
                    <tfoot hidden={!(data.interpolated[0] || data.interpolated[1] || data.interpolated[2]) } >
                        <tr>
                            <th>&nbsp;</th>
                            <th colSpan={3} >{t('Dashboard_StateOfBusiness.StateOfBusiness_InterpolatedLabel')}</th>
                        </tr>
                    </tfoot>
                </table>
            </TabPanel>
        </TabView>
      </div>
    </>) : <></>
  );
};

export default withTranslation()(StateOfBusiness);
