import React, { useState } from "react";
import { TabView, TabPanel } from "primereact/tabview";
import BarLine from "./BarLine";

const MultiTab = ({ data }) => {
  const [activeTab, setActiveTab] = useState(0);
  return (
    <>
      <div className="multiChart">
        <TabView
          activeIndex={activeTab}
          onTabChange={(e) => setActiveTab(e.index)}
        >
          {data.map((tab, i) => {
            return (
              <TabPanel header={tab.name} key={i}>
                <BarLine data={data[i]} />
              </TabPanel>
            );
          })}
        </TabView>
      </div>
    </>
  );
};

export default MultiTab;
