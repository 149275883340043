import React, { useState, useEffect, useRef } from 'react'
import ConfigActionBtn from '../Config/ConfigActionBtn'
import { InputNumber } from 'primereact/inputnumber';
import { Dropdown } from 'primereact/dropdown';
import { ToggleButton } from 'primereact/togglebutton';
import { confirmDialog } from 'primereact/confirmdialog';
import { withTranslation } from 'react-i18next';
import { MultiSelect } from 'primereact/multiselect';
import configurationService from '../../Api/requests/config';
import messagesService from '../../Api/client-side/messages';
import { Button } from 'primereact/button';

function GeneralConfig(props) {
	const [favoredLastDigit, setFavoredLastDigit] = useState(0.99);
	const [minWeeksForChanges, setMinWeeksForChanges] = useState(4);
	const [maxChangePricePerc, setMaxChangePricePerc] = useState(100);
	const [maxRecalcsPerWeek, setMaxRecalcsPerWeek] = useState(5);
	const [maxScheduledRecalculations, setMaxScheduledRecalculations] = useState(4);
	const [defaultDaysBetweenRepricing, setDefaultDaysBetweenRepricing] = useState(7);
	const [pduSpreadForDelayedPricing, setPduSpreadForDelayedPricing] = useState(3);
	const [demandCurveHistoryDisplay, setDemandCurveHistoryDisplay] = useState(26);
	const [dataChanged, setDataChanged] = useState(false);
	const [cpiCategory, setCPICategory] = useState(-1);
	const [cpiCategories, setCPICategories] = useState([]);
	const [dashboardSections, setDashboardSections] = useState([]);
	const [selectedDashboardSections, setSelectedDashboardSections] = useState([]);
	const [forceFirstDemandCurve, setForceFirstDemandCurve] = useState(false);

	const mounted = useRef(false);
	const defaultsRef = useRef(null);

	const { t } = props;
	const { setLoading } = props;
	const { allowed } = props;
	const { showHelp } = props;

	const readDefaults = async () => {
		mounted.current = false;

		let defaults = await configurationService.getGeneralConfiguration();

		if (defaults != null) {
			if (defaults.favoredLastDigit !== null) {
				setFavoredLastDigit(defaults.favoredLastDigit);
			}

			if (defaults.minWeeksForChanges !== null) {
				setMinWeeksForChanges(defaults.minWeeksForChanges);
			}

			let categories = await configurationService.getCPIcategories();
			categories.splice(0, 0, { code: "", name: t('ConfigurationDialog.NoCPI'), id: -1 });
			setCPICategories(categories);

			setDashboardSections(
				[
					{
						label: t('ConfigurationDialog.DummiesSpotlight'),
						value: "dummiesSpotlight"
					},
					{
						label: t('ConfigurationDialog.LowerGridCharts'),
						value: "lowerGridCharts"
					}
				]
			);

			setMaxChangePricePerc(defaults.maxPriceChangePercentage);
			setMaxRecalcsPerWeek(defaults.maxRecalculationsPerWeek);
			setDefaultDaysBetweenRepricing(defaults.defaultDaysBetweenRepricings);
			setPduSpreadForDelayedPricing(defaults.pduSpreadDelayedPerPeriod);
			setMaxScheduledRecalculations(defaults.maxScheduledRecalculations)
			setDemandCurveHistoryDisplay(defaults.demandCurveHistoryDisplay);
			setCPICategory(defaults.cpiCategory != null ? defaults.cpiCategory : -1);
			setSelectedDashboardSections(defaults.selectedDashboardSections != null ? defaults.selectedDashboardSections : []);
			setForceFirstDemandCurve(defaults.forceFirstDemandCurve);

			defaultsRef.current = defaults
			mounted.current = true;
			setDataChanged(false);

		}
	}

	useEffect(() => {
		readDefaults();
	}, []);  // eslint-disable-line react-hooks/exhaustive-deps

	useEffect(() => {
		if (mounted.current) {
			let readDefaults = defaultsRef.current;

			if (readDefaults.favoredLastDigit !== favoredLastDigit ||
				readDefaults.minWeeksForChanges !== minWeeksForChanges ||
				readDefaults.maxPriceChangePercentage !== maxChangePricePerc ||
				readDefaults.maxRecalculationsPerWeek !== maxRecalcsPerWeek ||
				readDefaults.defaultDaysBetweenRepricings !== defaultDaysBetweenRepricing ||
				readDefaults.pduSpreadDelayedPerPeriod !== pduSpreadForDelayedPricing ||
				readDefaults.maxScheduledRecalculations !== maxScheduledRecalculations ||
				readDefaults.demandCurveHistoryDisplay !== demandCurveHistoryDisplay ||
				(readDefaults.cpiCategory === null ? -1 : readDefaults.cpiCategory) !== cpiCategory ||
				JSON.stringify(readDefaults.selectedDashboardSections) !== JSON.stringify(selectedDashboardSections) ||
				readDefaults.forceFirstDemandCurve !== forceFirstDemandCurve) {

				setDataChanged(true);
			} else {
				setDataChanged(false);
			}
		}
	}, [favoredLastDigit, minWeeksForChanges, maxChangePricePerc, maxRecalcsPerWeek, defaultDaysBetweenRepricing, pduSpreadForDelayedPricing, maxScheduledRecalculations,
		demandCurveHistoryDisplay, cpiCategory, selectedDashboardSections, forceFirstDemandCurve]);

	const saveConfig = async () => {
		setLoading(true);

		let data = {
			favoredLastDigit: favoredLastDigit,
			minWeeksForChanges: minWeeksForChanges,
			maxPriceChangePercentage: maxChangePricePerc,
			maxRecalculationsPerWeek: maxRecalcsPerWeek,
			defaultDaysBetweenRepricings: defaultDaysBetweenRepricing,
			pduSpreadDelayedPerPeriod: pduSpreadForDelayedPricing,
			maxScheduledRecalculations: maxScheduledRecalculations,
			demandCurveHistoryDisplay: demandCurveHistoryDisplay,
			CPICategory: cpiCategory,
			selectedDashboardSections: selectedDashboardSections,
			forceFirstDemandCurve: forceFirstDemandCurve
		};

		let res = await configurationService.saveGeneralConfiguration(data);

		if (res === null) {
			messagesService.showSuccess(t('ConfigurationDialog.ConfigurationRefreshedTitle'), t('ConfigurationDialog.ConfigurationRefreshedMessage'));

			await readDefaults();
		} else {
			messagesService.showError(t('ConfigurationDialog.ConfigurationRefreshedErrorTitle'), t('ConfigurationDialog.ConfigurationRefreshedErrorMessage'));
		}

		setLoading(false);
	}

	const confirmSave = async () => {
		confirmDialog({
			message: t('ConfigurationDialog.ConfirmOverwriteMessage'),
			header: t('ConfigurationDialog.ConfirmOverwriteHeader'),
			acceptLabel: t('ConfigurationDialog.ConfirmDialogYes'),
			rejectLabel: t('ConfigurationDialog.ConfirmDialogNo'),
			className: 'configuration-confirm-panel',
			icon: 'pi pi-exclamation-triangle',
			accept: async () => await saveConfig()
		});
	}


	const confirmLostChanges = async () => {
		if (dataChanged) {
			confirmDialog({
				message: t('FieldMappings.ConfirmRefresh'),
				header: t('FieldMappings.ConfirmOverwriteHeader'),
				acceptLabel: t('FieldMappings.ConfirmDialogYes'),
				rejectLabel: t('FieldMappings.ConfirmDialogNo'),
				icon: 'pi pi-exclamation-triangle',
				accept: async () => await readDefaults()
			});
		}
	}

	if (allowed !== true) {
		return (<></>);
	} else {
		return (
			<>
				<h3 className="text-primary pt-3 pb-3 pl-3">
					{t('ConfigurationDialog.GeneralCategory')}
					<Button label="" title={t('ConfigurationDialog.HelpButton')} icon="pi pi-question-circle" className="p-button-config-help p-button-rounded p-button-info" onClick={() => showHelp('ConfigurationDialog.General_ImportHelpTitle', 'ConfigurationDialog.General_ImportHelpMessage')} />
				</h3>

				<div className="field grid mt-3 pl-3 pr-3">
					<label htmlFor="favoredLastDigit" className="col-12 md:col-6">{t('ConfigurationDialog.FavoredLastDigit')}</label>
					<div className="col-12 md:col-6">
						<InputNumber value={favoredLastDigit} onChange={(e) => setFavoredLastDigit(e.value)} id="favoredLastDigit" name="favoredLastDigit"
							inputClassName="inputfield textbox" showButtons={false} required minFractionDigits={2} maxFractionDigits={2}
							mode="decimal" min={0} max={1} />
					</div>
				</div>

				<div className="field grid mt-3 pl-3 pr-3">
					<label htmlFor="maxChangePricePerc" className="col-12 md:col-6">{t('ConfigurationDialog.MaxPriceChangePercentage')}</label>
					<div className="col-12 md:col-6">
						<InputNumber value={maxChangePricePerc} onChange={(e) => setMaxChangePricePerc(e.value)} id="maxChangePricePerc" name="maxChangePricePerc"
							inputClassName="inputfield textbox" showButtons={true} required minFractionDigits={0} maxFractionDigits={0}
							mode="decimal" min={0} max={100} />
					</div>
				</div>

				<div className="field grid mt-3 pl-3 pr-3">
					<label htmlFor="maxRecalcsPerWeek" className="col-12 md:col-6">{t('ConfigurationDialog.MaxRecalculationsPerWeek')}</label>
					<div className="col-12 md:col-6">
						<InputNumber value={maxRecalcsPerWeek} onChange={(e) => setMaxRecalcsPerWeek(e.value)} id="maxRecalcsPerWeek" name="maxRecalcsPerWeek"
							inputClassName="inputfield textbox" showButtons={true} required minFractionDigits={0} maxFractionDigits={0}
							mode="decimal" min={0} max={10} />
					</div>
				</div>

				<div className="field grid mt-3 pl-3 pr-3">
					<label htmlFor="maxScheduledRecalculations" className="col-12 md:col-6">{t('ConfigurationDialog.MaxScheduledRecalculations')}</label>
					<div className="col-12 md:col-6">
						<InputNumber value={maxScheduledRecalculations} onChange={(e) => setMaxScheduledRecalculations(e.value)} id="maxScheduledRecalculations" name="maxScheduledRecalculations"
							inputClassName="inputfield textbox" showButtons={true} required minFractionDigits={0} maxFractionDigits={0}
							mode="decimal" min={0} max={20} />
					</div>
				</div>

				<div className="field grid mt-3 pl-3 pr-3">
					<label htmlFor="minWeeksForChanges" className="col-12 md:col-6">{t('ConfigurationDialog.MinWeeksForChanges')}</label>
					<div className="col-12 md:col-6">
						<InputNumber value={minWeeksForChanges} onChange={(e) => setMinWeeksForChanges(e.value)} id="minWeeksForChanges" name="minWeeksForChanges"
							inputClassName="inputfield textbox" showButtons={true} required minFractionDigits={0} maxFractionDigits={0}
							mode="decimal" min={0} max={10} />
					</div>
				</div>

				<div className="field grid mt-3 pl-3 pr-3">
					<label htmlFor="defaultDaysBetweenRepricing" className="col-12 md:col-6">{t('ConfigurationDialog.DefaultDaysBetweenRepricing')}</label>
					<div className="col-12 md:col-6">
						<InputNumber value={defaultDaysBetweenRepricing} onChange={(e) => setDefaultDaysBetweenRepricing(e.value)} id="defaultDaysBetweenRepricing"
							name="defaultDaysBetweenRepricing"
							inputClassName="inputfield textbox" showButtons={true} required minFractionDigits={0} maxFractionDigits={0}
							mode="decimal" min={0} max={30} />
					</div>
				</div>

				<div className="field grid mt-3 pl-3 pr-3">
					<label htmlFor="pduSpreadForDelayedPricing" className="col-12 md:col-6">{t('ConfigurationDialog.PDUSpreadDelayedPerPeriod')}</label>
					<div className="col-12 md:col-6">
						<InputNumber value={pduSpreadForDelayedPricing} onChange={(e) => setPduSpreadForDelayedPricing(e.value)} id="pduSpreadForDelayedPricing"
							name="pduSpreadForDelayedPricing"
							inputClassName="inputfield textbox" showButtons={true} required minFractionDigits={0} maxFractionDigits={0}
							mode="decimal" min={0} max={5} />
					</div>
				</div>

				<div className="field grid mt-3 pl-3 pr-3">
					<label htmlFor="demandCurveHistoryDisplay" className="col-12 md:col-6">{t('ConfigurationDialog.DemandCurveHistoryDisplay')}</label>
					<div className="col-12 md:col-6">
						<InputNumber value={demandCurveHistoryDisplay} onChange={(e) => setDemandCurveHistoryDisplay(e.value)} id="demandCurveHistoryDisplay"
							name="demandCurveHistoryDisplay"
							inputClassName="inputfield textbox" showButtons={true} required minFractionDigits={0} maxFractionDigits={0}
							mode="decimal" min={0} max={52} />
					</div>
				</div>

				<div className="field grid mt-3 pl-3 pr-3">
					<label htmlFor="cpiCategory" className="col-12 md:col-6">{t('ConfigurationDialog.CPICategory')}</label>
					<div className="col-12 md:col-6">
						<Dropdown id="cpiCategory" value={cpiCategory} options={cpiCategories} optionLabel="name" placeholder={t('ConfigurationDialog.SelectCPICategory')}
							optionValue="id" onChange={(e) => setCPICategory(e.value)} />
					</div>
				</div>

				<div className="field grid mt-3 pl-3 pr-3">
					<label htmlFor="dashboardSections" className="col-12 md:col-6">{t('ConfigurationDialog.DashboardSections')}</label>
					<div className="col-12 md:col-6">
						<MultiSelect id="dashboardSections" value={selectedDashboardSections} options={dashboardSections}
							optionLabel="label" style={{ width: '100%' }}
							selectedItemsLabel={t('ConfigurationDialog.DashboardSectionsSelectedLabel')}
							placeholder={t('ConfigurationDialog.SelectedSections')}
							onChange={(e) => setSelectedDashboardSections(e.value)} selectAll={true} showSelectAll={true} 
							/>
					</div>
				</div>

				<div className="field grid mt-3 pl-3 pr-3">
					<label htmlFor="forceFirstDemandCurve" className="col-12 md:col-6">{t('ConfigurationDialog.ForceFirstDemandCurve')}</label>
					<div className="col-12 md:col-6">
						<ToggleButton id="forceFirstDemandCurve" onLabel={t('ConfigurationDialog.ToggleYes')} required
							offLabel={t('ConfigurationDialog.ToggleNo')} onIcon="pi pi-check" offIcon="pi pi-times"
							checked={forceFirstDemandCurve}
							onChange={(ev) => { setForceFirstDemandCurve(ev.value); }} />
					</div>
				</div>

				<div className="flex justify-content-end mr-3 bottom-buttons">
					<ConfigActionBtn onClick={(e) => confirmSave()} buttonText={t('ConfigurationDialog.SaveButton')} disabled={!dataChanged} />
					<ConfigActionBtn onClick={(e) => confirmLostChanges()} buttonText={t('ConfigurationDialog.CancelButton')} disabled={!dataChanged} />
				</div>
			</>
		)
	}
}

export default withTranslation()(GeneralConfig)
