import React, { useState, useEffect } from 'react'
import { useForm, Controller } from 'react-hook-form';
import { withTranslation } from 'react-i18next';
import ConfigActionBtn from '../Config/ConfigActionBtn'
import { DataTable } from 'primereact/datatable';
import { Dialog } from 'primereact/dialog';
import { Column } from 'primereact/column';
import { confirmDialog } from 'primereact/confirmdialog';
import { Password } from 'primereact/password';
import { Divider } from 'primereact/divider';
import { InputText } from 'primereact/inputtext';
import { MultiSelect } from 'primereact/multiselect';
import { Button } from 'primereact/button';
import messagesService from '../../Api/client-side/messages';
import authService from '../api-authorization/AuthorizeService';
import userDataService from '../../Api/requests/users';
import { classNames } from 'primereact/utils';

function Users(props) {
	const { t } = props;
	const { allowed } = props;
	const { showHelp } = props;

	const [user, setUser] = useState(null)
	const [userDialogVisible, setUserDialogVisible] = useState(false)
	const [loading, setLoading] = useState(false);
	const [totalRecords, setTotalRecords] = useState(0);
	const [users, setUsers] = useState(null);
	const [lazyParams, setLazyParams] = useState({
		first: 0,
		rows: 10,
		page: 1
	});

	let defaultUser = {
		id: '',
		firstname: '',
		surname: '',
		email: '',
		password: '',
		roles: [],
		phoneNumber: ''
	};

	const [userData, setUserData] = useState(defaultUser);

	const { control, formState: { errors }, handleSubmit, reset } = useForm({ reValidateMode: 'onChange', defaultValues: defaultUser });

	let rolesAdmin = [
		{ value: 'GlobalAdministrator', name: t('Users.Role_GlobalAdministrator') },
		{ value: 'Developer', name: t('Users.Role_Developer') },
		{ value: 'Administrator', name: t('Users.Role_Administrator') },
		{ value: 'Director', name: t('Users.Role_Director') },
		{ value: 'Manager', name: t('Users.Role_Manager') },
		{ value: 'Editor', name: t('Users.Role_Editor') },
		{ value: 'Viewer', name: t('Users.Role_Viewer') }
	];

	let roles = [
		{ value: 'Administrator', name: t('Users.Role_Administrator') },
		{ value: 'Director', name: t('Users.Role_Director') },
		{ value: 'Manager', name: t('Users.Role_Manager') },
		{ value: 'Editor', name: t('Users.Role_Editor') },
		{ value: 'Viewer', name: t('Users.Role_Viewer') }
	];

	useEffect(() => {
		reset(userData);
	}, [userData]) // eslint-disable-line react-hooks/exhaustive-deps

	useEffect(() => {
		loadLazyData();
	}, [lazyParams]) // eslint-disable-line react-hooks/exhaustive-deps

	const loadLazyData = async () => {
		setLoading(true);

		let itemsCount = await userDataService.getUsersItemsCount();
		setTotalRecords(itemsCount);

		let items = await userDataService.getUsersItems(lazyParams);
		setUsers(items);

		let user = await authService.getUser();
		setUser(user);

		setLoading(false);
	}

	const onPage = (event) => {
		let _lazyParams = { ...lazyParams, ...event };
		setLazyParams(_lazyParams);
	}

	const onSort = (event) => {
		let _lazyParams = { ...lazyParams, ...event };
		setLazyParams(_lazyParams);
	}

	const deleteUser = async (user) => {
		let ret = await userDataService.deleteUser(user);

		if (ret === null) {
			messagesService.showInfo(t('Users.DeleteSuccessTitle'), t('Users.DeleteSuccessMsg'));
			await loadLazyData();
		} else if (ret === 400) {
			messagesService.showError(t('Users.DeleteErrorTitle'), t('Users.DeleteErrorCannotDeleteCurrent'));
		} else {
			messagesService.showError(t('Users.DeleteErrorTitle'), ret);
		}
	}

	const getFormErrorMessage = (name) => {
		return errors[name] && <small className="p-error">{errors[name].message}</small>
	};

	const confirmDeleteUser = (user) => {
		confirmDialog({
			message: t('Users.ConfirmDeleteMsg'),
			header: t('Users.ConfirmDeleteHeader'),
			acceptLabel: t('Users.ConfirmDialogYes'),
			rejectLabel: t('Users.ConfirmDialogNo'),
			className: 'users-confirm-panel',
			icon: 'pi pi-exclamation-triangle',
			accept: () => deleteUser(user)
		});
	}

	const addUser = () => {
		// Dialog without initial data
		setUserData(defaultUser);
		reset(defaultUser);
		showUserDialog();
	}

	const editUser = (user) => {
		// Dialog with initial data
		// console.log(user);
		if (user.password === null) {
			user.password = '';
		}

		if (user.phoneNumber === null) {
			user.phoneNumber = '';
		}

		setUserData(user);
		showUserDialog();
	}

	const deleteBodyTemplate = (rowData) => {
		return (
			<React.Fragment>
				<Button icon="pi pi-trash" className="p-link p-row-editor-init" onClick={() => confirmDeleteUser(rowData)} />
			</React.Fragment>
		);
	}

	const editBodyTemplate = (rowData) => {
		return (
			<React.Fragment>
				<Button icon="pi pi-pencil" className="p-link p-row-editor-init" onClick={() => editUser(rowData)} />
			</React.Fragment>
		);
	}

	const showUserDialog = () => {
		setUserDialogVisible(true);
	}

	const hideUserDialog = () => {
		setUserDialogVisible(false);
	}

	const onSubmit = async (userData) => {
		let res = await userDataService.saveUser(userData);

		console.log(res);

		if (res === null) {
			reset();
			hideUserDialog();
			await loadLazyData();
			messagesService.showSuccess(t('Users.SuccessTitle'), t('Users.SuccessSave'));
		} else {
			messagesService.showError(t('Users.ErrorTitle'), t('Users.ErrorSave'));
		}
	};

	const saveUser = () => {
		handleSubmit(onSubmit)();
	}

	const closeUserDialog = () => {
		reset();
		hideUserDialog();
	}

	const userDialogFooter = () => (
		<div>
			<Button label={t('Users.Button_Save')} icon="pi pi-check" onClick={saveUser} />
			<Button label={t('Users.Button_Cancel')} icon="pi pi-times" onClick={closeUserDialog} />
		</div>
	);

	const passwordHeader = <h6>{t('Users.PasswordHint_Header')}</h6>;
	const passwordFooter = (
		<React.Fragment>
			<Divider />
			<p className="mt-2 mb-2">{t('Users.PasswordHint_Requirements')}</p>
			<ul className="pl-2 ml-2 mt-0" style={{ lineHeight: '1.5' }}>
				<li>{t('Users.PasswordHint_FirstRule')}</li>
				<li>{t('Users.PasswordHint_SecondRule')}</li>
				<li>{t('Users.PasswordHint_ThirdRule')}</li>
				<li>{t('Users.PasswordHint_FourthRule')}</li>
			</ul>
		</React.Fragment>
	);

	const validatePassword = (value) => {
		if (userData.id !== '') {
			// Allow empty password when editing an existing user
			return true;
		} else {
			// New user requires a password
			if (value.trim() === '') {
				return t('Users.Required_Password');
			} else {
				return true;
			}
		}
	}

	const emailIsUnique = async (email) => {
		// console.log(email);

		let valid = false;

		if (userData.id !== '') {
			// Allow same email for this user id
			valid = await userDataService.checkMailAllowed(userData.id, email);
		} else {
			valid = await userDataService.checkMailAllowed('', email);
		}

		if (valid) {
			return true;
		} else {
			return t('Users.Email_Unique_Error');
		}
	};

	const rolesBody = (rowData) => {
		return (
			<span>{rowData.roles.join(',')}</span>
		);
	}

	if (allowed !== true) {
		return (<></>);
	} else {
		return (
			<>
				<Dialog header={t('Users.AddUserDialogHeader')} className="grid p-config-dialog" visible={userDialogVisible} style={{ width: '50vw' }} position="center" footer={userDialogFooter()} onHide={() => hideUserDialog()}>
					<form onSubmit={handleSubmit(onSubmit)} className="grid config-subsection-wrapper p-4">
						<input type="hidden" name="id" id="id" value={ userData.id }/>
						<div className="p-fluid p-formgrid grid">
							<div className="field col-12 md:col-6">
								<label htmlFor="firstname">{t('Users.FirstName')}</label>
								<span className="p-error">{getFormErrorMessage('firstname')}</span>
								<Controller name="firstname" control={control} rules={{ required: t('Users.Required_Name'), minLength: 1, maxLength: 50 }} render={({ field, fieldState }) => (
									<InputText id={field.name} {...field} autoFocus className={classNames("inputField", "textbox", { 'p-invalid': fieldState.invalid })} />
								)} />
							</div>
							<div className="field col-12 md:col-6">
								<label htmlFor="surname">{t('Users.Surname')}</label>
								<span className="p-error">{getFormErrorMessage('surname')}</span>
								<Controller name="surname" control={control} rules={{ required: t('Users.Required_Surname'), minLength: 1, maxLength: 50 }} render={({ field, fieldState }) => (
									<InputText id={field.name} {...field} autoFocus className={classNames("inputField", "textbox", { 'p-invalid': fieldState.invalid })} />
								)} />
							</div>
							<div className="field col-12 md:col-6">
								<label htmlFor="email">{t('Users.EMail')}</label>
								<span className="p-error">{getFormErrorMessage('email')}</span>
								<Controller name="email" control={control} rules={{ required: t('Users.Required_EMail'), minLength: 1, maxLength: 50, validate: v => emailIsUnique(v) }} render={({ field, fieldState }) => (
									<InputText id={field.name} {...field} autoFocus className={classNames("inputField", "textbox", { 'p-invalid': fieldState.invalid })} autoComplete="off"/>
								)} />
							</div>
							<div className="field col-12 md:col-6">
								<label htmlFor="password">{t('Users.Password')}</label>
								<span className="p-error">{getFormErrorMessage('password')}</span>
								<Controller name="password" control={control} rules={{ minLength: 6, maxLength: 20, pattern: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z!@#$%^&*\d]{8,}$/ , validate: (v) => validatePassword(v) }} render={({ field, fieldState }) => (
									<Password id={field.name} {...field} toggleMask inputClassName="inputField textbox" autoComplete="new-password"
										weakLabel={t('Users.Password_Weak_Label')} mediumLabel={t('Users.Password_Medium_Label')} strongLabel={t('Users.Password_Strong_Label')}
										className={classNames({ 'p-invalid': fieldState.invalid })} header={passwordHeader} footer={passwordFooter} />
								)} />
							</div>
							<div className="field col-12 md:col-6">
								<label htmlFor="roles">{t('Users.Roles')}</label>
								<Controller name="roles" control={control} render={({ field }) => (
									<MultiSelect id={field.name} value={field.value} options={loading ? [] : ((user.isDeveloper || user.isGlobalAdmin) ? rolesAdmin : roles)} selectedItemsLabel={t('Users.RolesSelectedLabel')} placeholder={t('Users.SelectRoles')}
										onChange={(e) => field.onChange(e.value)} optionLabel="name" selectAll={false} showSelectAll={false} maxSelectedLabels={3} />
								)} />
							</div>
							<div className="field col-12 md:col-6">
								<label htmlFor="phoneNumber">{t('Users.PhoneNumber')}</label>
								<Controller name="phoneNumber" control={control} rules={{ minLength: 0, maxLength: 20 }} render={({ field }) => (
									<InputText id={field.name} {...field} autoFocus className="inputfield textbox" />
								)} />
							</div>
						</div>
					</form>
				</Dialog>


				<div className="grid mt-3 pl-3 pr-3">
					<h3 className="text-primary pt-3 pb-3 pl-3">
						{t('Users.Header')}
						<Button label="" title={t('Users.HelpButton')} icon="pi pi-question-circle" className="p-button-config-help p-button-rounded p-button-info" onClick={() => showHelp('Users.ImportHelpTitle', 'Users.ImportHelpMessage')} />
					</h3>

					<div className="col-12 md:col-12 datatable datatable-responsive">
						<DataTable value={users} lazy responsiveLayout="scroll"
							className="p-datatable-config" dataKey="id"
							paginatorPosition="top" paginator first={lazyParams.first} rows={10} totalRecords={totalRecords} onPage={onPage}
							onSort={onSort} sortField={lazyParams.sortField} sortOrder={lazyParams.sortOrder}
							loading={loading}>
							<Column field="firstname" sortable header={t('Users.Column_Name')} />
							<Column field="surname" sortable header={t('Users.Column_Surname')} />
							<Column body={rolesBody} header={t('Users.Column_Roles')} />
							<Column field="email" sortable header={t('Users.Column_EMail')} />
							<Column body={editBodyTemplate} style={{ width: '1rem' }}></Column>
							<Column body={deleteBodyTemplate} style={{ width: '1rem' }}></Column>
						</DataTable>
					</div>
				</div>

				<div className="flex justify-content-end mr-3 bottom-buttons">
					<ConfigActionBtn onClick={(e) => addUser(e)} buttonText={t('Users.AddNewUserButton')} />
				</div>
			</>)
	}
}

export default withTranslation()(Users)
