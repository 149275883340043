import React from 'react'
import { classNames } from 'primereact/utils'

const ConfigActionBtn = (props) => {
    const { buttonText } = props
    const { onClick } = props
    const { disabled } = props
    const { className } = props

    return (
        <div className="text-right mb-4 ml-4 inline-block">
            <button
                onClick={(e) => { onClick(e); }}
                type="button"
                className={classNames("btn", "btn-md btn-rounded-full", "btn-inline-block", className ? className : "btn-tertiary")}
            disabled={disabled}>
           {buttonText}
        </button>
        </div>
    )
}

export default ConfigActionBtn
