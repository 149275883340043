import React, { useEffect, useState, useRef } from 'react'
import ConfigActionBtn from '../Config/ConfigActionBtn'
import { InputNumber } from 'primereact/inputnumber';
// import { Dropdown } from 'primereact/dropdown';
import { Button } from 'primereact/button';
import { confirmDialog } from 'primereact/confirmdialog';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { ScrollPanel } from 'primereact/scrollpanel';
import { withTranslation } from 'react-i18next';
import configurationService from '../../Api/requests/config';
import messagesService from '../../Api/client-side/messages';

function PricingGuardrails(props) {
	const { t } = props
	const { skus } = props
	const { setLoading } = props
	const { currencySymbol } = props
	const { allowed } = props;
	const { showHelp } = props;

	const [dataChanged, setDataChanged] = useState(false)

	const [sku, setSku] = useState(null)
	const [keepWithinHistoricalMinMaxPrice, setKeepWithinHistoricalMinMaxPrice] = useState(null)
	const [minMarginPerc, setMinMarginPerc] = useState(null)
	const [maxMarginPerc, setMaxMarginPerc] = useState(null)
	const [minChangePercentage, setMinChangePercentage] = useState(null)
	const [maxChangePercentage, setMaxChangePercentage] = useState(null)

	// Data to read/write from/to backend
	const [absChangeRanges, setAbsChangeRanges] = useState([])
	const [minChangeAbsValues, setMinChangeAbsValues] = useState({})
	const [maxChangeAbsValues, setMaxChangeAbsValues] = useState({})

	// Data table info
	const [minMaxAbsValuesData, setMinMaxAbsValuesData] = useState([])

	const mounted = useRef(false)
	const defaultsRef = useRef(null)

	const loadAbsRanges = async () => {
		let theGuardrailsRangesOrdered = [];

		for (const key in defaultsRef.current.minChangeAbs) {
			theGuardrailsRangesOrdered.push(parseFloat(key));
		}

		// Order array
		theGuardrailsRangesOrdered.sort(function (a, b) {
			// Special case: -1 is INFINITY in the backend, so modify the sort function to make -1 the higher possible value
			if (a === -1) {
				// a > b always, so return 1
				return 1;
			} if (b === -1) {
				// b > a always, so return -1
				return -1;
			} else {
				// Default case
				return a - b;
			}
		});

		setMinChangeAbsValues({ ...defaultsRef.current.minChangeAbs });
		setMaxChangeAbsValues({ ...defaultsRef.current.maxChangeAbs });
		setAbsChangeRanges(theGuardrailsRangesOrdered);

		var absValuesTableData = [];

		var fromRangeActual = 0;

		for (var i = 0; i < theGuardrailsRangesOrdered.length; i++) {
			let theKey = theGuardrailsRangesOrdered[i];

			absValuesTableData.push({
				key: theKey,
				rangeFrom: fromRangeActual,
				rangeTo: (theGuardrailsRangesOrdered[i] === -1) ? Infinity : theGuardrailsRangesOrdered[i],
				minChangeAbs: defaultsRef.current.minChangeAbs[theKey],
				maxChangeAbs: defaultsRef.current.maxChangeAbs[theKey]
			});

			fromRangeActual = theGuardrailsRangesOrdered[i];
		}

		setMinMaxAbsValuesData(absValuesTableData);
		// console.log(absValuesTableData);
	}

	const saveConfig = async () => {
		setLoading(true);

		let data = {
			sku: sku,
			keepWithinHistoricalMinMaxPrice: keepWithinHistoricalMinMaxPrice,
			minMarginPercentage: minMarginPerc,
			maxMarginPercentage: maxMarginPerc,
			minChangePercentage: minChangePercentage,
			maxChangePercentage: maxChangePercentage,
			minChangeAbs: minChangeAbsValues,
			maxChangeAbs: maxChangeAbsValues

		};

		let res = await configurationService.saveGuardrailsConfiguration(data);

		if (res === null) {
			messagesService.showSuccess(t('ConfigurationDialog.ConfigurationRefreshedTitle'), t('ConfigurationDialog.ConfigurationRefreshedMessage'));

			await readDefaults(sku);
		} else {
			messagesService.showError(t('ConfigurationDialog.ConfigurationRefreshedErrorTitle'), t('ConfigurationDialog.ConfigurationRefreshedErrorMessage'));
		}

		setLoading(false);
	}

	const readDefaults = async (theSku) => {
		mounted.current = false;

		let defaults = await configurationService.getGuardrailsConfiguration(theSku);

		if (defaults != null) {

			setKeepWithinHistoricalMinMaxPrice(defaults.keepWithinHistoricalMinMaxPrice);
			setMinMarginPerc(defaults.minMarginPercentage);
			setMaxMarginPerc(defaults.maxMarginPercentage);
			setMinChangePercentage(defaults.minChangePercentage);
			setMaxChangePercentage(defaults.maxChangePercentage);

			defaultsRef.current = defaults;
			mounted.current = true;
			await loadAbsRanges();
			setDataChanged(false);
		}
	}

	const changedSomeAbsValues = () => {
		var ret = false;

		if (defaultsRef.current != null) {
			var i = 0;
			var currentPrice;

			// Some item has been added
			if (defaultsRef.current.minChangeAbs.length !== minChangeAbsValues.length ||
				defaultsRef.current.maxChangeAbs.length !== maxChangeAbsValues.length) {
				ret = true;
			}

			for (i = 0; i < absChangeRanges.length && !ret; i++) {
				currentPrice = absChangeRanges[i];

				if (!defaultsRef.current.minChangeAbs.hasOwnProperty(currentPrice) ||
					!defaultsRef.current.maxChangeAbs.hasOwnProperty(currentPrice) ||
					defaultsRef.current.minChangeAbs[currentPrice] !== minChangeAbsValues[currentPrice] ||
					defaultsRef.current.maxChangeAbs[currentPrice] !== maxChangeAbsValues[currentPrice]) {
					ret = true;
				}
			}
		}

		return ret;
	};

	const checkChangedValues = () => {
		let defVals = defaultsRef.current;

		if (defVals.keepWithinHistoricalMinMaxPrice !== keepWithinHistoricalMinMaxPrice ||
			defVals.minMarginPercentage !== minMarginPerc ||
			defVals.maxMarginPercentage !== maxMarginPerc ||
			defVals.minChangePercentage !== minChangePercentage ||
			defVals.maxChangePercentage !== maxChangePercentage ||
			changedSomeAbsValues()) {
			setDataChanged(true);
		} else {
			setDataChanged(false);
		}
	}

	const confirmSave = async () => {
		confirmDialog({
			message: t('ConfigurationDialog.ConfirmOverwriteMessage'),
			header: t('ConfigurationDialog.ConfirmOverwriteHeader'),
			acceptLabel: t('ConfigurationDialog.ConfirmDialogYes'),
			rejectLabel: t('ConfigurationDialog.ConfirmDialogNo'),
			className: 'configuration-confirm-panel',
			icon: 'pi pi-exclamation-triangle',
			accept: async () => {
				await saveConfig();
			}
		});
	}

	const confirmLostChanges = (callback) => {
		if (dataChanged) {
			confirmDialog({
				message: t('FieldMappings.ConfirmRefresh'),
				header: t('FieldMappings.ConfirmOverwriteHeader'),
				acceptLabel: t('FieldMappings.ConfirmDialogYes'),
				rejectLabel: t('FieldMappings.ConfirmDialogNo'),
				icon: 'pi pi-exclamation-triangle',
				accept: () => callback()
			});
		}
	}

	const onSkuChange = async (val) => {
		if (dataChanged) {
			confirmLostChanges(async () => {
				setSku(val);
				await readDefaults(val);
			});
		} else {
			setSku(val);
			await readDefaults(val);
		}
	}

	const confirmReset = () => {
		onSkuChange(sku);
	}

	useEffect(() => {
		if (mounted.current === true) {
			checkChangedValues();
		}
	}, [minMarginPerc, maxMarginPerc, minChangePercentage, maxChangePercentage, keepWithinHistoricalMinMaxPrice]); // eslint-disable-line react-hooks/exhaustive-deps

	const initialLoad = async () => {
		// Little hack to be sure the currency symbol and skus are ready before to load the default selection
		if (skus !== null && skus.length > 0 &&
			currencySymbol !== null) {
			// Set default SKU (general element)
			await onSkuChange("-1");
		}
	};

	useEffect(() => {
		initialLoad();
	}, [skus]); // eslint-disable-line react-hooks/exhaustive-deps

	useEffect(() => {
		initialLoad();
	}, [currencySymbol]); // eslint-disable-line react-hooks/exhaustive-deps

	const checkRange = (options, value, error) => {
		let rangeFrom = options.rowData.rangeFrom;
		let rangeTo = options.rowData.rangeTo;
		let minChange = options.rowData.minChangeAbs;
		let maxChange = options.rowData.maxChangeAbs;
		
		switch (options.field) {
			case "minChangeAbs":
				if (value > rangeTo) {
					error.outputMessage = t('ConfigurationDialog.MinChangeAbsCannotBeHigherThanMaxRange');
					return false;
				} else if (value >= maxChange) {
					// Cannot set minimum change absolute to a value higher than the upper limit of the range
					error.outputMessage = t('ConfigurationDialog.MinChangeAbsCannotBeHigherThanMaxChangeAbs');
					return false;
				}
				break;
			case "maxChangeAbs":
				if (value > rangeTo) {
					error.outputMessage = t('ConfigurationDialog.MaxChangeAbsCannotBeHigherThanMaxRange');
					return false;
				} else if (value <= minChange) {
					// Cannot set maximum change absolute to a value lower than the lower limit of the range
					error.outputMessage = t('ConfigurationDialog.MaxChangeAbsCannotBeLowerThanMinChangeAbs');
					return false;
				}
				break;
			case "rangeTo":
				if (rangeTo === Infinity) {
					// Cannot edit infinity upper range
					error.outputMessage = t('ConfigurationDialog.InfinityRangeCannotBeModified');
					return false;
				} else {
					let nextRangeTo = absChangeRanges[options.rowIndex + 1];

					// If next range is -1, it's really INFINITE, so allow any value
					if (nextRangeTo !== -1) {
						if (value < rangeFrom) {
							// Upper value from range cannot be higher than lower value
							error.outputMessage = t('ConfigurationDialog.UpperLimitRangeCannotBeLessThanLowerLimit');
							return false;
						} else if (value >= nextRangeTo) {
							// Upper value from range cannot be higher or equal than higher limit from next range
							error.outputMessage = t('ConfigurationDialog.UpperLimitRangeCannotBeHigherOrEqualThanNextHigherLimit');
							return false;
						}
					}
				}

				break;
			default:
				// Do nothing
				break;
		}

		return true;
	}

	const onDecimalValueChanged = (options, value) => {
		var error = {};

		if (checkRange(options, value, error)) {
			let theKey = options.rowData.key;

			switch (options.field) {
				case "minChangeAbs":
					minChangeAbsValues[theKey] = value;
					options.props.value[options.rowIndex].minChangeAbs = value;
					break;
				case "maxChangeAbs":
					maxChangeAbsValues[theKey] = value;
					options.props.value[options.rowIndex].maxChangeAbs = value;
					break;
				case "rangeTo":
					absChangeRanges[options.rowIndex] = value;
					options.props.value[options.rowIndex].rangeTo = value;
					options.props.value[options.rowIndex].key = value;

					// Replace old entry into min/max range with the new key
					let theNewKey = value;
					minChangeAbsValues[theNewKey] = minChangeAbsValues[theKey];
					maxChangeAbsValues[theNewKey] = maxChangeAbsValues[theKey];

					// Update lower limit from next range
					options.props.value[options.rowIndex + 1].rangeFrom = value;

					delete minChangeAbsValues[theKey];
					delete maxChangeAbsValues[theKey];

					break;
				default:
					// DO NOTHING
					break;
			}

			// Check if some value has been changed from original values
			checkChangedValues();
		} else {
			if (error.outputMessage) {
				messagesService.showError(t('ConfigurationDialog.ConfigurationRefreshedErrorTitle'), error.outputMessage);
			}
		}

		//console.log(props);
		//console.log(minChangeAbsValues);
		//console.log(maxChangeAbsValues);
		//console.log(absChangeRanges);
	}

	const decimalEditor = (props) => {
		if (props.field === "rangeTo" && props.rowData[props.field] === Infinity) {
			// Do not allow edit INFINITY upper range
			return "Infinity";
		} else {
			return <InputNumber value={props.rowData[props.field]} onChange={(e) => onDecimalValueChanged(props, e.value)}
				style={{ width: '70px' }} inputClassName="inputfield textbox" showButtons={false} required minFractionDigits={1} maxFractionDigits={2}
				mode="decimal" />
		}
	}

	const addAbsoluteChangeRange = (rowData, options) => {
		// rowData.value[rowData.rowIndex]
		let clicked = rowData;

		var newData = [...minMaxAbsValuesData];
		let next = newData[options.rowIndex + 1];

		console.log(next);

		var diff = (next.rangeTo - next.rangeFrom) / 2;

		var newItem = {
			key : clicked.key + diff,
			rangeFrom: clicked.rangeTo,
			rangeTo: clicked.key + diff,
			minChangeAbs: clicked.minChangeAbs,
			maxChangeAbs: clicked.maxChangeAbs
		}

		next.rangeFrom = clicked.key + diff;
		newData.splice(options.rowIndex + 1, 0, newItem);
		setMinMaxAbsValuesData(newData);

		absChangeRanges.splice(options.rowIndex + 1, 0, clicked.key + diff);

		// Check if some value has been changed from original values
		checkChangedValues();
	}

	const deleteRange = (rowData, options) => {
		let previous = null;
		let next = null;

		if (options.rowIndex > 0) {
			previous = options.props.value[options.rowIndex - 1];
		}

		if (options.rowIndex < (absChangeRanges.length - 1)) {
			next = options.props.value[options.rowIndex + 1];
		}

		if (previous && next) {
			next.rangeFrom = previous.rangeTo;
		} else {
			if (previous) {
				// Last item
				previous.rangeTo = Infinity;
			} else {
				// First Item
				next.rangeFrom = 0;
			}
		}

		delete minChangeAbsValues[rowData.key];
		delete maxChangeAbsValues[rowData.key];
		delete absChangeRanges[options.rowIndex];

		// Check if some value has been changed from original values
		minMaxAbsValuesData.splice(options.rowIndex, 1);
		var newData = [...minMaxAbsValuesData];
		setMinMaxAbsValuesData(newData);

		checkChangedValues();
	}

	const confirmDeleteRange = (rowData, options) => {
		confirmDialog({
			message: t('ConfigurationDialog.ConfirmDeleteRange'),
			header: t('ConfigurationDialog.ConfirmDeleteRangeHeader'),
			acceptLabel: t('ConfigurationDialog.ConfirmDialogYes'),
			rejectLabel: t('ConfigurationDialog.ConfirmDialogNo'),
			className: 'configuration-confirm-panel',
			icon: 'pi pi-exclamation-triangle',
			accept: () => deleteRange(rowData, options)
		});
	}

	const addRangeBodyTemplate = (rowData, options) => {
		return (
			<React.Fragment>
				<Button icon="pi pi-arrows-v" className="p-link p-row-editor-init" title={t('ConfigurationDialog.AddNewRangeButtonTooltip')} onClick={() => addAbsoluteChangeRange(rowData, options)} />&nbsp;
				<Button icon="pi pi-trash" className="p-link p-row-editor-init" title={t('ConfigurationDialog.DeleteButtonTooltip')} onClick={() => confirmDeleteRange(rowData, options)} />
			</React.Fragment>
		);
	}

	if (allowed !== true) {
		return (<></>);
	} else {
		return (
			<>
				<h3 className="text-primary pt-3 pb-3 pl-3">
					{t('PricingGuardrailsDialog.Header')}
					<Button label="" title={t('ConfigurationDialog.HelpButton')} icon="pi pi-question-circle" className="p-button-config-help p-button-rounded p-button-info" onClick={() => showHelp('ConfigurationDialog.Pricing_ImportHelpTitle', 'ConfigurationDialog.Pricing_ImportHelpMessage')} />
				</h3>

				{
					//<div className="field grid pl-3 pr-3 mt-3">
					//	<label htmlFor="sku" className="col-4 mb-2 md:col-2 md:mb-0">{t('ConfigurationDialog.SKU')}</label>
					//	<div className="col-8 md:col-10">
					//		<Dropdown id="sku" name="sku" value={sku} options={skus} optionLabel="label" optionValue="value" onChange={(e) => onSkuChange(e.value)} />
					//	</div>
					//</div>
				}

				<div className="field grid pl-3 pr-3 mt-3">
					<label className="form-checkbox form-checkbox-tertiary form-checkbox-md text-sm text-primary pl-2">
						<input type="checkbox"
							checked={(keepWithinHistoricalMinMaxPrice !== null) ? keepWithinHistoricalMinMaxPrice : false}
							onChange={(e) => {
								setKeepWithinHistoricalMinMaxPrice(e.target.checked)
							}} />
						<span className="form-checkmark mr-2"></span>
						{t('ConfigurationDialog.KeepWithinHistoricalMinMaxPrice')}
					</label>
				</div>

				<div className="field grid mt-3 pl-3 pr-3">
					<label htmlFor="minMarginPerc" className="col-6 md:col-3">{t('ConfigurationDialog.MinMarginPercentage')}</label>
					<div className="col-6 md:col-3">
						<InputNumber value={minMarginPerc} onChange={(e) => setMinMarginPerc(e.value)} id="minMarginPerc" name="minMarginPerc"
							inputClassName="inputfield textbox" showButtons={false} required minFractionDigits={1} maxFractionDigits={2}
							mode="decimal" min={0} max={1000} />
					</div>
					<label htmlFor="maxMarginPerc" className="col-6 md:col-3">{t('ConfigurationDialog.MaxMarginPercentage')}</label>
					<div className="col-6 md:col-3">
						<InputNumber value={maxMarginPerc} onChange={(e) => setMaxMarginPerc(e.value)} id="maxMarginPerc" name="maxMarginPerc"
							inputClassName="inputfield textbox" showButtons={false} required minFractionDigits={1} maxFractionDigits={2}
							mode="decimal" min={0} max={1000} />
					</div>
				</div>

				<div className="field grid mt-3 pl-3 pr-3">
					<label htmlFor="minChangePercentage" className="col-6 md:col-3">{t('ConfigurationDialog.MinChangePercentage')}</label>
					<div className="col-6 md:col-3">
						<InputNumber value={minChangePercentage} onChange={(e) => setMinChangePercentage(e.value)} id="minChangePercentage" name="minChangePercentage"
							inputClassName="inputfield textbox" showButtons={false} required minFractionDigits={1} maxFractionDigits={2}
							mode="decimal" min={0} max={1000} />
					</div>
					<label htmlFor="maxChangePercentage" className="col-6 md:col-3">{t('ConfigurationDialog.MaxChangePercentage')}</label>
					<div className="col-6 md:col-3">
						<InputNumber value={maxChangePercentage} onChange={(e) => setMaxChangePercentage(e.value)} id="maxChangePercentage" name="maxChangePercentage"
							inputClassName="inputfield textbox" showButtons={false} required minFractionDigits={1} maxFractionDigits={2}
							mode="decimal" min={0} max={1000} />
					</div>
				</div>

				<div className="field grid mt-3 pl-3 pr-3 scrollpanel-container">
					<ScrollPanel style={{ width: '100%', height: '310px' }} className="datatable datatable-responsive">
						<DataTable value={minMaxAbsValuesData} dataKey="key"
							className="p-datatable-config"
							paginator={false}
							footer={false}
							responsiveLayout="scroll"
							columnResizeMode="fit">
							<Column field="rangeFrom" header={t('ConfigurationDialog.ChangeAbsRangeFrom')}></Column>
							<Column field="rangeTo" header={t('ConfigurationDialog.ChangeAbsRangeTo')} editor={decimalEditor}></Column>
							<Column field="minChangeAbs" header={t('ConfigurationDialog.MinChangeAbs')} editor={decimalEditor}></Column>
							<Column field="maxChangeAbs" header={t('ConfigurationDialog.MaxChangeAbs')} editor={decimalEditor}></Column>
							<Column body={addRangeBodyTemplate} style={{ width: '6rem' }}></Column>
						</DataTable>
					</ScrollPanel>
				</div>

				<div className="flex justify-content-end mr-3 bottom-buttons">
					<ConfigActionBtn onClick={(e) => confirmSave()} buttonText={t('ConfigurationDialog.SaveButton')} disabled={!dataChanged} />
					<ConfigActionBtn onClick={(e) => confirmReset()} buttonText={t('ConfigurationDialog.CancelButton')} disabled={!dataChanged} />
				</div>
			</>
		);
	}
}

export default withTranslation()(PricingGuardrails)
