import axios from 'axios'
import authService from '../../components/api-authorization/AuthorizeService';

export class ConfigurationService {
	_subscription = null;
	_token = null;

	constructor() {
		this._subscription = authService.subscribe(() => {
			this.authorize();
		});

		this._subscribeTokenExpiration = authService.subscribeTokenExpiration((newToken) => {
			if (newToken !== this._token) {
				// console.log('upgrading CONFIG token', newToken);
				this._token = newToken;
			}
		});

		this.authorize();
	}

	authorize() {
		authService.isAuthenticated().then((isAuthenticated) => {
			// console.debug("ConfigurationService, isAuthenticated: ", isAuthenticated);
			if (isAuthenticated) {
				authService.getAccessToken().then((token) => {
					this._token = token;
					// console.debug("ConfigurationService, received token: ", token);
				});
			} else {
				this._token = null;
			}
		});

		// console.debug("ConfigurationService: ", this._token);
	}

	async getGeneralConfiguration() {
		let ret = null;

		if (this._token != null) {
			const config = {
				headers: { Authorization: `Bearer ${this._token}` }
			};

			let response = await axios.get('configuration', config);

			if (response.status === 200) {
				// console.debug("getGeneralConfiguration() OK");
				ret = response.data;
			} else {
				let error = response.data;
				console.error(error);
			}
		}

		return ret;
	}

	async getCPIcategories() {
		let ret = null;

		if (this._token != null) {
			const config = {
				headers: { Authorization: `Bearer ${this._token}` }
			};

			let response = await axios.get('configuration/cpicategories', config);

			if (response.status === 200) {
				// console.debug("getGeneralConfiguration() OK");
				ret = response.data;
			} else {
				let error = response.data;
				console.error(error);
			}
		}

		return ret;
	}

	async saveGeneralConfiguration(data) {
		let ret = null;

		if (this._token != null) {
			const config = {
				headers: { Authorization: `Bearer ${this._token}` }
			};

			try {
				let response = await axios.post('configuration', data, config);

				if (response.status === 200) {
					// console.debug("saveGeneralConfiguration() OK");
				} else {
					let error = response.data;
					console.error(error);
					ret = error;
				}
			} catch (e) {
				let error = e.response.data;
				console.error(error);
				ret = error;
			}
		}

		return ret;
	}

	async getAllSkus() {
		let ret = null;

		if (this._token != null) {
			const config = {
				headers: { Authorization: `Bearer ${this._token}` }
			};

			try {
				let response = await axios.get('configuration/skus', config);

				if (response.status === 200) {
					// console.debug("saveGeneralConfiguration() OK");
					ret = response.data;
				} else {
					let error = response.data;
					console.error(error);
					ret = error;
				}
			} catch (e) {
				let error = e.response.data;
				console.error(error);
				ret = error;
			}
		}

		return ret;
	}

	async getDevelopmentConfig() {
		var ret = null;

		if (this._token != null) {
			const config = {
				headers: { Authorization: `Bearer ${this._token}` }
			};

			let response = await axios.get('configuration/globalConfiguration', config);

			if (response.status === 200) {
				// console.debug("getDevelopmentConfig() OK");
				ret = response.data;
			} else {
				let error = response.data;
				console.error(error);
			}
		}

		return ret;
	}

	async setDevelopmentConfig(data) {
		var ret = null;

		if (this._token != null) {
			const config = {
				headers: { Authorization: `Bearer ${this._token}` }
			};

			let response = await axios.post('configuration/globalConfiguration', data, config);

			if (response.status === 200) {
				// console.debug("setDevelopmentConfig() OK");
				ret = null;
			} else {
				let error = response.data;
				ret = error;
				console.error(error);
			}
		}

		return ret;
	}

	async getGuardrailsConfiguration(sku) {
		var ret = null;

		if (this._token != null) {
			const config = {
				headers: { Authorization: `Bearer ${this._token}` }
			};

			let response = await axios.get('configuration/guardrailsConfiguration/?sku=' + sku, config);

			if (response.status === 200) {
				// console.debug("getGuardrailsConfiguration() OK");
				ret = response.data;
			} else {
				let error = response.data;
				console.error(error);
			}
		}

		return ret;
	}

	async saveGuardrailsConfiguration(data) {
		var ret = null;

		if (this._token != null) {
			const config = {
				headers: { Authorization: `Bearer ${this._token}` }
			};

			let response = await axios.post('configuration/guardrailsConfiguration', data, config);

			if (response.status === 200) {
				// console.debug("saveGuardrailsConfiguration() OK");
				ret = null;
			} else {
				let error = response.data;
				ret = error;
				console.error(error);
			}
		}

		return ret;
	}

	async getAlgorithmsConfiguration(sku) {
		var ret = null;

		if (this._token != null) {
			const config = {
				headers: { Authorization: `Bearer ${this._token}` }
			};

			let response = await axios.get('configuration/pricingAlgorithm/?sku=' + sku, config);

			if (response.status === 200) {
				// console.debug("getAlgorithmsConfiguration() OK");
				ret = response.data;
			} else {
				let error = response.data;
				console.error(error);
			}
		}

		return ret;
	}

	async saveAlgorithmsConfiguration(data) {
		var ret = null;

		if (this._token != null) {
			const config = {
				headers: { Authorization: `Bearer ${this._token}` }
			};

			let response = await axios.post('configuration/pricingAlgorithm', data, config);

			if (response.status === 200) {
				// console.debug("saveAlgorithmsConfiguration() OK");
				ret = null;
			} else {
				let error = response.data;
				ret = error;
				console.error(error);
			}
		}

		return ret;
	}

	async getHolidays(year) {
		var ret = null;

		if (this._token != null) {
			const config = {
				headers: { Authorization: `Bearer ${this._token}` }
			};

			let response = await axios.get('holidays/?year=' + year, config);

			if (response.status === 200) {
				// console.debug("getHolidays() OK");
				ret = response.data;
			} else {
				let error = response.data;
				console.error(error);
			}
		}

		return ret;
	}

	async saveHolidays(theHolidays) {
		var ret = null;

		if (this._token != null) {
			const config = {
				headers: { Authorization: `Bearer ${this._token}` }
			};

			let changedHolidays = theHolidays.filter(m => m.changed === true);

			for (let idx = 0; idx < changedHolidays.length; idx++) {

				// Override pk placeholder with real NULL value to send to backend
				if (changedHolidays[idx]['datePK'].startsWith('null-')) {
					changedHolidays[idx]['datePK'] = null;
				}
			}

			let response = await axios.post('holidays', changedHolidays, config);

			if (response.status === 200) {
				// console.debug("saveHolidays() OK");
				ret = null;
			} else {
				let error = response.data;
				ret = error;
				console.error(error);
			}
		}

		return ret;
	}

	async deleteHoliday(holiday) {
		var ret = null;

		if (this._token != null) {
			const config = {
				headers: {
					Authorization: `Bearer ${this._token}`,
					'Content-Type': 'application/json'
				},
				data: holiday
			};

			let response = await axios.delete('holidays', config);

			if (response.status === 200) {
				// console.debug("deleteHoliday() OK");
				ret = null;
			} else {
				let error = response.data;
				ret = error;
				console.error(error);
			}
		}

		return ret;
	}

	async loadPricingExportConfig() {
		var ret = null;

		if (this._token != null) {
			const config = {
				headers: { Authorization: `Bearer ${this._token}` }
			};

			let response = await axios.get('configuration/pricingExportConfig', config);

			if (response.status === 200) {
				// console.debug("getHolidays() OK");
				ret = response.data;
			} else {
				let error = response.data;
				console.error(error);
			}
		}

		return ret;
	}

	async savePricingExportConfig(data) {
		let ret = null;

		if (this._token != null) {
			const config = {
				headers: { Authorization: `Bearer ${this._token}` }
			};

			try {
				let response = await axios.post('configuration/pricingExportConfig', data, config);

				if (response.status === 200) {
					// console.debug("saveGeneralConfiguration() OK");
				} else {
					let error = response.data;
					console.error(error);
					ret = error;
				}
			} catch (e) {
				let error = e.response.data;
				console.error(error);
				ret = error;
			}
		}

		return ret;
	}

	static get instance() { return configurationService; }
}

const configurationService = new ConfigurationService();

export default configurationService;