import { React, useRef, useEffect, useState } from 'react';
import { Link, NavLink, useNavigate } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import { Badge } from 'primereact/badge';
import { ApplicationPaths } from './api-authorization/ApiAuthorizationConstants';
import authService from '../components/api-authorization/AuthorizeService';
import Moment from 'moment';
import { TieredMenu  } from 'primereact/tieredmenu';
import { Avatar } from 'primereact/avatar';
import { classNames } from 'primereact/utils';

import realTimeMessagingServices, { RealTimeMessagesService } from '../Api/requests/realtimemessaging'

const Header = (props) => {
	const { i18n, t } = props;
	const { reloadLayout } = props;
	const messagesSubscription = useRef(null);
	const authSubscription = useRef(null);
	const authSubscriptionTokenExp = useRef(null);
	const navigate = useNavigate();
	const userMenu = useRef(null);
	const [menuImageHackTimestamp, setMenuImageHackTimestamp] = useState(Date.now());
	// const [isAuthenticated, setIsAuthenticated] = useState(false);
	const [userMessagesCount, setUserMessagesCount] = useState(0);
	const [administrationEnabled, setAdministrationEnabled] = useState(false)

	const doLogout = () => {
		const logoutPath = `${ApplicationPaths.LogOut}`;
		navigate(logoutPath, { state: { local: true } });
	}

	const userMenuItems = [
		{
			label: t('Header.UserMenu_Messages'),
			icon: 'pi pi-info-circle',
			command: (event) => {
				navigate('/messages');
				userMenu.current.hide(event);
			}
		},
		{
			label: t('Header.UserMenu_Languages'),
			icon: 'pi pi-flag',
			items: [
				{ label: 'English', command: (event) => { switchLanguage(event, 'en');} },
				{ label: 'Espa\u00f1ol', command: (event) => { switchLanguage(event, 'es'); } }
			]
		},
		{
			label: t('Header.UserMenu_Logout'),
			icon: 'pi pi-sign-out',
			command: (event) => {
				doLogout();
				userMenu.current.hide(event);
			}
		}
	];

	const setLanguage = (lang) => {
		if (lang !== i18n.language) {
			// We have a language change!
			i18n.changeLanguage(lang).then((t) => {
				// Also set LOCALE for Moment.js
				Moment.locale(lang);

				// Call "reloadLayout" function from Layout.js to refresh the full page
				reloadLayout();
			});
		}
	}

	const switchLanguage = (event, lang) => {
		console.log(userMenu);
		console.log(userMenu.current);
		userMenu.current.hide(event);
		setLanguage(lang);
	}

	const updateUnviewedUserMessages = async () => {
		const userMessagesCount = await realTimeMessagingServices.getMessagesCount();
		setUserMessagesCount(userMessagesCount);
	}

	const newMessageReceived = async (msg) => {
		updateUnviewedUserMessages();
	}

	const removedMessageReceived = async () => {
		updateUnviewedUserMessages();
	}

	const authorization = async () => {
		let user = await authService.getUser();
		// console.log(user);
		if (user) {
			setAdministrationEnabled(user.isAdmin || user.isDeveloper || user.isGlobalAdmin || user.isDirector || user.isManager);
		} else {
			setAdministrationEnabled(false);
		}
	}

	useEffect(() => {
		async function startup() {
			await authorization();

			messagesSubscription.current = realTimeMessagingServices.subscribe(async (action, msg) => {
				if (action === RealTimeMessagesService.NEW_MESSAGE) {
					// We received a "new message to show at TOAST" signal
					await newMessageReceived(msg);
				} else if (action === RealTimeMessagesService.REMOVED_MESSAGE) {
					// We received a "messages deleted" signal
					await removedMessageReceived();
				} else if (action === RealTimeMessagesService.UPDATE_SIGNAL ||
					action === RealTimeMessagesService.REMOVE_ALL) {
					// We received a "messages updated" signal
					await updateUnviewedUserMessages();
				}
			});

			authSubscription.current = authService.subscribe(async () => {
				await authorization();
			});

			authSubscriptionTokenExp.current = authService.subscribeTokenExpiration(async () => {
				setMenuImageHackTimestamp(Date.now());
			});

			setTimeout(async () => { await updateUnviewedUserMessages() }, 500);

			let lang = i18n.language;
			if (lang.indexOf('-') >= 0) {
				lang = lang.replace(/[-_][a-z]+$/i, "");
			}

			setLanguage(lang);
		}

		startup();

		// Use this for "component unmount"
		return () => {
			realTimeMessagingServices.unsubscribe(messagesSubscription.current);
			authService.unsubscribe(authSubscription.current);
			authService.unsubscribeTokenExpiration(authSubscriptionTokenExp.current);
		}
	}, []); // eslint-disable-line react-hooks/exhaustive-deps

	const showUserMenu = (event) => {
		userMenu.current.toggle(event);
		event.preventDefault();
	}

	return (
	<header className="header" timestamp={menuImageHackTimestamp}>
		<TieredMenu model={userMenuItems} popup ref={userMenu} id="popup_menu" />

		<div className="header-logo">
			<Link to="/" title={t('Dashboard.Header')} >
			</Link>
		</div>
		<nav className="header-nav noprint">
			{/*<NavLink*/}
			{/*	to="/"*/}
			{/*	end*/}
			{/*	className={({ isActive }) => classNames("header-icon", "home", isActive ? "is-active" : "")}*/}
			{/*	title={t('Dashboard.Header')}>*/}
			{/*</NavLink>*/}
			<NavLink to="/pricing"
					className={({ isActive }) => classNames("header-icon", "pricing", isActive ? "is-active" : "")}
					title={t('PricingPage.Title')}>
			</NavLink>
			<NavLink to="/relationships"
					className={({ isActive }) => classNames("header-icon", "relationship", isActive ? "is-active" : "")}
					title={t('Relationships.Header')}>
			</NavLink>
			<NavLink to="/patterns"
					className={({ isActive }) => classNames("header-icon", "patterns", isActive ? "is-active" : "")}
					title={t('Patterns.Header')}>
			</NavLink>
			{/*<NavLink to="/browse"*/}
			{/*		className={(isActive) => classNames("header-icon", "browse", isActive ? "is-active" : "")}*/}
			{/*		title="Browse">*/}
			{/*</NavLink>*/}
		</nav>
		<div className="header-user noprint">
			{administrationEnabled && (
				<NavLink to="/config"
					className={({ isActive }) => classNames("header-icon", "admin", isActive ? "is-active" : "")}
					title={t('Administration.Header')}>
				</NavLink>
			)}
			<Avatar className="p-overlay-badge text-white" icon="pi pi-user" size="large" shape="circle" onClick={(e) => {
				showUserMenu(e);
			}}><Badge severity="info" value={userMessagesCount} /></Avatar>
		</div>
	</header>)
}
export default withTranslation()(Header)
