export class Calculate {
	constructor() {
		this.BOUNDARY_OFFSET_DEMAND_CURVE = 0.1;
		this.CHART_OFFSET = 0.1;
	}

	/**
	 *  Rounds given price to correct decimal points
	 *  @param {Number} price to round
	 */
	roundPrice(price) {
		return (Math.round(price * 100) / 100);
	}
	
	/**
	 *  Return the minimum allowed user price for given pricing element
	 *  @param {object} pricing item
	 */
	minUserPriceAllowed(item) {
		// let min = item.unitCostT1;
		let min = (1 - this.BOUNDARY_OFFSET_DEMAND_CURVE) * item.guardrailLow;
		return this.roundPrice(min);
	}

	/**
	 *  Return the maximum allowed user price for given pricing element
	 *  @param {object} pricing item
	 */
	maxUserPriceAllowed(item) {
		//  let max = 2 * item.guardrailHigh;
		let max = (1 + this.BOUNDARY_OFFSET_DEMAND_CURVE) * item.guardrailHigh;

		if (max < item.recommendedSellingPrice) {
			return this.roundPrice(item.recommendedSellingPrice);
		} else {
			return this.roundPrice(max);
		}
	}

	/**
	 *  Checks if given historic point is valid for demand curve
	 *  @param {number} index Index of the historic point being evaluated
 	 *  @param {object} item the item with the data
	 *  @returns true if historic point is valid for demand curve, false otherwise
	 */
	includeHistoricPoint(index, item) {
		var isValid = (item.historyUnits[index] > 0 && item.history[index] > 0);

		if (isValid) {
			if (item.interpolatedBecauseNoData.find(i => i == item.historyTransactions[index])) {
				// Hide interpolated data because it's not real data
				isValid = false;
			}
		}

		//if (isValid) {
		//	if (item.outliersIds.find(i => i == item.historyTransactions[index])) {
		//		isValid = false;
		//	}
		//}

		return isValid;
	}

	/**
	 *  Checks if given historic point is an outlier
	 *  @param {number} index Index of the historic point being evaluated
	   *  @param {object} item the item with the data
	 *  @returns true if historic point is an outlier, false otherwise
	 */
	isOutlier(index, item) {
		if (!item.outliersIds) {
			return false;
		} else {
			if (item.outliersIds.find(i => i === item.historyTransactions[index])) {
				return true;
			} else {
				return false;
			}
		}
	}


	/**
	 *  Retrieve the data from demand curve that must be plotted
	 *  @param {object} item the item with the data
	 *  @param {number} otherPrice (Optional) other price to consider to calculate boundaries
	 *  @param {number} minPrice (Optional) minimum price to use
	 *  @param {number} maxPrice (Optional) maximum price to use
	 *  @param {boolean} onlyPositive (Optional) if true, filter to retrieve only positive units and positive net profit points
	 */
	retrieveDemandCurveData(item, otherPrice, onlyPositive, minPrice, maxPrice) {
		var minCurve = item.guardrailLow;
		var maxCurve = item.guardrailHigh;

		if (minPrice !== undefined && Number.isFinite(minPrice)) {
			minCurve = Math.min(minCurve, minPrice);
		}
		if (maxPrice !== undefined && Number.isFinite(maxPrice)) {
			maxCurve = Math.max(maxCurve, maxPrice);
		}

		if (otherPrice) {
			minCurve = Math.min(minCurve, otherPrice);
			maxCurve = Math.max(maxCurve, otherPrice);
		}

		// Add a 10% of the data window on left and right of the chart
		minCurve = minCurve * (1 - this.BOUNDARY_OFFSET_DEMAND_CURVE);
		maxCurve = maxCurve * (1 + this.BOUNDARY_OFFSET_DEMAND_CURVE);

		if (onlyPositive === true) {
			return item.demandCurve
				.filter(d => d.netProfit >= 0 && d.units >= 0 && d.price >= minCurve && d.price <= maxCurve);
		} else {
			return item.demandCurve
				.filter(d => d.price >= minCurve && d.price <= maxCurve);
		}
	}

	/**
	 *  Retrieve min/max range to show on the demand curve charts
	 *  @param {object} item the item with the data that will be calculated
	 *  @param {number} otherPrice (Optional) other price to consider to calculate boundaries
	 *  @param {boolean} historicMinMax (Optional) historic points boundaries for chart
	 *  @param {number} chartOffset (Optional) user defined chart offset to use for chart
	 */
	minMaxDemandCurveChartValue(item, otherPrice, historicMinMax, chartOffset) {
		// For the min and max boundaries of the chart, we need to consider:
		//		* the "newPriceT1"
		//		* the guardrails
		//		* recommendedPrice when user has entered a price

		var min = Math.min(item.guardrailLow, item.newPriceT1, item.priceLastPeriod, item.recommendedSellingPrice); //, this.minUserPriceAllowed(item));
		var max = Math.max(item.guardrailHigh, item.newPriceT1, item.priceLastPeriod, item.recommendedSellingPrice); //, this.maxUserPriceAllowed(item));

		if (otherPrice) {
			min = Math.min(min, otherPrice);
			max = Math.max(max, otherPrice);
		}

		if (historicMinMax) {
			min = Math.min(min, historicMinMax.min);
			max = Math.max(max, historicMinMax.max);
		}

		if (item.demandCurve) {
			var minDemandCurve = item.demandCurve[0].price;
			var maxDemandCurve = item.demandCurve[item.demandCurve.length - 1].price;

			min = Math.min(min, minDemandCurve);
			max = Math.max(max, maxDemandCurve);
        }

		// Add a 10% of the data window on left and right of the chart
		var ratio = (max - min);
		if (chartOffset) {
			min = min - chartOffset * ratio;
			max = max + chartOffset * ratio;
		} else {
			min = min - this.CHART_OFFSET * ratio;
			max = max + this.CHART_OFFSET * ratio;
		}


		return { min: min, max: max };
	}

	/**
	 *  Checks if we have demand curve available
	 *  @param {object} item the item with the data that will be calculated
	 */
	hasLatestDemandCurveData(item) {
		return (item.demandCurve !== null);
	}

	/**
	 *  Format given dummy effect estimate value to show in grids
	 *  @param {number} value numeric value to format
	 *  @returns {string} formatted value
	 */
	formatDummyEffectEstimateNumber(value) {
		var retValue = value;

		if (value >= Math.pow(10, 5)) {
			retValue = value.toExponential(2);
		} else {
			retValue = retValue.toFixed(0);

			if (retValue === '-0') {
				retValue = '0';
			}
		}

		return retValue;
	}

	/**
	 *  Calculate synthetic variables on the pricing item
	 *  @param {object} item the item with the data that will be calculated
	 */
	calculatePricingItem(item)
	{
		item.priceThisPeriod = item.pricePeriodT;
		item.priceLastPeriod = item.pricePeriodT;
		item.profitThisPeriod = (item.priceThisPeriod - item.unitCostT) * item.unitsPeriodT;
		item.grossProfitThisPeriod = (item.priceThisPeriod * item.unitsPeriodT);

		if (item.recommendedSellingPrice === undefined) {
			item.recommendedSellingPrice = this.roundPrice(item.pricePeriodT1);
		}

		if (item.originalUnitsPeriodT1 === undefined) {
			item.originalUnitsPeriodT1 = item.unitsPeriodT1;
		}

		item.projectedTotalProfit = this.calculateProfit(item, item.recommendedSellingPrice);

		// Special case: User price with demand curve, we need to calculate UnitsT1 by ourselves based on extrapolation
		if (item.hasDemandCurve && (item.periodT1From === 0 || this.priceEditedByUser(item))) {
			item.unitsPeriodT1 = item.projectedTotalProfit / (item.recommendedSellingPrice - item.unitCostT1);
		} else {
			item.unitsPeriodT1 = item.originalUnitsPeriodT1;
		}

		item.changeInProfit = this.roundPrice(item.projectedTotalProfit - item.profitThisPeriod);		
		item.changeInProfitNormalized = item.changeInProfit / item.profitThisPeriod;

		item.changeInPrice = item.pricePeriodT1 - item.priceThisPeriod;
		item.changeInPriceNormalized = item.changeInPrice / item.priceThisPeriod;

		item.projectedTotalGrossProfit = item.unitsPeriodT1 * item.recommendedSellingPrice;
		item.changeInGrossProfit = this.roundPrice(item.projectedTotalGrossProfit - item.grossProfitThisPeriod);

		if (Math.abs(Math.round(item.changeInPrice)) > Number.EPSILON) {
			item.priceImpactOnProfit = Math.abs(item.changeInProfit / item.changeInPrice);
		} else {
			item.priceImpactOnProfit = 0;
		}

		if (item.hasDemandCurve) {
			item.guardrailLow = Math.max(item.guardrailLow, item.demandCurve[0].price);
			item.guardrailHigh = Math.min(item.guardrailHigh, item.demandCurve[item.demandCurve.length - 1].price);
        }

		//if (item.changeInPriceNormalized !== 0) {
		//    item.priceImpactOnProfit = item.changeInProfitNormalized / item.changeInPriceNormalized;
		//} else {
		//    item.priceImpactOnProfit = 0;
		//}

		//if (item.sku == 3000006560 && item.storeNumber == 25027) {
		//    debugger;
		//}
	}

	/**
	 * Checks if given field name is a dummy
	 * @param {string} fieldName Field name
	 * @returns {boolean} True if field is a dummy, false otherwise
	 * */
	isDummy(fieldName) {
		if (fieldName === "PromoFlag1" ||
			fieldName === "PromoFlag2" ||
			fieldName === "PromoFlag3" ||
			fieldName === "Dummy4" ||
			fieldName === "Dummy5" ||
			fieldName === "Dummy6" ||
			fieldName === "Dummy7" ||
			fieldName === "Dummy8" ||
			fieldName === "Dummy9" ||
			fieldName === "Dummy10" ||
			fieldName === "Dummy11" ||
			fieldName === "Dummy12" ||
			fieldName === "Dummy13" ||
			fieldName === "Dummy14" ||
			fieldName === "Dummy15" ||
			fieldName === "Dummy16" ||
			fieldName === "StockOut") {
			return true;
		} else {
			return false;
		}
	}

	/**
	 *  Checks if the price has been edited by user
	 *  @param {object} item the observation
	 *  @returns {boolean} true if price has been edited by user, false otherwise
	 */
	priceEditedByUser(item) {
		return (item.isRelationshipItem === false && (item.hasDemandCurve || item.pdu) && item.recommendedSellingPrice !== item.originalRecommendedPrice);
	}

	/**
	 *  Checks if the price has been edited by user and is inside the demand curve
	 *  @param {object} item the observation
	 *  @returns {boolean} true if user price is inside demand curve, false otherwise
	 */
	userPriceOutsideDemandCurve(item) {
		if (this.priceEditedByUser(item) && item.hasDemandCurve) {
			if (item.recommendedSellingPrice < item.demandCurve[0].price ||
				item.recommendedSellingPrice > item.demandCurve[item.demandCurve.length - 1].price) {
				return true;
			} else {
				return false;
            }
		} else {
			return false;
        }
    }

	/**
	 *  Calculate the profit for given pricing data
	 *  @param {object} item the item with the data that will be calculated
	 *  @param {number} price the price to estimate the profit for
	 *  @returns {number} calculated profit
	 */
	calculateProfit(item, price) {
		if (item.hasDemandCurve && item.demandCurve.length > 0 && (item.periodT1From === 0 || this.priceEditedByUser(item))) {
			// User defined price with existing demand curve, do an estimate using the demand curve
			// PriceEditedByUser will detect when the price has been edited by user, but not sent to backend and saved to database yet (inmediately
			// after the price has been changed in UI)
			return this.predictDemandCurveProfit(item, price);
		}
		else {
			return this.roundPrice((price - item.unitCostT1) * item.unitsPeriodT1)
		}
	}

	/**
	 *  Linear extrapolation
	 *  @param {array} values the list of all points.
	 *  @param {number} k0 index of the point to extrapolate, having only the x coordinate and we will return the y extrapolation for it
	 *  @param {number} k index of the first point (k) to use for extrapolation
	 *  @param {number} k1 index of the second point (k+1) to use for extrapolation
	 *  @returns {number} the interpolated y coordinate for point [k0].x
	 */	
	linearExtrapolate(values, k0, k, k1)
	{
		// Linear extrapolation y(x(*)) = y(k-1) + ((x(*) - x(k-1))/(x(k) - x(k-1))) * (y(k) - y(k-1))
		let x = values[k0].x
		let xk = values[k].x
		let xk1 = values[k1].x
		let yk = values[k].y
		let yk1 = values[k1].y
		return xk !== xk1 ? yk1 + ((x - xk1) / (xk - xk1)) * (yk - yk1) : yk // If two last points are equal avoid divide by zero
	}

	/**
	 *  Predict demand curve profit for given user price
	 *  @param {object} item Pricing item data having the demand curve data inside 
	 *  @param {number} userPrice The price to evaluate
	 *  @returns predicted net profit for given price
	 */
	predictDemandCurveProfit(item, userPrice)
	{
		if (item == null || userPrice == null || isNaN(userPrice) ) {
			return NaN;
		} else {
			var points = [{ label: 'userPrice', x: userPrice, y: null }];

			var found = false;

			var extendedDemandCurve = item.demandCurve;

			if (userPrice >= extendedDemandCurve[0].price && userPrice <= extendedDemandCurve[extendedDemandCurve.length - 1].price) {
				// Locate 2 nearest points to do a linear interpolation
				for (var i = 0; i < extendedDemandCurve.length && !found; i++) {
					if (extendedDemandCurve[i].price >= userPrice) {
						if (extendedDemandCurve[i].price === userPrice) {
							points[0].y = extendedDemandCurve[i].netProfit;
						} else {
							// point (k)
							points.push({
								label: 'k', x: extendedDemandCurve[i - 1].price, y: extendedDemandCurve[i - 1].netProfit
							});

							// point (k+1)
							points.push({
								label: 'k+1', x: extendedDemandCurve[i].price, y: extendedDemandCurve[i].netProfit
							});

							// extrapolation
							points[0].y = this.linearExtrapolate(points, 0, 1, 2);
						}

						found = true;
					}
				}
			}

			if (found) {
				return points[0].y;
			} else {
				if (extendedDemandCurve.length >= 2) {
					if (userPrice < extendedDemandCurve[0].price) {
						// point (k) = item 0
						points.push({
							label: 'k', x: extendedDemandCurve[0].price, y: extendedDemandCurve[0].netProfit
						});

						// point (k+1) = item 1
						points.push({
							label: 'k+1', x: extendedDemandCurve[1].price, y: extendedDemandCurve[1].netProfit
						});
					} else if (userPrice > extendedDemandCurve[extendedDemandCurve.length - 1].price) {
						// point (k) = item (n-1)
						points.push({
							label: 'k', x: extendedDemandCurve[extendedDemandCurve.length - 2].price, y: extendedDemandCurve[extendedDemandCurve.length - 2].netProfit
						});

						// point (k+1) = item (n)
						points.push({
							label: 'k+1', x: extendedDemandCurve[extendedDemandCurve.length - 1].price, y: extendedDemandCurve[extendedDemandCurve.length - 1].netProfit
						});
					}

					// extrapolation
					return this.linearExtrapolate(points, 0, 1, 2);
				} else {
					return NaN;
				}
			}
		}
	}
}
