import axios from 'axios'
import authService from '../../components/api-authorization/AuthorizeService';
import Moment from 'moment';

function roundNumber(value, decimals) {
	var power = Math.pow(10, decimals);
	return Math.round((value + Number.EPSILON) * power) / power;
}

export class DashboardDataService {
	_subscription = null;
	_token = null;
	_subscribeTokenExpiration = null;

	constructor() {
		this._subscription = authService.subscribe(() => {
			this.authorize();
		});

		this._subscribeTokenExpiration = authService.subscribeTokenExpiration((newToken) => {
			if (newToken !== this._token) {
				// console.log('upgrading DashboardDataService token', newToken);
				this._token = newToken;
			}
		});

		this.authorize();
	}

	authorize() {
		authService.isAuthenticated().then((isAuthenticated) => {
			// console.debug("PricingDataService, isAuthenticated: ", isAuthenticated);
			if (isAuthenticated) {
				authService.getAccessToken().then((token) => {
					this._token = token;
					// console.debug("PricingDataService, received token: ", token);
				});
			} else {
				this._token = null;
			}
		});

		// console.debug("PricingDataService: ", this._token);
	}

	async getMultiTabWidgetData(i18n) {
		var ret = null;

		if (this._token != null) {
			const config = {
				headers: { Authorization: `Bearer ${this._token}` }
			};

			let response = await axios.get('dashboard/pricing', config);

			if (response.status === 200) {
				// console.debug("getMultiTabWidgetData() OK");

				ret = {
					tabs: [
						{
							name: i18n.t('Dashboard_Pricing.Pricing_Maturity_Tab'),
							type: "pie",
							labels: [
								i18n.t("Dashboard_Pricing.2_Years_Or_More"),
								i18n.t("Dashboard_Pricing.60_Days"),
								i18n.t("Dashboard_Pricing.1_Year"),
								i18n.t("Dashboard_Pricing.30_Days"),
								i18n.t("Dashboard_Pricing.120_Days")
							],
							legend: {
								display: false,
							},
							tooltip: {
								callbacks: {
									label: function (tooltipItem, data) {
										var label = tooltipItem.label || '';

										if (label) {
											label += ': ';
										}
										label += tooltipItem.formattedValue + '%';

										return label;
									}
								}
							},
							categories: [
								{ color: "#014548", title: i18n.t("Dashboard_Pricing.2_Years_Or_More"), value: response.data.pricingMaturity.values[0], percentage: `${response.data.pricingMaturity.values[0].toFixed(2)}%` },
								{ color: "#FF911E", title: i18n.t("Dashboard_Pricing.60_Days"), value: response.data.pricingMaturity.values[3], percentage: `${response.data.pricingMaturity.values[3].toFixed(2)}%` },
								{ color: "#2F989C", title: i18n.t("Dashboard_Pricing.1_Year"), value: response.data.pricingMaturity.values[1], percentage: `${response.data.pricingMaturity.values[1].toFixed(2)}%` },
								{ color: "#BC4211", title: i18n.t("Dashboard_Pricing.30_Days"), value: response.data.pricingMaturity.values[4], percentage: `${response.data.pricingMaturity.values[4].toFixed(2)}%` },
								{ color: "#FFB362", title: i18n.t("Dashboard_Pricing.120_Days"), value: response.data.pricingMaturity.values[2], percentage: `${response.data.pricingMaturity.values[2].toFixed(2)}%` }
							],
						},
						{
							name: i18n.t('Dashboard_Pricing.Pricing_Direction_Tab'),
							type: "polarArea",
							labels: [
								i18n.t('Dashboard_Pricing.Pricing_Direction_Higher'),
								i18n.t('Dashboard_Pricing.Pricing_Direction_Unchanged'),
								i18n.t('Dashboard_Pricing.Pricing_Direction_Lower'),
							],
							legend: {
								display: false,
							},
							tooltip: {
								callbacks: {
									label: function (tooltipItem, data) {
										var label = tooltipItem.label || '';

										if (label) {
											label += ': ';
										}
										label += tooltipItem.formattedValue + '%';

										return label;
									}
								}
							},
							scales: {
								r: {
									grid: {
										display: false,
									},
									ticks: {
										display: false,
									},
								},
							},
							categories: [
								{ color: "#2E999E", title: i18n.t("Dashboard_Pricing.Pricing_Direction_Higher"), value: response.data.pricingDirection.higher, percentage: `${response.data.pricingDirection.higher.toFixed(2)}%` },
								{ color: "#8fb9bf", title: i18n.t("Dashboard_Pricing.Pricing_Direction_Unchanged"), value: response.data.pricingDirection.unchanged, percentage: `${response.data.pricingDirection.unchanged.toFixed(2)}%`  }
							],
							leftCategories: [
								{ color: "#C05220", title: i18n.t("Dashboard_Pricing.Pricing_Direction_Lower"), value: response.data.pricingDirection.lower, percentage: `${response.data.pricingDirection.lower.toFixed(2)}%` }
							]
						},
						{
							name: i18n.t('Dashboard_Pricing.Pricing_Source_Current_Tab'),
							type: "doughnut",
							labels: [
								i18n.t("Dashboard_Pricing.Source_Current_Pricing_Unchanged"),
								i18n.t("Dashboard_Pricing.Source_Current_Pricing_UserEntry"),
								i18n.t("Dashboard_Pricing.Source_Current_Pricing_PriceTest"),
								i18n.t("Dashboard_Pricing.Source_Current_Pricing_DemandCurve")
							],
							tooltip: {
								callbacks: {
									label: function (tooltipItem, data) {
										var label = tooltipItem.label || '';

										if (label) {
											label += ': ';
										}
										label += tooltipItem.formattedValue + '%';

										return label;
									}
								}
							},
							legend: {
								display: false,
							},
							categories: [
								{ color: "#8FB9BF", title: i18n.t("Dashboard_Pricing.Source_Current_Pricing_Unchanged"), value: response.data.currentPricing.sourceUnchangedPercentage, percentage: `${response.data.currentPricing.sourceUnchangedPercentage.toFixed(2)}%` },
								{ color: "#C05220", title: i18n.t("Dashboard_Pricing.Source_Current_Pricing_UserEntry"), value: response.data.currentPricing.sourceUserEntryPercentage, percentage: `${response.data.currentPricing.sourceUserEntryPercentage.toFixed(2)}%` },
								{ color: "#004547", title: i18n.t("Dashboard_Pricing.Source_Current_Pricing_PriceTest"), value: response.data.currentPricing.sourcePriceTestPercentage, percentage: `${response.data.currentPricing.sourcePriceTestPercentage.toFixed(2)}%` },
								{
									color: "#DD7D07",
									title: i18n.t("Dashboard_Pricing.Source_Current_Pricing_DemandCurve"), value: response.data.currentPricing.sourceDemandCurvePercentage, percentage: `${response.data.currentPricing.sourceDemandCurvePercentage.toFixed(2)}%`,
									subTitle: i18n.t("Dashboard_Pricing.Source_Current_Pricing_DemandCurve_Constrained", { Perc: response.data.currentPricing.sourceDemandCurveNotExtended.toFixed(2) }), otherValue: response.data.currentPricing.sourceDemandCurveNotExtended
								}
							],
						},
					],
				};

				// console.log(ret);
			} else {
				let error = response.data;
				console.error(error);
			}
		}

		return ret;
	}

	async getMultiChartData(i18n) {
		var ret = null;

		if (this._token != null) {
			const config = {
				headers: { Authorization: `Bearer ${this._token}` }
			};

			let responseOportunity = await axios.get('dashboard/opportunity', config);
			let responsePerformance = null;

			if (responseOportunity.status === 200) {
				responsePerformance = await axios.get('dashboard/performance', config);

				if (responsePerformance.status === 200) {
					// console.debug("getMultiChartData() OK");

					ret = [
						{
							name: i18n.t('Dashboard_Opportunity.Opportunity_Tab'),
							tabs: [
								{
									name: i18n.t('Dashboard_Opportunity.Items_Potential_For_Profit_Gain'),
									type: "bar",
									maxBarThickness: 50,
									legend: {
										position: "bottom",
										display: false,
									},
									tooltip: {
										callbacks: {
											label: function (tooltipItem, data) {
												var label = tooltipItem.dataset.label || '';

												if (label) {
													label += ': ';
												}
												label +=tooltipItem.parsed.y;

												return label;
											}
										}
									},
									scales: {
										x: {
											ticks: {
												color: "#004547",
											},
											grid: {
												color: "",
											},
											title: {
												color: "#014548",
												display: true,
												text: i18n.t('Dashboard_Opportunity.Potential_For_Profit_Gain'),
												font: {
													size: 13,
													weight: 600,
												},
											},
										},
										y: {
											ticks: {
												color: "#004547",
												callback: function (label, index, labels) {
													return (label < 0) ? 0 : label;
												}
											},
											title: {
												color: "#014548",
												display: true,
												text: i18n.t('Dashboard_Opportunity.Number_Of_Items'),
												font: {
													size: 13,
													weight: 600,
												},
											},
										},
									},
									labels: responseOportunity.data.buckets.map(b => `${b.toFixed(0)}%`),
									color: "#004547",
									barName: i18n.t('Dashboard_Opportunity.Items_Count_Category'),
									categories: responseOportunity.data.values.map(v => ({ value: v, color: "#004547" }))
								},
							],
						},
						{
							name: i18n.t('Dashboard_Performance.Performance_Tab'),
							tabs: [
								{
									name: i18n.t('Dashboard_Performance.Profit_Change_For_Items_By_Category'),
									type: "bar",
									labels: [
										i18n.t('Dashboard_Performance.Label_Last_Year'),
										i18n.t('Dashboard_Performance.Label_Last_Quarter'),
										i18n.t('Dashboard_Performance.Label_Last_Month'),
										i18n.t('Dashboard_Performance.Label_Last_Week')
									],
									legend: {
										position: "bottom",
										display: true,
										labels: {
											color: "#004547",
											boxWidth: 10,
											font: {
												size: 11,
											},
										},
									},
									tooltip: {
										callbacks: {
											label: function (tooltipItem, data) {
												var label = tooltipItem.dataset.label || '';

												if (label) {
													label += ': ';
												}
												label +=  roundNumber(tooltipItem.parsed.y, 2) + '%';

												return label;
											}
										}
									},
									scales: {
										x: {
											grid: {
												color: "",
											},
											ticks: {
												color: "#004547",
											},
											gridLines: {
												drawBorder: false,
											},
										},
										y: {
											grid: {
												color: "",
											},
											ticks: {
												color: "#004547",
												stepSize: 5,
												callback: function (value) {
													return value + "%";
												},
											},
										},
									},
									categories: [
										{
											label: i18n.t('Dashboard_Performance.System_Repricings'),
											color: "#eab470",
											barCategory: responsePerformance.data.systemRepricings.map(v => ({ value: v }))
										},
										{
											label: i18n.t('Dashboard_Performance.User_Changes_Suggested_Repricings'),
											color: "#8aafc9",
											barCategory: responsePerformance.data.userChanges.map(v => ({ value: v }))
										},
										{
											label: i18n.t('Dashboard_Performance.User_Change_No_Suggested_Repricing'),
											color: "#c05220",
											barCategory: responsePerformance.data.userChangeNoSuggested.map(v => ({ value: v }))
										},
										{
											label: i18n.t('Dashboard_Performance.Unchanged_Prices'),
											color: "#94cac1",
											barCategory: responsePerformance.data.unchangedPrices.map(v => ({ value: v }))
										}
									],
								},
							],
						},
					];
				} else {
					let error = responsePerformance.data;
					console.error(error);
				}
			} else {
				let error = responseOportunity.data;
				console.error(error);
			}
		}

		return ret;
	}

	async getKpiPerformance(i18n) {
		var ret = null;

		if (this._token != null) {
			const config = {
				headers: { Authorization: `Bearer ${this._token}` }
			};

			let response = await axios.get('dashboard/kpiperformancelastweek', config);

			if (response.status === 200) {
				// console.debug("getKpiPerformance() OK");

				ret = {
					tabs: [
						{
							name: i18n.t('Dashboard_KPI_Performance.KPI_LastWeek_Tab'),
							type: "bar",
							// maxBarThickness: 25,
							labels: [
								i18n.t('Dashboard_KPI_Performance.Revenue'),
								i18n.t('Dashboard_KPI_Performance.Units'),
								i18n.t('Dashboard_KPI_Performance.Profit'),
								i18n.t('Dashboard_KPI_Performance.Margin'),
								i18n.t('Dashboard_KPI_Performance.Cost')
							],
							legend: {
								position: "bottom",
								display: true,
								labels: {
									color: "#004547",
									boxWidth: 10,
									font: {
										size: 14,
									},
								},
							},
							tooltip: {
								//enabled: false,
								//external: function (context) {
								//	// Tooltip Element
								//	var tooltipEl = document.getElementById('chartjs-tooltip');
								//},
								callbacks: {
									title: function () { },
									label: function (tooltipItem) {
										return tooltipItem.label;
									},
									footer: function (tooltipItem) {
										const tooltipData = [];
										var accumulated = tooltipItem[0].dataset.accumulated[tooltipItem[0].dataIndex].value;

										tooltipData.push(i18n.t('Dashboard_KPI_Performance.Tooltip_Perc_Change') + tooltipItem[0].formattedValue + '%');
										tooltipData.push(i18n.t('Dashboard_KPI_Performance.Tooltip_Abs_Change') + accumulated.toFixed(2));

										return tooltipData;
									}
								}
							},
							//title: {
							//	color: "#004547",
							//	display: true,
							//	text: i18n.t('Dashboard_KPI_Performance.KPI_Chart_Title')
							//},
							scales: {
								x: {
									// stacked: true,
									barPercentage: 0.7,
									categoryPercentage: 1,
									grid: {
										color: "",
									},
									ticks: {
										color: "#004547",
									},
								},
								y: {
									// stacked: true,
									grid: {
										color: "#ebedef",
									},
									ticks: {
										color: "#004547",
										callback: function (value) {
											return value + "%";
										},
									},
									title: {
										color: "#014548",
										display: true,
										text: i18n.t('Dashboard_KPI_Performance.Total_Percentage'),
										font: {
											size: 13,
											weight: 600,
										},
									},
								},
							},
							bar: [
								{
									label: i18n.t('Dashboard_KPI_Performance.Unchanged'), color: '#2E999E',
									accumulated: response.data.unchanged_Cum.map(v => ({ value: roundNumber(v, 2) })),
									categories: response.data.unchanged.map((v) => ({ value: roundNumber(v * 100, 2) }))
								},
								{
									label: i18n.t('Dashboard_KPI_Performance.Repriced'), color: '#004547',
									accumulated: response.data.repriced_Cum.map(v => ({ value: roundNumber(v, 2) })),
									categories: response.data.repriced.map((v, idx) => ({ value: roundNumber(v * 100, 2) }))
								}
							]
						},
					],
				};
			} else {
				let error = response.data;
				console.error(error);
			}
		}

		return ret;
	}

	async getSpotLight(i18n) {
		var ret = null;

		if (this._token != null) {
			const config = {
				headers: { Authorization: `Bearer ${this._token}` }
			};

			let response = await axios.get('dashboard/spotlight', config);

			if (response.status === 200) {
				// console.debug("getSpotLight() OK");
				ret = {
					tabs: [
						{
							name: i18n.t("Dashboard_Spotlight.Spotlight_Tab"),
							categories: response.data.categories,
						},
					],
				};

				var idx = 0;
				for (idx = 0; idx < ret.tabs[0].categories.length; idx++) {
					ret.tabs[0].categories[idx].label = i18n.t('Dashboard_Spotlight.' + ret.tabs[0].categories[idx].labelResource);
				}
				
			} else {
				let error = response.data;
				console.error(error);
			}
		}

		return ret;
	}

	async getStateOfBusiness() {
		var ret = null;

		if (this._token != null) {
			const config = {
				headers: { Authorization: `Bearer ${this._token}` }
			};

			let response = await axios.get('dashboard/stateOfBusiness', config);

			if (response.status === 200) {
				// console.debug("getStateOfBusiness() OK");
				ret = response.data;
			} else {
				let error = response.data;
				console.error(error);
			}
		}

		return ret;	
	}

	async getRepricingPerformanceLastPeriod(i18n) {
		var ret = null;

		if (this._token != null) {
			const config = {
				headers: { Authorization: `Bearer ${this._token}` }
			};

			let response = await axios.get('dashboard/repricingperformancelastperiod', config);

			if (response.status === 200) {
				// console.debug("getRepricingPerformanceLastPeriod() OK");
				ret = {
					tabs: [
						{
							name: i18n.t('Dashboard_Repricing_Performance.Name'),
							type: "pie",
							categories: [
								{
									color: "#2E999E",
									percentage: `${response.data.higherProfit}`,
									title: i18n.t('Dashboard_Repricing_Performance.Higher_Profit'),
									value: response.data.higherProfit,
								},
								{
									color: "#C05220",
									percentage: `${response.data.lowerProfit}`,
									title: i18n.t('Dashboard_Repricing_Performance.Lower_Profit'),
									value: response.data.lowerProfit,
								},
							],
						},
					],
				};
			} else {
				let error = response.data;
				console.error(error);
			}
		}

		return ret;		
	};

	async getPricingMovementOverTime(i18n) {
		var ret = null;

		if (this._token != null) {
			const config = {
				headers: { Authorization: `Bearer ${this._token}` }
			};

			let response = await axios.get('dashboard/pricingmovementovertime', config);

			if (response.status === 200) {
				// console.debug("getPricingMovementOverTime() OK");
				ret = {
					tabs: [
						{
							name: i18n.t('Dashboard_Pricing_Movement.Name'),
							type: "line",
							scales: {
								x: {
									grid: {
										color: "",
									},
									ticks: {
										color: "#004547",
										autoSkip: false,
										maxRotation: 90,
										minRotation: 45,
									},
								},
								y: {
									ticks: {
										color: "#004547",
									},
									grid: {
										color: "#ebedef",
									},
									gridLines: {
										zeroLineColor: "transparent",
									},
								},
							},
							legend: {
								position: "bottom",
								display: true,
								labels: {
									color: "#004547",
									boxWidth: 10,
									font: {
										size: 11,
									},
								},
							},
							labels: response.data.labels.map(d => Moment(d).format('DD MMM YY')),
							lines: [
								{
									repricedItem: i18n.t('Dashboard_Pricing_Movement.Baseline'),
									borderColor: "#004547",
									tension: 0.4,
									backgroundColor: "#004547",
									categories: response.data.baseline.map(b => ({ value: b })),
								},
								{
									repricedItem: i18n.t('Dashboard_Pricing_Movement.Repriced'),
									borderColor: "#2E999E",
									tension: 0.4,
									backgroundColor: "#2E999E",
									categories: response.data.repriced.map(r => ({ value: r })),
								},
							],
						},
					],
				};
			} else {
				let error = response.data;
				console.error(error);
			}
		}

		return ret;
	};

	async getProfitOpportunityCategory(i18n) {
		var ret = null;

		if (this._token != null) {
			const config = {
				headers: { Authorization: `Bearer ${this._token}` }
			};

			let response = await axios.get('dashboard/profitopportunitybycategory', config);

			var colors = ["#c05220", "#dd7d07", "#8c8c8c", "#636363", "#004547", "#2e999e", "#8fb9bf", "#f0a583"];

			if (response.status === 200) {
				// console.debug("getProfitOpportunityCategory() OK");
				ret = [{
					name: i18n.t('Dashboard_Profit_Opportunity_By_Category.PricingStatusTitle'),
					tabs: [
						{
							name: i18n.t('Dashboard_Profit_Opportunity_By_Category.Name'),
							type: "doughnut",
							labels: response.data.labels,
							legend: {
								position: "right",
								display: true,
								labels: {
									//generateLabels: chart => chart.data.labels.map((l, i) => ({
									//	text: l.length > 13 ? (l.substr(10) + "...") : l
									//})),
									color: "#004547",
									padding: 12,
									usePointStyle: true,
									boxWidth: 8,
									font: {
										size: 13,
									},
								},
							},

							categories: response.data.categories.map((c, idx) => ({ color: colors[idx % colors.length], value: roundNumber(c, 2) })),
						},
					],
				}];

				// console.log(ret);
			} else {
				let error = response.data;
				console.error(error);
			}
		}

		return ret;
	};

	async getPerformancePeriodOverPeriod(i18n) {
		var ret = null;

		if (this._token != null) {
			const config = {
				headers: { Authorization: `Bearer ${this._token}` }
			};

			let response = await axios.get('dashboard/performanceperiodoverperiod', config);

			if (response.status === 200) {
				// console.debug("getPerformancePeriodOverPeriod() OK");
				ret = {
					tabs: [
						{
							name: i18n.t('Dashboard_Performance_Period_Over_Period.Name'),
							type: "bar",
							maxBarThickness: 20,
							labels: response.data.labels.map(d => Moment(d).format('DD MMM YY')),
							legend: {
								position: "bottom",
								display: false,
								labels: {
									padding: 20,
									color: "#004547",
									boxWidth: 10,
									font: {
										size: 14,
									},
								},
							},
							tooltip: {
								callbacks: {
									label: function (tooltipItem, data) {
										var label = tooltipItem.dataset.label || '';

										if (label) {
											label += ': ';
										}
										label += roundNumber(tooltipItem.parsed.y, 2) + '%';

										return label;
									}
								}
							},
							scales: {
								x: {
									stacked: true,
									grid: {
										color: "",
									},
									ticks: {
										beginAtZero: true,
										color: "#004547",
										font: {
											size: 14,
										},
										maxRotation: 90,
										minRotation: 45,
									},
								},

								y: {
									stacked: true,
									// grid: {
									//   color: "",
									// },
									ticks: {
										color: "#004547",
										font: {
											size: 14,
										},
										stepSize: 5,
										callback: function (value) {
											return value + "%";
										},
									},
									title: {
										color: "#014548",
										display: true,
										text: i18n.t('Dashboard_Performance_Period_Over_Period.Total_Percentage'),
										font: {
											size: 13,
											weight: 600,
										},
									},
								},
							},
							categories: [
								{
									percentage: response.data.moreProfitPercentage,
									title: i18n.t('Dashboard_Performance_Period_Over_Period.MoreProfit'),
									color: "#004547",
									barCategory: response.data.moreProfit.map(v => ({ value: v }))
								},
								{
									percentage: response.data.lowerProfitPercentage,
									title: i18n.t('Dashboard_Performance_Period_Over_Period.LowerProfit'),
									color: "#c05220",
									barCategory: response.data.lowerProfit.map(v => ({ value: v }))
								},
								{
									percentage: response.data.lowerProfitAvoidedPercentage,
									title: i18n.t('Dashboard_Performance_Period_Over_Period.LowerProfitAvoided'),
									color: "#6BB6C1",
									barCategory: response.data.lowerProfitAvoided.map(v => ({ value: v }))
								},
							],
						},
					],
				};
			} else {
				let error = response.data;
				console.error(error);
			}
		}

		return ret;
	};

	async getDemandCurve(i18n) {
		var ret = null;

		if (this._token != null) {
			const config = {
				headers: { Authorization: `Bearer ${this._token}` }
			};

			let response = await axios.get('dashboard/demandCurve', config);

			if (response.status === 200) {
				// console.debug("getPerformancePeriodOverPeriod() OK");
				ret = {
					tabs: [
						{
							name: i18n.t('Dashboard_Demand_Curve.Name'),
							type: "line",

							labels: response.data.values.map(v => v.toFixed(2)),
							scales: {
								x: {
									ticks: {
										color: "#014548",
									},
									grid: {
										color: "#ebedef",
									},
									title: {
										color: "#014548",
										display: true,
										text: "Price",
										font: {
											size: 13,
											weight: 600,
										},
									},
								},
								y: {
									ticks: {
										color: "#014548",
										font: {
											size: 14,
										},
									},
									grid: {
										color: "#ebedef",
									},
									title: {
										color: "#014548",
										display: true,
										text: i18n.t('Dashboard_Demand_Curve.Units'),
										font: {
											size: 13,
											weight: 600,
										},
									},
								}
							},
							legend: {
								display: false,
							},
							color: "#014548",
							tension: 0.4,
							categories: response.data.prices.map(v => ({ value: v }))
						}
					]
				};
			} else {
				let error = response.data;
				console.error(error);
			}
		}

		return ret;
	};

	static get instance() { return dashboardService; }
}

const dashboardService = new DashboardDataService();

export default dashboardService;