import React from 'react'
import { NavLink } from 'react-router-dom'

const PricingActionBtn = () => {
  if (
    sessionStorage.getItem('saveRowSelected') &&
    JSON.parse(sessionStorage.getItem('saveRowSelected')).length > 0
  ) {
    return (
      <div className="col-12 lg:col-9 pl-lg-5">
        <div className="text-right mb-4">
          <NavLink
            to="/pricing"
            className="btn btn-primary btn-md btn-rounded-full btn-inline-block"
          >
            Pricing Actions
          </NavLink>
        </div>
      </div>
    )
  }
  return false
}

export default PricingActionBtn
