import React from 'react'
import { withTranslation } from 'react-i18next'
import PricingActionBtn from '../components/Pricing/PricingActionBtn'

function BrowsePage(props) {
	const { t } = props
	return (
		<div className="grid">
			<div className="col-12 lg:col-3">
				<h1 className="text-primary">{t('Browse.Header')}</h1>
			</div>
			<PricingActionBtn />
		</div>
	)
}

export default withTranslation()(BrowsePage)
