import i18n from 'i18next';
import Backend from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';
import { addLocale } from 'primereact/api';

import Moment from 'moment';

i18n
    // load translation using http -> see /public/locales
    // learn more: https://github.com/i18next/i18next-http-backend
    .use(Backend)
    // detect user language
    // learn more: https://github.com/i18next/i18next-browser-languageDetector
    .use(LanguageDetector)
    // pass the i18n instance to react-i18next.
    .use(initReactI18next)
    // init i18next
    // for all options read: https://www.i18next.com/overview/configuration-options
	.init({
		fallbackLng: 'en',
		debug: false,
		languages: ['en', 'es'],
		react: {
			bindI18n: 'languageChanged',
			useSuspense: false
		},
		interpolation: {
			escapeValue: false, // not needed for react as it escapes by default
		}
    }).then((t) => {
        // Add here the PrimeReact's Calendar supported languages
        addLocale('es', {
            firstDayOfWeek: 1,
            dayNames: ['domingo', 'lunes', 'martes', 'miércoles', 'jueves', 'viernes', 'sábado'],
            dayNamesShort: ['dom', 'lun', 'mar', 'mié', 'jue', 'vie', 'sáb'],
            dayNamesMin: ['D', 'L', 'M', 'X', 'J', 'V', 'S'],
            monthNames: ['enero', 'febrero', 'marzo', 'abril', 'mayo', 'junio', 'julio', 'agosto', 'septiembre', 'octubre', 'noviembre', 'diciembre'],
            monthNamesShort: ['ene', 'feb', 'mar', 'abr', 'may', 'jun', 'jul', 'ago', 'sep', 'oct', 'nov', 'dic'],
            today: 'Hoy',
            clear: 'Limpiar'
        });

        let lang = i18n.language;
        if (lang.indexOf('-') >= 0) {
            lang = lang.replace(/[-_][a-z]+$/i, "");
        }

        i18n.changeLanguage(lang);

        // When i18n has finished to load (so we have the detected language accesible), set LOCALE for Moment.js
        // strip COUNTRY portion from language
        Moment.locale(lang);
    });

export default i18n;