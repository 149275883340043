import React, { useEffect, useState } from 'react'
import { withTranslation } from 'react-i18next'
import { Chart } from 'primereact/chart'
import {RadioButton} from "primereact/radiobutton";
import { contextualConfirmDialog } from '../../../components/User/ContextualConfirmDialog'
import {Calculate} from "../../../Api/client-side/calculate";
import { InputNumber } from 'primereact/inputnumber';
import { classNames } from 'primereact/utils';
import { Checkbox } from 'primereact/checkbox';
import moment from 'moment'
// import annotationPlugin from 'chartjs-plugin-annotation';

function GuardrailsPanel(props) {
	const { modal, selectedItem, onPriceChangeHandler, currencySymbol, canEditPrices, t, tReady } = props
	const { selectedPrice, setSelectedPrice } = props
	const [currency, setCurrency] = useState('$');
	const [option, setOption] = useState('');
	const [hasManualPrice, setHasManualPrice] = useState(false);
	const [manualPriceValue, setManualPriceValue] = useState(undefined);
	const [recommendedPrice, setRecommendedPrice] = useState(undefined);
	const [lastPrice, setLastPrice] = useState(undefined);
	const [chartData, setChartData] = useState({});
	const [selectedItemLocal, setSelectedItemLocal] = useState(null);
	const [showHistoric, setShowHistoric] = useState(false);

	var calculate = new Calculate();

	const [basicOptions, setBasicOptions] = useState({
		maintainAspectRatio: true,
		aspectRatio: 1.4,
		animation: {
			duration: 0
		},
		plugins: {
			tooltip: {
				// This is to hide the "boundary points" tooltips
				filter: (tooltipItem) => {
					let label = tooltipItem.dataset.data[tooltipItem.dataIndex].label;
					return (label !== 'start' && label !== 'end' && label !== 'demandCurveData');
				},
				callbacks: {
					title: function (item) {
						// console.log(item);
						var theTitle = '';

						for (var i = 0; i < item.length; i++) {

							let theDate = moment(item[i].raw.label);

							if (theDate.isValid()) {
								theTitle += theDate.format("DD MMM yy");
								theTitle += "\r\n";
							}

							if (i === (item.length - 1)) {
								// Add price
								theTitle += item[i].label;
							}
						}

						return theTitle;
					}
				}
			},			
			autocolors: false,
			enabled: true,
			legend: {
				onClick: null,
				display: false,
				position: 'bottom',
				labels: {
					color: '#495057',
					boxWidth: 10,
					boxHeight: 10,
					filter: function (legendItem, data) {
						let label = data.datasets[legendItem.datasetIndex].label || '';
						if (typeof (label) === 'undefined') {
							return false;
						}
						return label;
					}
				}
			}
		},
		scales: {
			x: {
				title: {
					display: true,
					text: t('PricingTable.PriceAxis')
				},
				ticks: {
					color: '#495057',
					// Workaround the "minimumFractionDigits value is out of range in Chart.js" error when setting the absolute min/max values
					callback: function (val, index) {
						if (val < 100) {
							return calculate.roundPrice(val).toFixed(2);
						} else {
							return calculate.roundPrice(val).toFixed(0);
						}
					}
				},
				grid: {
					color: '#ebedef'
				},
				type: 'linear'
			},
			y: {
				title: {
					display: true,
					text: t('PricingTable.ProfitAxis')
				},
				ticks: {
					color: '#495057'
				},
				grid: {
					color: '#ebedef'
				}
			}
		}
	});

	useEffect(() => {
		// console.log(selectedItem);

		if (selectedItem.current != null) {
			// console.log(selectedItem);
			setSelectedItemLocal(selectedItem.current);
		}
	}, []); // eslint-disable-line react-hooks/exhaustive-deps

	useEffect(() => {
		if (selectedItemLocal != null) {

			if (selectedItemLocal.periodT1From === 0) {
				setManualPriceValue(parseFloat(selectedItemLocal.recommendedSellingPrice));
			}
			renderChartData();
		}
	}, [selectedItemLocal]); // eslint-disable-line react-hooks/exhaustive-deps


	useEffect(() => {
		if (selectedItem != null) {
			renderChartData();
		}
	}, [manualPriceValue]);  // eslint-disable-line react-hooks/exhaustive-deps

	useEffect(() => {
		if (currencySymbol !== null) {
			setCurrency(currencySymbol);
		}
	}, [currencySymbol]);

	useEffect(() => {
		if (selectedItemLocal != null) {
			renderChartData();
		}
	}, [showHistoric])  // eslint-disable-line react-hooks/exhaustive-deps

	/**
	 *  Create the array of points to plot the case when no demand curve is available.
	 *  Extrapolation of user price is made by linear extrapolation of recommended price and last price.
	 *  @returns {Array} array of sorted points to plot the chart
	 */	
	const calculateChartDataLinear = () => {
		const userPrice = hasManualPrice ? parseFloat(manualPriceValue) : ((selectedItemLocal.periodT1From === 0) ? selectedItemLocal.recommendedSellingPrice : null);
		const hasUserValue = userPrice != null && !isNaN(userPrice);
		const recommendedPrice = selectedItemLocal.newPriceT1; // parseFloat(selectedItemLocal.recommendedSellingPrice);
		const lastPrice = selectedItemLocal.priceLastPeriod;

		const guardrailHigh = parseFloat(selectedItemLocal.guardrailHigh);
		const guardrailLow = parseFloat(selectedItemLocal.guardrailLow);

		const recommendedPriceProfit = (recommendedPrice - selectedItemLocal.unitCostT1) * selectedItemLocal.unitsPeriodT1;
		const lastProfit = (lastPrice - selectedItemLocal.unitCostT) * selectedItemLocal.unitsPeriodT;

		var minPrice = Math.min(recommendedPrice, lastPrice, guardrailLow, guardrailHigh);
		var maxPrice = Math.max(recommendedPrice, lastPrice, guardrailLow, guardrailHigh);

		if (hasUserValue) {
			minPrice = Math.min(minPrice, userPrice);
			maxPrice = Math.max(maxPrice, userPrice);
		}

		const stepPrice = maxPrice - ((minPrice + maxPrice) / 2);

		const startPrice = calculate.roundPrice(minPrice - stepPrice);
		const endPrice = calculate.roundPrice(maxPrice + stepPrice);

		const data = [
			{ label: 'start', y: null, x: startPrice },
			{ label: 'recommendedPrice', y: calculate.roundPrice(recommendedPriceProfit), x: recommendedPrice },
			{ label: 'last', y: calculate.roundPrice(lastProfit), x: lastPrice },
			{ label: 'end', y: null, x: endPrice }
		]

		// extrapolate start/end points based on the line generated by "last price" and "recommended price"
		data[0].y = calculate.roundPrice(calculate.linearExtrapolate(data, 0, 2, 1));
		data[3].y = calculate.roundPrice(calculate.linearExtrapolate(data, 3, 2, 1));

		if (hasUserValue) {
			// add user price and extrapolate it based on the line generated by "last price" and "recommended price"
			data.push({ label: 'userPrice', y: null, x: userPrice });
			data[4].y = calculate.roundPrice(calculate.linearExtrapolate(data, 4, 2, 1));
		}

		// Sort by price to plot it
		var dataSorted = data.sort((a, b) => a.x - b.x);

		// Set recommended price (green dot)
		setRecommendedPrice(recommendedPrice);

		// Set last price (orange dot)
		setLastPrice(lastPrice);

		return dataSorted;
	}

	/**
	 *  Create the array of points to plot the demand curve together with special points.
	 *  Extrapolation of user price is made by a linear extrapolation of 2 consecutive demand curve points
	 *  @param {Number} historicMin Historic min price (if available)
	 *  @param {Number} historicMax Historic min price (if available)
	 *  @returns {Array} array of sorted points to plot the chart
	 */
	const calculateChartDataCurve = (historicMin, historicMax) => {
		// Curve extrapolation based on demand curve points
		const userPrice = hasManualPrice ? parseFloat(manualPriceValue) : ((selectedItemLocal.periodT1From === 0) ? selectedItemLocal.recommendedSellingPrice : null);
		const hasUserValue = userPrice != null && !isNaN(userPrice);

		// Use optimalPriceT1 as recommended price for demand curve, that we know is always inside guardrails
		const recommendedPrice = parseFloat(selectedItemLocal.newPriceT1); // parseFloat(selectedItemLocal.recommendedSellingPrice);
		const lastPrice = selectedItemLocal.priceLastPeriod;

		const userPriceProfit = hasUserValue ? calculate.roundPrice(calculate.predictDemandCurveProfit(selectedItemLocal, userPrice)) : null;
		const recommendedPriceProfit = calculate.roundPrice((recommendedPrice - selectedItemLocal.unitCostT1) * selectedItemLocal.predictedUnitsT1);	// Recommended price when it's not an user price!
		const lastProfit = calculate.roundPrice((lastPrice - selectedItemLocal.unitCostT) * selectedItemLocal.unitsPeriodT);

		// Consider user price, that could be outside guardrails from a previous assigned value
		var data = calculate.retrieveDemandCurveData(selectedItemLocal, userPrice, true, historicMin, historicMax)
			.map(item => ({ label: 'demandCurveData', y: item.netProfit, x: item.price }));

		data.push({ label: 'recommendedPrice', y: recommendedPriceProfit, x: recommendedPrice });
		data.push({ label: 'last', y: lastProfit, x: lastPrice });

		if (hasUserValue && !isNaN(userPriceProfit)) {
			data.push({ label: 'userPrice', y: userPriceProfit, x: userPrice });
		}

		var dataSorted = data.sort((a, b) => a.x - b.x);

		setRecommendedPrice(recommendedPrice);
		setLastPrice(lastPrice);

		return dataSorted;
	}

	/**
	 *  Toggle label for vertical line annotations (guardrails)
	 *  @param {object} ctx Annotation plugin context object
	 *  @param {Event} event User event
	 *  @param {string} annotationName Name of the annotation 
	 *  @returns {Array} array of sorted points to plot the chart
	 */
	const toggleLabel = (ctx, event, annotationName, show) => {
		// const oneThirdHeight = ctx.element.height / 3;
		const chart = ctx.chart;
		const annotationOpts = chart.options.plugins.annotation.annotations[annotationName];
		if (show && annotationOpts.label.display === false) {
			annotationOpts.label.display = true;
			annotationOpts.label.position = ((event.y - 5) / ctx.chart.chartArea.height * 100) + '%';
			chart.update();
		} else {
			annotationOpts.label.display = false;
			chart.update();
		}
	}

	/**
	 * Generate and set state for dataset and options variables used by the chart
	 * Set state variables chartData and basicOptions
	 */
	const renderChartData = () => {
		if (selectedItemLocal != null) {
			var minHistoric = Number.POSITIVE_INFINITY;
			var maxHistoric = Number.NEGATIVE_INFINITY;
			var historicRangeRatio = 0;

			var extraSerie = [];
			var historic = [];
			var outliers = [];

			// Historic points
			var addedHistoricPoints = 0;

			var theIdx;
			for (theIdx = selectedItemLocal.history.length - 1; theIdx >= 0 && addedHistoricPoints < selectedItemLocal.demandCurveHistoricPoints; theIdx--) {
				if (calculate.includeHistoricPoint(theIdx, selectedItemLocal)) {
					let historicItem = {
						x: calculate.roundPrice(selectedItemLocal.history[theIdx]),
						y: calculate.roundPrice(selectedItemLocal.historyProfit[theIdx]),
						label: selectedItemLocal.historyObservationDates[theIdx]
					};

					if (calculate.isOutlier(theIdx, selectedItemLocal)) {
						outliers.push(historicItem);
					} else {
						historic.push(historicItem);
					}

					addedHistoricPoints++;

					if (historicItem.x < minHistoric) {
						minHistoric = historicItem.x;
					}
					if (historicItem.x > maxHistoric) {
						maxHistoric = historicItem.x;
					}
				}
			}

			if (showHistoric) {
				historicRangeRatio = (maxHistoric - minHistoric);
			}

			var min = showHistoric ? (minHistoric - 0.05 * historicRangeRatio) : Number.POSITIVE_INFINITY;
			var max = showHistoric ? (maxHistoric + 0.05 * historicRangeRatio) : Number.NEGATIVE_INFINITY;

			var dataSorted = selectedItemLocal.hasDemandCurve ? calculateChartDataCurve(minHistoric, maxHistoric) : calculateChartDataLinear();
			var lineData = dataSorted.map(item => ({ label: item.label, x: item.x, y: item.y }));

			if (!selectedItemLocal.hasDemandCurve) {
				// (1) No demand curve case

				min = Math.min(calculate.roundPrice(lineData[0].x), selectedItemLocal.guardrailLow, selectedItemLocal.guardrailHigh);
				max = Math.max(calculate.roundPrice(lineData[lineData.length - 1].x), selectedItemLocal.guardrailLow, selectedItemLocal.guardrailHigh);
			} else {
				// (2) Demand curve case

				// Last price will probably be outside the demand curve, so put it as a independent series in the dataset
				let findIdx = lineData.findIndex(item => item.label === 'last');

				if (findIdx >= 0) {
					// Save as extra series to show the "Last price point" alone
					extraSerie.push(dataSorted[findIdx]);

					// And remove the last price point from the line data 
					lineData = lineData.slice(0, findIdx).concat(dataSorted.slice(findIdx + 1, dataSorted.length));
				}

				let userPriceIdx = lineData.findIndex(item => item.label === 'userPrice');

				var userPoint = null;

				if (userPriceIdx >= 0) {
					userPoint = lineData[userPriceIdx].x;
				}

				let minMax = calculate.minMaxDemandCurveChartValue(selectedItemLocal, userPoint);

				// Consider the LAST point
				min = Math.min(min, minMax.min);
				max = Math.max(max, minMax.max);
			}

			var pointBackgroundColors = [];
			var pointBorderColors = [];

			// Set colors for points
			for (var idx = 0; idx < lineData.length; idx++) {
				if (lineData[idx].label === 'userPrice') {
					pointBackgroundColors.push('#c05220');
					pointBorderColors.push('#c05220');
				} else if (lineData[idx].label === 'recommendedPrice') {
					pointBackgroundColors.push('#2e999e');
					pointBorderColors.push('#2e999e');
				} else if (lineData[idx].label === 'last') {
					pointBackgroundColors.push('#dd7d07');
					pointBorderColors.push('#dd7d07');
				} else {
					pointBackgroundColors.push('transparent');
					pointBorderColors.push('transparent');
				}
			}

			var dataSet = {
				datasets: []
			};

			dataSet.datasets.push(
				{
					label: t('PricingTable.GuardrailsPanelProfitCurveLegend'),
					data: lineData,
					pointBackgroundColor: pointBackgroundColors,
					pointBorderColor: pointBorderColors,
					type: (calculate.hasLatestDemandCurveData(selectedItemLocal) || selectedItemLocal.pdu) ? 'line' : 'scatter',
					fill: false,
					radius: 6,
					borderColor: '#2e999e',
					backgroundColor: '#2e999e',
					borderWidth: 3,
					// cubicInterpolationMode: (selectedItemLocal.hasDemandCurve) ? 'monotone' : 'none',
					tension: (selectedItemLocal.hasDemandCurve) ? 0.1 : 0
				});

			if (extraSerie.length > 0) {
				dataSet.datasets.splice(0, 0, {
					data: extraSerie,
					fill: true,
					radius: 6,
					borderColor: '#dd7d07',
					backgroundColor: '#dd7d07',
					borderWidth: 3
				});
			}

			if (showHistoric && historic.length > 0) {
				dataSet.datasets.push({
					label: t('PricingTable.GuardrailsPanelObservationsLegend'),
					isHistoricDataset: true,
					type: 'scatter',
					data: historic,
					borderColor: '#004547',
					backgroundColor: '#004547',
					pointRadius: 3,
					pointStyle: 'circle'
				});

				dataSet.datasets.push({
					label: t('PricingTable.GuardrailsPanelOutliersLegend'),
					isHistoricDataset: true,
					type: 'scatter',
					data: outliers,
					borderColor: '#8d350e',
					backgroundColor: '#8d350e',
					pointRadius: 3,
					pointStyle: 'circle'
				});
			}

			var basicOptionsCopy = { ...basicOptions };

			basicOptionsCopy.scales.x.min = min;
			basicOptionsCopy.scales.x.max = max;

			basicOptionsCopy.plugins.annotation = {
				annotations: {
					guardrailLow: {
						drawTime: 'beforeDatasetsDraw',
						type: "line",
						mode: "vertical",
						scaleID: "x",
						value: selectedItemLocal.guardrailLow,
						borderColor: "#ffc98e",
						borderWidth: 3,
						label: {
							content: selectedItemLocal.guardrailLow.toFixed(2),
							display: false,
							font: {
								size: '10px'
							}
						},
						enter(ctx, event) {
							toggleLabel(ctx, event, 'guardrailLow', true);
						},
						leave(ctx, event) {
							toggleLabel(ctx, event, 'guardrailLow', false);
						}
					},
					guardrailHigh: {
						drawTime: 'beforeDatasetsDraw',
						type: "line",
						mode: "vertical",
						scaleID: "x",
						value: selectedItemLocal.guardrailHigh,
						borderColor: "#ffc98e",
						borderWidth: 3,
						label: {
							content: selectedItemLocal.guardrailHigh.toFixed(2),
							display: false,
							font: {
								size: '10px'
							}
						},
						enter(ctx, event) {
							toggleLabel(ctx, event, 'guardrailHigh', true);
						},
						leave(ctx, event) {
							toggleLabel(ctx, event, 'guardrailHigh', false);
						}
					}
				}
			}

			basicOptionsCopy.plugins.legend.display = (showHistoric && historic.length > 0);

			setChartData(dataSet);
			setBasicOptions(basicOptionsCopy);
			// console.log(basicOptionsCopy);
		}
	}

	const getMinimumAllowedUserPrice = () => {
		return calculate.minUserPriceAllowed(selectedItemLocal);
	}

	const getMaximumAllowedUserPrice = () => {
		return calculate.maxUserPriceAllowed(selectedItemLocal);
	}

	const showHelp = (event, title, msg) => {
		contextualConfirmDialog({
			message: msg,
			header: title,
			hideRejectButton: true,
			acceptLabel: t('PricingTable.OkButton'),
			accept: () => {
				// Reset Price
			},
			contextEvent: event
		});
	}

	var showIcon = false;
	var titleText = "";

	if (selectedItemLocal != null) {
		if (selectedItemLocal.guardrailApplied) {
			titleText = t('PricingTable.PDUPricingConstrainedPopupTitle');
			showIcon = true;
		} else if (selectedItemLocal.pdu) {
			titleText = t('PricingTable.PDUPricingPopupTitle');
			showIcon = false;
		} else if (selectedItemLocal.hasDemandCurve) {
			titleText = selectedItemLocal.demandCurveExtensionPrice ? t('PricingTable.ExtendedDemandCurvePricingPopupTitle') : t('PricingTable.DemandCurvePricingConstrainedPopupTitle');
			showIcon = !selectedItemLocal.demandCurveExtensionPrice;
		} else {
			// No repricing event
			titleText = t('PricingTable.NoRepricingPopupTitle');
			showIcon = false;
		}
	}

	if (selectedItemLocal === null && tReady) {
		return null;
	} else if (tReady) {
		return (
			<>
				<div className="mb-2 text-base text-primary mb-4 grid">
					<div className="col-11">
						{(showIcon) && (<span className="btn-circle bg-sixth guardrails-panel-icon">
							<span className={"pi-svg pi bg-white pi-guardrail"}></span>
						</span>)}
						<div className="guardrails-panel-title">
						{titleText}
						</div>
					</div>
					<div className="col-1">
						<button
							type="button"
							onClick={(e) => showHelp(e, '', t('PricingTable.GuardrailsPanelHelp'))}
							className="btn-circle bg-sixth text-white pi pi-question-circle">
						</button>
					</div>
				</div>
				<div className="grid mt-2">
					<div className="col-5 text-primary">
						<div className="row">
							<div className="field-radiobutton">
								<RadioButton inputId="guardrail2" name="guardrail" value="optimalOption"
											 className="p-radiobutton-sixth"
											 disabled={canEditPrices === false}
											 onChange={(e) => {
												 setSelectedPrice(recommendedPrice);
												 setHasManualPrice(false);
												 setOption(e.value);
											 }} checked={option === "optimalOption"}/>
								<label htmlFor="guardrail2">
									<div className="text-md">
										{currency} {recommendedPrice ? recommendedPrice.toFixed(2) : 0}
									</div>
									<div className="text-sm mb-2">
										<div className="col-12 pb-0 pl-0">{
											(selectedItemLocal != null && selectedItemLocal.hasDemandCurve) ?
												t('PricingTable.RecommendedPriceLabel') :
												((selectedItemLocal != null && selectedItemLocal.pdu)
													? t('PricingTable.SuggestedPriceLabel')
													: t('PricingTable.CurrentPriceLabel')) }</div>
									</div>
								</label>
							</div>
						</div>
						<div className="row">
							<div className="field-radiobutton">
								<RadioButton inputId="guardrail3" name="guardrail" value="lastOption"
											 className="p-radiobutton-seventh"
											 disabled={canEditPrices === false}
											 onChange={(e) => {
												 setSelectedPrice(lastPrice);
												 setHasManualPrice(false);
												 setOption(e.value);
											 }} checked={option === "lastOption"}/>
								<label htmlFor="guardrail3">
									<div className="text-md">
										{currency} {lastPrice ? lastPrice.toFixed(2) : 0}
									</div>
									<div className="text-sm mb-2">
										<div className="col-12 pb-0 pl-0">{t('PricingTable.LastPrice')}</div>
									</div>
								</label>
							</div>
						</div>
						<div className="row" style={{ display: (canEditPrices === false) ? "none": "" }}>
							<div className="field-radiobutton">
									<RadioButton inputId="guardrail1" name="guardrail" value="manualOption" style={{ "marginTop": "-28px" }}
											 className="p-radiobutton-secondary"
											 onChange={(e) => {
												 setSelectedPrice('');
												 setHasManualPrice(true);
												 setOption(e.value);
											 }} checked={option === "manualOption"}/>
									<label htmlFor="guardrail1">
									<div className="text-md col-6 p-0">
										<InputNumber minFractionDigits={1} maxFractionDigits={2} inputClassName="inputfield textbox" value={manualPriceValue}
											min={getMinimumAllowedUserPrice()}
											max={getMaximumAllowedUserPrice()}
											tooltip={t('PricingTable.UserPriceTextboxTooltip', { currencySymbol: currency, rangeMin: getMinimumAllowedUserPrice(), rangeMax: getMaximumAllowedUserPrice() })}
											onValueChange={(e) => {
												setOption("manualOption");
												setHasManualPrice(true);
												setSelectedPrice(e.value);
												setManualPriceValue(e.value);
											}} />
									</div>
									<div className="text-sm mb-2">
										<div className="col-12 pb-0 pl-0">
											{t('PricingTable.SpecifyAnotherPrice')}
										</div>
									</div>
								</label>
							</div>
						</div>
						<div className="row">
							<div className="field-radiobutton">
								<Checkbox inputId="showHistoricCheckbox" checked={showHistoric} onChange={(e) => setShowHistoric(!showHistoric)} className="p-radiobutton-secondary"/>
								<label htmlFor="showHistoricCheckbox" className="text-sm">{t('PricingTable.ShowHistoricPoints')}</label>
							</div>
						</div>
					</div>
					<div className="col-7">
						<div className="grid p-justify-center p-ai-center mr-3">
							<Chart
								type="line"
								className="mr-3"
								data={chartData}
								options={basicOptions}
							/>
						</div>
						<div className="text-sm font-bold mb-2">
							<div className="col-12 pb-0 pl-0 text-center">
								<div className="bg-seventh p-line-chart-legend" style={{ backgroundColor: "#ffc98e" }}></div>
								<span className="text-primary">{t('PricingTable.GuardrailsPanelLegend')}</span>
							</div>
						</div>
					</div>
				</div>
				<div className="text-center mt-1">
					<button
						type="button"
						className={classNames("btn", (canEditPrices === false) ? "btn-sixth" : "btn-secondary", "btn-rounded-full", "btn-md", "mr-5")}
						onClick={(e) => {
							modal.current.hide()
							setSelectedPrice('')
							setOption('')
						}}>{(canEditPrices === false) ? t('PricingTable.OkButton') : t('PricingTable.CancelButton')}</button>
					{canEditPrices && (<button
						type="button"
						className="btn btn-sixth btn-rounded-full btn-md"
						onClick={(e) => {
							if (!selectedPrice) return

							onPriceChangeHandler(selectedPrice);

							modal.current.hide()
							setSelectedPrice('')
							setOption('')
						}}>{t('PricingTable.SaveButton')}</button>
					)}
				</div>
			</>
		);
	} else {
		return null;
	}
}

export default withTranslation()(GuardrailsPanel)