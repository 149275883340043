import React, { useState, useEffect, useRef } from 'react'
import { withTranslation } from 'react-i18next';
import ConfigActionBtn from '../Config/ConfigActionBtn'
import { confirmDialog } from 'primereact/confirmdialog';
import { Calendar } from 'primereact/calendar';
import { DataTable } from 'primereact/datatable';
import { Dropdown } from 'primereact/dropdown';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { ScrollPanel } from 'primereact/scrollpanel';
import { Column } from 'primereact/column';
import configurationService from '../../Api/requests/config'
import messagesService from '../../Api/client-side/messages'
import moment from 'moment';

function Holidays(props) {
	const { t } = props;
	const { setLoading } = props;
	const { allowed } = props;
	const { showHelp } = props;

	const [dataChanged, setDataChanged] = useState(false)
	const [holidays, setHolidays] = useState([])
	const [year, setYear] = useState(new Date().getFullYear())
	const [disabledDates, setDisabledDates] = useState([])
	const [originalRows, setOriginalRows] = useState([])
	const [editingRows, setEditingRows] = useState({})

	const minDate = new Date(new Date().getFullYear(), 0, 1);
	const maxDate = new Date(new Date().getFullYear(), 11, 31);

	const actualActiveIndex = useRef(-1);

	const holidayYears = [
		{ value: 0, label: "Fixed" },
		{ value: new Date().getFullYear(), label: new Date().getFullYear().toString() }
	];

	const reloadTable = async (theYear) => {
		setLoading(true);
		let theHolidays = await configurationService.getHolidays(theYear);

		var newDisabledDates = [];

		for (let idx = 0; idx < theHolidays.length; idx++) {
			newDisabledDates.push(moment(theHolidays[idx].date).toDate());
		}

		setDisabledDates(newDisabledDates);

		setHolidays(theHolidays);
		setDataChanged(false);
		setLoading(false);
	}

	const onEditorValueChange = (config, value) => {
		let updatedHolidays = [...config.props.value]
		let item = updatedHolidays[config.rowIndex]
		item[config.field] = value
		item['changed'] = true;
		config.rowData[config.field] = value;

		setHolidays(updatedHolidays)

		setDataChanged(true);
	}


	const onRowEditInit = (event) => {
		var theOriginalRows = originalRows;
		theOriginalRows[event.index] = { ...holidays[event.index] };
		setOriginalRows(theOriginalRows);
	}

	const onRowEditCancel = (event) =>  {
		let theHolidays = [...holidays];
		theHolidays[event.index] = originalRows[event.index];

		let theOriginalRows = [...originalRows];
		delete theOriginalRows[event.index];

		setOriginalRows(theOriginalRows);
		setHolidays(theHolidays);
	}

	const setActiveRowIndex = (index) => {
		let theOriginalRows = [...originalRows];
		theOriginalRows[index] = { ...holidays[index] };

		let theEditingRows = { ...editingRows, ...{ [`${holidays[index].datePK}`]: true } };

		setOriginalRows(theOriginalRows);
		setEditingRows(theEditingRows);
	}

	const addHolidayElement = (e) => {
		let theHolidays = [...holidays];

		// Start looking for a free space from 1-Jan
		var newDate = new Date();
		newDate.setDate(1);
		newDate.setMonth(0);

		// Locate a free date
		var theDisabledDates = [...disabledDates];
		let idxPrev = theDisabledDates.findIndex((d) => (d.getMonth() === newDate.getMonth()) && (d.getDate() === newDate.getDate()));
		while (idxPrev >= 0) {
			// Lookup next date forward
			let theDate = newDate;
			theDate = moment(theDate).add(1, 'days').toDate();
			idxPrev = theDisabledDates.findIndex((d) => (d.getMonth() === theDate.getMonth()) && (d.getDate() === theDate.getDate()));
			newDate = theDate;
		}

		theHolidays.push({
			description: 'New Holiday ' + holidays.length,
			date: moment(newDate).format(),
			datePK: 'null-' + holidays.length,
			dirty: true,
			isFixed: (year === 0)
		});

		theDisabledDates.push(newDate);

		setDisabledDates(theDisabledDates);
		setHolidays(theHolidays);
		setDataChanged(true);
		actualActiveIndex.current = (theHolidays.length - 1);
		// setActiveRowIndex(theHolidays.length - 1);
	}

	useEffect(() => {

		// HACK to be sure the holidays are all loaded into the grid before to set the latest item when adding a new holiday
		if (actualActiveIndex.current >= 0) {
			setActiveRowIndex(holidays.length - 1);
			actualActiveIndex.current = -1;
		}
	}, [holidays]);  // eslint-disable-line react-hooks/exhaustive-deps

	const onRowEditChange = (event)  => {
		setEditingRows(event.data);
	}

	const confirmLostChanges = (callback) => {
		if (dataChanged) {
			confirmDialog({
				message: t('Holidays.ConfirmRefresh'),
				header: t('Holidays.ConfirmOverwriteHeader'),
				acceptLabel: t('Holidays.ConfirmDialogYes'),
				rejectLabel: t('Holidays.ConfirmDialogNo'),
				className: 'holidays-confirm-panel',
				icon: 'pi pi-exclamation-triangle',
				accept: () => callback()
			});
		}
	}

	const onHolidayTypeChanged = async (val) => {
		if (dataChanged) {
			confirmLostChanges(async () => {
				setYear(val);
				await reloadTable(val);
			});
		} else {
			setYear(val);
			await reloadTable(val);
		}
	}

	const dateBody = (rowData, props) => {
		let date = moment(rowData['date']).format('DD-MMMM');
		return (<React.Fragment>
				{date}
			</React.Fragment>);
	}

	const dateEditor = (props) => {
		// Format javascript Date object from the value on the data row
		let date = moment(props.rowData['date']).toDate();

		return (
			<Calendar id="date" inputClassName="inputfield textbox" monthNavigator value={date} minDate={minDate} maxDate={maxDate} dateFormat="dd-MM" disabledDates={disabledDates}
				yearNavigator={false} readOnlyInput
				onChange={(e) => {
					onSetDate(props, e.target.value);
				}
				} />
		);
	}

	const onSetDate = (props, value) => {
		// Override year (we want always actual year)
		let currentYear = new Date().getFullYear();
		value.setFullYear(currentYear);

		// Locate the previous entry in "disabled dates" because now it should be enabled (because it's not selected anymore)
		let key = props.rowData['datePK'];

		if (key.startsWith('null-')) {
			// It's a new holiday, so pick the key from the selected date, so we can remove it
			key = props.rowData['date'];
		}

		let dateKey = moment(key).toDate();

		var theDisabledDates = disabledDates;

		let idxPrev = theDisabledDates.findIndex((d) => (d.getMonth() === dateKey.getMonth()) && (d.getDate() === dateKey.getDate()));

		if (idxPrev >= 0) {
			theDisabledDates.splice(idxPrev, 1);
		}
		theDisabledDates.push(value);
		setDisabledDates(theDisabledDates);

		// Date format to be saved to the array of objects
		let dateFormat = moment(value).format();
		onEditorValueChange(props, dateFormat);
	}

	const inputTextEditor = (props, field) => {
		return <InputText type="text" value={props.rowData[field]} className="inputfield textbox" onChange={(e) => onEditorValueChange(props, e.target.value)} />;
	}

	const descriptionEditor = (props) => {
		return (
			<React.Fragment>
				{inputTextEditor(props, 'description')}
			</React.Fragment>
		);
	}

	const deleteHoliday = async (holiday) => {
		if (!holiday['datePK'].startsWith('null-')) {

			// Remove from server side
			let res = await configurationService.deleteHoliday(holiday);

			if (res === null) {
				// TODO: Confirmation message!
			} else {
				// TODO: Error message!
			}
		}

		// Remove from client side
		var theHolidays = [...holidays];
		let idx = theHolidays.findIndex(i => i['datePK'] === holiday['datePK']);
		let date = moment(theHolidays[idx].date).toDate();
		theHolidays.splice(idx, 1);

		// Remove from the list of disabled dates
		var theDisabledDates = disabledDates;
		idx = theDisabledDates.findIndex(d => d.getMonth() === date.getMonth() && d.getDate() === date.getDate())
		theDisabledDates.splice(idx, 1);

		// Just remove from list
		setDisabledDates(theDisabledDates);
		setHolidays(theHolidays);
	}

	const confirmDeleteHoliday = (holiday) => {
		confirmDialog({
			message: t('Holidays.ConfirmDelete'),
			header: t('Holidays.ConfirmDeleteHeader'),
			acceptLabel: t('Holidays.ConfirmDialogYes'),
			rejectLabel: t('Holidays.ConfirmDialogNo'),
			className: 'holidays-confirm-panel',
			icon: 'pi pi-exclamation-triangle',
			accept: () => deleteHoliday(holiday)
		});
	}

	const confirmSave = async () => {
		confirmDialog({
			message: t('Holidays.ConfirmOverwriteMessage'),
			header: t('Holidays.ConfirmOverwriteHeader'),
			acceptLabel: t('Holidays.ConfirmDialogYes'),
			rejectLabel: t('Holidays.ConfirmDialogNo'),
			icon: 'pi pi-exclamation-triangle',
			accept: async () => {

				let res = await configurationService.saveHolidays(holidays);

				if (res === null) {
					messagesService.showSuccess(t('Holidays.HolidaysRefreshedTitle'), t('Holidays.HolidaysRefreshedMessage'));
					await reloadTable(year);
				} else {
					messagesService.showError(t('Holidays.HolidaysRefreshedErrorTitle'), t('Holidays.HolidaysRefreshedErrorMessage'));
				}
			}
		});
	}

	const deleteBodyTemplate = (rowData) => {
		return (
			<React.Fragment>
				<Button icon="pi pi-trash" className="p-link p-row-editor-init" onClick={() => confirmDeleteHoliday(rowData)} />
			</React.Fragment>
		);
	}

	const confirmReset = () => {
		onHolidayTypeChanged(year);
	}

	useEffect(() => {
		reloadTable(year);
	}, []);  // eslint-disable-line react-hooks/exhaustive-deps

	if (allowed !== true) {
		return (<></>);
	} else {
		return (
			<>
				<h3 className="text-primary pt-3 pb-3 pl-3">
					{t('HolidaysDialog.Header')}
					<Button label="" title={t('Holidays.HelpButton')} icon="pi pi-question-circle" className="p-button-config-help p-button-rounded p-button-info" onClick={() => showHelp('Holidays.ImportHelpTitle', 'Holidays.ImportHelpMessage')} />
				</h3>
				<div className="field grid mt-3 pl-3 pr-3">
					<div className="col-12 xl:col-5">
						<Dropdown id="language" name="language" value={year} options={holidayYears} optionLabel="label" optionValue="value" onChange={(e) => onHolidayTypeChanged(e.value)} />
					</div>
				</div>

				<div className="field grid mt-3 pl-3 pr-3 scrollpanel-container">
					<ScrollPanel style={{ width: '100%', height: '400px' }} className="datatable datatable-responsive">
						<DataTable value={holidays} dataKey="datePK"
							className="p-datatable-config"
							paginator={false}
							footer={false}
							editMode="row" editingRows={editingRows}
							onRowEditChange={onRowEditChange} onRowEditInit={onRowEditInit} onRowEditCancel={onRowEditCancel}
							responsiveLayout="scroll"
							columnResizeMode="fit">
							<Column field="description" header={t('Holidays.DescriptionColumn')} editor={descriptionEditor} style={{ maxWidth: '50%' }}></Column>
							<Column field="date" header={t('Holidays.DateColumn')} body={dateBody} editor={dateEditor} style={{ width: '10rem' }}></Column>
							<Column rowEditor headerStyle={{ width: '6rem' }} bodyStyle={{ textAlign: 'center' }} ></Column>
							<Column body={deleteBodyTemplate} style={{ width: '4rem' }}></Column>
						</DataTable>
					</ScrollPanel>
				</div>

				<div className="flex justify-content-end mr-3 bottom-buttons">
					<ConfigActionBtn onClick={(e) => addHolidayElement(e)} buttonText={t('Holidays.AddHolidaysButton')} />
					<ConfigActionBtn onClick={(e) => confirmSave()} buttonText={t('ConfigurationDialog.SaveButton')} disabled={!dataChanged} />
					<ConfigActionBtn onClick={(e) => confirmReset()} buttonText={t('ConfigurationDialog.CancelButton')} disabled={!dataChanged} />
				</div>
			</>
		);
	}
}

export default withTranslation()(Holidays)
