import React, { useState, useRef, useEffect } from 'react'
import { withTranslation } from 'react-i18next';
import { InputText } from 'primereact/inputtext';
import { FileUpload } from 'primereact/fileupload';
import { Dropdown } from 'primereact/dropdown';
import ConfigActionBtn from '../Config/ConfigActionBtn'
import importService from '../../Api/requests/import'
import messagesService from '../../Api/client-side/messages';
import { Button } from 'primereact/button';

function ImportData(props) {
	const { t } = props;
	const { allowed, showHelp, allowExtendedFunctionalities } = props;

	const [uploading, setUploading] = useState(false);
	const [importFile, setImportFile] = useState('');
	const [fileFormat, setFileFormat] = useState('csv');
	const [importType, setImportType] = useState(0);
	const [csvSeparator, setCsvSeparator] = useState(',');
	const [reimportAll, setReimportAll] = useState(true);
	const [accumulatePerWeek, setAccumulatePerWeek] = useState(false);
	const [importProgress, setImportProgress] = useState(-1);

	const importCheckProcess = useRef(null);
	const uploadFileControl = useRef(null);

	// Use those references to keep the latest value so we can drop the file at component unmount
	const refImport = useRef(null);
	const refFileFormat = useRef(null);
	refImport.current = importFile;
	refFileFormat.current = fileFormat;

	const csvSeparators = [
		{ value: ',', label: t('ImportDataDialog.Comma') },
		{ value: ';', label: t('ImportDataDialog.SemiColon') },
		{ value: '\t', label: t('ImportDataDialog.Tab') }
	];

	const fileFormats = [
		{ value: 'csv', label: t('ImportDataDialog.CSVFormat') },
		{ value: 'xml', label: t('ImportDataDialog.XMLFormat') },
		{ value: 'json', label: t('ImportDataDialog.JSONFormat') }
	];

	const importTypes = [
		{ value: 0, label: "Transaction Data" },
		{ value: 1, label: "Products" }
	];

	const onFileUploadError = (e) => {
		setUploading(false);
	}

	const onFileBeforeSend = (e) => {
		// Attach authorization header to UPLOAD request
		e.xhr.setRequestHeader('Authorization', `Bearer ${importService._token}`);
		e.xhr.setRequestHeader('X-XSRF-TOKEN', importService.getAntiForgeryToken());
		e.formData.append('previousFile', importFile);
		e.formData.append('fileFormat', fileFormat);

		setUploading(true);
	}

	const onFileUpload = (e) => {
		messagesService.showInfo(t('ImportDataDialog.FileUploadedTitle'), t('ImportDataDialog.FileUploadedMessage'));
		setImportFile(e.files[0].name);
		setUploading(false);
	}

	const doImport = async (e) => {
		let importData = {
			importFile: importFile,
			fileFormat: fileFormat,
			reimportAll: reimportAll,
			accumulatePerWeek: accumulatePerWeek,
			csvSeparator: csvSeparator,
			fileType: importType
		};

		try {
			var res = await importService.importFile(importData);

			// Do not show this message, because it's the real time messaging who is going to keep track of what's happening
			// messagesService.showSuccess(t('ImportDataDialog.ImportConfirmedTitle'), t('ImportDataDialog.ImportConfirmedMessage'));
			if (res) {
				if (res === 'ImportAlreadyInProgress') {
					messagesService.showError(t('ImportDataDialog.ImportErrorTitle'), t('ImportDataDialog.ImportAlreadyInProgress'));
				} else {
					messagesService.showError(t('ImportDataDialog.ImportErrorTitle'), t('ImportDataDialog.ImportErrorMessage'));
				}
			} else {
				setImportFile('');
				setImportProgress(0);
				setTimeout(async () => {
					await checkImportData();
				}, 10000);
				
			}
		} catch (e) {
			messagesService.showError(t('ImportDataDialog.ImportErrorTitle'), t('ImportDataDialog.ImportErrorMessage'));
			console.error(e);
		}
	}

	const checkImportData = async () => {
		var importProcess = await importService.fileAnalyzingCompletition();

		if (importProcess !== null) {
			var timeoutProcess = setInterval(async () => {
				var thisImportProcess = await importService.fileAnalyzingCompletition();

				if (thisImportProcess === null) {
					clearInterval(importCheckProcess.current);
					importCheckProcess.current = null;
					setImportProgress(-1);
				} else {
					setImportProgress(thisImportProcess);
				}
			}, 10000);

			setImportProgress(importProcess);
			importCheckProcess.current = timeoutProcess;
		} else {
			importCheckProcess.current = null;
			setImportProgress(-1);
		}
	}

	useEffect(() => {
		async function releaseDropedFiles() {
			// Use references to be sure we have the latest value
			await importService.dropTempFile(refImport.current, refFileFormat.current);
			if (importCheckProcess.current != null) {
				clearTimeout(importCheckProcess.current);
			}
		}

		checkImportData();

		// Use this for "component unmount"
		return () => { releaseDropedFiles(); }
	}, []);

	if (allowed !== true) {
		return (<></>);
	} else {
		return (
			<div style={ { position: "relative" } }>
				<div id="overlay_panel_uploading" style={{ width: "100%", height: "100%", display: uploading ? "" : "none" }} className="p-overlaypanel-wrapper p-general-loading-overlay">
					<i className="p-datatable-loading-icon pi-spin pi pi-spinner"></i>
				</div>
				<h3 className="text-primary pt-3 pb-3 pl-3">
					{t('ImportDataDialog.Header')}
					<Button label="" title={t('ImportDataDialog.HelpButton')} icon="pi pi-question-circle" className="p-button-config-help p-button-rounded p-button-info" onClick={() => showHelp('ImportDataDialog.ImportHelpTitle', 'ImportDataDialog.ImportHelpMessage')} />
				</h3>
				<div className="field grid mt-3 pl-3 pr-3">
					<label htmlFor="csvData" className="col-2">{t('ImportDataDialog.ImportFile')}</label>
					<div className="col-8 xl:col-6 xxl-7">
						<InputText value={importFile} readOnly={true} id="fileImport" name="fileImport" className="inputfield textbox" />
					</div>
					<div className="col-2 xl:col-4 xxl-3">
						<FileUpload mode="basic" key="uploadFileControl" disabled={uploading || importProgress >= 0} ref={uploadFileControl}
							name="csvData" url="/dataimport/uploadFile" maxFileSize={100000000}
							onBeforeSend={(e) => onFileBeforeSend(e)}
							onError={(e) => onFileUploadError(e)}
							onUpload={(e) => onFileUpload(e)}
							auto chooseLabel={t('ImportDataDialog.BrowseButton')} />
					</div>
				</div>
				<div className="field grid pl-3 pr-3">
					<label htmlFor="fileFormatImport" className="col-4 mb-2 md:col-2 md:mb-0">{t('ImportDataDialog.FileFormat')}</label>
					<div className="col-8 md:col-10">
						<Dropdown id="fileFormatImport" name="fileFormatImport" value={fileFormat} options={fileFormats} optionLabel="label" optionValue="value" onChange={(e) => setFileFormat(e.value)} />
					</div>
				</div>
				<div className="field grid pl-3 pr-3">
					<label htmlFor="importType" className="col-4 mb-2 md:col-2 md:mb-0">{t('ImportDataDialog.FileType')}</label>
					<div className="col-8 md:col-10">
						<Dropdown id="importType" name="importType" value={importType} options={importTypes} optionLabel="label" optionValue="value" onChange={(e) => setImportType(e.value)} />
					</div>
				</div>
				<div className="field grid pl-3 pr-3" style={{ display: ((fileFormat === "csv") ? "" : "none") }} >
					<label htmlFor="csvSeparator" className="col-4 mb-2 md:col-2 md:mb-0">{t('ImportDataDialog.CSVSeparator')}</label>
					<div className="col-8 md:col-10">
						<Dropdown id="csvSeparator" name="csvSeparator" value={csvSeparator} options={csvSeparators} optionLabel="label" optionValue="value" onChange={(e) => setCsvSeparator(e.value)} />
					</div>
				</div>

				{(allowExtendedFunctionalities === true) && (
					<>
						<div className="field grid pl-3 pr-3">
							<label className="form-checkbox form-checkbox-tertiary form-checkbox-md text-sm text-primary pl-2">
								<input type="checkbox"
									checked={reimportAll}
									onChange={(e) => {
										setReimportAll(e.target.checked)
									}} />
								<span className="form-checkmark mr-2"></span>
								{t('ImportDataDialog.ReimportAll')}
							</label>
							<label className="form-checkbox form-checkbox-tertiary form-checkbox-md text-sm text-primary pl-2">
								<input type="checkbox"
									checked={accumulatePerWeek}
									onChange={(e) => {
										setAccumulatePerWeek(e.target.checked)
									}} />
								<span className="form-checkmark mr-2"></span>
								{t('ImportDataDialog.AccumulatePerWeek')}
							</label>
						</div>
						

						<div className="flex justify-content-end mr-3 bottom-buttons">
							{(importProgress >= 0) && (
								<>
									<div className="mr-3 mt-2">
										<label>{t('ImportDataDialog.ProcessingImportFile')}</label>
									</div>
									<div>
										<i className="pi pi-spin pi-cog" style={{ fontSize: '2rem' }}></i>
									</div>
								</>
							)}
							<ConfigActionBtn onClick={(e) => doImport(e)} buttonText={t('ImportDataDialog.BeginImportButton')} disabled={importFile === '' || importProgress >= 0} />
						</div>
					</>
				)}

			</div>
		)
	}
}

export default withTranslation()(ImportData)
