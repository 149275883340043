import React, { useState, useEffect, useRef } from 'react'
import { withTranslation } from 'react-i18next';
import moment  from 'moment';
import { Calendar } from 'primereact/calendar';
import { Dropdown } from 'primereact/dropdown';
import { Button } from 'primereact/button';
import ConfigActionBtn from '../Config/ConfigActionBtn'
import messagesService from '../../Api/client-side/messages';
import developmentFrameworkService from '../../Api/requests/developmentFramework';
import configurationService from '../../Api/requests/config';

function ExperimentalData(props) {
	const { setLoading } = props;
	const { t } = props;
	const { allowed } = props;
	const { showHelp } = props;

	const [fromDate, setFromDate] = useState(null);
	const [toDate, setToDate] = useState(null);
	const [enableExperimentalFramework, setEnableExperimentalFramework] = useState(null);
	const [selectWorkflowsSamples, setSelectWorkflowsSamples] = useState(false)
	const [logLevel, setLogLevel] = useState(null)
	const [initializing, setInitializing] = useState(false)

	const defaultsRef = useRef(null);

	const logLevels = [
		{ value: 0, label: 'Trace' },
		{ value: 1, label: 'Debug' },
		{ value: 2, label: 'Info' },
		{ value: 3, label: 'Warning' },
		{ value: 4, label: 'Error' }
	];

	const readDefaults = async () => {
		// Initialize default values
		defaultsRef.current = await configurationService.getDevelopmentConfig();
		setEnableExperimentalFramework(defaultsRef.current.experimentalFrameworkEnabled);
		setLogLevel(defaultsRef.current.logLevel);
	}

	const saveConfig = async () => {
		let parms = {
			experimentalFrameworkEnabled: enableExperimentalFramework,
			logLevel: logLevel
		};

		setLoading(true);
		let res = await configurationService.setDevelopmentConfig(parms);
		setLoading(false);

		if (res === null) {
			messagesService.showSuccess(t('ExperimentalDataDialog.Header'), t('ExperimentalDataDialog.ConfigurationSaved'));
			await readDefaults();
		} else {
			messagesService.showError(t('ExperimentalDataDialog.Header'), t('ExperimentalDataDialog.ConfigurationSaveError'));
		}
	}

	useEffect(() => {
		setInitializing(true);
		readDefaults();
		setInitializing(false);
	}, []);

	useEffect(() => {
		if (!initializing) {
			if (enableExperimentalFramework !== null && logLevel !== null && defaultsRef.current !== null) {
				if (defaultsRef.current.experimentalFrameworkEnabled !== enableExperimentalFramework ||
					defaultsRef.current.logLevel !== logLevel) {
					saveConfig();
				}
			}
		}
		
	}, [enableExperimentalFramework, logLevel]); // eslint-disable-line react-hooks/exhaustive-deps

	const downloadCSV = async () => {
		let dateFrom = (fromDate != null) ? moment(fromDate).format() : null;
		let dateTo = (toDate != null) ? moment(toDate).format() : null;

		let parms = {
			dateFrom: (dateFrom != null) ? moment(dateFrom).format('yyyy-MM-DD') : null,
			dateTo: (dateTo != null) ? moment(dateTo).format('yyyy-MM-DD') : null,
			selectWorkflowsSamples: selectWorkflowsSamples
		};

		setLoading(true);

		await developmentFrameworkService.downloadDevelopmentFrameworkData(parms);

		setLoading(false);
	};

	if (allowed !== true) {
		return (<></>);
	} else {
		return (
			<>
				<h3 className="text-primary pt-3 pb-3 pl-3">
					{t('ExperimentalDataDialog.Header')}
					<Button label="" title={t('ExperimentalDataDialog.HelpButton')} icon="pi pi-question-circle" className="p-button-config-help p-button-rounded p-button-info" onClick={() => showHelp('ExperimentalDataDialog.ImportHelpTitle', 'ExperimentalDataDialog.ImportHelpMessage')} />
				</h3>

				<div className="field grid pl-3 mt-3">
					<label className="form-checkbox form-checkbox-tertiary form-checkbox-md text-sm text-primary pl-2">
						<input type="checkbox"
							name="enableExperimentalFramework"
							checked={(enableExperimentalFramework !== null) ? enableExperimentalFramework : false}
							onChange={(e) => setEnableExperimentalFramework(e.target.checked)} />
						<span className="form-checkmark mr-2"></span>
						{t('ExperimentalDataDialog.EnableExperimentalFramework')}
					</label>
				</div>

				<div className="field grid mt-3 pl-3 pr-3">
					<label htmlFor="logLevel" className="col-12 md:col-4">{t('ExperimentalDataDialog.LogLevel')}</label>
					<div className="col-12 md:col-8">
						<Dropdown id="logLevel" name="logLevel" value={logLevel} options={logLevels} optionLabel="label" optionValue="value" onChange={(e) => setLogLevel(e.value)} />
					</div>
				</div>

				<div className="field grid mt-3 pl-3 pr-3">
					<label htmlFor="dateFrom" className="col-12 md:col-4">{t('ExperimentalDataDialog.DateFrom')}</label>
					<div className="col-12 md:col-8">
						<Calendar value={fromDate} onChange={(e) => setFromDate(e.value)} showIcon showTime id="fromDate"
							name="fromDate" inputClassName="inputfield textbox" />
					</div>
				</div>

				<div className="field grid mt-3 pl-3 pr-3">
					<label htmlFor="toDate" className="col-12 md:col-4">{t('ExperimentalDataDialog.DateTo')}</label>
					<div className="col-12 md:col-8">
						<Calendar value={toDate} onChange={(e) => setToDate(e.value)} showIcon showTime id="toDate"
							name="toDate" inputClassName="inputfield textbox" />
					</div>
				</div>

				<div className="field grid pl-3 mt-3">
					<label className="form-checkbox form-checkbox-tertiary form-checkbox-md text-sm text-primary pl-2">
						<input type="checkbox"
							name="selectWorkflowSamples"
							checked={selectWorkflowsSamples}
							onChange={(e) => setSelectWorkflowsSamples(e.target.checked)} />
						<span className="form-checkmark mr-2"></span>
						{t('ExperimentalDataDialog.SelectRepresentativeWorkflowSample')}
					</label>
				</div>

				<div className="flex justify-content-end mr-3 bottom-buttons">
					<ConfigActionBtn onClick={(e) => downloadCSV()} buttonText={t('ExperimentalDataDialog.DownloadButton')} />
				</div>
			</>
		);
	}
}

export default withTranslation()(ExperimentalData)
