import React, { useEffect, useRef } from 'react'
import { withTranslation } from 'react-i18next';
import realTimeMessagingServices, { RealTimeMessagesService } from '../../Api/requests/realtimemessaging'
import messagesService from '../../Api/client-side/messages'

function RealTimeMessages() {
    const messagesSubscription = useRef(null);

    const newMessageReceived = (msg) => {
        switch (msg.severity) {
            case 0:
                messagesService.showInfo('', msg.messageText);
                break;
            case 1:
                messagesService.showSuccess('', msg.messageText);
                break;
            case 2:
                messagesService.showWarning('', msg.messageText);
                break;
            case 3:
                messagesService.showError('', msg.messageText);
                break;
            default:
                messagesService.showInfo('', msg.messageText);
                break;
        }
    };

    useEffect(() => {
        messagesSubscription.current = realTimeMessagingServices.subscribe((action, msg) => {
            if (action === RealTimeMessagesService.NEW_MESSAGE) {
                newMessageReceived(msg);
            }
        });

        // Use this for "component unmount"
        return () => {
            realTimeMessagingServices.unsubscribe(messagesSubscription.current);

            // TODO: Is there a better place to put that?
            realTimeMessagingServices.unInitialize();
        }
    }, []);

    return (
        <>

        </>
    );
}

export default withTranslation()(RealTimeMessages);