import React, { Component, startTransition } from 'react'
import { createRoot } from 'react-dom/client';
import { OverlayPanel } from 'primereact/overlaypanel'
import { DomHandler } from 'primereact/utils';
import { withTranslation } from 'react-i18next'

export function contextualConfirmDialog(props) {
	let appendTo = props.appendTo || document.body;

	let contextualConfirmDialogWrapper = document.createDocumentFragment();
	DomHandler.appendChild(contextualConfirmDialogWrapper, appendTo);

	props = { ...props, ...{ visible: props.visible === undefined ? true : props.visible } };

	const root = createRoot(contextualConfirmDialogWrapper);

	let confirmDialogEl = React.createElement(ContextualConfirmDialog, props);
	root.render(confirmDialogEl);

	let userUpdateConfirmDialog = (newProps) => {
		props = { ...props, ...newProps };
		root.render(React.cloneElement(confirmDialogEl, props));
	}

	return {
		_destroy: () => {
			root.unmount();
		},
		show: () => {
			userUpdateConfirmDialog({
				visible: true, onHide: () => {
					userUpdateConfirmDialog({ visible: false }); // reset
				}
			});
		},
		hide: () => {
			userUpdateConfirmDialog({ visible: false });
		},
		update: (newProps) => {
			userUpdateConfirmDialog(newProps);
		}
	}
}

export class ContextualConfirmDialogComp extends Component {
	constructor(props) {
		super(props);

		this.state = {
			visible: props.visible
		};

		this.reject = this.reject.bind(this);
		this.accept = this.accept.bind(this);
		this.hide = this.hide.bind(this);
	}

	acceptLabel() {
		return this.props.acceptLabel || 'accept';
	}

	rejectLabel() {
		return this.props.rejectLabel || 'reject';
	}

	accept() {
		if (this.props.accept) {
			this.props.accept();
		}

		this.hide('accept');
	}

	reject() {
		if (this.props.reject) {
			this.props.reject();
		}

		this.hide('reject');
	}

	getEventPath(event, idx) {
		var path = event.path || (event.composedPath && event.composedPath());

		if (path) {
			return path[idx];
		} else {
			// This browser doesn't supply path information
		}
	}

	show() {
		this.setState({ visible: true });
	}

	hide(result) {
		this.setState({ visible: false }, () => {
			if (this.props.onHide) {
				this.props.onHide(result);
			}
		});
	}

	componentDidMount() {
		if (this.props.visible) {
			this.show();
		}
	}

	componentDidUpdate(prevProps) {
		if (prevProps.visible !== this.props.visible) {
			this.setState({ visible: this.props.visible });
		}

		// startTransition will trigger CSSTransition to make the dialog visible and put it in the correct place
		startTransition(() => {
			if (this.state.visible) {
				if (this.props.eventPathIndex) {
					this.panelRef.show(this.props.contextEvent, this.getEventPath(this.props.contextEvent.nativeEvent, this.props.eventPathIndex));
				} else if (this.props.targetElement) {
					this.panelRef.show(this.props.contextEvent, this.props.targetElement);
				} else {
					this.panelRef.show(this.props.contextEvent);
				}
				// this.panelRef.show(this.props.contextEvent, this.getEventPath(this.props.contextEvent.nativeEvent, 1));
			} else {
				this.panelRef.hide();
			}
		});
	}

	renderElement() {
		return (<OverlayPanel 
			id={this.props.id ? this.props.id : "overlay_panel_confirmDialog"}
			ref={(overlayPanel) => { this.panelRef = overlayPanel; }}
			className="p-overlaypanel-wrapper p-overlaypanel-rounded-lg">
			<button
				onClick={this.hide}
				className="modal-close">
			</button>
			{
				(this.props.header) && (
					<div className="text-lg text-primary mb-2 overlay-title">
						<span className={"mr-2 " + (this.props.icon ? this.props.icon : "")} style={{ marginBottom: '-2px', display: this.props.icon ? "" : "none" }}></span>
						{this.props.header}
					</div>)
			}
			<p className="text-primary text-base mb-3">
				{this.props.message}
			</p>
			<div className="text-center">
				<button
					type="button"
					className="btn btn-secondary btn-rounded-full btn-xs mr-2"
					onClick={this.accept}>{this.acceptLabel()}
				</button>
				{(this.props.hideRejectButton === undefined || this.props.hideRejectButton === false) &&
					(<button
						type="button"
						className="btn btn-sixth btn-rounded-full btn-xs"
						onClick={this.reject}>{this.rejectLabel()}
				</button>)
				}
			</div>
		</OverlayPanel>);
	}

	render() {
		const element = this.renderElement();

		return element;
		// return <Portal element={element} appendTo={this.props.appendTo} />;
	}
}

export const ContextualConfirmDialog = withTranslation()(ContextualConfirmDialogComp);