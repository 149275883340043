import ApiAuthorzationRoutes from './components/api-authorization/ApiAuthorizationRoutes';
import Browse from './pages/Browse';
import PatternsPage from './pages/Patterns';
import Pricing from './pages/Pricing';
import MessagesPage from './pages/Messages';
// import User from './pages/User';
import Config from './pages/Config';
import TermsPage from './pages/Terms';
import UserAgreementPage from './pages/UserAgreement';
import PrivacyPage from './pages/Privacy';
import Dashboard from './pages/Dashboard';
import RelationshipsPage from './pages/Relationships';

const AppRoutes = [
    {
        index: true,
        path: '/',
        requireAuth: true,
        element: <Dashboard />
    },
    {
        path: '/browse',
        requireAuth: true,
        element: <Browse />
    },
    {
        path: '/pricing',
        requireAuth: true,
        element: <Pricing />
    },
    {
        path: '/patterns',
        requireAuth: true,
        element: <PatternsPage />
    },
    {
        path: '/config',
        requireAuth: true,
        element: <Config />
    },
    {
        path: '/messages',
        requireAuth: true,
        element: <MessagesPage />
    },
    {
        path: '/terms',
        element: <TermsPage />
    },
    {
        path: '/useragreement',
        element: <UserAgreementPage />
    },
    {
        path: '/privacy',
        element: <PrivacyPage />
    },
    {
        path: '/relationships',
        requireAuth: true,
        element: <RelationshipsPage />
    },    
    ...ApiAuthorzationRoutes
];

export default AppRoutes;
