import React, { useState, useEffect, useRef } from 'react'
import { withTranslation } from 'react-i18next';
import ConfigActionBtn from '../Config/ConfigActionBtn'
import { confirmDialog } from 'primereact/confirmdialog';
import { Checkbox } from 'primereact/checkbox';
import { Dropdown } from 'primereact/dropdown';
import { DataTable } from 'primereact/datatable';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { ScrollPanel } from 'primereact/scrollpanel';
import { Column } from 'primereact/column';
import patternsService from '../../Api/requests/patterns'
import messagesService from '../../Api/client-side/messages'
import importService from '../../Api/requests/import';

function PatternPresets(props) {
	const { t } = props;
	const { setLoading } = props;
	const { allowed } = props;
	const { showHelp } = props;

	const [dataChanged, setDataChanged] = useState(false)
	const [configDataChanged, setConfigDataChanged] = useState(false);
	const [presets, setPresets] = useState([])
	const [originalRows, setOriginalRows] = useState([])
	const [editingRows, setEditingRows] = useState({})

	const actualActiveIndex = useRef(-1);
	const [defaultMetric, setDefaultMetric] = useState(-1);
	const [factors, setFactors] = useState([])
	const [metrics, setMetrics] = useState([])
	const [operations, setOperations] = useState([])

	const reloadTable = async () => {
		setLoading(true);
		let thePresets = await patternsService.getPresets();
		setPresets(thePresets);
		setLoading(false);
	}

	const onEditorValueChange = (config, value, extraAction) => {
		let updatedPresets = [...config.props.value];
		let item = updatedPresets[config.rowIndex];
		item[config.field] = value;
		item['dirty'] = true;
		config.rowData[config.field] = value;

		if (extraAction) {
			extraAction(updatedPresets);
		}

		setPresets(updatedPresets);
		setDataChanged(true);
	}

	const onRowEditInit = (event) => {
		var theOriginalRows = originalRows;
		theOriginalRows[event.index] = { ...presets[event.index] };
		setOriginalRows(theOriginalRows);
	}

	const onRowEditCancel = (event) =>  {
		let thePresets = [...presets];
		thePresets[event.index] = originalRows[event.index];

		let theOriginalRows = [...originalRows];
		delete theOriginalRows[event.index];

		setOriginalRows(theOriginalRows);
		setPresets(thePresets);
	}

	const setActiveRowIndex = (index) => {
		let theOriginalRows = [...originalRows];
		theOriginalRows[index] = { ...presets[index] };

		let theEditingRows = { ...editingRows, ...{ [`${presets[index].id}`]: true } };

		setOriginalRows(theOriginalRows);
		setEditingRows(theEditingRows);
	}

	const addPresetElement = (e) => {
		let thePresets = [...presets];
		console.log(presets);

		thePresets.push({
			name: 'New Preset ' + presets.length,
			id: presets.length * -1,
			factor1: 5,
			factor2: null,
			factor3: null,
			operation1: 1,
			operation2: null,
			operation3: null,
			isDefaultPreset: false,
			dirty: true
		});

		setPresets(thePresets);
		setDataChanged(true);
		actualActiveIndex.current = (thePresets.length - 1);
	}

	useEffect(() => {

		// HACK to be sure the presets are all loaded into the grid before to set the latest item when adding a new preset
		if (actualActiveIndex.current >= 0) {
			setActiveRowIndex(presets.length - 1);
			actualActiveIndex.current = -1;
		}
	}, [presets]);  // eslint-disable-line react-hooks/exhaustive-deps

	const onRowEditChange = (event) => {
		setEditingRows(event.data);
	}

	const onRowEditComplete = (event) => {
		// set isDefaultPreset to FALSE for all rows except this when it was checked for this row
		if (event.newData.isDefaultPreset === true) {
			for (var iPreset = 0; iPreset < presets.length; iPreset++) {
				if (presets[iPreset].id !== event.newData.id) {
					if (presets[iPreset].isDefaultPreset === true) {
						presets[iPreset].isDefaultPreset = false;
						presets[iPreset].dirty = true;
					}
				}
			}
		}
	}

	const factorColumnBody = (rowData, props) => {
		let id = rowData[props.field]

		if (id == null) {
			return (<>-</>);
		} else {
			var found = false;
			var selectedFactor = null;
			for (var idF = 0; idF < factors.length && !found; idF++) {
				let theFactor = factors[idF].items.find(o => o.id === id);
				if (theFactor) {
					selectedFactor = theFactor;
					found = true;
				}
			}
			if (selectedFactor) {
				return (
					<React.Fragment>
						{selectedFactor.label}
					</React.Fragment>
				);
			} else {
				return (
					<React.Fragment>-</React.Fragment>
				);
			}
		}
	}

	const operationColumnBody = (rowData, props) => {
		let id = rowData[props.field]

		if (id == null) {
			return (<>-</>);
		} else {
			let theOperation = operations.find(o => o.id === id);

			if (theOperation) {
				return (
					<React.Fragment>
						{theOperation.label}
					</React.Fragment>
				);
			} else {
				<React.Fragment>-</React.Fragment>
			}
		}
	}

	const factorColumnEditor = (config, field) => {
		return (<Dropdown value={config.rowData[field]} onChange={(e) => onEditorValueChange(config, e.value)} 
						optionGroupLabel="groupLabel"
						optionGroupChildren="items"
						optionLabel="label"
						optionValue="id"
						showClear={field !== 'factor1'} 
						options={factors}
						placeholder={t('Patterns.FactorNoOption')}/>
		);
	}

	const operationColumnEditor = (config, field) => {
		return (<Dropdown value={config.rowData[field]} onChange={(e) => onEditorValueChange(config, e.value)}
						optionLabel="label"
						optionValue="id"
						showClear={field !== 'operation1'} 
						options={operations} 
						placeholder={t('Patterns.OperationNoOption')} />
		);
	}

	const inputTextEditor = (props, field) => {
		return <InputText type="text" value={props.rowData[field]} className="inputfield textbox" onChange={(e) => onEditorValueChange(props, e.target.value)} />;
	}

	const isDefaultBody = (rowData, config) => {
		return (
			<Checkbox checked={rowData['isDefaultPreset']} readOnly disabled/>
		);
	}

	const isDefaultEditor = (config) => {
		if (config.rowData['isDefaultPreset'] === true) {
			return (<Checkbox checked={config.rowData['isDefaultPreset']} readOnly disabled/>);
		} else {
			return (<Checkbox checked={config.rowData['isDefaultPreset']} onChange={(e) => {
				// set all default checked to FALSE except this
				// let thisId = config.rowData.id;
				onEditorValueChange(config, e.checked);
			}} />);
		}
	}

	const nameEditor = (props) => {
		return (
			<React.Fragment>
				{inputTextEditor(props, 'name')}
			</React.Fragment>
		);
	}

	const deletePreset = async (preset) => {
		var doContinue = true;

		if (preset['id'] > 0) {

			// Remove from server side
			let res = await patternsService.deletePreset(preset);

			if (res === null) {
				messagesService.showInfo(t('Patterns.InfoTitle'), t('Patterns.PresetDeletedMessage'));
			} else {
				messagesService.showError(t('Patterns.DeleteErrorTitle'), t('Patterns.PresetDeletedErrorMessage'));
				doContinue = false;
			}
		}

		if (doContinue) {
			// Remove from client side
			var thePresets = [...presets];
			let idx = thePresets.findIndex(i => i['id'] === preset['id']);
			thePresets.splice(idx, 1);

			setPresets(thePresets);
		}
	}

	const confirmDeletePreset = (preset) => {
		if (preset['isDefaultPreset'] === true) {
			confirmDialog({
				message: t('Patterns.CannotDeleteDefaultPresetMessage'),
				header: t('Patterns.InfoTitle'),
				acceptLabel: t('Patterns.ConfirmDialogOk'),
				className: 'scheduler-confirm-panel',
				icon: 'pi pi-exclamation-triangle',
				rejectClassName: 'hidden',
				accept: () => { }
			});
		} else {
			confirmDialog({
				message: t('Patterns.ConfirmDelete'),
				header: t('Patterns.ConfirmDeleteHeader'),
				acceptLabel: t('Patterns.ConfirmDialogYes'),
				rejectLabel: t('Patterns.ConfirmDialogNo'),
				className: 'scheduler-confirm-panel',
				icon: 'pi pi-exclamation-triangle',
				accept: () => deletePreset(preset)
			});
		}
	}

	const confirmSave = async () => {
		confirmDialog({
			message: t('Patterns.ConfirmOverwriteMessage'),
			header: t('Patterns.ConfirmOverwriteHeader'),
			acceptLabel: t('Patterns.ConfirmDialogYes'),
			rejectLabel: t('Patterns.ConfirmDialogNo'),
			icon: 'pi pi-exclamation-triangle',
			accept: async () => {
				var ok = true;

				var res = null;

				if (dataChanged) {
					res = await patternsService.savePresets(presets);
					await reloadTable();
				}

				if (res === null) {
					if (configDataChanged) {
						let cfg = {
							defaultMetric: defaultMetric
						};
						res = await patternsService.savePatternsConfig(cfg);
						await loadConfig();
					}

					if (res != null) {
						ok = false;
					}
				} else {
					ok = false;
				}

				if (ok) {
					messagesService.showSuccess(t('Patterns.PresetsRefreshedTitle'), t('Patterns.PresetsRefreshedMessage'));
				} else {
					messagesService.showError(t('Patterns.PresetsRefreshedErrorTitle'), res);
				}

			}
		});
	}

	const deleteBodyTemplate = (rowData) => {
		return (
			<React.Fragment>
				<Button icon="pi pi-trash" className="p-link p-row-editor-init" onClick={() => confirmDeletePreset(rowData)} />
			</React.Fragment>
		);
	}

	const confirmReset = () => {
		reloadTable();
		loadConfig();
		setDataChanged(false);
	}

	const loadConfig = async () => {
		setLoading(true);
		let patternsConfig = await patternsService.getPatternsConfig();
		setDefaultMetric(patternsConfig.defaultMetric);
		setConfigDataChanged(false);
		setLoading(false);
	}

	useEffect(() => {
		reloadTable();

		async function getDefaults() {
			// Initialize default values
			let factorsAndMetrics = await patternsService.getFactorsAndMetrics(importService, t);
			let theOperations = [
				{ label: t('Patterns.SumOperation'), id: 0 },
				{ label: t('Patterns.AverageOperation'), id: 1 },
				{ label: t('Patterns.MedianOperation'), id: 2 }
			];

			setFactors(factorsAndMetrics.factors);
			setMetrics(factorsAndMetrics.metricsForCategoricalFactors);
			setOperations(theOperations);

			await loadConfig();
		}

		getDefaults();

	}, []);  // eslint-disable-line react-hooks/exhaustive-deps

	if (allowed !== true) {
		return (<></>);
	} else {
		return (
			<>
				<h3 className="text-primary pt-3 pb-3 pl-3">
					{t('Patterns.ConfigHeader')}
					<Button label="" title={t('Patterns.HelpButton')} icon="pi pi-question-circle" className="p-button-config-help p-button-rounded p-button-info" onClick={() => showHelp('Patterns.PresetsHelpTitle', 'Patterns.PresetsHelpMessage')} />
				</h3>
				<div className="field grid mt-3 pl-3 pr-3">
					<div className="col-12 md:col-12 scrollpanel-container">
						<ScrollPanel style={{ width: '100%', height: '350px' }} className="datatable datatable-responsive">
							<DataTable value={presets} dataKey="id"
								className="p-datatable-config"
								paginator={false}
								footer={false}
								editMode="row" editingRows={editingRows}
								style={{ width: '1520px', 'paddingRight': '25px'}}
								onRowEditChange={onRowEditChange} onRowEditInit={onRowEditInit} onRowEditCancel={onRowEditCancel} 
								onRowEditComplete={onRowEditComplete}
								columnResizeMode="fit">
								<Column field="name" header={t('Patterns.NameColumn')} editor={nameEditor} style={{ width: '150px' }}></Column>
								<Column field="isDefaultPreset" header={t('Patterns.IsDefaultColumn')} bodyClassName="center-column" headerClassName="center-column" body={isDefaultBody} editor={isDefaultEditor} style={{ width: '100px' }}></Column>
								<Column field="factor1" header={t('Patterns.Factor1Label')} body={factorColumnBody} editor={(e) => factorColumnEditor(e, 'factor1')} style={{ width: '250px' }}></Column>
								<Column field="operation1" header={t('Patterns.Operation1Label')} body={operationColumnBody} editor={(e) => operationColumnEditor(e, 'operation1')} style={{ width: '130px' }}></Column>
								<Column field="factor2" header={t('Patterns.Factor2Label')} body={factorColumnBody} editor={(e) => factorColumnEditor(e, 'factor2')} style={{ width: '250px' }}></Column>
								<Column field="operation2" header={t('Patterns.Operation2Label')} body={operationColumnBody} editor={(e) => operationColumnEditor(e, 'operation2')} style={{ width: '130px' }}></Column>
								<Column field="factor3" header={t('Patterns.Factor3Label')} body={factorColumnBody} editor={(e) => factorColumnEditor(e, 'factor3')} style={{ width: '250px' }}></Column>
								<Column field="operation3" header={t('Patterns.Operation3Label')} body={operationColumnBody} editor={(e) => operationColumnEditor(e, 'operation3')} style={{ width: '130px' }}></Column>
								<Column rowEditor headerStyle={{ width: '90px' }} bodyStyle={{ textAlign: 'center' }} ></Column>
								<Column body={deleteBodyTemplate} style={{ width: '40px', 'paddingLeft': '0 important' }}></Column>
							</DataTable>
						</ScrollPanel>
					</div>
				</div>
				<div className="flex justify-content-end mr-3 bottom-buttons">
					<ConfigActionBtn onClick={(e) => addPresetElement(e)} buttonText={t('Patterns.AddPresetButton')} />
				</div>
				<div className="field grid mt-3 pl-3 pr-3">
					<label htmlFor="defaultMetric" className="col-12 md:col-3">{t('Patterns.DefaultMetricLabel')}</label>
					<div className="col-12 md:col-3">
						<Dropdown id="defaultMetric" value={defaultMetric} options={metrics}
							optionLabel="label"
							name="defaultMetric"
							placeholder={t('Patterns.Factor1NoOption')}
							optionValue="id" onChange={(e) => {
								setDefaultMetric(e.value);
								setConfigDataChanged(true);
							}} />
					</div>
				</div>

				<div className="flex justify-content-end mr-3 bottom-buttons">
					<ConfigActionBtn onClick={(e) => confirmSave()} buttonText={t('Patterns.SaveButton')} disabled={!dataChanged && !configDataChanged} />
					<ConfigActionBtn onClick={(e) => confirmReset()} buttonText={t('Patterns.CancelButton')} disabled={!dataChanged && !configDataChanged} />
				</div>
			</>
		);
	}
}

export default withTranslation()(PatternPresets)
