import React, { useState, useEffect } from "react";
import { TabView, TabPanel } from "primereact/tabview";
import PieChart from "../Charts/PieChart/PieChart";

const generatePieChartData = (rawData, labels) => {
	return {
		labels: labels ? labels : [],
		datasets: [
			{
				data: rawData.map((item) => item.value),
				backgroundColor: rawData.map((item) => item.color),
			},
		],
	};
};

const MultiTabWidget = (props) => {
	const [activeTab, setActiveTab] = useState(0);
	const [polarData, setPolarData] = useState([]);

	useEffect(() => {
		let polarChart = [];

		props?.data?.tabs.forEach((item) => {
			if (item?.type === "polarArea") {
				item?.categories.forEach((cat) => {
					polarChart.push(cat);
				});
				item.leftCategories.forEach((leftCat) => {
					polarChart.push(leftCat);
				});
				setPolarData(polarChart);
			}
		});
	}, [props?.data?.tabs]);

	return (
		<div className="dashboard-widget">
			<div className="widget-inner-box">
				<div className="dashboard-tab">
					<TabView
						activeIndex={activeTab}
						onTabChange={(e) => setActiveTab(e.index)}
						className="tabview-custom"
					>
						{props.data.tabs.map((tab, i) => {
							return (
								<TabPanel header={tab.name} key={i}>
									{/* pi chart */}
									{tab.type === "pie" && (
										<div className="grid">
											<div className="col-5 lg:col-5">
												<PieChart
													typeChart={tab.type}
													legend={tab.legend}
													tooltip={tab.tooltip}
													data={generatePieChartData(tab.categories, tab.labels)}
												/>
											</div>
											<div className="col-7 lg:col-7 right_text">
												<div className="grid">
													{tab.categories.map((category, i) => {
														return (
															<div className="lg:col-6" key={i}>
																<div
																	style={{ color: category.color }}
																	className="chart-categories-percentage"
																>
																	{category.percentage}
																</div>
																<div className="chart-category-name">
																	{category.title}
																</div>
															</div>
														);
													})}
												</div>
											</div>
										</div>
									)}

									{tab.type === "polarArea" && (
										<div className="grid price-direction">
											<div className="col-2 lg:col-2">
												<div className="grid m-top">
													{tab.leftCategories.map((category, i) => {
														return (
															<div className="lg:col-6" key={i}>
																<div
																	style={{ color: category.color }}
																	className="chart-categories-percentage"
																>
																	{category.percentage}
																</div>

																<div className="chart-category-name">
																	{category.title}
																</div>
															</div>
														);
													})}
												</div>
											</div>

											<div className="col-5 lg:col-5">
												<PieChart
													typeChart={tab.type}
													legend={tab.legend}
													tooltip={tab.tooltip}
													scales={tab.scales}
													data={generatePieChartData(polarData, tab.labels)}
												/>
											</div>
											<div className="col-4 lg:col-4 text-Right">
												<div className="grid d-flex">
													{tab.categories.map((category, i) => {
														return (
															<div className="lg:col-6" key={i}>
																<div
																	style={{ color: category.color }}
																	className="chart-categories-percentage"
																>
																	{category.percentage}
																</div>

																<div className="chart-category-name">
																	{category.title}
																</div>
															</div>
														);
													})}
												</div>
											</div>
										</div>
									)}

									{tab.type === "doughnut" && (
										<div className="grid">
											<div className="col-5 lg:col-5">
												<PieChart
													tooltip={tab.tooltip}
													typeChart={tab.type}
													legend={tab.legend}
													data={generatePieChartData(tab.categories, tab.labels)}
												/>
											</div>
											<div className="col-7 lg:col-7 d-flex">
												<div className="grid align-items-center">
													{tab.categories.map((category, i) => {
														return (
															<div className="lg:col-6 width-style" key={i}>
																<div
																	style={{ color: category.color }}
																	className="chart-categories-percentage"
																>
																	{category.percentage}
																</div>
																<div className="chart-category-name">
																	<span>{category.title}</span>
																	<span> {category.subTitle}</span>
																</div>
															</div>
														);
													})}
												</div>
											</div>
										</div>
									)}
								</TabPanel>
							);
						})}
					</TabView>
					<i className="widget-option-btn pi pi-chevron-circle-down"></i>
				</div>
				<div className="tab-footer">
					{props.data.tabs.map((tab, index) => {
						return (
							<p
								key={index}
								onClick={() => setActiveTab(index)}
								className={`tab-dots ${activeTab === index ? "tab-active" : ""
									}`}
							>
								.
							</p>
						);
					})}
				</div>
			</div>
		</div>
	);
};

export default MultiTabWidget;
