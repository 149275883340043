import React, { useState, useEffect, useRef } from 'react'
import { DataTable } from 'primereact/datatable'
import { Column } from 'primereact/column'
// import { ProgressBar } from 'primereact/progressbar'
import PricingProgressBar from './General/PricingProgressBar'
import { OverlayPanel } from 'primereact/overlaypanel'
import { InputNumber } from 'primereact/inputnumber'
import { Calculate } from '../../Api/client-side/calculate'
import { Tooltip } from 'primereact/tooltip';
import { withTranslation } from 'react-i18next'
import { contextualConfirmDialog } from '../../components/User/ContextualConfirmDialog'
import { Rating } from 'primereact/rating';
import GuardrailsPanel from "./Panels/GuardrailsPanel";
import { classNames } from 'primereact/utils'
import importService from '../../Api/requests/import'
import * as moment from 'moment'

const PricingTable = (props) => {
	const { dt } = props;
	const { dtSelected } = props;
	const { allItemsActiveButton } = props;
	const { loading } = props;
	const { totalRecords } = props;
	const { onPage } = props;
	const { onSort } = props;
	const { onColumnsChange } = props;
	const { rowsPerPage, setRowsPerPage } = props;
	const { lazyParams } = props;
	const { products, setProducts } = props;
	const { checkedItems, setCheckedItems } = props;
	const { onRowClicked } = props;
	const { currencySymbol } = props;
	const { /*sessionStorageSelectedRowsKey, */ sessionStorageColumnsSelectedKey } = props;
	const { canEditPrices } = props;
	const { checkFieldAvailable, allowCalculatedColumns, allowGuardrailsPopup } = props;
	const { paginatorLeftAddon } = props;
	const { customColumns } = props;
	const { t, tReady } = props;

	const [currentMaxProfit, setCurrentMaxProfit] = useState(0);
	const [currentEdit, setCurrentEdit] = useState(null);
	const [editingRows, setEditingRows] = useState({});
	const [selectedPrice, setSelectedPrice] = useState('');

	// Those are used for ResetPrice callback functions to keep updated variable values
	const productsRef = useRef([]);
	const checkedItemsRef = useRef([]);

	const currentRow = useRef(null);
	const negativeProfitOverlay = useRef(null);
	const userPriceOutsideDemandCurveOverlay = useRef(null);
	const columnChooserPanel = useRef(null);
	const newGuardrailOverlay = useRef(null);

	const calculate = new Calculate();

	let originalRows = {};

	useEffect(() => {
		let dataset = allItemsActiveButton ? products : checkedItemsRef.current;

		if (dataset && currentEdit) {
			let index = dataset.findIndex((item) => item.observationId === currentEdit.observationId)
			let products = [...dataset]
			originalRows[index] = { ...products[index] }
			let _editingRows = {
				...editingRows,
				...{ [`${products[index].observationId}`]: true }
			}
			setEditingRows(_editingRows)
			setCurrentEdit(null)
		}
	}, [currentEdit]) // eslint-disable-line react-hooks/exhaustive-deps

	// Reference variables used by callback functions (like in resetPrice()) so we keep an 
	// updated version of them instead of the state value when the callback has been loaded,
	// like it happens when accessing the useState variables.
	useEffect(() => {
		productsRef.current = products;
	}, [products]);

	useEffect(() => {
		checkedItemsRef.current = checkedItems;
	}, [checkedItems]);
	/////////////////////////////////////////////////////////////////////////////////////

	const templatePaginator = {
		layout: 'FirstPageLink PrevPageLink PageLinks NextPageLink RowsPerPageDropdown',
		FirstPageLink: () => {
			return <span className="p-paginator-info text-primary text-md">{t('PricingTable.PageLabel')}</span>
		},
		PrevPageLink: (options) => {
			return (
				<button
					type="button"
					className={options.className + ' pi pi-angle-left'}
					onClick={options.onClick}
					disabled={options.disabled}>
				</button>
			)
		},
		NextPageLink: (options) => {
			return (
				<button
					type="button"
					className={options.className + ' pi pi-angle-right text-primary'}
					onClick={options.onClick}
					disabled={options.disabled}
				>
				</button>
			)
		},
		'RowsPerPageDropdown': (options) => {
			return null
		}
	}

	const dateColumnTemplate = (rowData, item) => {
		if (rowData[item.field]) {
			return moment(rowData[item.field]).format("DD MMM yyyy");
		} else {
			return t('PricingTable.YesNoColumnValue_Not_Available');
		}
	}

	const unitsColumnTemplate = (rowData, item) => {
		var theItemValue = rowData[item.field];
		
		if (theItemValue % 100 !== 0) {
			theItemValue = theItemValue.toFixed(2);
		}
		return theItemValue;
	}

	const skuTemplateColumn = (rowData, item) => {
		if (rowData.isRelationshipItem === true) {
			return (
				<span>
					{`[[${rowData.description}]]`}
				</span>);
		} else {
			return (
				<span>
					{rowData[item.field]}
				</span>);
		}
	}

	const storeTemplateColumn = (rowData, item) => {
		if (rowData.isRelationshipItem === true) {
			return '';
		} else {
			return (
				<span>
					{rowData[item.field]}
				</span>);
		}
	}

	const priceColumnTemplate = (rowData, item) => {
		if (rowData[item.field]) {
			return (
				<div className="grid align-items-center justify-content-start vertical-container p-relative mt-0">
					<div className="col-8 text-right">
						{currencySymbol}{rowData[item.field].toFixed(2)}
					</div>
				</div>);
		} else {
			return t('PricingTable.YesNoColumnValue_Not_Available');
		}
	}

	const dummyEffectColumnTemplateUnits = (rowData, item) => {
		if (rowData.dummiesEffects[item.field] !== undefined) {
			return `${rowData.dummiesEffects[item.field].toFixed(2)}`;
		} else {
			return t('PricingTable.YesNoColumnValue_Not_Available');
		}
	}

	const dummyEffectColumnTemplateConfidence = (rowData, item) => {
		if (rowData.dummiesEffects[item.field] !== undefined) {
			var rating = rowData.dummiesEffects[item.field];
			return <Rating value={rating} cancel={false} className="p-rating-center" readOnly stars={5} />;

			// return `${rowData.dummiesEffects[item.field].toFixed(0)}`;
		} else {
			return t('PricingTable.YesNoColumnValue_Not_Available');
		}
	}

	const dummyEffectColumnTemplateProfitChange = (rowData, item) => {
		if (rowData.dummiesEffects[item.field] !== undefined) {
			return `${rowData.dummiesEffects[item.field].toFixed(2)}`;
		} else {
			return t('PricingTable.YesNoColumnValue_Not_Available');
		}
	}

	const nullableNumericColumnTemplate = (rowData, item) => {
		if (rowData[item.field]) {
			return rowData[item.field];
		} else {
			return t('PricingTable.YesNoColumnValue_Not_Available');
		}
	}

	const projectedTotalProfitTemplate = (rowData) => {
		// Use logarithmic scale for percentage
		let numerator = rowData.projectedTotalProfit > 1 ? Math.log10(rowData.projectedTotalProfit) : 0;
		let denominator = currentMaxProfit > 1 ? Math.log10(currentMaxProfit) : 1;
		let percentage = ((numerator / denominator) * 100);

		return (
			<PricingProgressBar
				percentage={percentage}
				value={rowData.projectedTotalProfit}
				currencySymbol={currencySymbol}
			></PricingProgressBar>
		)
	}

	const changeInProfitTemplate = (rowData) => {
		return (
			<div className="grid align-items-center justify-content-start vertical-container p-relative mt-0">
				<div className="col-8 text-right p-0">
					{currencySymbol}{rowData.changeInProfit.toFixed(2)}
				</div>
				{
					(rowData.changeInProfit < 0)
						? (<div className="col-4 text-right pt-0 pb-0"><span className="btn-circle text-white bg-secondary pi pi-exclamation-circle" onClick={(e) => { negativeProfitOverlay.current.toggle(e) }}></span></div>)
						: null
				}
			</div>)
	}

	const unitsChangeTemplate = (rowData) => {
		return (
			<span>
				{(rowData.unitsPeriodT1 - rowData.unitsPeriodT).toFixed(2)}
			</span>
		)
	}
	const unitsChangePercentageTemplate = (rowData) => {
		return (
			<span>
				{(((rowData.unitsPeriodT1 - rowData.unitsPeriodT) / rowData.unitsPeriodT) * 100).toFixed(2)} %
			</span>
		)
	}
	const profitChangePercentageTemplate = (rowData) => {
		return (
			<span>
				{(((rowData.projectedTotalProfit - rowData.profitThisPeriod) / rowData.profitThisPeriod) * 100).toFixed(2)} %
			</span>
		)
	}

	const updatePrice = (dataset, observationId, field, value, rowData) => {
		if (dataset) {
			let productIndex = dataset.findIndex(
				(item) => item.observationId === observationId
			)

			if (productIndex >= 0) {
				let updatedProducts = [...dataset];
				let item = updatedProducts[productIndex];

				if (item[field] !== value) {
					item[field] = value;
					item.changedByUser = true;
					calculate.calculatePricingItem(item);

					if (rowData) {
						rowData[field] = value;
						rowData.changedByUser = true;
						calculate.calculatePricingItem(rowData);
					}
				}

				return updatedProducts;
			}
		}

		return null;
	}

	const onEditorValueChange = (config, value) => {
		let dataset = config.props.value;

		let updatedProducts = updatePrice(dataset, config.rowData.observationId, config.field, value, config.rowData);

		if (config.props.id === "allProducts") {
			setProducts(updatedProducts);

			// Update the price in "checked items" data grid
			if (checkedItemsRef.current) {
				let productIndex = checkedItemsRef.current.findIndex(
					(item) => item.observationId === config.rowData.observationId,
				)

				if (productIndex >= 0) {
					let updatedChecked = updatePrice(checkedItemsRef.current, config.rowData.observationId, config.field, value, null);
					setCheckedItems(updatedChecked);
				} else {
					var theCheckedItems = [...checkedItemsRef.current];
					theCheckedItems.push(config.rowData);
					setCheckedItems(theCheckedItems);
				}
			} else {
				// No checked items, add the first one
				var onlyOneCheckedItem = [];
				onlyOneCheckedItem.push(config.rowData);
				setCheckedItems(onlyOneCheckedItem);
			}
		} else {
			setCheckedItems(updatedProducts);

			// Update the price change in "main" data grid
			if (products) {
				let updatedAll = updatePrice(products, config.rowData.observationId, config.field, value, null);
				setProducts(updatedAll);
			}
		}
	}

	const onRowEditChange = (event) => {
		setEditingRows(event.data)
	}

	const dataTableRowClass = (data) =>
	{
		return {
			'row-relationship': data.isRelationshipItem === true
		}
	}

	const resetPriceConfirmation = (rowData, event) => {
		// Store state of the row being updated
		currentRow.current = rowData;

		contextualConfirmDialog({
			message: t('PricingTable.ResetToOriginalPriceMsg'),
			header: ' ',
			acceptLabel: t('PricingTable.ResetButton'),
			rejectLabel: t('PricingTable.CancelButton'),
			icon: 'pi pi-contextDialog-tick m-auto block',	// Center icon because we don't have a header here
			accept: () => {
				// Reset Price
				resetPrice();
			},
			reject: () => {
				// Do Nothing
			},
			contextEvent: event
		});
	}

	const cancelPriceOverride = (props, event) => {
		setEditingRows({});
		currentRow.current = props.rowData;
		
		contextualConfirmDialog({
			message: t('PricingTable.SaveChangesConfirmation'),
			header: null,
			acceptLabel: t('PricingTable.SaveButton'),
			rejectLabel: t('PricingTable.CancelButton'),
			icon: 'pi pi-contextDialog-warning',
			accept: () => {
				// Do Nothing
			},
			reject: () => {
				// Reset Price
				resetPrice();
			},
			eventPathIndex: 2,
			contextEvent: event
		});
	}

	const priceOutsideRange = (props, event) => {
		setEditingRows({})
		currentRow.current = props.rowData;

		contextualConfirmDialog({
			message: t('PricingTable.ProfitCannotBePredictedAtThisPrice'),
			header: t('PricingTable.SaveChangesConfirmation'),
			acceptLabel: t('PricingTable.SaveButton'),
			rejectLabel: t('PricingTable.CancelButton'),
			icon: 'pi pi-contextDialog-warning',
			accept: () => {
				// Do Nothing
			},
			reject: () => {
				// Reset Price
				resetPrice();
			},
			eventPathIndex: 2,
			contextEvent: event
		});
	}

	const inputTextEditor = (props, field) => {
		// console.log(props);

		return (
			<InputNumber
				className="col-4"
				autoFocus
				tooltip={t('PricingTable.UserPriceTextboxTooltip', { currencySymbol: currencySymbol, rangeMin: calculate.minUserPriceAllowed(props.rowData), rangeMax: calculate.maxUserPriceAllowed(props.rowData) })}
				value={props.rowData[field]}
				min={calculate.minUserPriceAllowed(props.rowData)}
				max={calculate.maxUserPriceAllowed(props.rowData)}
				maxFractionDigits={2}
				mode="decimal"
				onBlur={(e) => {
					// onValueChange will be executed before onBlur, which allow us to handle the change before to hide the inputNumber control
					setEditingRows({});
				}}
				onValueChange={(e) => {
					onEditorValueChange(props, e.value);

					if (props.rowData.guardrailHigh < props.rowData.recommendedSellingPrice ||
						props.rowData.guardrailLow > props.rowData.recommendedSellingPrice) {
						// If the user price is outside guardrails, warn the user and allow him/her to reset back the change
						priceOutsideRange(props, e.originalEvent);
					} else {
						// If the user price is inside guardrails, allow the user to reset back the change
						cancelPriceOverride(props, e.originalEvent);
					}
				}}
			/>
		)
	}

	const rowGuardrailButton = (rowData) => {
		var appliedButtonClass = 'btn-circle mr-1 ';
		var appliedIconClass = 'pi-svg pi bg-white ';

		var allowPopup = allowGuardrailsPopup;
		var showButton = (rowData.isRelationshipItem === false);

		if (rowData.isRelationshipItem === false &&
			(rowData.recommendedSellingPrice !== rowData.originalRecommendedPrice ||
				rowData.periodT1From === 0)) {
			// User modified the price, check if it's inside guardrails
			if (rowData.guardrailHigh >= rowData.recommendedSellingPrice &&
				rowData.guardrailLow <= rowData.recommendedSellingPrice) {
				// Inside guardrails
				// appliedIconClass += 'pi-guardrail-constrained';
				appliedIconClass += 'pi-recommended-price';
			} else {
				// Outside guardrails
				appliedIconClass += 'pi-out-of-guardrail';
			}

			//showButton = true;
		} else if (rowData.pdu) {
			// PDU modified the price, check if it's inside guardrails
			if (rowData.pduTowardsGuardrail || rowData.grandFathered) {
				// Outside guardrails
				appliedIconClass += 'pi-out-of-guardrail';
			} else if (rowData.guardrailApplied) {
				appliedIconClass += 'pi-guardrail-constrained';
			} else {
				appliedIconClass += 'pi-recommended-price';
			}

			//showButton = true;
		} else if (rowData.hasDemandCurve) {
			// Demand curve price is ALWAYS inside the guardrails
			appliedIconClass += 'pi-recommended-price';
			//showButton = true;
		} else if (rowData.hasDemandCurve === false && rowData.pdu === false) {
			// No repricing event available, so show the icon
			appliedIconClass += 'pi-recommended-price';
		}

		if (showButton) {
			if (rowData.recommendedSellingPrice > rowData.priceLastPeriod) {
				// Price increased, arrow down
				appliedButtonClass += 'bg-sixth';
			} else if (rowData.recommendedSellingPrice < rowData.priceLastPeriod) {
				// Price increased, arrow up
				appliedButtonClass += 'bg-seventh rotate-180';
			} else {
				// Same price, equal sign
				appliedButtonClass += 'bg-sixth';
				appliedIconClass = 'pi-svg pi bg-white pi-same-price';	// Same price it doesn't matter why it will always show the equal sign
			}

			return (<button
				type="button"
				className={allowPopup ? appliedButtonClass : classNames(appliedButtonClass, "cursor-auto")}
				onClick={(e) => {
					// Store state of the row being updated
					if (allowPopup) {
						currentRow.current = rowData;
						newGuardrailOverlay.current.toggle(e);
					}
				}}>
				<span className={appliedIconClass}></span>
			</button>);
		} else {
			return null;
		}
	}

	const recommendedPriceTemplate = (rowData) => {
		return (
			<>
				<div className="grid col-offset-4 align-items-center justify-content-start vertical-container p-relative">

					{ /* Price text */ }
					<span
						className={classNames("text-underline", "mr-1", (rowData.recommendedSellingPrice >= rowData.priceThisPeriod ? 'text-sixth' : 'text-secondary'))}
						onClick={() => {
							if (canEditPrices === true && rowData.isRelationshipItem !== true) {
								setCurrentEdit(rowData);
							}
						}}>
						{currencySymbol}{parseFloat(rowData.recommendedSellingPrice).toFixed(2)}
					</span>

					{ /* Guardrail button */ }
					{rowGuardrailButton(rowData)}

					{ /* Price edited button */ }
					{calculate.priceEditedByUser(rowData) && (
						<button
							type="button"
							className={
								'btn-circle text-white ' +
								(rowData.recommendedSellingPrice > rowData.originalRecommendedPrice
									? 'bg-sixth'
									: (rowData.recommendedSellingPrice < rowData.originalRecommendedPrice ?
										'bg-seventh' : '')
								)
							}
							onClick={(e) => {
								resetPriceConfirmation(rowData, e);
							}}>
							<span className="pi-svg pi pi-edit-pencil bg-white"></span>
						</button>
					)}

					{ /* Price edited - Out of Demand Curve */}
					{calculate.userPriceOutsideDemandCurve(rowData) && (
						<button
							type="button"
							className='btn-circle text-white bg-secondary ml-1'
							onClick={(e) => { userPriceOutsideDemandCurveOverlay.current.toggle(e) }}>
							<span className="pi pi-info-circle"></span>
						</button>
					)}
				</div>
			</>
		)
	}

	const daysSinceLastPriceChangeTemplate = (rowData) => {
		return <React.Fragment>{rowData.daysSinceLastPriceChange}</React.Fragment>
	};

	const longTermPriceIndicatorTemplate = (rowData) => {
		return (
			<>
				<div className="grid col-offset-4 align-items-center justify-content-start vertical-container p-relative">
					<span
						className={classNames("mr-1", (rowData.longTermPriceIndicator >= rowData.previousLongTermPriceIndicator ? 'text-sixth' : 'text-secondary'))}>
						{parseFloat(rowData.longTermPriceIndicator).toFixed(2)}
					</span>
					<button
						type="button"
						className={classNames("btn-circle", "mr-1", "cursor-auto", rowData.longTermPriceIndicator >= rowData.previousLongTermPriceIndicator ? "bg-sixth" : "bg-seventh rotate-180")}>
						<span className={classNames("pi-svg", "pi", "bg-white", rowData.longTermPriceIndicator === rowData.previousLongTermPriceIndicator ? "pi-same-price" : "pi-recommended-price")}></span>
					</button>					
				</div>
			</>
		)
	}

	const longTermPriceAverageIndicatorTemplate = (rowData) => {
		return (
			<>
				<div className="grid col-offset-4 align-items-center justify-content-start vertical-container p-relative">
					<span
						className={classNames("mr-1", (rowData.longTermPriceAverageIndicator >= rowData.previousLongTermPriceAverageIndicator ? 'text-sixth' : 'text-secondary'))}>
						{parseFloat(rowData.longTermPriceAverageIndicator).toFixed(2)}
					</span>
					<button
						type="button"
						className={classNames("btn-circle", "mr-1", "cursor-auto", rowData.longTermPriceAverageIndicator >= rowData.previousLongTermPriceAverageIndicator ? "bg-sixth" : "bg-seventh rotate-180")}>
						<span className={classNames("pi-svg", "pi", "bg-white", rowData.longTermPriceAverageIndicator === rowData.previousLongTermPriceAverageIndicator ? "pi-same-price" : "pi-recommended-price")}></span>
					</button>
				</div>
			</>
		)
	}

	const infoColumnTemplate = (rowData) => {
		var detailText = '';

		if (rowData.periodT1From === 0) {
			detailText = t('PricingTable.State_UserEdited');
		} else if (rowData.grandFathered) {
			detailText = t('PricingTable.State_OutsideGuardrail_Existing');
		} else if (rowData.pduTowardsGuardrail) {
			detailText = t('PricingTable.State_Test_OutsideGuardrail');
		} else if (rowData.guardrailApplied) {
			detailText = t('PricingTable.State_Guardrail_Constrained');
		} else if (rowData.pdu) {
			detailText = t('PricingTable.State_PDU');
		} else if (rowData.hasDemandCurve) {
			if (rowData.demandCurveExtensionPrice != null) {
				detailText = t('PricingTable.State_ExtendedDemandCurve');
			} else {
				detailText = t('PricingTable.State_DemandCurve');
			}
		}

		return (
			<div className="align-items-center justify-content-start vertical-container p-relative grid-info-column">
				{detailText}
			</div>
		);
	}

	const mappingFieldAvailable = (mapping) => {
		if (checkFieldAvailable) {
			return checkFieldAvailable(mapping);
		} else {
			return mapping.displayColumn;
		}
	}

	const confidenceColumnTemplate = (rowData) => {
		var stars = 0;

		if (rowData.demandCurveConfidence === null) {
			stars = 0;
		} else if (rowData.demandCurveConfidence <= 20) {
			stars = 1;
		} else if (rowData.demandCurveConfidence <= 40) {
			stars = 2;
		} else if (rowData.demandCurveConfidence <= 60) {
			stars = 3;
		} else if (rowData.demandCurveConfidence <= 80) {
			stars = 4;
		} else {
			stars = 5;
		}

		return <Rating value={stars} cancel={false} readOnly stars={5} />;
		/*
		var starItems = [];

		for (var i = 0; i < stars; i++) {
			starItems.push(<img src={IcnStar} key={ rowData.observationId + "_star_" + i } alt="Star" className="pr-1" />);
		}

		return (
			<div className="flex align-items-center justify-content-start">
				{ starItems }
			</div>
		);
		*/
	}
	//const priceImpactOnProfitTemplate = (rowData) => {
	//	return (
	//		<ProgressBar
	//			className={`p-progressbar-height p-progressbar-nobackground ${rowData.priceImpactOnProfit < 0 ? 'low' : ''}`}
	//			value={Math.abs(rowData.priceImpactOnProfit.toFixed(2))}
	//			displayValueTemplate={priceImpactOnProfitProgressTemplate}></ProgressBar>
	//	)
	//}
	const lastWeekPriceTemplate = (rowData) => {
		return (
			<div className="grid align-items-center justify-content-start vertical-container p-relative mt-0">
				<div className="col-8 text-right">
					{currencySymbol}{rowData.priceThisPeriod.toFixed(2)}
				</div>
			</div>);
	}
	//const priceImpactOnProfitProgressTemplate = (value) => {
	//	return (
	//		<React.Fragment >
	//			{currencySymbol}{value.toFixed(2)}
	//		</React.Fragment>
	//	)
	//}

	const [columns, setColumns] = useState([])
	const [selectedColumns, setSelectedColumns] = useState([])

	useEffect(() => {
		if (onColumnsChange) {
			onColumnsChange(columns);
		}
	}, [columns])  // eslint-disable-line react-hooks/exhaustive-deps

	useEffect(() => {
		setCurrentMaxProfit(props.currentMaxProfit);
	}, [props]);

	const rawColumnNameBeautify = (mappingData) => {
		if (mappingData.columnHeader) {
			return mappingData.columnHeader;
		}
		else if (mappingData.name) {
			let str = mappingData.name;
			return str[0].toUpperCase() + str.substr(1, str.length - 1).toLowerCase();
		} else {
			return '';
		}
	};

	/**
	 * Retrieve header for dummy effect column in grid
	 * @param {Array} mappings mappings array with information for grid columns
	 * @param {string} fieldName dummy effect field name
	 * @param {string} failbackName fixed failback name to use when the field mapping doesn't have a columnHeader property
	 * @param {boolean} unitsEffect true if it's the header for a UNITS effect, false if it's for a PROFIT effect
	 * @param {boolean} isConfidence true if it's the header for the CONFIDENCE column, false otherwise
	 */
	const retrieveHeaderDummyEffect = (mappings, fieldName, failbackName, unitsEffect, isConfidence) => {
		var baseHeader = retrieveHeader(mappings, fieldName, failbackName);

		if (unitsEffect) {
			if (isConfidence) {
				return t('PricingTable.DummyEffectFieldNameEffectConfidence', { fieldName: baseHeader });
			} else {
				return t('PricingTable.DummyEffectFieldNameEffectUnits', { fieldName: baseHeader });
			}
		} else {
			return t('PricingTable.DummyEffectFieldNameEffectProfit', { fieldName: baseHeader });
		}
	}

	/**
	 * Retrieve header for column based on mappings array
	 * @param {Array} mappings mapping array with information for grid columns
	 * @param {string} fieldName field name to add column
	 * @param {string} failbackName fixed failback name to use when the field mapping doesn't have a columnHeader property
	 */
	const retrieveHeader = (mappings, fieldName, failbackName) => {
		var idx = -1;
		idx = mappings.findIndex(m => m.mapping.toUpperCase() === fieldName.toUpperCase());

		if (idx >= 0) {
			if (mappings[idx].columnHeader) {
				return mappings[idx].columnHeader;
			} else {
				return failbackName;
			}
		} else {
			return failbackName;
		}
	}

	useEffect(() => {
		const loadColumns = async () => {
			if (tReady && currencySymbol) {

				// Retrieve all mappings
				let mappings = await importService.getMappingItems(0);

				// Initialize default columns
				let theColumns = [];

				// Those are basic fields and are added manually at left side of the datagrid ALWAYS, so don't process them again when checking the mappings
				let fixedColumnsMapping = ["SKU", "StoreNumber", "SellingPrice", "Units"];
			
				let propertyMapping = {
					"Date": { field: "date", header: retrieveHeader(mappings, "date", t('PricingTable.Date_Header')), body: dateColumnTemplate },
					"StoreNumber": { field: "storeNumber", body: storeTemplateColumn },
					"SKU": { field: "sku", body: skuTemplateColumn },
					"Description": { field: "description", header: retrieveHeader(mappings, "description", t('PricingTable.Description_Header')) },
					"Units": { field: "unitsPeriodT1", body: unitsColumnTemplate, sortField: "unitsT1", header: t('PricingTable.Units_Header') },
					"Revenue": { field: "revenue", body: priceColumnTemplate, header: retrieveHeader(mappings, "revenue", t('PricingTable.Revenue_Header')) },
					"SellingPrice": { field: "sellingPrice" },
					"ListPrice": { field: "listPrice", body: priceColumnTemplate, header: retrieveHeader(mappings, "listPrice", t('PricingTable.ListPrice_Header')) },

					"PromoFlag1": { field: "PromoFlag1_Effect_Units", body: dummyEffectColumnTemplateUnits, header: retrieveHeaderDummyEffect(mappings, "PromoFlag1", 'PromoFlag1', true, false) },
					"PromoFlag2": { field: "PromoFlag2_Effect_Units", body: dummyEffectColumnTemplateUnits, header: retrieveHeaderDummyEffect(mappings, "PromoFlag2", 'PromoFlag2', true, false) },
					"PromoFlag3": { field: "PromoFlag3_Effect_Units", body: dummyEffectColumnTemplateUnits, header: retrieveHeaderDummyEffect(mappings, "PromoFlag3", 'PromoFlag3', true, false) },
					"Dummy4": { field: "Dummy4_Effect_Units", body: dummyEffectColumnTemplateUnits, header: retrieveHeaderDummyEffect(mappings, "Dummy4", 'Dummy4', true, false) },
					"Dummy5": { field: "Dummy5_Effect_Units", body: dummyEffectColumnTemplateUnits, header: retrieveHeaderDummyEffect(mappings, "Dummy5", 'Dummy5', true, false) },
					"Dummy6": { field: "Dummy6_Effect_Units", body: dummyEffectColumnTemplateUnits, header: retrieveHeaderDummyEffect(mappings, "Dummy6", 'Dummy6', true, false) },
					"Dummy7": { field: "Dummy7_Effect_Units", body: dummyEffectColumnTemplateUnits, header: retrieveHeaderDummyEffect(mappings, "Dummy7", 'Dummy7', true, false) },
					"Dummy8": { field: "Dummy8_Effect_Units", body: dummyEffectColumnTemplateUnits, header: retrieveHeaderDummyEffect(mappings, "Dummy8", 'Dummy8', true, false) },
					"Dummy9": { field: "Dummy9_Effect_Units", body: dummyEffectColumnTemplateUnits, header: retrieveHeaderDummyEffect(mappings, "Dummy9", 'Dummy9', true, false) },
					"Dummy10": { field: "Dummy10_Effect_Units", body: dummyEffectColumnTemplateUnits, header: retrieveHeaderDummyEffect(mappings, "Dummy10", 'Dummy10', true, false) },
					"Dummy11": { field: "Dummy11_Effect_Units", body: dummyEffectColumnTemplateUnits, header: retrieveHeaderDummyEffect(mappings, "Dummy11", 'Dummy11', true, false) },
					"Dummy12": { field: "Dummy12_Effect_Units", body: dummyEffectColumnTemplateUnits, header: retrieveHeaderDummyEffect(mappings, "Dummy12", 'Dummy12', true, false) },
					"Dummy13": { field: "Dummy13_Effect_Units", body: dummyEffectColumnTemplateUnits, header: retrieveHeaderDummyEffect(mappings, "Dummy13", 'Dummy13', true, false) },
					"Dummy14": { field: "Dummy14_Effect_Units", body: dummyEffectColumnTemplateUnits, header: retrieveHeaderDummyEffect(mappings, "Dummy14", 'Dummy14', true, false) },
					"Dummy15": { field: "Dummy15_Effect_Units", body: dummyEffectColumnTemplateUnits, header: retrieveHeaderDummyEffect(mappings, "Dummy15", 'Dummy15', true, false) },
					"Dummy16": { field: "Dummy16_Effect_Units", body: dummyEffectColumnTemplateUnits, header: retrieveHeaderDummyEffect(mappings, "Dummy16", 'Dummy16', true, false) },

					"StockOut": { field: "StockOut_Effect_Units", body: dummyEffectColumnTemplateUnits, header: retrieveHeaderDummyEffect(mappings, "stockout", t('PricingTable.StockOut_Header'), true, false) },

					"PromoFlag1_Effect_Profit_Change": { field: "PromoFlag1_Effect_Profit", body: dummyEffectColumnTemplateProfitChange, header: retrieveHeaderDummyEffect(mappings, "PromoFlag1", 'PromoFlag1', false, false) },
					"PromoFlag2_Effect_Profit_Change": { field: "PromoFlag2_Effect_Profit", body: dummyEffectColumnTemplateProfitChange, header: retrieveHeaderDummyEffect(mappings, "PromoFlag2", 'PromoFlag2', false, false) },
					"PromoFlag3_Effect_Profit_Change": { field: "PromoFlag3_Effect_Profit", body: dummyEffectColumnTemplateProfitChange, header: retrieveHeaderDummyEffect(mappings, "PromoFlag3", 'PromoFlag3', false, false) },
					"Dummy4_Effect_Profit_Change": { field: "Dummy4_Effect_Profit", body: dummyEffectColumnTemplateProfitChange, header: retrieveHeaderDummyEffect(mappings, "Dummy4", 'Dummy4', false, false) },
					"Dummy5_Effect_Profit_Change": { field: "Dummy5_Effect_Profit", body: dummyEffectColumnTemplateProfitChange, header: retrieveHeaderDummyEffect(mappings, "Dummy5", 'Dummy5', false, false) },
					"Dummy6_Effect_Profit_Change": { field: "Dummy6_Effect_Profit", body: dummyEffectColumnTemplateProfitChange, header: retrieveHeaderDummyEffect(mappings, "Dummy6", 'Dummy6', false, false) },
					"Dummy7_Effect_Profit_Change": { field: "Dummy7_Effect_Profit", body: dummyEffectColumnTemplateProfitChange, header: retrieveHeaderDummyEffect(mappings, "Dummy7", 'Dummy7', false, false) },
					"Dummy8_Effect_Profit_Change": { field: "Dummy8_Effect_Profit", body: dummyEffectColumnTemplateProfitChange, header: retrieveHeaderDummyEffect(mappings, "Dummy8", 'Dummy8', false, false) },
					"Dummy9_Effect_Profit_Change": { field: "Dummy9_Effect_Profit", body: dummyEffectColumnTemplateProfitChange, header: retrieveHeaderDummyEffect(mappings, "Dummy9", 'Dummy9', false, false) },
					"Dummy10_Effect_Profit_Change": { field: "Dummy10_Effect_Profit", body: dummyEffectColumnTemplateProfitChange, header: retrieveHeaderDummyEffect(mappings, "Dummy10", 'Dummy10', false, false) },
					"Dummy11_Effect_Profit_Change": { field: "Dummy11_Effect_Profit", body: dummyEffectColumnTemplateProfitChange, header: retrieveHeaderDummyEffect(mappings, "Dummy11", 'Dummy11', false, false) },
					"Dummy12_Effect_Profit_Change": { field: "Dummy12_Effect_Profit", body: dummyEffectColumnTemplateProfitChange, header: retrieveHeaderDummyEffect(mappings, "Dummy12", 'Dummy12', false, false) },
					"Dummy13_Effect_Profit_Change": { field: "Dummy13_Effect_Profit", body: dummyEffectColumnTemplateProfitChange, header: retrieveHeaderDummyEffect(mappings, "Dummy13", 'Dummy13', false, false) },
					"Dummy14_Effect_Profit_Change": { field: "Dummy14_Effect_Profit", body: dummyEffectColumnTemplateProfitChange, header: retrieveHeaderDummyEffect(mappings, "Dummy14", 'Dummy14', false, false) },
					"Dummy15_Effect_Profit_Change": { field: "Dummy15_Effect_Profit", body: dummyEffectColumnTemplateProfitChange, header: retrieveHeaderDummyEffect(mappings, "Dummy15", 'Dummy15', false, false) },
					"Dummy16_Effect_Profit_Change": { field: "Dummy16_Effect_Profit", body: dummyEffectColumnTemplateProfitChange, header: retrieveHeaderDummyEffect(mappings, "Dummy16", 'Dummy16', false, false) },
					"StockOut_Effect_Profit_Change": { field: "StockOut_Effect_Profit", body: dummyEffectColumnTemplateProfitChange, header: retrieveHeaderDummyEffect(mappings, "stockout", t('PricingTable.StockOut_Header'), false, false) },

					"PromoFlag1_Confidence": { field: "PromoFlag1_Effect_Confidence", body: dummyEffectColumnTemplateConfidence, header: retrieveHeaderDummyEffect(mappings, "PromoFlag1", 'PromoFlag1', true, true) },
					"PromoFlag2_Confidence": { field: "PromoFlag2_Effect_Confidence", body: dummyEffectColumnTemplateConfidence, header: retrieveHeaderDummyEffect(mappings, "PromoFlag2", 'PromoFlag2', true, true) },
					"PromoFlag3_Confidence": { field: "PromoFlag3_Effect_Confidence", body: dummyEffectColumnTemplateConfidence, header: retrieveHeaderDummyEffect(mappings, "PromoFlag3", 'PromoFlag3', true, true) },
					"Dummy4_Confidence": { field: "Dummy4_Effect_Confidence", body: dummyEffectColumnTemplateConfidence, header: retrieveHeaderDummyEffect(mappings, "Dummy4", 'Dummy4', true, true) },
					"Dummy5_Confidence": { field: "Dummy5_Effect_Confidence", body: dummyEffectColumnTemplateConfidence, header: retrieveHeaderDummyEffect(mappings, "Dummy5", 'Dummy5', true, true) },
					"Dummy6_Confidence": { field: "Dummy6_Effect_Confidence", body: dummyEffectColumnTemplateConfidence, header: retrieveHeaderDummyEffect(mappings, "Dummy6", 'Dummy6', true, true) },
					"Dummy7_Confidence": { field: "Dummy7_Effect_Confidence", body: dummyEffectColumnTemplateConfidence, header: retrieveHeaderDummyEffect(mappings, "Dummy7", 'Dummy7', true, true) },
					"Dummy8_Confidence": { field: "Dummy8_Effect_Confidence", body: dummyEffectColumnTemplateConfidence, header: retrieveHeaderDummyEffect(mappings, "Dummy8", 'Dummy8', true, true) },
					"Dummy9_Confidence": { field: "Dummy9_Effect_Confidence", body: dummyEffectColumnTemplateConfidence, header: retrieveHeaderDummyEffect(mappings, "Dummy9", 'Dummy9', true, true) },
					"Dummy10_Confidence": { field: "Dummy10_Effect_Confidence", body: dummyEffectColumnTemplateConfidence, header: retrieveHeaderDummyEffect(mappings, "Dummy10", 'Dummy10', true, true) },
					"Dummy11_Confidence": { field: "Dummy11_Effect_Confidence", body: dummyEffectColumnTemplateConfidence, header: retrieveHeaderDummyEffect(mappings, "Dummy11", 'Dummy11', true, true) },
					"Dummy12_Confidence": { field: "Dummy12_Effect_Confidence", body: dummyEffectColumnTemplateConfidence, header: retrieveHeaderDummyEffect(mappings, "Dummy12", 'Dummy12', true, true) },
					"Dummy13_Confidence": { field: "Dummy13_Effect_Confidence", body: dummyEffectColumnTemplateConfidence, header: retrieveHeaderDummyEffect(mappings, "Dummy13", 'Dummy13', true, true) },
					"Dummy14_Confidence": { field: "Dummy14_Effect_Confidence", body: dummyEffectColumnTemplateConfidence, header: retrieveHeaderDummyEffect(mappings, "Dummy14", 'Dummy14', true, true) },
					"Dummy15_Confidence": { field: "Dummy15_Effect_Confidence", body: dummyEffectColumnTemplateConfidence, header: retrieveHeaderDummyEffect(mappings, "Dummy15", 'Dummy15', true, true) },
					"Dummy16_Confidence": { field: "Dummy16_Effect_Confidence", body: dummyEffectColumnTemplateConfidence, header: retrieveHeaderDummyEffect(mappings, "Dummy16", 'Dummy16', true, true) },
					"StockOut_Confidence": { field: "StockOut_Effect_Confidence", body: dummyEffectColumnTemplateConfidence, header: retrieveHeaderDummyEffect(mappings, "stockout", t('PricingTable.StockOut_Header'), true, false, true) },

					"UnitCost": { field: "unitCostT", sortField: "unitCost", body: priceColumnTemplate, header: retrieveHeader(mappings, "unitCost", t('PricingTable.UnitCost_Header')) },
					"Inventory": { field: "inventory", body: nullableNumericColumnTemplate, header: retrieveHeader(mappings, "inventory", t('PricingTable.Inventory_Header')) },
					"InventoryStarting": { field: "inventory_Starting", body: nullableNumericColumnTemplate, header: retrieveHeader(mappings, "inventory_Starting", t('PricingTable.InventoryStarting_Header')) },
					"InventoryEnding": { field: "inventory_Ending", body: nullableNumericColumnTemplate, header: retrieveHeader(mappings, "inventory_Ending", t('PricingTable.InventoryEnding_Header')) },

					// ProductLookup table
					"Product_UPC": { field: "sku" },
					"Product_Description": { field: "description" },
					"Product_Manufacturer": { field: "product_manufacturer" },
					"Product_Category": { field: "product_category" },
					"Product_SubCategory": { field: "product_subcategory" },
					"Product_Size": { field: "product_size" },

					"PriceGroup": { field: "PriceGroup" }
				}

				// Start basic columns
				var idx = -1;
				if (customColumns) {
					for (const [key, value] of Object.entries(customColumns)) {
						theColumns.push(value);
					}
				}

				idx = mappings.findIndex(m => m.mapping === "SKU");

				if (idx >= 0 && mappings[idx].displayColumn && mappingFieldAvailable(mappings[idx])) {
					theColumns.push({
						field: 'sku',
						header: mappings[idx].columnHeader ? mappings[idx].columnHeader : t('PricingTable.SKU_Header'),
						body: skuTemplateColumn,
						intialState: true,
						sortableDisabled: false
					});
				}

				idx = mappings.findIndex(m => m.mapping === "StoreNumber");
				if (idx >= 0 && mappings[idx].displayColumn && mappingFieldAvailable(mappings[idx])) {
					theColumns.push({
						field: 'storeNumber',
						header: mappings[idx].columnHeader ? mappings[idx].columnHeader : t('PricingTable.Store_Header'),
						body: storeTemplateColumn,
						intialState: true,
						sortableDisabled: false
					});
				}

				idx = mappings.findIndex(m => m.mapping === "SellingPrice");
				if (idx >= 0 && mappings[idx].displayColumn && mappingFieldAvailable(mappings[idx])) {
					theColumns.push({
						field: 'recommendedSellingPrice',
						header: t('PricingTable.RecommendedPrice_Header'),
						body: recommendedPriceTemplate,
						intialState: true,
						sortableDisabled: false
					});
				}

				if (allowCalculatedColumns) {
					theColumns.push({
						field: 'projectedTotalProfit',
						header: t('PricingTable.ProjectedTotalProfit_Header'),
						body: projectedTotalProfitTemplate,
						intialState: false,
						sortableDisabled: false
					});

					theColumns.push({
						field: 'changeInProfit',
						header: t('PricingTable.ChangeInProfit_Header'),
						intialState: true,
						body: changeInProfitTemplate,
						sortableDisabled: false
					});
					theColumns.push({
						field: 'projectedTotalGrossProfit',
						header: t('PricingTable.ProjectedTotalGrossProfit_Header'),
						body: priceColumnTemplate,
						intialState: false,
						sortableDisabled: false
					});

					theColumns.push({
						field: 'changeInGrossProfit',
						header: t('PricingTable.ChangeInGrossProfit_Header'),
						body: priceColumnTemplate,
						intialState: false,
						sortableDisabled: false
					});
				}

				// Put UNITS up in the columns list
				theColumns.push(propertyMapping["Units"]);

				if (idx >= 0 && mappings[idx].displayColumn) {
					theColumns.push({
						field: 'sellingPrice',
						header: mappings[idx].columnHeader ? mappings[idx].columnHeader : t('PricingTable.LastWeekPrice_Header'),
						intialState: false,
						body: lastWeekPriceTemplate,
						sortableDisabled: false
					});
				};
				// End basic columns

				if (allowCalculatedColumns) {
					// Start calculated "artificial" columns
					theColumns.push({
						field: 'longTermPriceIndicator',
						header: t('PricingTable.LongTermPriceIndicator_Header'),
						body: longTermPriceIndicatorTemplate,
						intialState: false,
						sortableDisabled: false
					});

					theColumns.push({
						field: 'longTermPriceAverageIndicator',
						header: t('PricingTable.LongTermPriceAverageIndicator_Header'),
						body: longTermPriceAverageIndicatorTemplate,
						intialState: false,
						sortableDisabled: false
					});					

					theColumns.push({
						field: 'info',
						header: t('PricingTable.InfoColumn_Header'),
						body: infoColumnTemplate,
						intialState: false,
						sortableDisabled: true
					});

					theColumns.push({
						field: 'confidence',
						header: t('PricingTable.Confidence_Header'),
						body: confidenceColumnTemplate,
						intialState: false,
						sortableDisabled: false
					});
					theColumns.push({
						field: 'lastPriceChangeDate',
						header: t('PricingTable.DaysSinceLastPriceChange_Header'),
						body: daysSinceLastPriceChangeTemplate,
						intialState: false,
						sortableDisabled: false
					});

					theColumns.push({
						field: 'unitsChange',
						header: t('PricingTable.Units_Change'),
						body: unitsChangeTemplate,
						intialState: false,
						sortableDisabled: true
					});
					theColumns.push({
						field: 'unitsChangePercentage',
						header: t('PricingTable.Units_Change_Percentage'),
						body: unitsChangePercentageTemplate,
						intialState: false,
						sortableDisabled: true
					});
					theColumns.push({
						field: 'profitChangePercentage',
						header: t('PricingTable.Profit_Change_Percentage'),
						body: profitChangePercentageTemplate,
						intialState: false,
						sortableDisabled: true
					});
					// End of calculated artificial values
				}

				// Start dynamic fields from mapping configuration.
				for (var i = 0; i < mappings.length; i++) {
					let idx = i;
					if (fixedColumnsMapping.findIndex(name => name.toUpperCase() === mappings[idx].mapping.toUpperCase()) < 0) {
						if (mappingFieldAvailable(mappings[idx]) && propertyMapping[mappings[idx].mapping] !== undefined) {
							var item = {
								field: propertyMapping[mappings[idx].mapping].field,
								header: propertyMapping[mappings[idx].mapping].header ? propertyMapping[mappings[idx].mapping].header : rawColumnNameBeautify(mappings[idx]),
								intialState: false,
								sortableDisabled: false
							};

							if (propertyMapping[mappings[idx].mapping].body) {
								item.body = propertyMapping[mappings[idx].mapping].body;
							}

							if (propertyMapping[mappings[idx].mapping].sortField) {
								item.sortField = propertyMapping[mappings[idx].mapping].sortField;
							}

							theColumns.push(item);

							if (calculate.isDummy(mappings[idx].mapping)) {
								var lookupName = `${mappings[idx].mapping}_Effect_Profit_Change`;
								addExtraDummyEffect(mappings[idx], propertyMapping, lookupName, theColumns);

								lookupName = `${mappings[idx].mapping}_Confidence`;
								addExtraDummyEffect(mappings[idx], propertyMapping, lookupName, theColumns);
							}
						}
					}
				}
				// End dynamic fields from mapping configuration

				setColumns(theColumns);
			}
		}

		loadColumns();
	}, [tReady, currencySymbol, currentMaxProfit]) // eslint-disable-line react-hooks/exhaustive-deps

	const addExtraDummyEffect = (columnMapping, propertyMapping, lookupName, theColumns) => {
		var absItem = {
			field: `${propertyMapping[lookupName].field}`,
			header: propertyMapping[lookupName].header ? propertyMapping[lookupName].header : rawColumnNameBeautify(columnMapping),
			intialState: false,
			sortableDisabled: false
		};

		if (propertyMapping[lookupName].body) {
			absItem.body = propertyMapping[lookupName].body;
		}

		if (propertyMapping[lookupName].sortField) {
			absItem.sortField = propertyMapping[lookupName].sortField;
		}

		theColumns.push(absItem);
	}

	const overlayHeaderTable = (
		<div className="text-primary text-base">
			{t('PricingTable.ColumnChooserTitle')}
		</div>
	)

	const paginatorLeft = (
		<>
		<div className="paginator-options">
			<button
				type="button"
				className="btn btn-none btn-column-chooser"
				onClick={(e) => columnChooserPanel.current.toggle(e)}
				aria-haspopup
				aria-controls="overlay_panel"
				title={t('PricingTable.ColumnChooserTitle')}>
			</button>

			<OverlayPanel
				ref={columnChooserPanel}
				id="overlay_panel"
				className="overlaypanel-wrapper nopadding">
				<div
					className="datatable datatable-responsive column-chooser"
					header="Responsive"
				>
						<DataTable
							value={columns}
							header={overlayHeaderTable}
							className="p-datatable-products"
							columnResizeMode="fit"
							selectionMode="checkbox"
							selection={selectedColumns}
							scrollable
							scrollHeight="70vh"
							onSelectionChange={(e) => saveColumnChooserLocalStorage(e)}
							dataKey="header">
							<Column selectionMode="multiple" headerStyle={{ width: '3em' }}></Column>
							<Column field="header" header="Column Chooser"></Column>
						</DataTable>
				</div>
			</OverlayPanel>
		</div>
		{ paginatorLeftAddon }
		</>
	)
	const paginatorOptions = [10,20,30,50,100]

	const pagePerView = (<div className="p-page-per-view">
		{t('PricingTable.ItemsPerPage')}
		<div className="p-page-per-view-items">
			{paginatorOptions.map(
				(item) => <button className={item === rowsPerPage ? 'isActive' : '' }
					 onClick={(e) => {
						 setRowsPerPage(item);
						 onPageChanged({ rows: item });
					 }}
					 key={item}
					 type="button">{item}</button>
			)}
		</div>
	</div>)

	const [dynamicColumns, setDynamicColumns] = useState([])

	const saveSelectedRowLocalStorage = (e, theDataTable) => {
		if (e.type === "all") {
			if (e.value && e.value.length > 0) {
				// Add all
				var theCheckedItems = [...e.value, ...checkedItems];

				// Remove duplicated
				theCheckedItems = theCheckedItems.reduce((unique, o) => {
					if (!unique.some((obj) => obj.sku === o.sku && obj.storeNumber === o.storeNumber)) {
						unique.push(o);
					}
					return unique;
				}, []);

				setCheckedItems(theCheckedItems);
			} else {
				// Remove all
				var myCheckedItems = [...checkedItems];

				myCheckedItems = myCheckedItems.reduce((unique, o) => {
					if (!theDataTable.props.value.some((obj) => obj.sku === o.sku && obj.storeNumber === o.storeNumber)) {
						unique.push(o);
					}
					return unique;
				}, []);	// Initialize "unique" array with the items on current page for grid, because we don't want them

				setCheckedItems(myCheckedItems);
			}
		} else if (e.type === "checkbox") {
			setCheckedItems(e.value);
		}
	}

	const saveColumnChooserLocalStorage = (e) => {
		let orderedSelectedColumns = columns.filter((col) =>
			e.value.some((sCol) => sCol.field === col.field)
		)
		setSelectedColumns(orderedSelectedColumns)

		// Save only field names to session storage, because if we restore the full object it will not have the hook to the BODY method
		// and the templates will not be rendered
		let fields = orderedSelectedColumns.map(a => a.field);
		sessionStorage.setItem(sessionStorageColumnsSelectedKey, JSON.stringify(fields))
	}

	const rowClicked = (e) => {
		let target = e.originalEvent.target;
		let tr = target.closest('tr');
		let tbody = target.closest('tbody');

		// Remove previously selected row class...
		for (let i = 0; i < tbody.children.length; i++) {
			tbody.children[i].classList.remove('row-selected');
		}

		// ...and add selected class to selected row
		tr.classList.add('row-selected')

		if (onRowClicked) {
			onRowClicked(e.data);
		}
	}

	useEffect(() => {
		// Read previously selected columns from session storage when the pricing table is first loaded
		var theFields = sessionStorage.getItem(sessionStorageColumnsSelectedKey)
			? JSON.parse(sessionStorage.getItem(sessionStorageColumnsSelectedKey))
			: columns.filter((item) => item.intialState === true).map(a => a.field);

		var theColumns = [];

		theFields.forEach((field) => {
			let element = columns.find((item) => item.field === field);

			if (element) {
				theColumns.push(element);
			}
		});

		setSelectedColumns(theColumns)
	}, [columns]); // eslint-disable-line react-hooks/exhaustive-deps

	useEffect(() => {
		// When selected columns changes, update the dynamicColumns too (that handles the <Column/> components for the main datatable)
		var theDynamicColumns = selectedColumns.map((col, i) => {
			return (
				<Column
					key={col.field}
					field={col.field}
					header={col.header}
					body={col.body}
					sortable={!col.sortableDisabled}
					sortField={col.sortField ? col.sortField : col.field}
					editor={
						(col.field === 'recommendedSellingPrice')
							? (props) => inputTextEditor(props, 'recommendedSellingPrice')
							: undefined
					}
				/>
			)
		});

		setDynamicColumns(theDynamicColumns);

	}, [selectedColumns]); // eslint-disable-line react-hooks/exhaustive-deps

	const removeRowSelectedEffect = () => {
		var container = allItemsActiveButton && (dt && dt.current) ? dt.current.container : (dtSelected && dtSelected.current) ? dtSelected.current.container : null;

		if(!container) return null

		// Remove selected row class
		let rows = container.querySelectorAll("tr.row-selected");
		if (rows) {
			rows.forEach((el) => {
				el.classList.remove('row-selected');
			});
		}
	}

	const onPageChanged = (event, grid) => {
		if (onPage) {
			removeRowSelectedEffect();
			onPage(event, grid);
		}
	};

	const onSortInvoked = (event) => {
		if (onSort) {
			removeRowSelectedEffect();
			onSort(event);
		}
	};

	const resetPrice = () => {
		let allProducts = updatePrice(productsRef.current, currentRow.current.observationId, "recommendedSellingPrice", currentRow.current.originalRecommendedPrice, null);
		let checkedProducts = updatePrice(checkedItemsRef.current, currentRow.current.observationId, "recommendedSellingPrice", currentRow.current.originalRecommendedPrice, null);

		if (checkedProducts === null) {
			checkedProducts = [];
		}

		checkedProducts.push(currentRow.current);

		setProducts(allProducts);
		setCheckedItems(checkedProducts);
	};

	const canSelectProduct = (rowData, options) => { 
		if (rowData.isRelationshipItem === true) {
			return null;
		} else {
			return true;
        }
	}

	return (
		<>
			<Tooltip target=".info-icon" />

			<div
				className="datatable datatable-rounded datatable-responsive change-order-paginator"
				header="Responsive">

				{(allItemsActiveButton) ?
					<DataTable
						ref={dt}
						id="allProducts"
						value={products}
						lazy
						className="p-datatable-products"
						rowHover
						emptyMessage={t('PricingTable.NoItemFoundMsg')}
						paginator
						paginatorTemplate={templatePaginator}
						paginatorLeft={paginatorLeft}
						header={pagePerView}
						resizableColumns
						columnResizeMode="fit"
						showGridlines
						selectionMode="checkbox"
						showSelectionElement={canSelectProduct}
						selection={checkedItems}
						onSelectionChange={(e) => saveSelectedRowLocalStorage(e, dt.current)}
						onRowClick={(e) => rowClicked(e)}
						editMode="row"
						editingRows={editingRows}
						onRowEditChange={onRowEditChange}
						rowClassName={dataTableRowClass}
						dataKey="observationId"
						onSort={(e) => onSortInvoked(e)}
						sortField={lazyParams.sortField}
						sortOrder={lazyParams.sortOrder}
						first={lazyParams.first}
						rows={rowsPerPage}
						totalRecords={totalRecords}
						onPage={(e) => onPageChanged(e, 'main')}
						responsiveLayout="scroll"
						loading={loading}>
						<Column
							selectionMode="multiple"
							headerStyle={{ width: '3em' }}
						></Column>
						{dynamicColumns}
					</DataTable>
					:
					<DataTable
						ref={dtSelected}
						value={checkedItems}
						id="checkedProducts"
						className="p-datatable-products"
						rowHover
						emptyMessage={t('PricingTable.NoItemFoundMsg')}
						paginator
						paginatorTemplate={templatePaginator}
						paginatorLeft={paginatorLeft}
						header={pagePerView}
						resizableColumns
						columnResizeMode="fit"
						showGridlines
						selectionMode="checkbox"
						showSelectionElement={canSelectProduct}
						selection={checkedItems}
						onSelectionChange={(e) => saveSelectedRowLocalStorage(e, dtSelected.current)}
						onRowClick={(e) => rowClicked(e)}
						editMode="row"
						editingRows={editingRows}
						onRowEditChange={onRowEditChange}
						rowClassName={dataTableRowClass}
						dataKey="observationId"
						onSort={(e) => onSortInvoked(e)}
						sortField={lazyParams.sortField}
						sortOrder={lazyParams.sortOrder}
						first={lazyParams.first}
						rows={rowsPerPage}
						totalRecords={checkedItems != null ? checkedItems.length : 0}
						onPage={(e) => onPageChanged(e, 'checked')}
						loading={loading}>
						<Column
							selectionMode="multiple"
							headerStyle={{width: '3em'}}
						></Column>
						{dynamicColumns}
					</DataTable>
				}

				<OverlayPanel
					ref={negativeProfitOverlay}
					style={{ width: '450px' }}
					id="overlay_panel_negative_profit"
					className="p-overlaypanel-wrapper p-overlaypanel-rounded-lg">
					<div>
						<div className="mb-3 text-lg text-primary mb-2 overlay-title">
							<div alt="Info" className="mr-2 icon warning"></div>
							<span className="mb-1" >{t('PricingTable.NegativeProfitPanelTitle')}</span>
						</div>
						<p className="text-primary text-base">
							{t('PricingTable.NegativeProfitPanelMsg')}
						</p>
					</div>
				</OverlayPanel>

				<OverlayPanel
					ref={userPriceOutsideDemandCurveOverlay}
					style={{ width: '450px' }}
					id="overlay_panel_negative_profit"
					className="p-overlaypanel-wrapper p-overlaypanel-rounded-lg">
					<div>
						<div className="mb-3 text-lg text-primary mb-2 overlay-title">
							<div alt="Info" className="mr-2 icon warning"></div>
							<span className="mb-1" >{t('PricingTable.UserPriceOutOfDemandCurveTitle')}</span>
						</div>
						<p className="text-primary text-base">
							{t('PricingTable.UserPriceOutOfDemandCurveMsg')}
						</p>
					</div>
				</OverlayPanel>				

				<OverlayPanel
					ref={newGuardrailOverlay}
					style={{ width: '580px', height: '380px' }}
					id="overlay_panel_guardrail"
					className="p-overlaypanel-wrapper p-overlaypanel-rounded-lg">
					<GuardrailsPanel
						modal={newGuardrailOverlay}
						onPriceChangeHandler={(theSelectedPrice) => {
							//console.log('------ test ------');
							//console.log(guardrail);
							//console.log(currentRow.current);
							//console.log('-------------------');


							// First update the "all products" grid...
							/*let allProducts = */ updatePrice(products, currentRow.current.observationId, "recommendedSellingPrice", parseFloat(theSelectedPrice), currentRow.current);

							// ... later the "checked items" grid
							if (checkedItemsRef.current) {
								let productIndex = checkedItemsRef.current.findIndex(
									(item) => item.observationId === currentRow.current.observationId,
								)

								if (productIndex >= 0) {
									// We have the item already checked, so update existing entry in checked items
									let checkedItems = updatePrice(checkedItemsRef.current, currentRow.current.observationId, "recommendedSellingPrice", parseFloat(theSelectedPrice), null);
									setCheckedItems(checkedItems);
								} else {
									// We don't have the item checked, so add it to checked items
									var theCheckedItems = [...checkedItemsRef.current];
									theCheckedItems.push(currentRow.current);
									setCheckedItems(theCheckedItems);
								}
							} else {
								// No checked items, add the first one
								var onlyOneCheckedItem = [];
								onlyOneCheckedItem.push(currentRow.current);
								setCheckedItems(onlyOneCheckedItem);
							}
						}}
						selectedItem={currentRow}
						currencySymbol={currencySymbol}
						selectedPrice={selectedPrice}
						setSelectedPrice={setSelectedPrice}
						canEditPrices={canEditPrices}
					/>
				</OverlayPanel>
			</div>
		</>
	)
}

export default withTranslation()(PricingTable)
