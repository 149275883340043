import { React, useEffect } from 'react'

function PrivacyPage(props) {
	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	return (
		<div className="grid p-legal-content">
			<div className="col-12" style={{ "whiteSpace": "pre-line" }}>
				<h2 className="text-primary">PRIVACY POLICY</h2>

				<p>
					<b><u>Website Privacy Policy.</u></b> Pricimetrics, Inc. (&#8220;Pricimetrics&#8221;, &#8220;we&#8221;, or &#8220;us&#8221;) operates the website located at www.Pricimetrics.com and www.pricimetricsmax.com (&#8220;Website(s)&#8221;). This document serves as Pricimetrics' Privacy Policy (the &#8220;Policy&#8221;) for the Websites' as it applies to the Website's users.  Privacy Policy (the &#8220;Policy&#8221;) applies to the Websites' Users. Pricimetrics makes the Websites available to individuals (&#8220;Users&#8221; or &#8220;you&#8221;). All activities engaged in through the Websites are subject to this Policy. This Policy explains what information Pricimetrics collects about its Users, how Pricimetrics uses and/or shares this information, and how such information is maintained. By using the Websites, you accept the terms of this Policy. This Policy applies only with respect to the information collected by Pricimetrics through the Websites, and not any information collected or obtained through other methods or sources. Please note that Pricimetrics may change this Policy at any time (See &#8220;Changes to this Policy,&#8221; below), and that Pricimetrics may in the future use your information for additional purposes not currently included in this Policy. Pricimetrics collects Users' personally-identifiable information (&#8220;PII&#8221;) that is volunteered by Users. Examples of PII that may be requested and/or collected include but are not limited to: first and last name, address, zip code, email address, telephone number, facsimile number, and company or business identity. From time to time, Pricimetrics may also present opportunities for Users to voluntarily provide additional information about themselves.
				</p>

				<p>
					We also use information that is automatically collected, to understand more about our Websites to determine how users navigate our Websites to improve Website performance, to protect the security and integrity of our Website and business, to identify and protect our systems from fraudulent activity and access, to provide advertising that may be of interest to our visitors, and to monitor legal compliance.
				</p>

				<p>
					<b><u>Automatic Information Collection.</u></b> When you access, and use our Websites it may use technology to automatically collect
				</p>
				<ul>
					<li>
						<b><u>Usage Details.</u></b> When you access and use the Websites we may automatically collect certain details of your access to and use of the Websites including location data, logs, and other communication data and the resources that you access and use on or through the Website.
					</li>
					<li>
						<b><u>Device Information.</u></b> We may collect information about your mobile device and internet connection, including the device's unique device identifier, IP address, operating system, browser type, mobile network information, and the device's telephone number.
					</li>
				</ul>
				<p>
					<b><u>Use and Sharing of PII and other information we collect.</u></b> Pricimetrics uses PII and other information we collect to provide the User with information about Pricimetrics' Services, and to provide Pricimetrics' third-party vendors with information about User's needs in accordance with HIPPA.
				</p>

				<h3>YOUR USE OF THE WEBSITE SERVES AS YOUR ACKNOWLEDGEMENT AND APPROVAL OF THIS PRACTICE. IF YOU WANT TO ENSURE THAT YOUR PII IS NOT AVAILABLE TO THIRD PARTIES, YOU MUST DISCONTINUE YOUR USE OF THE WEBSITE.</h3>

				<p>
					Users' PII and other information we collect may also be used to: deliver and improve our services; manage our business; manage your access and provide you with customer support; perform research and analysis about your use of, or interest in, our or others products, services, or content; communicate with you by email, postal mail, telephone and/or mobile devices about products or services that may be of interest to you either from us or other third parties; develop, display, and track content and advertising tailored to your interests on our Service and other Websites or Websites, including providing our advertisements to you when you visit other websites or Websites; analyze data about our Website (i.e., analytics); verify your eligibility to utilize our Service; enforce or exercise any rights in our Terms of Service; and perform functions or services as otherwise described to you at the time of collection.
				</p>

				<p>
					PII and other information collected by us may be added to our databases and used for future marketing purposes, including but not limited to email and direct marketing. We may also share your PII with third-party vendors that perform certain services on our behalf. These services may include fulfilling orders, providing customer service and marketing assistance, performing business and sales analysis, ad tracking and analytics, member screenings, supporting our Website functionality, and supporting other features offered as part of our services. These vendors may have access to personal information needed to perform their functions but are not permitted to share or use such information for any other purposes.
				</p>

				<p>
					In addition, we may also disclose Users' PII and other information we collect in order to: (1) comply with applicable laws (including, without limitation, the CAN-SPAM Act); (2) respond to governmental inquiries; (3) comply with valid legal process; (4) protect the rights or property of Pricimetrics, including without limitation, filing copyright Websites with the Library of Congress, Copyright Office, or (5) protect the health and personal safety of any individual. By submitting your PII through the Websites you agree that your PII may be used in any manner contemplated in this section.
				</p>

				<p>
					<b><u>How PII is Protected.</u></b> We appreciate our Users and take your privacy seriously. Any PII that is stored on our computers is protected from unauthorized access and use via passwords as well as other standard industry-acknowledged means. Our servers which store this information are protected by a firewall. We take additional precautions for PII which is especially sensitive, such as financial information. We will only request or transmit this information over secure Internet connections using precautions such as Secure Sockets Layer (SSL) encryption, security keys and password authentication from any third parties receiving this information. Though we take the utmost precautions to protect your PII, please be informed that no data transmission over a cellular phone or the Internet, nor any storage of information on servers or other media, is ever 100% completely secure. While we aim to protect your PII to the greatest extent possible, this policy is not intended to be, and should not be construed as, a warranty or guarantee of absolute security of your PII. As always, you should use common sense whenever you disclose personal information over the Internet or a cellular network, regardless of the Websites or Website(s) you use. If you suspect that your PII is being used in connection with the Website in a manner contrary to this Privacy Policy, please let us know immediately. To contact us, please send an email to <a href="mailto:support@PricimetricsMax.com">support@PricimetricsMax.com</a>.
				</p>

				<p>
					<b><u>Non-Personally Identifiable Information.</u></b>  Pricimetrics may also collect certain non-personally identifiable information, including but not limited to the information more fully described below. Authentication Tokens. Pricimetrics may use authentication tokens on the Website. Authentication tokens are small pieces of information that enable the Website to more easily communicate and interact with the User. For example, Pricimetrics may place an authentication token on a User`s mobile device if a User uses that device to register for Pricimetrics' Website. The next time that User uses the Websites Pricimetrics server will recognize the authentication token (and the User) and allow the User to perform certain actions immediately without having to log in.
				</p>

				<p>
					<b><u>Mobile Device Identifiers.</u></b> Pricimetrics may collect information about the mobile devices from which you access the Website. We may collect and store the unique identifier assigned to your mobile device(s) by the manufacturer, or other identifying information about your device.
				</p>

				<p>
					<b><u>Cookies.</u></b> To provide better service and a more effective Websites we sometimes use first-party and third-party &#8220;cookies&#8221; as part of our interaction with your browser. A cookie is a small text file placed on your computer's hard drive by our web page server. Cookies are commonly used on Websites and do not harm your system. By configuring your preferences or options in your browser, you determine if and how a cookie will be accepted. We use cookies to determine if you have previously visited our Website and the pages you have visited, and for a number of administrative, marketing or remarketing purposes.  We use both first-party and third-party cookies for different purposes:
				</p>

				<p>
					<b><u>First-party cookies and third-party cookies.</u></b> Cookies can be first-party or third-party.  A first-party cookie is one that you receive directly from Company when visiting our Website.  A third-party cookie is one that you have received from another party, such as Google or Facebook.  We do not control what third parties do on other Websites.  However, we may work with certain third-party providers such as Google or Facebook to permit their cookies to function through our Website so we can learn more about your web experience on our Website and better personalize our services for you.
				</p>

				<p>
					<u><b>Persistent and session cookies.</b></u> A persistent cookie is a cookie that is stored by the web browser on your device until it expires or you delete it. The expiration of a persistent cookie is determined by the creator of the cookie and can be upon a certain date or after a length of session time has passed. This means that, for the cookie's entire lifespan, its information will be transmitted to the creator's server every time the user visits the Website that it belongs to or another website or Website configured to check for that cookie (such as an advertisement placed on that website or Website). For this reason, persistent cookies are also called &#8220;tracking cookies.&#8221; A session cookie is created temporarily on your device for use by the Website during your visit. We may use this type of cookie to store information you enter and track your activity within the Website. The session cookie will be deleted after you leave the Website or when the Website is closed.  A good example of a session cookie is the shopping cart on an e-commerce Website. The session cookie stores the items that you add to your cart so they are not forgotten while you view products on other pages of the Website. Using a session cookie, the items will all be in the cart when you go to the checkout page.
				</p>

				<p>
					<b><u>Other Data.</u></b> All photographs, opinions, ideas, suggestions, other feedback, and all other information submitted by You through the Website may be used by us without any restriction and free of charge. In certain areas of our Websites such as when you request more information, should you abandon a form on our Websites your information may still be collected and utilized for notices or communications pertaining to the Websites products or services. If you choose not to provide personal information, you can still browse most of our Website (the areas that do not require registration) anonymously. If you place a call to us, whether by landline or mobile device, we may also capture your phone number and any other information you provide during the call. This information may also be used to communicate with you about the Websites products or services via phone, mail, email, social media or third-party Websites.
				</p>

				<p>
					When you visit our Websites we may directly and through third-party service providers automatically log certain information about your visit including: the pages you visit while on our Website; the IP address of a referring Websites if any; the type of browser, device or hardware you are using; your IP address and general geographic information; and the date and time you accessed our Website. Through the use of third-party tools, such as Google Analytics (Remarketing, Display Network Impression Reporting, Demographics and Interest Reporting, and other integrated services), we may also collect certain demographic information and information about interests from a portion of the visitors to our Website. This information may link to personal data that you voluntarily provide to us which will allow us to serve interest-based ads and content.
				</p>

				<p>
					Although the information collected through the foregoing methods does not itself contain any PII, Pricimetrics may analyze and match such information with other information that you provide (including PII) as well as information that Pricimetrics may obtain elsewhere, and Pricimetrics may share all or some of such information with actual or prospective movers, van lines, or other third parties. Pricimetrics may also disclose non-PII in order to comply with applicable laws; respond to governmental inquiries; comply with valid legal process; or protect the rights or property of Pricimetrics or Users of the Website.
				</p>

				<p>
					<b><u>Access to Your Information.</u></b> If you want to review, correct or change your User information, please submit your request in writing to <a href="mailto:support@PricimetricsMax.com">support@PricimetricsMax.com</a>.
				</p>

				<p>
					<b><u>Third Party Services.</u></b> Pricimetrics' communications to you as well as the Websites may contain links to the Websites of other providers of products and services that may be of interest to you. We may also use third-party service providers to serve interest-based advertisements on our behalf on our Website(s), social media networks and across the Internet. These advertising service providers may collect non-identifiable information about your visits to our Websites and your interactions with our products and services. Such non-identifiable information does not include your name, address, email address or other personal information. The information is collected through the use of cookies and pixel tags (also known as action tags), which is industry-standard technology used by most major Websites. Interest-based ads are then displayed based on the information that is collected.
				</p>

				<p>
					In addition to the information about your visits to our Websites our service providers may also use the information about your visits to other Websites to target advertisements for programs and services available from us.
				</p>

				<p>
					When you click the link to one of these other entities, you will leave the Website(s) and be connected to the website or Websites of such entity. In such an event, this Policy will not apply to your use of, and activities on, those third-party websites or Websites. Pricimetrics does not have any control over the information handling practices of these other entities, and you should familiarize yourself with the privacy policies of such other entities before you share any PII with them. We encourage you to read all other legal notices posted by these other entities as well. Pricimetrics shall have no responsibility or liability for your visitation to, and the data collection and use policies and practices of, these other entities.
				</p>

				<p>
					<b><u>Security.</u></b> While Pricimetrics takes reasonable precautions to safeguard information transmitted between Pricimetrics and Users of the Website Pricimetrics may be unable to prevent unauthorized access to such information by third parties or inadvertent disclosure of such information. Users acknowledge this risk when communicating with Pricimetrics.
				</p>

				<p>
					<b><u>Consent to Processing.</u></b>  By providing PII and other information to Pricimetrics, Users of the Website fully understand and unambiguously consent to the collection and processing of such information in, and the transfer of such information to, the United States and other countries or territories, in accordance with the terms of this Policy.
				</p>

				<p>
					<b><u>Transfer in Certain Circumstances.</u></b> In its sole discretion, Pricimetrics may transfer, sell or assign information collected on or about Users of the Websites including without limitation, PII and other User-provided information, to one or more third parties as a result of the sale, merger, consolidation, change in control, transfer of substantial assets, reorganization or liquidation of Pricimetrics.
				</p>

				<p>
					<b><u>Opt Out.</u></b> We honor all requests to be removed from our marketing lists. If you do not want to receive e-mail, telephone messages or direct mail from us, contact us at support@PricimetricsMax.com and we will remove your name from our in-house list and add you to our marketing suppression list. You will also have an opportunity to add your phone number to our internal &#8220;Do Not Call&#8221; list during each phone call you receive from us. You will further have the ability to opt-out or manage certain advertising preferences through links provided in marketing and promotional e-mails you may receive. We honor all requests to be removed from our e-mailing lists within ten days, and update our suppression list every ten days. If you prefer to learn about new offers from us through a specific medium &mdash; for example, through the mail &mdash; simply let us know your preference by sending us an email at <a href="mailto:support@PricimetricsMax.com">support@PricimetricsMax.com</a>.
				</p>

				<p>
					We are fully committed to complying with your wishes regarding receiving commercial e-mail messages from us and with the laws regarding unsolicited e-mail. If for any reason you receive a commercial message directly from us or on our behalf more than ten days after making a request to be taken off our mailing list, we would request that you forward a copy of the e-mail to support@PricimetricsMax.com with a brief explanation of your efforts to unsubscribe and the approximate timeframe you made the request. We will immediately investigate the matter, confirm that you have been removed, and provide a written response to you detailing our efforts. Please note that requests to be removed from our direct mail list will be processed as soon as possible, but given the nature of direct mail, it may be impossible to prevent a mailing that is being processed or underway from reaching you. If you receive multiple mailings from us after your request, please contact us at <a href="mailto:support@PricimetricsMax.com">support@PricimetricsMax.com</a>.
				</p>

				<h3><u>Notice to California Residents.</u></h3>

				<p>
					This Section is for California Residents and supplements the information contained in this Privacy Policy. As used in this Section, &#8220;consumers&#8221; or &#8220;you&#8221; applies solely to those people who reside in the State of California. We add this notice to comply with the California Consumer Privacy Act of 2018 (&#8220;CCPA&#8221;) and other California privacy laws.  Any terms defined in the CCPA have the same meaning when used in this Section.
				</p>

				<p>
					<b><u>Sale of Data.</u></b> We do NOT &#8220;sell&#8221; personal information as defined by the CCPA.  We will continue to monitor and review our processing activities and will notify you if this changes and take the appropriate steps to stay in compliance with the CCPA.
				</p>
				<p>
					<b><u>Information We Collect.</u></b> We collect information that identifies, relates to, describes, references, is capable of being associated with, or could reasonably be linked, directly or indirectly, with a particular consumer or device (&#8220;personal information&#8221;). In particular, we have collected the following categories of personal information from consumers within the last twelve (12) months:
				</p>

				<table className="p-legal-table">
					<thead>
						<tr>
							<td width="24%">Category</td>
							<td width="63%">Examples</td>
							<td width="12%">Collected</td>
						</tr>
					</thead>
					<tbody>						
						<tr>
							<td width="24%">A. Identifiers.</td>
							<td width="63%">A real name, alias, postal address, unique personal identifier, online identifier, Internet Protocol address, email address, account name, Social Security number, driver's license number, passport number, or other similar identifiers.</td>
							<td width="12%">YES</td>
						</tr>
						<tr>
							<td width="24%">B. Personal information categories listed in the California Customer Records statute (Cal. Civ. Code &#167; 1798.80(e)).</td>
							<td width="63%">A name, signature, Social Security number, physical characteristics or description, address, telephone number, passport number, driver's license or state identification card number, insurance policy number, education, employment, employment history, bank account number, credit card number, debit card number, or any other financial information, medical information, or health insurance information. Some personal information included in this category may overlap with other categories.</td>
							<td width="12%">YES</td>
						</tr>
						<tr>
							<td width="24%">C. Protected classification characteristics under California or federal law.</td>
							<td width="63%">Age (40 years or older), race, color, ancestry, national origin, citizenship, religion or creed, marital status, medical condition, physical or mental disability, sex (including gender, gender identity, gender expression, pregnancy or childbirth and related medical conditions), sexual orientation, veteran or military status, genetic information (including familial genetic information).</td>
							<td width="12%">NO</td>
						</tr>
						<tr>
							<td width="24%">D. Commercial information.</td>
							<td width="63%">Records of personal property, products or services purchased, obtained, or considered, or other purchasing or consuming histories or tendencies.</td>
							<td width="12%">NO</td>
						</tr>
						<tr>
							<td width="24%">E. Biometric information.</td>
							<td width="63%">Genetic, physiological, behavioral, and biological characteristics, or activity patterns used to extract a template or other identifier or identifying information, such as, fingerprints, faceprints, and voiceprints, iris or retina scans, keystroke, gait, or other physical patterns, and sleep, health, or exercise data.</td>
							<td width="12%">NO</td>
						</tr>
						<tr>
							<td width="24%">F. Internet or other similar network activity.</td>
							<td width="63%">Browsing history, search history, information on a consumer's interaction with a website, Websites, or advertisement.</td>
							<td width="12%">YES</td>
						</tr>
						<tr>
							<td width="24%">G. Geolocation data.</td>
							<td width="63%">Physical location or movements.</td>
							<td width="12%">YES</td>
						</tr>
						<tr>
							<td width="24%">H. Sensory data.</td>
							<td width="63%">Audio, electronic, visual, thermal, olfactory, or similar information.</td>
							<td width="12%">NO</td>
						</tr>
						<tr>
							<td width="24%">I. Professional or employment-related information.</td>
							<td width="63%">Current or past job history or performance evaluations.</td>
							<td width="12%">NO</td>
						</tr>
						<tr>
							<td width="24%">J. Non-public education information (per the Family Educational Rights and Privacy Act (20 U.S.C. Section 1232g, 34 C.F.R. Part 99)).</td>
							<td width="63%">Education records directly related to a student maintained by an educational institution or party acting on its behalf, such as grades, transcripts, class lists, student schedules, student identification codes, student financial information, or student disciplinary records.</td>
							<td width="12%">NO</td>
						</tr>
						<tr>
							<td width="24%">K. Inferences drawn from other personal information.</td>
							<td width="63%">Profile reflecting a person's preferences, characteristics, psychological trends, predispositions, behavior, attitudes, intelligence, abilities, and aptitudes.</td>
							<td width="12%">NO</td>
						</tr>
					</tbody>
				</table>

				<p>
					Personal information does not include:
				</p>
				<ul>
					<li>Publicly available information from government records.</li>
					<li>De-identified or aggregated consumer information.</li>
					<li>Information excluded from the CCPA's scope</li>
					<li>
						We obtain the categories of personal information listed above from the following categories of sources:

						<ul>
							<li>Directly from you. For example, from documents that you provide to us related to the services for which you engage us.</li>
							<li>Indirectly from our customers or their agents. For example, through information we collect from you in the course of providing services to you.</li>
							<li>Directly and indirectly from activity on our Website. For example, from submissions through our Website portal or Website usage details collected automatically.</li>
							<li>From third-parties that interact with us in connection with the services we perform.</li>
							<li>From customers for whom we provide services.</li>
						</ul>
					</li>
				</ul>

				<p>
					<b><u>Use of Personal Information.</u></b> We may use or disclose the personal information we collect for one or more of the following business purposes:
				</p>
				<ul>
					<li>To fulfill or meet the reason for which the information is provided. For example, if you provide us with personal information in order to enroll in a course, we will use that information to assist you in enrolling in such course.</li>
					<li>To provide you with information, products or services that you request from us.</li>
					<li>To provide you with email alerts, event registrations and other notices concerning our products or services, or events or news, that may be of interest to you.</li>
					<li>To carry out our obligations and enforce our rights arising from any contracts entered into between you and us, including for billing and collections.</li>
					<li>To improve our Website(s) and present its or their contents to you.</li>
					<li>For testing, market research, analysis and product development.</li>
					<li>As necessary or appropriate to protect the rights, property or safety of us, our clients or others.</li>
					<li>To respond to law enforcement requests and as required by applicable law, court order, or governmental regulations.</li>
					<li>As described to you when collecting your personal information or as otherwise set forth in the CCPA.</li>
					<li>To evaluate or conduct a merger, divestiture, restructuring, reorganization, dissolution, or other sale or transfer of some or all of our assets, whether as a going concern or as part of bankruptcy, liquidation, or similar proceeding, in which personal information held by us is among the assets transferred.</li>
				</ul>

				<p>
					We will not collect additional categories of personal information or use the personal information we collected for materially different, unrelated, or incompatible purposes without providing you notice.
				</p>

				<p>
					<u><b>Sharing Personal Information.</b></u> We may disclose your personal information to a third party for a business purpose.  When we disclose personal information for a business purpose, we enter a contract that describes the purpose and requires the recipient to both keep that personal information confidential and not use it for any purpose except performing the contract.
				</p>

				<p>
					We disclose your personal information for a business purposes to the following categories of third parties:
				</p>
				<ul>
					<li>Service providers.</li>
					<li>Third parties to whom you or your agents authorize us to disclose your personal information in connection with products or services we provide to you or to those third parties whose products or offerings we deem, in our sole discretion, to be of interest to you.</li>
				</ul>

				<p>
					In the preceding twelve (12) months, we have not sold any personal information.
				</p>
				<p>
					<u><b>Your Rights and Choices: The CCPA provides consumers (California residents) with specific rights regarding their personal information. This section describes your CCPA rights and explains how to exercise those rights.</b></u>
				</p>

				<h3>Access to Specific Information and Data Portability Rights</h3>

				<p>
					You have the right to request that we disclose certain information to you about our collection and use of your personal information over the past 12 months. Once we receive and confirm your verifiable consumer request, we will disclose to you:
				</p>

				<ul>
					<li>The categories of personal information we collected about you.</li>
					<li>The categories of sources for the personal information we collected about you.</li>
					<li>Our business or commercial purpose for collecting or selling that personal information.</li>
					<li>The categories of third parties with whom we share that personal information.</li>
					<li>The specific pieces of personal information we collected about you (also called a data portability request).</li>
					<li>If we sold or disclosed your personal information for a business purpose, two separate lists disclosing:
						<ul>
							<li>sales, identifying the personal information categories that each category of recipient purchased; and</li>
							<li>disclosures for a business purpose, identifying the personal information categories that each category of recipient obtained.</li>
						</ul>
					</li>
				</ul>

				<p>
					<u><b>Deletion Request Rights.</b></u> You have the right to request that we delete any of your personal information that we collected from you and retained, subject to certain exceptions. Once we receive and confirm your verifiable consumer request, we will delete (and direct our service providers to delete) your personal information from our records, unless an exception applies.
				</p>

				<p>
					We may deny your deletion request if retaining the information is necessary for us or our service providers to:
				</p>

				<ul className="list-decimal">
					<li>Complete the transaction for which we collected the personal information, provide a good or service that you requested, take actions reasonably anticipated within the context of our ongoing business relationship with you, or otherwise perform our contract with you.</li>
					<li>Detect security incidents, protect against malicious, deceptive, fraudulent, or illegal activity, or prosecute those responsible for such activities.</li>
					<li>Debug products to identify and repair errors that impair existing intended functionality.</li>
					<li>Exercise free speech, ensure the right of another consumer to exercise their free speech rights, or exercise another right provided for by law.</li>
					<li>Comply with the California Electronic Communications Privacy Act (Cal. Penal Code &#167; 1546 ).</li>
					<li>Engage in public or peer-reviewed scientific, historical, or statistical research in the public interest that adheres to all other applicable ethics and privacy laws, when the information's deletion may likely render impossible or seriously impair the research's achievement, if you previously provided informed consent.</li>
					<li>Enable solely internal uses that are reasonably aligned with consumer expectations based on your relationship with us.</li>
					<li>Comply with a legal obligation.</li>
					<li>Make other internal and lawful uses of that information that are compatible with the context in which you provided it.</li>
				</ul>

				<p>
					<b><u>Exercising Access, Data Portability, and Deletion Rights.</u></b> To exercise the access, data portability, and deletion rights described above, please submit a verifiable consumer request to us by emailing us at <a href="mailto:support@PricimetricsMax.com">support@PricimetricsMax.com</a>.
				</p>

				<p>
					Only you or a person registered with the California Secretary of State that you authorize to act on your behalf, may make a verifiable consumer request related to your personal information. You may also make a verifiable consumer request on behalf of your minor child.
				</p>

				<p>
					You may only make a verifiable consumer request for access or data portability twice within a 12-month period. The verifiable consumer request must:
				</p>
				<ul>
					<li>Provide sufficient information that allows us to reasonably verify you are the person about whom we collected personal information or an authorized representative.</li>
					<li>Describe your request with sufficient detail that allows us to properly understand, evaluate, and respond to it.</li>
				</ul>

				<p>
					We cannot respond to your request or provide you with personal information if we cannot verify your identity or authority to make the request and confirm the personal information relates to you.  Making a verifiable consumer request does not require you to create an account with us.  We will only use personal information provided in a verifiable consumer request to verify the requestor's identity or authority to make the request.
				</p>

				<p>
					<b><u>Response Timing and Format.</u></b> We endeavor to respond to a verifiable consumer request within 45 days of its receipt.  If we require more time (up to 90 days), we will inform you of the reason and extension period in writing.  If you have an account with us, we will deliver our written response to that account.  If you do not have an account with us, we will deliver our written response by mail or electronically, at your option.  Any disclosures we provide will only cover the 12-month period preceding the verifiable consumer request's receipt.  The response we provide will also explain the reasons we cannot comply with a request, if applicable.  For data portability requests, we will select a format to provide your personal information that is readily useable and should allow you to transmit the information from one entity to another entity without hindrance.
				</p>

				<p>
					We do not charge a fee to process or respond to your verifiable consumer request unless it is excessive, repetitive, or manifestly unfounded.  If we determine that the request warrants a fee, we will tell you why we made that decision and provide you with a cost estimate before completing your request.
				</p>

				<p>
					<b><u>Non-Discrimination.</u></b> We will not discriminate against you for exercising any of your CCPA rights. Unless permitted by the CCPA, we will not:
				</p>

				<p>
					Deny you goods or services.
				</p>
				<ul>
					<li>Charge you different prices or rates for goods or services, including through granting discounts or other benefits, or imposing penalties.</li>
					<li>Provide you a different level or quality of goods or services.</li>
				</ul>

				<p>
					Suggest that you may receive a different price or rate for goods or services or a different level or quality of goods or services.
				</p>

				<p>
					<b><u>Changes to This Policy.</u></b> Pricimetrics may, from time to time, amend this Policy, in whole or part, at its sole discretion. Any changes to this Policy will be effective immediately upon the posting of the revised policy to the Website.
				</p>

				<p>
					<b><u>Questions About This Policy.</u></b> Questions about this privacy policy or our privacy practices should be directed to <a href="mailto:support@PricimetricsMax.com">support@PricimetricsMax.com</a>.
				</p>
			</div>
		</div>
	)
}

export default PrivacyPage
