import React, { useState } from "react";
import { TabView, TabPanel } from "primereact/tabview";
import RepricingOverPeriodChart from "../Charts/RepricingOverPeriodChart/RepricingOverPeriodChart";

const RepricingOverPeriod = (props) => {
  const [activeTab, setActiveTab] = useState(0);
  const generateBarChartData = (rawData, barThickness, labels) => {
	return {
	  labels: labels,
	  datasets: rawData.map((obj) => {
		return {
		  label: obj.title,
		  maxBarThickness: barThickness,
		  data: obj.barCategory.map((item) => item.value),
		  backgroundColor: obj.color,
		};
	  }),
	};
  };

  return (
	<div className="dashboard-widget">
	  <div className="widget-inner-box">
		<div className="dashboard-tab">
		  <TabView
			activeIndex={activeTab}
			onTabChange={(e) => setActiveTab(e.index)}
			className="tabview-custom"
		  >
			{props.data.tabs.map((tab, i) => {
			  return (
				<TabPanel header={tab.name} key={i}>
				  {/* bar chart */}
				  {tab.type === "bar" && (
					<div className="grid">
					  <div className="col-9  lg:col-9">
						<RepricingOverPeriodChart
						  typeChart={tab.type}
						  legend={tab.legend}
						  scales={tab.scales}
						  tooltip={tab.tooltip}
						  data={generateBarChartData(
							tab.categories,
							tab.barThickness,
							tab.labels
						  )}
						/>
					  </div>
					  <div className="col-3 lg:col-3 right-box">
						<div className="grid">
						  <div className="lg:col-12 right-box-contents">
							{tab.categories.map((item, j) => {
							  return (
								<div
								key={"cat_" + i + "_" + j}
								className="chart-categories-percentage"
								style={{ color: item.color }}
								>
								{item.percentage}%
								<div className="chart-category-name">
									{item.title}
								</div>
								</div>
							  );
							})}
						  </div>
						</div>
					  </div>
					</div>
				  )}
				</TabPanel>
			  );
			})}
		  </TabView>
		  <i className="widget-option-btn pi pi-chevron-circle-down"></i>
		</div>
	  </div>
	</div>
  );
};

export default RepricingOverPeriod;
