import React, { useEffect, useState, useRef } from 'react'
import { withTranslation } from 'react-i18next';
import ConfigActionBtn from '../Config/ConfigActionBtn'
import { InputNumber } from 'primereact/inputnumber';
import { ToggleButton } from 'primereact/togglebutton';
import { Button } from 'primereact/button';
import { Dropdown } from 'primereact/dropdown';
import { Chips } from 'primereact/chips';
import { confirmDialog } from 'primereact/confirmdialog';
import configurationService from '../../Api/requests/config';
import messagesService from '../../Api/client-side/messages';

function PricingAlgorithmsConfig(props) {
	const { t } = props
	const { skus } = props
	const { setLoading } = props
	const { allowed } = props;
	const { showHelp } = props;

	const [dataChanged, setDataChanged] = useState(false)
	const [sku, setSku] = useState(null)
	const [priceCheckRange, setPriceCheckRange] = useState(null)
	const [priceConstantPercent, setPriceConstantPercent] = useState(null)
	const [priceConstant, setPriceConstant] = useState(null)
	const [inventoryConstraintRemove, setInventoryConstraintRemove] = useState(null)
	const [outlierThreshold, setOutlierThreshold] = useState(null)
	const [outlierRemove, setOutlierRemove] = useState(null)
	const [pduTriggerPeriods, setPduTriggerPeriods] = useState(null)
	const [pduMinPricePerc, setPduMinPricePerc] = useState(null)
	const [minStablePricing, setMinStablePricing] = useState(null)
	const [minPricePercent, setMinPricePercent] = useState(null)
	const [periodicityPriceUnitCorrelation, setPeriodicityPriceUnitCorrelation] = useState(null)
	const [longRegressionWindowSize, setLongRegressionWindowSize] = useState(null)
	const [daysBetweenLongRegressions, setDaysBetweenLongRegressions] = useState(null)
	const [penalizedRunsLongRegression, setPenalizedRunsLongRegression] = useState(null)
	const [longRegressionPeriodsForPerfomanceEvaluation, setLongRegressionPeriodsForPerfomanceEvaluation] = useState(null)
	const [longRegressionBPMPValue, setLongRegressionBPMPValue] = useState(null)
	const [modelDownSelectPercentage, setModelDownSelectPercentage] = useState(null)
	const [modelDownSelectAbsolute, setModelDownSelectAbsolute] = useState(null)
	const [modelSetAsideThreshold, setModelSetAsideThreshold] = useState(null)
	const [modelSetAsidePeriods, setModelSetAsidePeriods] = useState(null)
	const [modelSetAsideExtension, setModelSetAsideExtension] = useState(null)
	const [modelProbationPeriods, setModelProbationPeriods] = useState(null)
	const [extensionMultiplier, setExtensionMultiplier] = useState(null)
	const [calculationsPrice, setCalculationsPrice] = useState(null)
	const [sellingPricePreProcessingPeriods, setSellingPricePreProcessingPeriods] = useState(null)
	const [sellingPricePreProcessRemoveSmallestPeriodsCount, setSellingPricePreProcessRemoveSmallestPeriodsCount] = useState(null)
	const [modelDownSelectionStdDeviationForOutliers, setModelDownSelectionStdDeviationForOutliers] = useState(null)
	const [minimumAcceptedAvgSAEPerc, setMinimumAcceptedAvgSAEPerc] = useState(null)
	const [dummyEffectUpperLimitPerc, setDummyEffectUpperLimit] = useState(null)
	const [dummyEffectMaxValuePerc, setDummyEffectMaxValue] = useState(null)
	const [linLinSlopeMultiplierLimit, setLinLinSlopeMultiplierLimit] = useState(null)
	const [longTermPriceChangeMonths, setLongTermPriceChangeMonths] = useState(null)

	const [pcapCondition, setPcapCondition] = useState(null)
	const [collinearThreshold, setCollinearThreshold] = useState(null)
	const [windowSizes, setWindowSizes] = useState(null)

	const mounted = useRef(false)
	const defaultsRef = useRef(null)

	const calculationsPriceOptions = [
		{ label: t('ConfigurationDialog.CalculationsPrice_ListPrice'), value: 0 },
		{ label: t('ConfigurationDialog.CalculationsPrice_SellingPrice'), value: 1 },
	];

	const saveConfig = async () => {

		let data = {
			sku: sku,
			priceCheckRange: priceCheckRange,
			priceConstantPercent: priceConstantPercent,
			priceConstant: priceConstant,
			inventoryConstraintRemove: inventoryConstraintRemove,
			outlierThreshold: outlierThreshold,
			outlierRemove: outlierRemove,
			pduTriggerPeriods: pduTriggerPeriods,
			leastPrice: pduMinPricePerc,
			minStablePricing: minStablePricing,
			minPricePercent: minPricePercent,
			periodicityPriceUnitCorrelation: periodicityPriceUnitCorrelation,
			pcapCondition: pcapCondition,
			collinearThreshold: collinearThreshold,
			windowSizes: windowSizes,
			longRegressionWindowSize: longRegressionWindowSize,
			daysBetweenLongRegressions: daysBetweenLongRegressions,
			penalizedRunsLongRegression: penalizedRunsLongRegression,
			longRegressionPeriodsForPerfomanceEvaluation: longRegressionPeriodsForPerfomanceEvaluation,
			longRegressionBPMPValue: longRegressionBPMPValue,
			modelDownSelectPercentage: modelDownSelectPercentage,
			modelDownSelectAbsolute: modelDownSelectAbsolute,
			modelSetAsideThreshold: modelSetAsideThreshold,
			modelSetAsidePeriods: modelSetAsidePeriods,
			modelSetAsideExtension: modelSetAsideExtension,
			modelProbationPeriods: modelProbationPeriods,
			extensionMultiplier: extensionMultiplier,
			calculationsPrice: calculationsPrice,
			sellingPricePreProcessingPeriods: sellingPricePreProcessingPeriods,
			sellingPricePreProcessRemoveSmallestPeriodsCount: sellingPricePreProcessRemoveSmallestPeriodsCount,
			modelDownSelectionStdDeviationForOutliers: modelDownSelectionStdDeviationForOutliers,
			minimumAcceptedAvgSAEPerc: minimumAcceptedAvgSAEPerc,
			dummyEffectUpperLimitPerc: dummyEffectUpperLimitPerc,
			dummyEffectMaxValuePerc: dummyEffectMaxValuePerc,
			linLinSlopeMultiplierLimit: linLinSlopeMultiplierLimit,
			longTermPriceChangeMonths: longTermPriceChangeMonths
		}

		let defVals = defaultsRef.current;
		if (defVals.longTermPriceChangeMonths !== longTermPriceChangeMonths) {
			setTimeout(() => {
				confirmDialog({
					message: t('ConfigurationDialog.ConfirmRecalculateSyntheticVariablesMsg'),
					header: t('ConfigurationDialog.ConfirmRecalculateSyntheticVariablesHeader'),
					acceptLabel: t('ConfigurationDialog.ConfirmRecalculateSyntheticVariablesUpdateNow'),
					rejectLabel: t('ConfigurationDialog.ConfirmRecalculateSyntheticVariablesUpdateLater'),
					className: 'configuration-confirm-panel',
					icon: 'pi pi-exclamation-triangle',
					accept: async () => {
						data.syntheticUpdateStrategy = 0;	// Update Now
						await doSave(data);
					},
					reject: async () => {
						data.syntheticUpdateStrategy = 1;	// Schedule Update Later
						await doSave(data);
					}
				});
			}, 500);
		} else {
			await doSave(data);
		}

	}

	const doSave = async (data) => {
		setLoading(true);

		let res = await configurationService.saveAlgorithmsConfiguration(data);

		if (res === null) {
			messagesService.showSuccess(t('ConfigurationDialog.ConfigurationRefreshedTitle'), t('ConfigurationDialog.ConfigurationRefreshedMessage'));

			await readDefaults(sku);
		} else {
			messagesService.showError(t('ConfigurationDialog.ConfigurationRefreshedErrorTitle'), t('ConfigurationDialog.ConfigurationRefreshedErrorMessage'));
		}

		setLoading(false);
	}

	const readDefaults = async (theSku) => {
		mounted.current = false;

		let defaults = await configurationService.getAlgorithmsConfiguration(theSku);

		if (defaults != null) {

			defaultsRef.current = defaults;

			setPriceCheckRange(defaults.priceCheckRange);
			setPriceConstantPercent(defaults.priceConstantPercent);
			setPriceConstant(defaults.priceConstant);
			setInventoryConstraintRemove(defaults.inventoryConstraintRemove);
			setOutlierThreshold(defaults.outlierThreshold);
			setOutlierRemove(defaults.outlierRemove);
			setPduTriggerPeriods(defaults.pduTriggerPeriods);
			setPduMinPricePerc(defaults.leastPrice);
			setMinStablePricing(defaults.minStablePricing);
			setMinPricePercent(defaults.minPricePercent);
			setPeriodicityPriceUnitCorrelation(defaults.periodicityPriceUnitCorrelation);
			setPcapCondition(defaults.pcapCondition);
			setCollinearThreshold(defaults.collinearThreshold);
			setWindowSizes(defaults.windowSizes);
			setLongRegressionWindowSize(defaults.longRegressionWindowSize);
			setDaysBetweenLongRegressions(defaults.daysBetweenLongRegressions);
			setPenalizedRunsLongRegression(defaults.penalizedRunsLongRegression);
			setLongRegressionPeriodsForPerfomanceEvaluation(defaults.longRegressionPeriodsForPerfomanceEvaluation);
			setLongRegressionBPMPValue(defaults.longRegressionBPMPValue);
			setModelDownSelectPercentage(defaults.modelDownSelectPercentage);
			setModelDownSelectAbsolute(defaults.modelDownSelectAbsolute);
			setModelSetAsideThreshold(defaults.modelSetAsideThreshold);
			setModelSetAsidePeriods(defaults.modelSetAsidePeriods);
			setModelSetAsideExtension(defaults.modelSetAsideExtension);
			setModelProbationPeriods(defaults.modelProbationPeriods);
			setExtensionMultiplier(defaults.extensionMultiplier);
			setCalculationsPrice(defaults.calculationsPrice);
			setSellingPricePreProcessingPeriods(defaults.sellingPricePreProcessingPeriods);
			setSellingPricePreProcessRemoveSmallestPeriodsCount(defaults.sellingPricePreProcessRemoveSmallestPeriodsCount);
			setModelDownSelectionStdDeviationForOutliers(defaults.modelDownSelectionStdDeviationForOutliers);
			setMinimumAcceptedAvgSAEPerc(defaults.minimumAcceptedAvgSAEPerc);
			setDummyEffectUpperLimit(defaults.dummyEffectUpperLimitPerc);
			setDummyEffectMaxValue(defaults.dummyEffectMaxValuePerc);
			setLinLinSlopeMultiplierLimit(defaults.linLinSlopeMultiplierLimit);
			setLongTermPriceChangeMonths(defaults.longTermPriceChangeMonths);
			mounted.current = true;
			setDataChanged(false);
		}
	}

	const areEqualWindowSizes = () => {
		var i;
		let defVals = defaultsRef.current;
		var ret = true;

		if (defVals.windowSizes.length !== windowSizes.length) {
			ret = false;
		} else {
			for (i = 0; i < windowSizes.length && ret; i++) {
				if (defVals.windowSizes[i] !== windowSizes[i]) {
					ret = false;
				}
			}
		}

		return ret;
	};

	const checkChangedValues = () => {
		let defVals = defaultsRef.current;

		if (defVals.priceCheckRange !== priceCheckRange ||
			defVals.priceConstantPercent !== priceConstantPercent ||
			defVals.priceConstant !== priceConstant ||
			defVals.inventoryConstraintRemove !== inventoryConstraintRemove || 
			defVals.outlierThreshold !== outlierThreshold ||
			defVals.outlierRemove !== outlierRemove ||
			defVals.pduTriggerPeriods !== pduTriggerPeriods ||
			defVals.leastPrice !== pduMinPricePerc ||
			defVals.minStablePricing !== minStablePricing ||
			defVals.minPricePercent !== minPricePercent ||
			defVals.periodicityPriceUnitCorrelation !== periodicityPriceUnitCorrelation ||
			defVals.pcapCondition !== pcapCondition ||
			defVals.collinearThreshold !== collinearThreshold ||
			defVals.longRegressionWindowSize !== longRegressionWindowSize ||
			defVals.daysBetweenLongRegressions !== daysBetweenLongRegressions ||
			defVals.penalizedRunsLongRegression !== penalizedRunsLongRegression ||
			defVals.longRegressionPeriodsForPerfomanceEvaluation !== longRegressionPeriodsForPerfomanceEvaluation ||
			defVals.longRegressionBPMPValue !== longRegressionBPMPValue ||
			defVals.modelDownSelectPercentage !== modelDownSelectPercentage ||
			defVals.modelDownSelectAbsolute !== modelDownSelectAbsolute ||
			defVals.modelSetAsideThreshold !== modelSetAsideThreshold ||
			defVals.modelSetAsidePeriods !== modelSetAsidePeriods ||
			defVals.modelSetAsideExtension !== modelSetAsideExtension ||
			defVals.modelProbationPeriods !== modelProbationPeriods ||
			defVals.extensionMultiplier !== extensionMultiplier ||
			defVals.calculationsPrice !== calculationsPrice ||
			defVals.sellingPricePreProcessingPeriods !== sellingPricePreProcessingPeriods ||
			defVals.sellingPricePreProcessRemoveSmallestPeriodsCount !== sellingPricePreProcessRemoveSmallestPeriodsCount ||
			defVals.modelDownSelectionStdDeviationForOutliers !== modelDownSelectionStdDeviationForOutliers ||
			defVals.minimumAcceptedAvgSAEPerc !== minimumAcceptedAvgSAEPerc ||
			defVals.dummyEffectUpperLimitPerc !== dummyEffectUpperLimitPerc ||
			defVals.dummyEffectMaxValuePerc !== dummyEffectMaxValuePerc ||
			defVals.linLinSlopeMultiplierLimit !== linLinSlopeMultiplierLimit ||
			defVals.longTermPriceChangeMonths !== longTermPriceChangeMonths ||
			!areEqualWindowSizes())
		{
			setDataChanged(true);
		} else {
			setDataChanged(false);
		}
	}

	const validateChip = (ev) => {
		let i = 0;

		for (i = 0; i < ev.value.length; i++) {
			if (typeof ev.value[i] !== 'number') {

				let tryParse = parseInt(ev.value[i]);

				if (isNaN(tryParse)) {
					return false;
				} else {
					ev.value[i] = tryParse;
				}
			}
		}

		return true;
	}

	const confirmSave = async () => {
		confirmDialog({
			message: t('ConfigurationDialog.ConfirmOverwriteMessage'),
			header: t('ConfigurationDialog.ConfirmOverwriteHeader'),
			acceptLabel: t('ConfigurationDialog.ConfirmDialogYes'),
			rejectLabel: t('ConfigurationDialog.ConfirmDialogNo'),
			className: 'configuration-confirm-panel',
			icon: 'pi pi-exclamation-triangle',
			accept: async () => {
				await saveConfig();
			}
		});
	}

	const confirmLostChanges = (callback) => {
		if (dataChanged) {
			confirmDialog({
				message: t('FieldMappings.ConfirmRefresh'),
				header: t('FieldMappings.ConfirmOverwriteHeader'),
				acceptLabel: t('FieldMappings.ConfirmDialogYes'),
				rejectLabel: t('FieldMappings.ConfirmDialogNo'),
				icon: 'pi pi-exclamation-triangle',
				accept: () => callback()
			});
		}
	}

	const onSkuChange = async (val) => {
		if (dataChanged) {
			confirmLostChanges(async () => {
				setSku(val);
				await readDefaults(val);
			});
		} else {
			setSku(val);
			await readDefaults(val);
		}
	}
	const confirmReset = () => {
		onSkuChange(sku);
	}

	useEffect(() => {
		if (mounted.current === true) {
			checkChangedValues();
		}

	}, [priceCheckRange, priceConstantPercent, priceConstant, inventoryConstraintRemove, outlierThreshold,  // eslint-disable-line react-hooks/exhaustive-deps
		outlierRemove, pduTriggerPeriods, pduMinPricePerc, minStablePricing, minPricePercent,
		periodicityPriceUnitCorrelation, pcapCondition, collinearThreshold, longRegressionWindowSize, daysBetweenLongRegressions, penalizedRunsLongRegression,
		longRegressionPeriodsForPerfomanceEvaluation, longRegressionBPMPValue, modelDownSelectPercentage, modelDownSelectAbsolute, modelSetAsideThreshold, modelSetAsidePeriods,
		modelSetAsideExtension, modelProbationPeriods, extensionMultiplier, calculationsPrice, sellingPricePreProcessingPeriods, sellingPricePreProcessRemoveSmallestPeriodsCount,
		dummyEffectUpperLimitPerc, dummyEffectMaxValuePerc, linLinSlopeMultiplierLimit, longTermPriceChangeMonths, minimumAcceptedAvgSAEPerc, modelDownSelectionStdDeviationForOutliers, windowSizes]);

	useEffect(() => {

		if (skus != null && skus.length > 0) {
			// Set default SKU (general element)
			onSkuChange("-1");
		}

	}, [skus]); // eslint-disable-line react-hooks/exhaustive-deps

	if (allowed !== true) {
		return (<></>);
	} else {
		return (
			<>
				<h3 className="text-primary pt-3 pb-3 pl-3">
					{t('ConfigurationDialog.PricingAlgorithms_Parameters')}
					<Button label="" title={t('ConfigurationDialog.HelpButton')} icon="pi pi-question-circle" className="p-button-config-help p-button-rounded p-button-info" onClick={() => showHelp('ConfigurationDialog.PricingAlgorithms_ImportHelpTitle', 'ConfigurationDialog.PricingAlgorithms_ImportHelpMessage')} />
				</h3>

				<div className="field grid pl-3 pr-3 mt-3">
					<label htmlFor="sku" className="col-6 mb-2 md:col-4 md:mb-0">{t('ConfigurationDialog.SKU')}</label>
					<div className="col-6 md:col-2">
						<Dropdown id="sku" name="sku" value={sku} options={skus} optionLabel="label" optionValue="value" onChange={(e) => onSkuChange(e.value)} />
					</div>
				</div>

				<div className="field grid mt-3 pl-3 pr-3">
					<label htmlFor="priceCheckRange" className="col-6 md:col-4">{t('ConfigurationDialog.PriceCheckRange')}</label>
					<div className="col-6 md:col-2">
						<InputNumber value={priceCheckRange} onChange={(e) => setPriceCheckRange(e.value)} id="priceCheckRange" name="priceCheckRange"
							inputClassName="inputfield textbox" showButtons={false} required minFractionDigits={0} maxFractionDigits={0}
							mode="decimal" />
					</div>

					<label htmlFor="priceConstantPercent" className="col-6 md:col-4">{t('ConfigurationDialog.PriceConstantPercent')}</label>
					<div className="col-6 md:col-2">
						<InputNumber value={priceConstantPercent} onChange={(e) => setPriceConstantPercent(e.value)} id="priceConstantPercent" name="priceConstantPercent"
							inputClassName="inputfield textbox" showButtons={false} required minFractionDigits={2} maxFractionDigits={2}
							mode="decimal" min={0} max={1} />
					</div>
				</div>

				<div className="field grid mt-3 pl-3 pr-3">
					<label htmlFor="priceConstant" className="col-6 md:col-4">{t('ConfigurationDialog.PriceConstant')}</label>
					<div className="col-6 md:col-2">
						<InputNumber value={priceConstant} onChange={(e) => setPriceConstant(e.value)} id="priceConstant" name="priceConstant"
							inputClassName="inputfield textbox" showButtons={false} required minFractionDigits={0} maxFractionDigits={0}
							mode="decimal" />
					</div>
					<label htmlFor="priceConstantPercent" className="col-6 md:col-4">{t('ConfigurationDialog.InvConstraintRemove')}</label>
					<div className="col-6 md:col-2">
						<ToggleButton id="priceConstantPercent" onLabel={t('ConfigurationDialog.ToggleYes')} required
							offLabel={t('ConfigurationDialog.ToggleNo')} onIcon="pi pi-check" offIcon="pi pi-times"
							checked={inventoryConstraintRemove}
							onChange={(ev) => { setInventoryConstraintRemove(ev.value); }} />
					</div>
				</div>

				<div className="field grid mt-3 pl-3 pr-3">
					<label htmlFor="outlierThreshold" className="col-6 md:col-4">{t('ConfigurationDialog.OutlierThreshold')}</label>
					<div className="col-6 md:col-2">
						<InputNumber value={outlierThreshold} onChange={(e) => setOutlierThreshold(e.value)} id="outlierThreshold" name="outlierThreshold"
							inputClassName="inputfield textbox" showButtons={false} required minFractionDigits={0} maxFractionDigits={0}
							min={0}
							mode="decimal" />
					</div>
					<label htmlFor="outlierRemove" className="col-6 md:col-4">{t('ConfigurationDialog.OutlierRemove')}</label>
					<div className="col-6 md:col-2">
						<ToggleButton id="outlierRemove" onLabel={t('ConfigurationDialog.ToggleYes')} required
							offLabel={t('ConfigurationDialog.ToggleNo')} onIcon="pi pi-check" offIcon="pi pi-times"
							checked={outlierRemove}
							onChange={(ev) => { setOutlierRemove(ev.value); }} />
					</div>
				</div>

				<div className="field grid mt-3 pl-3 pr-3">
					<label htmlFor="pduTriggerPeriods" className="col-6 md:col-4">{t('ConfigurationDialog.MinStablePricing')}</label>
					<div className="col-6 md:col-2">
						<InputNumber value={minStablePricing} onChange={(e) => setMinStablePricing(e.value)} id="minStablePricing" name="minStablePricing"
							inputClassName="inputfield textbox" showButtons={false} required minFractionDigits={0} maxFractionDigits={2}
							min={0}
							mode="decimal" />
					</div>
					<label htmlFor="pduMinPricePerc" className="col-6 md:col-4">{t('ConfigurationDialog.MinPricePercent')}</label>
					<div className="col-6 md:col-2">
						<InputNumber value={minPricePercent} onChange={(e) => setMinPricePercent(e.value)} id="minPricePercent" name="minPricePercent"
							inputClassName="inputfield textbox" showButtons={false} required minFractionDigits={1} maxFractionDigits={2}
							min={0} max={100}
							mode="decimal" />
					</div>
				</div>

				<div className="field grid mt-3 pl-3 pr-3">
					<label htmlFor="pduTriggerPeriods" className="col-6 md:col-4">{t('ConfigurationDialog.PDUTriggerPeriods')}</label>
					<div className="col-6 md:col-2">
						<InputNumber value={pduTriggerPeriods} onChange={(e) => setPduTriggerPeriods(e.value)} id="pduTriggerPeriods" name="pduTriggerPeriods"
							inputClassName="inputfield textbox" showButtons={false} required minFractionDigits={0} maxFractionDigits={0}
							min={0}
							mode="decimal" />
					</div>
					<label htmlFor="pduMinPricePerc" className="col-6 md:col-4">{t('ConfigurationDialog.LeastPrice')}</label>
					<div className="col-6 md:col-2">
						<InputNumber value={pduMinPricePerc} onChange={(e) => setPduMinPricePerc(e.value)} id="pduMinPricePerc" name="pduMinPricePerc"
							inputClassName="inputfield textbox" showButtons={false} required minFractionDigits={1} maxFractionDigits={2}
							min={0} max={100}
							mode="decimal" />
					</div>
				</div>

				<div className="field grid mt-3 pl-3 pr-3">
					<label htmlFor="periodicityPriceUnitCorrelation" className="col-6 md:col-4">{t('ConfigurationDialog.PeriodicityPriceUnitCorrelation')}</label>
					<div className="col-6 md:col-2">
						<InputNumber value={periodicityPriceUnitCorrelation} onChange={(e) => setPeriodicityPriceUnitCorrelation(e.value)} id="periodicityPriceUnitCorrelation" name="periodicityPriceUnitCorrelation"
							inputClassName="inputfield textbox" showButtons={false} required minFractionDigits={1} maxFractionDigits={2}
							min={0} max={1}
							mode="decimal" />
					</div>

					<label htmlFor="pcapCondition" className="col-6 md:col-4">{t('ConfigurationDialog.PCAPCondition')}</label>
					<div className="col-6 md:col-2">
						<InputNumber value={pcapCondition} onChange={(e) => setPcapCondition(e.value)} id="pcapCondition" name="pcapCondition"
							inputClassName="inputfield textbox" showButtons={false} required minFractionDigits={1} maxFractionDigits={2}
							min={0} max={1}
							mode="decimal" />
					</div>
				</div>

				<div className="field grid mt-3 pl-3 pr-3">
					<label htmlFor="collinearThreshold" className="col-6 md:col-4 align-items-start mt-1">{t('ConfigurationDialog.CollinearThreshold')}</label>
					<div className="col-6 md:col-2">
						<InputNumber value={collinearThreshold} onChange={(e) => setCollinearThreshold(e.value)} id="collinearThreshold" name="collinearThreshold"
							inputClassName="inputfield textbox" showButtons={false} required minFractionDigits={1} maxFractionDigits={2}
							min={0} max={1}
							mode="decimal" />
					</div>

					<label htmlFor="longRegressionWindowSize" className="col-6 md:col-4 align-items-start mt-1">{t('ConfigurationDialog.LongRegressionWindowSize')}</label>
					<div className="col-6 md:col-2">
						<InputNumber value={longRegressionWindowSize} onChange={(e) => setLongRegressionWindowSize(e.value)} id="longRegressionWindowSize" name="longRegressionWindowSize"
							inputClassName="inputfield textbox" showButtons={false} required minFractionDigits={0} maxFractionDigits={0} min={1} max={156}
							mode="decimal" />
					</div>
				</div>

				<div className="field grid mt-3 pl-3 pr-3">
					<label htmlFor="daysBetweenLongRegressions" className="col-6 md:col-4 align-items-start mt-1">{t('ConfigurationDialog.DaysBetweenLongRegressions')}</label>
					<div className="col-6 md:col-2">
						<InputNumber value={daysBetweenLongRegressions} onChange={(e) => setDaysBetweenLongRegressions(e.value)} id="daysBetweenLongRegressions" name="daysBetweenLongRegressions"
							inputClassName="inputfield textbox" showButtons={false} required minFractionDigits={0} maxFractionDigits={0} min={0}
							mode="decimal" />
					</div>

					<label htmlFor="penalizedRunsLongRegression" className="col-6 md:col-4 align-items-start mt-1">{t('ConfigurationDialog.PenalizedRunsLongRegression')}</label>
					<div className="col-6 md:col-2">
						<InputNumber value={penalizedRunsLongRegression} onChange={(e) => setPenalizedRunsLongRegression(e.value)} id="penalizedRunsLongRegression" name="penalizedRunsLongRegression"
							inputClassName="inputfield textbox" showButtons={false} required minFractionDigits={0} maxFractionDigits={0} min={0}
							mode="decimal" />
					</div>
				</div>

				<div className="field grid mt-3 pl-3 pr-3">
					<label htmlFor="longRegressionPeriodsForPerfomanceEvaluation" className="col-6 md:col-4 align-items-start mt-1">{t('ConfigurationDialog.LongRegressionPeriodsForPerfomanceEvaluation')}</label>
					<div className="col-6 md:col-2">
						<InputNumber value={longRegressionPeriodsForPerfomanceEvaluation} onChange={(e) => setLongRegressionPeriodsForPerfomanceEvaluation(e.value)} id="longRegressionPeriodsForPerfomanceEvaluation" name="longRegressionPeriodsForPerfomanceEvaluation"
							inputClassName="inputfield textbox" showButtons={false} required minFractionDigits={0} maxFractionDigits={0} min={0}
							mode="decimal" />
					</div>

					<label htmlFor="longRegressionBPMPValue" className="col-6 md:col-4 align-items-start mt-1">{t('ConfigurationDialog.LongRegressionBPMPValue')}</label>
					<div className="col-6 md:col-2">
						<InputNumber value={longRegressionBPMPValue} onChange={(e) => setLongRegressionBPMPValue(e.value)} id="longRegressionBPMPValue" name="longRegressionBPMPValue"
							inputClassName="inputfield textbox" showButtons={false} required minFractionDigits={1} maxFractionDigits={2} min={0} max={1}
							mode="decimal" />
					</div>			
				</div>

				<div className="field grid mt-3 pl-3 pr-3">
					<label htmlFor="modelDownSelectPercentage" className="col-6 md:col-4 align-items-start mt-1">{t('ConfigurationDialog.ModelDownSelectPercentage')}</label>
					<div className="col-6 md:col-2">
						<InputNumber value={modelDownSelectPercentage} onChange={(e) => setModelDownSelectPercentage(e.value)} id="modelDownSelectPercentage" name="modelDownSelectPercentage"
							inputClassName="inputfield textbox" showButtons={false} required minFractionDigits={1} maxFractionDigits={2} min={0} max={100}
							mode="decimal" />
					</div>

					<label htmlFor="modelDownSelectAbsolute" className="col-6 md:col-4 align-items-start mt-1">{t('ConfigurationDialog.ModelDownSelectAbsolute')}</label>
					<div className="col-6 md:col-2">
						<InputNumber value={modelDownSelectAbsolute} onChange={(e) => setModelDownSelectAbsolute(e.value)} id="modelDownSelectAbsolute" name="modelDownSelectAbsolute"
							inputClassName="inputfield textbox" showButtons={true} required minFractionDigits={0} maxFractionDigits={0} min={0} max={100}
							mode="decimal" />
					</div>
				</div>

				<div className="field grid mt-3 pl-3 pr-3">
					<label htmlFor="modelDownSelectionStdDeviationForOutliers" className="col-6 md:col-4 align-items-start mt-1">{t('ConfigurationDialog.ModelDownSelectionStdDeviationForOutliers')}</label>
					<div className="col-6 md:col-2">
						<InputNumber value={modelDownSelectionStdDeviationForOutliers} onChange={(e) => setModelDownSelectionStdDeviationForOutliers(e.value)} id="modelDownSelectionStdDeviationForOutliers" name="modelDownSelectionStdDeviationForOutliers"
							inputClassName="inputfield textbox" showButtons={false} required minFractionDigits={0} maxFractionDigits={2} min={0} max={5} mode="decimal" />
					</div>

					<label htmlFor="minimumAcceptedAvgSAEPerc" className="col-6 md:col-4 align-items-start mt-1">{t('ConfigurationDialog.MinimumAcceptedAvgSAEPerc')}</label>
					<div className="col-6 md:col-2">
						<InputNumber value={minimumAcceptedAvgSAEPerc} onChange={(e) => setMinimumAcceptedAvgSAEPerc(e.value)} id="minimumAcceptedAvgSAEPerc" name="minimumAcceptedAvgSAEPerc"
							inputClassName="inputfield textbox" showButtons={false} required minFractionDigits={0} maxFractionDigits={2} min={0} max={1}
							mode="decimal" />
					</div>
				</div>

				<div className="field grid mt-3 pl-3 pr-3">
					<label htmlFor="modelSetAsideThreshold" className="col-6 md:col-4 align-items-start mt-1">{t('ConfigurationDialog.ModelSetAsideThreshold')}</label>
					<div className="col-6 md:col-2">
						<InputNumber value={modelSetAsideThreshold} onChange={(e) => setModelSetAsideThreshold(e.value)} id="modelSetAsideThreshold" name="modelSetAsideThreshold"
							inputClassName="inputfield textbox" showButtons={true} required minFractionDigits={0} maxFractionDigits={0} min={0} max={100}
							mode="decimal" />
					</div>

					<label htmlFor="modelSetAsidePeriods" className="col-6 md:col-4 align-items-start mt-1">{t('ConfigurationDialog.ModelSetAsidePeriods')}</label>
					<div className="col-6 md:col-2">
						<InputNumber value={modelSetAsidePeriods} onChange={(e) => setModelSetAsidePeriods(e.value)} id="modelSetAsidePeriods" name="modelSetAsidePeriods"
							inputClassName="inputfield textbox" showButtons={true} required minFractionDigits={0} maxFractionDigits={0} min={0} max={100}
							mode="decimal" />
					</div>
				</div>

				<div className="field grid mt-3 pl-3 pr-3">
					<label htmlFor="modelSetAsideExtension" className="col-6 md:col-4 align-items-start mt-1">{t('ConfigurationDialog.ModelSetAsideExtension')}</label>
					<div className="col-6 md:col-2">
						<InputNumber value={modelSetAsideExtension} onChange={(e) => setModelSetAsideExtension(e.value)} id="modelSetAsideExtension" name="modelSetAsideExtension"
							inputClassName="inputfield textbox" showButtons={true} required minFractionDigits={0} maxFractionDigits={0} min={0} max={100}
							mode="decimal" />
					</div>

					<label htmlFor="modelProbationPeriods" className="col-6 md:col-4 align-items-start mt-1">{t('ConfigurationDialog.ModelProbationPeriods')}</label>
					<div className="col-6 md:col-2">
						<InputNumber value={modelProbationPeriods} onChange={(e) => setModelProbationPeriods(e.value)} id="modelProbationPeriods" name="modelProbationPeriods"
							inputClassName="inputfield textbox" showButtons={true} required minFractionDigits={0} maxFractionDigits={0} min={0} max={100}
							mode="decimal" />
					</div>					
				</div>

				<div className="field grid mt-3 pl-3 pr-3">

					<label htmlFor="extensionMultiplier" className="col-6 md:col-4 align-items-start mt-1">{t('ConfigurationDialog.ExtensionMultiplier')}</label>
					<div className="col-6 md:col-2">
						<InputNumber value={extensionMultiplier} onChange={(e) => setExtensionMultiplier(e.value)} id="extensionMultiplier" name="extensionMultiplier"
							inputClassName="inputfield textbox" showButtons={true} required minFractionDigits={0} maxFractionDigits={0} min={0} max={20}
							mode="decimal" />
					</div>
					<label htmlFor="calculationsPrice" className="col-6 md:col-4 align-items-start mt-1">{t('ConfigurationDialog.CalculationsPrice')}</label>
					<div className="col-6 md:col-2">
						<Dropdown id="calculationsPrice" name="calculationsPrice" value={calculationsPrice} options={calculationsPriceOptions} optionLabel="label" optionValue="value" onChange={(e) => setCalculationsPrice(e.value)} />
					</div>
				</div>

				<div className="field grid mt-3 pl-3 pr-3">
					<label htmlFor="dummyEffectUpperLimitPerc" className="col-6 md:col-4 align-items-start mt-1">{t('ConfigurationDialog.DummyEffectUpperLimitPerc')}</label>
					<div className="col-6 md:col-2">
						<InputNumber value={dummyEffectUpperLimitPerc} onChange={(e) => setDummyEffectUpperLimit(e.value)} id="dummyEffectUpperLimitPerc" name="dummyEffectUpperLimitPerc"
							inputClassName="inputfield textbox" showButtons={false} required minFractionDigits={1} maxFractionDigits={2} min={0} max={20}
							mode="decimal" />
					</div>
					<label htmlFor="dummyEffectMaxValuePerc" className="col-6 md:col-4 align-items-start mt-1">{t('ConfigurationDialog.DummyEffectMaxValuePerc')}</label>
					<div className="col-6 md:col-2">
						<InputNumber value={dummyEffectMaxValuePerc} onChange={(e) => setDummyEffectMaxValue(e.value)} id="dummyEffectMaxValuePerc"
							name="dummyEffectMaxValuePerc" inputClassName="inputfield textbox" showButtons={false} required minFractionDigits={1} maxFractionDigits={2} min={0} max={20}
							mode="decimal" />
					</div>
				</div>

				<div className="field grid mt-3 pl-3 pr-3">
					<label htmlFor="linLinSlopeMultiplierLimit" className="col-6 md:col-4 align-items-start mt-1">{t('ConfigurationDialog.LinLinSlopeMultiplierLimit')}</label>
					<div className="col-6 md:col-2">
						<InputNumber value={linLinSlopeMultiplierLimit} onChange={(e) => setLinLinSlopeMultiplierLimit(e.value)} id="linLinSlopeMultiplierLimit" name="linLinSlopeMultiplierLimit"
							inputClassName="inputfield textbox" showButtons={false} required minFractionDigits={1} maxFractionDigits={2} min={0} max={20}
							mode="decimal" />
					</div>

					<label htmlFor="longTermPriceChangeMonths" className="col-6 md:col-4 align-items-start mt-1">{t('ConfigurationDialog.LongTermPriceChangeMonths')}</label>
					<div className="col-6 md:col-2">
						<InputNumber value={longTermPriceChangeMonths} onChange={(e) => setLongTermPriceChangeMonths(e.value)} id="longTermPriceChangeMonths" name="longTermPriceChangeMonths"
							inputClassName="inputfield textbox" showButtons={true} required minFractionDigits={0} maxFractionDigits={2} min={0} max={36}
							mode="decimal" />
					</div>
				</div>				

				<div className="field grid mt-3 pl-3 pr-3">

					<label htmlFor="sellingPricePreProcessingPeriods" className="col-6 md:col-4 align-items-start mt-1">{t('ConfigurationDialog.SellingPricePreProcessingPeriods')}</label>
					<div className="col-6 md:col-2">
						<InputNumber value={sellingPricePreProcessingPeriods} onChange={(e) => setSellingPricePreProcessingPeriods(e.value)} id="sellingPricePreProcessingPeriods" name="sellingPricePreProcessingPeriods"
							inputClassName="inputfield textbox" showButtons={true} required minFractionDigits={0} maxFractionDigits={0} min={0} max={20}
							mode="decimal" />
					</div>
					<label htmlFor="sellingPricePreProcessRemoveSmallestPeriodsCount" className="col-6 md:col-4 align-items-start mt-1">{t('ConfigurationDialog.SellingPricePreProcessRemoveSmallestPeriodsCount')}</label>
					<div className="col-6 md:col-2">
						<InputNumber value={sellingPricePreProcessRemoveSmallestPeriodsCount} onChange={(e) => setSellingPricePreProcessRemoveSmallestPeriodsCount(e.value)} id="sellingPricePreProcessRemoveSmallestPeriodsCount"
							name="sellingPricePreProcessRemoveSmallestPeriodsCount" inputClassName="inputfield textbox" showButtons={true} required minFractionDigits={0} maxFractionDigits={0} min={0} max={20}
							mode="decimal" />
					</div>
				</div>

				

				<div className="field grid mt-3 pl-3 pr-3">
					<label htmlFor="windowSizes" className="col-6 md:col-4 align-items-start mt-1">{t('ConfigurationDialog.WindowSizes')}</label>
					<div className="col-6 md:col-2">
						<Chips id="windowSizes" value={windowSizes} onChange={(ev) => { if (validateChip(ev)) setWindowSizes(ev.value); }} allowDuplicate={false}></Chips>
					</div>
				</div>				

				<div className="flex justify-content-end mr-3 bottom-buttons">
					<ConfigActionBtn onClick={(e) => confirmSave()} buttonText={t('ConfigurationDialog.SaveButton')} disabled={!dataChanged} />
					<ConfigActionBtn onClick={(e) => confirmReset()} buttonText={t('ConfigurationDialog.CancelButton')} disabled={!dataChanged} />
				</div>
			</>
		);
	}
}

export default withTranslation()(PricingAlgorithmsConfig)
