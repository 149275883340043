import React, { useEffect, useRef, useState } from "react"
import { withTranslation } from "react-i18next";
import FilterPricing from "../components/Pricing/FilterPricing"
import DataTablePricing from "../components/Pricing/PricingTable"
import { Calculate } from "../Api/client-side/calculate"
import pricingService from "../Api/requests/pricing"
import authService from "../components/api-authorization/AuthorizeService";
import messagesService from "../Api/client-side/messages"
import { confirmDialog } from 'primereact/confirmdialog';
import { ConfirmDialog } from 'primereact/confirmdialog';
import { ListBox } from 'primereact/listbox';
import { contextualConfirmDialog } from '../components/User/ContextualConfirmDialog'
import { InputText } from 'primereact/inputtext';
import { Dialog } from 'primereact/dialog'
import { OverlayPanel } from "primereact/overlaypanel";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { ScrollPanel } from 'primereact/scrollpanel';

const RelationshipsPage = (props) => {
	const relationshipsGridSessionStorageSelectedRowsKey = "relationshipsGridSelectedRows";
	const relationshipsGridSessionStorageColumnsSelectedKey = "relationshipsGridSelectedColumns";

	const [relationshipExtraColumns, setRelationshipExtraColumns] = useState({});
	const [rowsPerPage, setRowsPerPage] = useState(10);
	const [search, setSearch] = useState("");
	const [searchOutside, setSearchOutside] = useState(false);
	const [checkedItems, setCheckedItems] = useState([])
	const [items, setItems] = useState(null);
	const [itemsSearched, setItemsSearched] = useState(null);
	const [itemsFiltered, setItemsFiltered] = useState(null);
	const [currentMaxProfit, setCurrentMaxProfit] = useState(null);
	const [loading, setLoading] = useState(false);
	const [initializing, setInitializing] = useState(true);
	const [totalRecords, setTotalRecords] = useState(0);
	const [currencySymbol, setCurrencySymbol] = useState(null);
	const [companyName, setCompanyName] = useState("");
	const [lastRepricing, setLastRepricing] = useState(null);
	const [relationshipType, setRelationshipType] = useState(-1)
	const [relationships, setRelationships] = useState([])
	const [relationshipId, setRelationshipId] = useState(-1)
	const [onlyChecked, setOnlyChecked] = useState(false)
	const [filteredRelationships, setFilteredRelationships] = useState([])

	const [groupName, setGroupName] = useState('')
	const [groupSelected, setGroupSelected] = useState(false)
	const [relationshipChanged, setRelationshipChanged] = useState(false)

	const [priceAsOneDialogVisible, setPriceAsOneDialogVisible] = useState(false)
	const [priceLadderDialogVisible, setPriceLadderDialogVisible] = useState(false)

	const { t, tReady } = props;

	const [lazyParams, setLazyParams] = useState(null);
	const [filters, setFilters] = useState([]);
	const [clearFiltersFlag, setClearFiltersFlag] = useState(false);

	const calculate = new Calculate();
	const dt = useRef(null);
	const dtSelected = useRef(null);
	const filterPriceGroupsOverlay = useRef(null)

	const loadRelationships = async () => {
		let theRelationships = await pricingService.getRelationships();
		if (theRelationships != null) {
			setRelationships(theRelationships);
		}
	}

	/**
	 *  Load default session values
	 *  @returns local storage checked items, refreshed from backend
	 */
	const loadDefaults = async () => {
		let user = await authService.getUser();
		setCurrencySymbol(user.currencySymbol);
		setCompanyName(user.company);

		let theLastRepricing = await pricingService.getLastRepricingDateTime();

		if (theLastRepricing != null) {
			setLastRepricing(theLastRepricing);
		} else {
			setLastRepricing(null);
		}

		await loadRelationships();

		setLazyParams({
			first: 0,
			rows: rowsPerPage,
			page: 1,
			includeRelationships: false,
			sortField: "changeInProfit", // "projectedTotalProfit",
			sortOrder: -1,
		});
	};

	useEffect(() => {
		if (tReady) {
			setRelationshipExtraColumns({
				"PriceGroup": {
					field: "priceGroupName",
					header: t('Relationships.PriceGroupFilterHeader'),
					intialState: false,
					sortableDisabled: false
				}
			});

			var theFilters = [
				{
					field: "priceGroupId",
					header: t("PricingFilters.PriceAsOneGroupsFilterButton"),
					value: "",
					filteredIds: [],
					booleanFilter: false,
					customFilterHandler: (e, item) => {
						filterPriceGroupsOverlay.current.toggle(e);
					},
					exclusiveWith: []
				},
				{
					field: "sku",
					header: t("PricingFilters.SKUFilterButton"),
					value: "",
					booleanFilter: false,
				},
				{
					field: "date",
					header: t("PricingFilters.DateFilterButton"),
					value: "",
					booleanFilter: false,
				},
				{
					field: "storeNumber",
					header: t("PricingFilters.StoreFilterButton"),
					value: "",
					booleanFilter: false,
				},
				{
					field: "description",
					header: t("PricingFilters.DescriptionFilterButton"),
					value: "",
					booleanFilter: false,
				},
				{
					field: "pdu",
					header: t("PricingFilters.PDUFilterButton"),
					value: "",
					booleanFilter: true,
					exclusiveWith: ["demandCurve"],
				},
				{
					field: "demandCurve",
					header: t("PricingFilters.DCUFilterButton"),
					value: "",
					booleanFilter: true,
					exclusiveWith: ["pdu"],
				},
				{
					field: "guardrailed",
					header: t("PricingFilters.GuardrailFilterButton"),
					value: "",
					booleanFilter: true,
					exclusiveWith: [],
				}
			];

			setFilters(theFilters);
		}
	}, [tReady]); // eslint-disable-line react-hooks/exhaustive-deps

	useEffect(() => {
		loadDefaults();
	}, []);	// eslint-disable-line react-hooks/exhaustive-deps

	useEffect(() => {
		const lazyParametersChanged = async () => {
			//if (currentMaxProfit === null) {
				loadLazyFilters({ filters: filters });
			//}
			await loadLazyData();

			if (initializing) {
				// Load checked items from local storage in initialization time
				setInitializing(false);
			}
		}

		if (lazyParams !== null) {
			lazyParametersChanged();
		}
	}, [lazyParams]) // eslint-disable-line react-hooks/exhaustive-deps

	useEffect(() => {
		const doReload = async () => {
			if (!initializing) {
				await loadLazyFilters({ filters: filters });
				await loadLazyData();	// Reload with checked/unchecked items state flag
			}
		}

		doReload();
	}, [onlyChecked]); // eslint-disable-line react-hooks/exhaustive-deps

	const loadLazyData = async () => {
		setLoading(true);

		// Consume api when no only selected rows is enabled
		//if (allItemsActiveButton) {
		var data = await pricingService.getPricingItems(
			lazyParams,
			filters,
			search,
			searchOutside,
			onlyChecked ? checkedItems : undefined);

		data = data.map((item) => {
			item.changedByUser = false;
			calculate.calculatePricingItem(item);
			item.originalRecommendedPrice = item.newPriceT1; // item.optimalPriceT1;
			item.originalUnitsPeriodT1 = item.unitsPeriodT1;
			return item;
		});

		setItems(data);
		setItemsSearched(data);

		let _lazyParams = { ...lazyParams, ...{ rows: 100 } } // rows attribute limits the maximum amount of filter items we're going to retrieve
		let theFilterdata = await pricingService.getPricingFilterItems(
			_lazyParams,
			filters,
			search,
			searchOutside);
		setItemsFiltered(theFilterdata);

		setLoading(false);
	}

	const onPage = async (event, grid) => {
		let _lazyParams = { ...lazyParams, ...event }
		setLazyParams(_lazyParams);
	};

	const onSort = (event) => {
		let _lazyParams = { ...lazyParams, ...event }
		setLazyParams(_lazyParams);
	};

	const onFilter = (event) => {
		let _lazyParams = { ...lazyParams, ...event } // rows attribute required for keeping rows number on filter
		setLazyParams(_lazyParams);
	};

	const fillMaxProfit = async (filters) => {
		let theFilters = filters;
		let theMaxProfit = await pricingService.getPricingMaxProfit(
			theFilters,
			search,
			searchOutside,
			false);
		if (theMaxProfit === 0) {
			theMaxProfit = 1;
		} else {
			theMaxProfit = Math.abs(theMaxProfit);
		}
		setCurrentMaxProfit(theMaxProfit);
	};

	const loadLazyFilters = async (filters) => {
		await fillMaxProfit(filters.filters);

		var totalRecords = await pricingService.getPricingItemsCount(
			filters.filters,
			search,
			searchOutside,
			onlyChecked ? checkedItems : undefined,
			false);
		setTotalRecords(totalRecords);
	};

	useEffect(() => {
		async function doLoad() {
			if (Array.isArray(items)) {
				await loadLazyData();
			}
		}
		doLoad();
	}, [search, searchOutside]); // eslint-disable-line react-hooks/exhaustive-deps

	const isCheckedItem = (theRel, sku, storeNumber) => {
		return (theRel.checkedItems.findIndex(p => p.sku === sku && p.storeNumber === storeNumber) >= 0);
	}

	useEffect(() => {
		if (relationshipId === -1) {
			setRelationshipChanged(checkedItems.length > 0);
		} else {
			let theRel = relationships.find(r => r.id === relationshipId);

			if (checkedItems.length !== theRel.checkedItems.length) {
				setRelationshipChanged(true);
			} else {
				var idx = 0;
				var equals = true;
				for (idx = 0; idx < checkedItems.length && equals; idx++) {
					if (!isCheckedItem(theRel, checkedItems[idx].sku, checkedItems[idx].storeNumber)) {
						equals = false;
                    }
				}

				setRelationshipChanged(!equals);
            }
		}

		
	}, [checkedItems]); // eslint-disable-line react-hooks/exhaustive-deps

	const onSearched = async (searchTerm, searchOutside, textChanged) => {
		setSearchOutside(searchOutside)
		if (textChanged || searchTerm !== "") {
			setSearch(searchTerm)
			if (onlyChecked) {
				setOnlyChecked(false);
            }
		}
	};

	const isFieldAvailable = (mapping) => {
		return mapping.relationshipScreen;
	}

	const resetRelationshipSelection = () => {
		setCheckedItems([]);
		setRelationshipId(-1);
		setRelationshipType(-1);
		setGroupName('');
		setGroupSelected(false);
		setOnlyChecked(false);
		setRelationshipChanged(false);
	}

	const resetAll = () => {
		setCheckedItems([]);
		setRelationshipId(-1);
		setRelationshipType(-1);
		setGroupName('');
		setGroupSelected(false);
		setClearFiltersFlag(true);
		setOnlyChecked(false);
		setRelationshipChanged(false);
	}

	const confirmResetRelationshipSelection = (header, message, ev) => {
		if (relationshipChanged) {
			setTimeout(() => {
				confirmDialog({
					message: message,
					header: header,
					acceptLabel: t('Relationships.ConfirmYes'),
					rejectLabel: t('Relationships.ConfirmNo'),
					className: 'confirm-panel',
					// icon: 'pi pi-exclamation-triangle',
					accept: () => {
						resetRelationshipSelection();
					},
					contextEvent: ev
				});
			}, 100);
		} else {
			resetRelationshipSelection();
		}
	}

	const confirmReset = (header, message, ev) => {
		if (relationshipChanged) {
			setTimeout(() => {
				confirmDialog({
					message: message,
					header: header,
					acceptLabel: t('Relationships.ConfirmYes'),
					rejectLabel: t('Relationships.ConfirmNo'),
					className: 'confirm-panel',
					// icon: 'pi pi-exclamation-triangle',
					accept: () => {
						resetAll();
					}
				});
			}, 100);
		} else {
			resetAll();
        }
	}

	useEffect(() => {
		if (clearFiltersFlag === true) {
			setClearFiltersFlag(false);
		}
	}, [clearFiltersFlag]);

	const save = async (e) => {
		if (relationshipChanged) {
			if (checkedItems === undefined || checkedItems.length <= 1) {
				let isNewGroup = (relationshipId === -1);
				if (isNewGroup) {
					// New group and no element checked, so just ask to confirm the "group name" lost
					confirmReset(t('Relationships.ConfirmLostChangesHeader'), t('Relationships.ConfirmLostChanges'), e);
				} else {
					// Selected group doesn't have 2 or more elements selected, so warn this and don't continue
					contextualConfirmDialog({
						message: t('Relationships.TwoItemsRequiredMessage'),
						header: ' ',
						acceptLabel: t('Relationships.OkButton'),
						hideRejectButton: true,
						icon: 'pi pi-exclamation-triangle text-center m-auto block',	// Center icon because we don't have a header here
						accept: () => {
							// DO NOTHING!
						},
						contextEvent: e
					});
				}
			} else {
				confirmDialog({
					message: t('Relationships.ConfirmSaveRelationshipMessage'),
					header: t('Relationships.ConfirmSaveRelationshipHeader'),
					acceptLabel: t('Relationships.ConfirmSave'),
					rejectLabel: t('Relationships.ConfirmDiscard'),
					className: 'confirm-panel',
					// icon: 'pi pi-exclamation-triangle',
					accept: async () => {
						let data = {
							checkedItems: checkedItems.map(c => ({ sku: c.sku, storeNumber: c.storeNumber })),
							type: relationshipType,
							label: groupName,
							id: relationshipId > 0 ? relationshipId : null
						};

						let res = await pricingService.saveRelationship(data);

						// Reload relationships to get changes
						await loadRelationships();

						if (res) {
							// confirmReset(t('Relationships.ConfirmSaveHeader'), t('Relationships.ConfirmSaveMessage'), e);
							resetRelationshipSelection();
							messagesService.showSuccess(t('Relationships.SuccessTitle'), t('Relationships.SaveSuccessMessage'));
						} else {
							messagesService.showError(t('Relationships.ErrorTitle'), t('Relationships.SaveErrorMessage'));
						}
					},
					reject: () => {
						confirmResetRelationshipSelection(t('Relationships.ConfirmLostChangesHeader'), t('Relationships.ConfirmLostChanges'), e);
					},
				});
			}
		} else {
			resetRelationshipSelection();
        }
	}

	const loadCheckedItems = async (relationship) => {
		let theCheckedItems = await pricingService.getPricingItems({}, null, null, null, relationship.checkedItems);
		setOnlyChecked(true);
		setCheckedItems(theCheckedItems);
	}

	const confirmChangeRelationship = async (handler) => {
		confirmDialog({
			message: t('Relationships.ConfirmLostChanges'),
			header: t('Relationships.ConfirmLostChangesHeader'),
			acceptLabel: t('Relationships.ConfirmYes'),
			rejectLabel: t('Relationships.ConfirmNo'),
			className: 'confirm-panel',
			// icon: 'pi pi-exclamation-triangle',
			accept: async () => {
				await handler();
			}
		});
	}

	const selectRelationship = async (e) => {
		if (e.value > 0) {
			setRelationshipId(e.value);
			let theRel = relationships.find(r => r.id === e.value);
			setGroupName(theRel.label);
		}
	}

	const changeRelationshipModeConfirm = (e, mode) => {
		if (groupSelected) {
			confirmChangeRelationship(async () => {
				changeRelationshipMode(e, mode);
			});
		} else {
			changeRelationshipMode(e, mode);
        }
    }

	const changeRelationshipMode = (e, mode) => {
		var setVisible = false;

		setOnlyChecked(false);
		if (relationshipType !== -1) {
			setCheckedItems([]);
		}

		if (relationshipType === mode) {
			// Reset mode
			setRelationshipType(-1);
			setVisible = false;
		} else {
			setRelationshipType(mode);
			setVisible = true;
		}

		setRelationshipId(-1);
		setGroupSelected(false);
		setGroupName('');

		switch (mode) {
			case 0:
				setPriceLadderDialogVisible(false);
				setPriceAsOneDialogVisible(setVisible);
				break;
			case 1:
				setPriceAsOneDialogVisible(false);
				setPriceLadderDialogVisible(setVisible);
				break;
			default:
				// This should not happen
				setPriceLadderDialogVisible(false);
				setPriceAsOneDialogVisible(setVisible);
		}
	}

	const paginatorLeftAddon = (
		<>
			<div className="relationship-current-group" style={{ 'display': groupSelected ? "" : "none" }}>
				<div className="pricing-field grid config-subsection-wrapper">
					<div className="col-3 title pt-2 xxl-3">
						{(relationshipType === 0) &&
							(<>
								<i className="pi p-icn-relationship-current pi-price-as-one mr-1"></i>
								<span className="p-current-group-text">{t('Relationships.PriceAsOneOption')}</span>
							</>)
						}
						{(relationshipType === 1) && 
							(<>
								<i className="pi p-icn-relationship-current pi-ladder mr-1"></i>
								<span className="p-current-group-text">{ t('Relationships.PriceLadderOption') }</span>
							</>)
						}
					</div>
					<div className="col-7 field pt-2 xxl-7">
						<InputText id="newGroupName" value={groupName} className="inputfield textbox p-inputtext-readonly" readOnly={true} style={{ width: '100%' }}  />
					</div>
					<div className="col-2 pt-2 xxl-2">
						<button
							onClick={(e) => {
								save(e);
							}}
							type="button"
							className="btn btn-md btn-rounded-full btn-inline-block btn-sixth">
							{t('Relationships.CloseButton')}
						</button>
					</div>

					<div className="col-12 xxl-12 title flex justify-content-center">
						<label className="form-checkbox form-checkbox-tertiary form-checkbox-md text-sm text-primary p-checked-items-checkbox">
							<input type="checkbox"
								checked={onlyChecked}
								onChange={(e) => {
									setOnlyChecked(e.target.checked);
								}} />
							<span className="form-checkmark mr-2"></span>{t('Relationships.HideUncheckedElements')}</label>
					</div>
				</div>
			</div>
		</>
	);

	const hideRelationshipSelectionDialog = () => {
		setRelationshipType(-1);
		setPriceLadderDialogVisible(false);
		setPriceAsOneDialogVisible(false);
		resetRelationshipSelection();
	}

	const selectGroupAction = (e) => {
		let isNewGroup = (relationshipId < 0);

		if (isNewGroup) {
			if (groupName === '') {
				contextualConfirmDialog({
					message: t('Relationships.LabelRequiredMsg'),
					header: ' ',
					acceptLabel: t('Relationships.OkButton'),
					hideRejectButton: true,
					icon: 'pi pi-exclamation-triangle text-center m-auto block',	// Center icon because we don't have a header here
					accept: () => {
						// DO NOTHING
					},
					contextEvent: e
				});
			} else {
				var filtered = relationships.filter(element => {
					if (element.label === groupName) {
						return true;
					}

					return false;
				});

				var exists = filtered.length > 0;

				if (exists) {
					confirmDialog({
						message: t('Relationships.NewGroupAlreadyExistMsg', { GroupName: groupName }),
						header: t('Relationships.NewGroupDialogTitle'),
						acceptLabel: t('Relationships.OkButton'),
						rejectClassName: 'hidden',
						className: 'confirm-panel',
						// icon: 'pi pi-exclamation-triangle',
						accept: () => {
							// DO NOTHING
						}
					});
				} else {
					confirmDialog({
						message: t('Relationships.NewGroupDialogMsg', { GroupName: groupName }),
						header: t('Relationships.NewGroupDialogTitle'),
						acceptLabel: t('Relationships.OkButton'),
						rejectLabel: t('Relationships.CancelButton'),
						className: 'confirm-panel',
						// icon: 'pi pi-exclamation-triangle',
						accept: () => {
							setGroupSelected(true);
							setGroupSelected(groupName);
							setPriceAsOneDialogVisible(false);
							setPriceLadderDialogVisible(false);
						},
						reject: () => {
							hideRelationshipSelectionDialog();
						}
					});
				}
			}
		} else {
			setGroupSelected(true);
			setGroupSelected(groupName);
			setPriceAsOneDialogVisible(false);
			setPriceLadderDialogVisible(false);

			if (!isNewGroup) {
				// Load relationship items
				let theRel = relationships.find(r => r.id === relationshipId);
				loadCheckedItems(theRel);
			}
		}
	}

	const confirmDeleteGroup = () => {
		confirmDialog({
			message: t('Relationships.DeleteRelationshipMsg', { GroupName: groupName }),
			header: t('Relationships.DeleteRelationshipTitle'),
			acceptLabel: t('Relationships.OkButton'),
			rejectLabel: t('Relationships.CancelButton'),
			className: 'confirm-panel',
			// icon: 'pi pi-exclamation-triangle',
			accept: async () => {
				let res = await pricingService.deleteGroup(relationshipId);

				if (res) {
					messagesService.showSuccess(t('Relationships.SuccessTitle'), t('Relationships.DeleteRelationshipConfirmationMsg', { GroupName: groupName }));
					setRelationshipId(-1);
					setGroupName('');
					await loadRelationships();
				}
			},
			reject: () => {
				// DO NOTHING
			}
		});
	}

	const renderDialogFooter = () => {
		if (!groupSelected) {
			return (
				<div className="grid">
					<div className="col-3">
						<button
							onClick={(e) => {
								confirmDeleteGroup();
							}}
							type="button"
							disabled={relationshipId <= 0}
							className="btn btn-md btn-rounded-full btn-inline-block btn-secondary">
							{t('Relationships.DeleteButton')}
						</button>
					</div>
					<div className="col-3">
					</div>
					<div className="col-3">
						<button
							onClick={(e) => {
								hideRelationshipSelectionDialog();
							}}
							type="button"
							className="btn btn-md btn-rounded-full btn-inline-block btn-tertiary">
							{t('Relationships.CancelButton')}
						</button>
					</div>
					<div className="col-3">
						<button
							onClick={(e) => {
								selectGroupAction(e);
							}}
							type="button"
							className="btn btn-md btn-rounded-full btn-inline-block btn-sixth">
							{t('Relationships.OkButton')}
						</button>
					</div>
				</div>
			);
		} else {
			return (
				<div className="grid">
					<div className="col-3">
					</div>
					<div className="col-3">
					</div>
					<div className="col-3">
					</div>
					<div className="col-3">
					</div>
				</div>);
        }
	}

	const relationshipSelectionContent = (type) => {
		return (
			<div className="grid config-subsection-wrapper" style={{ 'display': groupSelected ? "none" : "" }}>
				<div className="col-12 field mb-0">
					<label htmlFor="newGroupName" className="text-primary">{t('Relationships.SelectExistingGroupLabel')}</label>
					<InputText id="newGroupName" autoComplete="off" value={groupName} className="inputfield textbox p-relationships-textbox" onChange={(e) => { setGroupName(e.target.value); setRelationshipId(-1); }} style={{ width: '100%' }} />
				</div>
				<div className="col-12">
					<ListBox value={relationshipId} options={relationships}
						onChange={(e) => selectRelationship(e)} optionValue="id" className="p-listbox-relationships" optionLabel="label" style={{ width: '100%', height: '15rem' }} />
				</div>
			</div>
		);
	}

	const handlePriceGroupFilterChange = (e, isClearFilter) => {
		if (isClearFilter) {
			let newFilters = [...filters];
			let priceGroupFilter = newFilters.find(item => item.field === 'priceGroupId');
			priceGroupFilter.filteredIds = [];

			setFilters(newFilters);
			setFilteredRelationships([]);
		} else {
			if (e.type === "checkbox") {
				let newFilters = [...filters];
				let newFilteredRelationships = [...filteredRelationships];
				let priceGroupFilter = newFilters.find(item => item.field === 'priceGroupId');

				priceGroupFilter.filteredIds = e.value.map(e => e.id);
				newFilteredRelationships = e.value;

				setFilters(newFilters);
				setFilteredRelationships(newFilteredRelationships);
			}
		}
	};

	useEffect(() => {
		if (filteredRelationships.length > 0) {
			let _lazyParams = { ...lazyParams }
			setLazyParams(_lazyParams);
		}
	}, [filteredRelationships]);	// eslint-disable-line react-hooks/exhaustive-deps

	const priceGroupsFilterTable = () => {
		return (<DataTable
			value={relationships}
			className="p-datatable-customers"
			columnResizeMode="fit"
			selectionMode="multiple"
			selection={filteredRelationships}
			onSelectionChange={e => {
				handlePriceGroupFilterChange(e)
			}}
			dataKey="id">
			<Column selectionMode="multiple" headerStyle={{ width: '3em' }}
			></Column>
			<Column field={"label"}></Column>
		</DataTable>);
	};

	if (lazyParams === null || filters === []) {
		return null;
	} else {
		return (
			<>
				<ConfirmDialog />
				<div className="grid">
					<div className="col-12 lg:col-3">
						<h3 className="text-primary">{companyName}</h3>
						<h1 className="text-primary">{t("Relationships.Header")}</h1>
						<h4 className="text-primary text-subtitle" style={{ display: (lastRepricing == null) ? "none" : "" }}>{t('PricingPage.LastRepricing', { date: lastRepricing })}</h4>

						<div className="filter-wrapper pricing-field mb-3">
							<h4 className="text-primary text-md mb-3">{t('Relationships.RelationshipToSpecifyTitle')}</h4>
							<div className='radio-relationship-wrapper'>
								<div className="radio-item radio-item-tertiary radio-item-md mr-2">
									<input id="priceAsOne" type="checkbox"
										onChange={(e) => changeRelationshipModeConfirm(e, 0)} checked={relationshipType === 0}
										className="p-radiobutton-secondary p-relationship-button-checkbox" name="relationshipType" />
									<label htmlFor="priceAsOne" className="text-sm btn-rounded-full">
										<i className="pi p-icn-relationship-button pi-price-as-one mr-1"></i>
										{t('Relationships.PriceAsOneOption')}
									</label>
								</div>
								{/*<div className="radio-item radio-item-tertiary radio-item-md mr-2">*/}
								{/*	<input id="priceLadder" type="checkbox"*/}
								{/*		onChange={(e) => changeRelationshipModeConfirm(e, 1)} checked={relationshipType === 1}*/}
								{/*		className="p-radiobutton-secondary p-relationship-button-checkbox" name="relationshipType" />*/}
								{/*	<label htmlFor="priceLadder" className="text-sm btn-rounded-full">*/}
								{/*		<i className="pi pi-ladder mr-1"></i>*/}
								{/*		{t('Relationships.PriceLadderOption')}*/}
								{/*	</label>*/}
								{/*</div>*/}
							</div>
						</div>

						<FilterPricing
							clearFiltersFlag={clearFiltersFlag}
		 					itemsFiltered={itemsFiltered}
							onFilter={onFilter}
							onSearched={onSearched}
							setFilters={setFilters}
							filters={filters}
							loadLazyFilters={loadLazyFilters}
						/>
					</div>
					<div className="col-12 lg:col-9 pl-lg-5">
						<div className="flex justify-content-end mb-4">
							&nbsp;
						</div>
						<DataTablePricing
							products={itemsSearched}
							setProducts={setItemsSearched}
							checkedItems={checkedItems}
							setCheckedItems={setCheckedItems}
							searched={search}
							sessionStorageSelectedRowsKey={relationshipsGridSessionStorageSelectedRowsKey}
							sessionStorageColumnsSelectedKey={relationshipsGridSessionStorageColumnsSelectedKey}
							dt={dt}
							selection={checkedItems}
							dtSelected={dtSelected}
							loading={loading}
							totalRecords={totalRecords}
							lazyParams={lazyParams}
							canEditPrices={false}
							onPage={onPage}
							onSort={onSort}
							rowsPerPage={rowsPerPage}
							setRowsPerPage={setRowsPerPage}
							currencySymbol={currencySymbol}
							allItemsActiveButton={true}
							currentMaxProfit={currentMaxProfit}
							showTiles={false}
							allowCalculatedColumns={false}
							checkFieldAvailable={isFieldAvailable}
							allowGuardrailsPopup={false}
							paginatorLeftAddon={paginatorLeftAddon}
							customColumns={relationshipExtraColumns}
						/>
					</div>
				</div>

				<Dialog header={t('Relationships.PriceAsOneOption')} visible={priceAsOneDialogVisible} modal={true} style={{ width: '500px' }} footer={renderDialogFooter()} onHide={(e) => changeRelationshipMode(e, 0)}>
					{ relationshipSelectionContent(0) }
				</Dialog>

				<Dialog header={t('Relationships.PriceLadderOption')} visible={priceLadderDialogVisible} modal={true} style={{ width: '500px' }} footer={renderDialogFooter()} onHide={(e) => changeRelationshipMode(e, 1)}>
					{relationshipSelectionContent(1)}
				</Dialog>

				<OverlayPanel
					ref={filterPriceGroupsOverlay}
					id="overlay_panel_price_groups"
					className="p-overlaypanel-wrapper p-overlaypanel-rounded-lg">
					<div style={{ textAlign: 'right' }}>
						<div
							className="p-mx-auto clear-filter"
							onClick={(e) => handlePriceGroupFilterChange(e, true)}
						>
							{t('PricingFilters.ClearFilter')}
						</div>
					</div>
					<div className="grid filter mt-2">
						<div className="datatable datatable-responsive filter" header="Responsive">
							{(relationships.length > 10) ?
								(<ScrollPanel className="scroll-panel-filters">
									priceGroupsFilterTable()
								</ScrollPanel>) :
									priceGroupsFilterTable()
							}

						</div>
					</div>
				</OverlayPanel>
			</>
		);
	}
};
export default withTranslation()(RelationshipsPage);
