import axios from 'axios'
import authService from '../../components/api-authorization/AuthorizeService';

export class ImportDataService {
	_subscription = null;
	_token = null;
	_subscribeTokenExpiration = null;

	constructor() {
		this._subscription = authService.subscribe(() => {
			this.authorize();
		});

		this._subscribeTokenExpiration = authService.subscribeTokenExpiration((newToken) => {
			if (newToken !== this._token) {
				// console.log('upgrading IMPORT token:', newToken);
				this._token = newToken;
			}
		});

		this.authorize();
	}

	authorize() {
		authService.isAuthenticated().then((isAuthenticated) => {
			// console.debug("PricingDataService, isAuthenticated: ", isAuthenticated);
			if (isAuthenticated) {
				authService.getAccessToken().then((token) => {
					this._token = token;
					// console.debug("PricingDataService, received token: ", token);
				});
			} else {
				this._token = null;
			}
		});

		// console.debug("PricingDataService: ", this._token);
	}

	async fileAnalyzingCompletition() {
		let res = null;

		if (this._token != null) {
			const config = {
				headers:
				{
					Authorization: `Bearer ${this._token}`,
					"X-XSRF-TOKEN": this.getAntiForgeryToken()
				}
			};

			try {
				let response = await axios.get('dataimport/importProgress', config);

				if (response.status === 200) {
					// console.debug("fileAnalyzingCompletition() OK");
					let progress = response.data;

					if (progress >= 0) {
						res = progress;
					} else {
						res = null;
					}
				} else {
					res = null
				}
			} catch (e) {				
				res = null;
			}
		}

		return res;
	}

	async dropTempFile(importFile, fileFormat) {
		if (importFile && importFile !== '') {
			if (this._token != null) {
				const config = {
					headers: {
						Authorization: `Bearer ${this._token}`,
						"X-XSRF-TOKEN": this.getAntiForgeryToken()
					}
				};

				// Build formData object.
				let formData = new FormData();
				formData.append('fileName', importFile);
				formData.append('fileFormat', fileFormat);

				let response = await axios.post('dataimport/dropTempFile', formData, config);

				if (response.status === 200) {
					// console.debug("dropTempFile() OK");
				} else {
					var error = response.statusText;
					console.error(error);
				}
			}
		}
	}

	async importFile(importData) {
		let res = null;

		if (this._token != null) {
			const config = {
				headers:
				{
					Authorization: `Bearer ${this._token}`,
					"X-XSRF-TOKEN": this.getAntiForgeryToken()
				}
			};

			try {
				let response = await axios.post('dataimport/doImport', importData, config);

				if (response.status === 200) {
					// console.debug("importFile() OK");
					res = null;
				} else {
					let error = response.data;
					console.error(error);
					res = error;
				}
			} catch (e) {
				let error = e.response.data;
				console.error(error);
				res = error;
			}
		}

		return res;
	}

	async getMappingItems(tableType) {
		let ret = [];
		if (this._token != null) {
			const config = {
				headers: { Authorization: `Bearer ${this._token}` }
			};

			let response = await axios.get('dataimport/mappings/?tableType=' + tableType, config);

			if (response.status === 200) {
				ret = response.data;
				// console.debug(`getMappingItems() OK, res=${ret}`);
			} else {
				var error = response.data;
				console.error(error);
			}
		}

		return ret;
	}

	async saveMappings(allMappings) {
		var changedMappings = allMappings.filter((e) => { return e.changed === true; });
		// console.log(changedMappings);

		let ret = null;
		if (this._token != null) {
			const config = {
				headers: { Authorization: `Bearer ${this._token}` }
			};

			let response = await axios.put('dataimport/mappings/', changedMappings, config);

			if (response.status === 200) {
				// console.debug(`getMappingItems() OK, res=${ret}`);
			} else {
				ret = response.data;
				console.error(ret);
			}
		}

		return ret;
	}

	getAntiForgeryToken() {
		const xsrfToken = document.cookie
			.split("; ")
			.find(row => row.startsWith("XSRF-TOKEN="))
			.split("=")[1];

		return xsrfToken;
	}

	static get instance() { return importService; }
}

const importService = new ImportDataService();

export default importService;