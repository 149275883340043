import React, { useState, useEffect, useRef } from 'react'
import { withTranslation } from 'react-i18next';
import ConfigActionBtn from '../Config/ConfigActionBtn'
import { confirmDialog } from 'primereact/confirmdialog';
import { Checkbox } from 'primereact/checkbox';
import { Calendar } from 'primereact/calendar';
import { DataTable } from 'primereact/datatable';
// import { Dropdown } from 'primereact/dropdown';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { ScrollPanel } from 'primereact/scrollpanel';
import { Column } from 'primereact/column';
// import configurationService from '../../Api/requests/config'
import pricingService from '../../Api/requests/pricing'
import messagesService from '../../Api/client-side/messages'
import moment from 'moment';
import { InputNumber } from 'primereact/inputnumber';

function Scheduler(props) {
	const { t } = props;
	const { setLoading } = props;
	const { allowed } = props;
	const { showHelp } = props;

	const [dataChanged, setDataChanged] = useState(false)
	const [tasks, setTasks] = useState([])
	const [originalRows, setOriginalRows] = useState([])
	const [editingRows, setEditingRows] = useState({})

	const minDate = new Date(new Date().getFullYear(), 0, 1);
	const maxDate = new Date(new Date().getFullYear(), 11, 31);

	const actualActiveIndex = useRef(-1);
	const [daysInterval, setDaysInterval] = useState(7)
	const [maxScheduledRecalculations, setMaxScheduledRecalculations] = useState(4)
	const [maxRecalculationsPerWeek, setMaxRecalculationsPerWeek] = useState(1)

	const reloadTable = async () => {
		setLoading(true);
		let theTasks = await pricingService.getSchedulerTasks();

		setTasks(theTasks);
		setDataChanged(false);
		setLoading(false);
	}

	const onEditorValueChange = (config, value) => {
		let updatedTasks = [...config.props.value]
		let item = updatedTasks[config.rowIndex]
		item[config.field] = value
		item['changed'] = true;
		config.rowData[config.field] = value;

		setTasks(updatedTasks)

		setDataChanged(true);
	}


	const onRowEditInit = (event) => {
		var theOriginalRows = originalRows;
		theOriginalRows[event.index] = { ...tasks[event.index] };
		setOriginalRows(theOriginalRows);
	}

	const onRowEditCancel = (event) =>  {
		let theTasks = [...tasks];
		theTasks[event.index] = originalRows[event.index];

		let theOriginalRows = [...originalRows];
		delete theOriginalRows[event.index];

		setOriginalRows(theOriginalRows);
		setTasks(theTasks);
	}

	const setActiveRowIndex = (index) => {
		let theOriginalRows = [...originalRows];
		theOriginalRows[index] = { ...tasks[index] };

		let theEditingRows = { ...editingRows, ...{ [`${tasks[index].jobGuid}`]: true } };

		setOriginalRows(theOriginalRows);
		setEditingRows(theEditingRows);
	}

	const runNow = async () => {
		if (checkMaxTasks()) {
			setLoading(true);
			var res = await pricingService.runPricingNow();
			setLoading(false);

			if (res === null) {
				messagesService.showInfo(t('Scheduler.RunNowTitle'), t('Scheduler.RunNowMessage'));
				await reloadTable();
			} else {
				if (res === "ImportInProgressError") {
					messagesService.showWarning("" /* intentional no title */, t('Scheduler.ImportInProgressError'));
				} else {
					messagesService.showError(t('Scheduler.RunNowTitle'), res);
				}
			}
		}
	}

	const checkMaxTasks = () => {
		if (tasks.length === maxScheduledRecalculations) {
			messagesService.showInfo(t('Scheduler.MaxScheduledRecalculationsTitle'), t('Scheduler.MaxScheduledRecalculationsMaxReachedMessage'));
			return false;
		} else {
			return true;
		}
	}

	const addTaskElement = (e) => {
		let theTasks = [...tasks];

		if (checkMaxTasks()) {
			// Start looking for a free space from 1-Jan
			var newDate = new Date();

			theTasks.push({
				description: 'New Task ' + tasks.length,
				date: moment(newDate).format(),
				daysInterval: daysInterval,
				jobGuid: 'null-' + tasks.length,
				recurrent: false,
				dirty: true
			});

			setTasks(theTasks);
			setDataChanged(true);
			actualActiveIndex.current = (theTasks.length - 1);
		}
	}

	useEffect(() => {

		// HACK to be sure the tasks are all loaded into the grid before to set the latest item when adding a new task
		if (actualActiveIndex.current >= 0) {
			setActiveRowIndex(tasks.length - 1);
			actualActiveIndex.current = -1;
		}
	}, [tasks]);  // eslint-disable-line react-hooks/exhaustive-deps

	const onRowEditChange = (event)  => {
		setEditingRows(event.data);
	}

/*	const confirmLostChanges = (callback) => {
		if (dataChanged) {
			confirmDialog({
				message: t('Scheduler.ConfirmRefresh'),
				header: t('Scheduler.ConfirmOverwriteHeader'),
				acceptLabel: t('Scheduler.ConfirmDialogYes'),
				rejectLabel: t('Scheduler.ConfirmDialogNo'),
				className: 'scheduler-confirm-panel',
				icon: 'pi pi-exclamation-triangle',
				accept: () => callback()
			});
		}
	}
*/

	const dateBody = (rowData, props) => {
		let date = moment(rowData['date']).format('DD-MMM HH:mm');
		return (<React.Fragment>
				{date}
			</React.Fragment>);
	}

	const recurrentColumnBody = (rowData, config) => {
		return (
			<Checkbox checked={rowData['recurrent']} readOnly disabled onChange={(e) => onEditorValueChange(config, e.checked)} />
		);
	}

	const recurrentColumnEditor = (config) => {
		return (
			<Checkbox checked={config.rowData['recurrent']} onChange={(e) => onEditorValueChange(config, e.checked)} />
		);
	}

	const dateEditor = (props) => {
		// Format javascript Date object from the value on the data row
		let date = moment(props.rowData['date']).toDate();

		return (
			<Calendar id="date" inputClassName="inputfield textbox" monthNavigator value={date} minDate={minDate} maxDate={maxDate} dateFormat="dd-MM"
				yearNavigator={false} showTime hourFormat="24" readOnlyInput
				onChange={(e) => {
					onSetDate(props, e.target.value);
				}
				} />
		);
	}

	const onSetDate = (props, value) => {
		// Override year (we want always actual year)
		let currentYear = new Date().getFullYear();
		value.setFullYear(currentYear);

		// Locate the previous entry in "disabled dates" because now it should be enabled (because it's not selected anymore)
		// let currentData = props.rowData['date'];

		// let dateKey = moment(currentData).toDate();

		// Date format to be saved to the array of objects
		let dateFormat = moment(value).format();
		onEditorValueChange(props, dateFormat);
	}

	const inputTextEditor = (props, field) => {
		return <InputText type="text" value={props.rowData[field]} className="inputfield textbox" onChange={(e) => onEditorValueChange(props, e.target.value)} />;
	}

	const inputNumberEditor = (props, field, min, max) => {
		return (<InputNumber value={props.rowData[field]} onChange={(e) => onEditorValueChange(props, e.value)} id={field} name={field}
			inputClassName="inputfield textbox" showButtons={true} required minFractionDigits={0} maxFractionDigits={0}
			mode="decimal" min={min} max={max} />);
	}

	const descriptionEditor = (props) => {
		return (
			<React.Fragment>
				{inputTextEditor(props, 'description')}
			</React.Fragment>
		);
	}

	const daysIntervalEditor = (props) => {
		return (
			<React.Fragment>
				{inputNumberEditor(props, 'daysInterval', 1, 30)}
			</React.Fragment>
		);
	}

	const daysIntervalBody = (rowData, props) => {
		// console.log(props);
		// console.log(rowData);
		return (
			<React.Fragment>
				{(rowData['recurrent'] === true) ? rowData['daysInterval'] : ''}
			</React.Fragment>
		);
	}

	const deleteTask = async (task) => {
		if (task['executing'] === true) {
			messagesService.showInfo(t('Scheduler.SchedulerInfoTitle'), t('Scheduler.SchedulerTaskIsBeingExecutedMessage'));
		} else {
			var doContinue = true;

			if (!task['jobGuid'].startsWith('null-')) {

				// Remove from server side
				let res = await pricingService.deleteSchedulerTask(task);

				if (res === null) {
					messagesService.showInfo(t('Scheduler.InfoTitle'), t('Scheduler.TaskDeletedMessage'));
				} else {
					console.log('acata', res);
					messagesService.showError(t('Scheduler.DeleteErrorTitle'), t('Scheduler.TaskDeletedErrorMessage'));
					doContinue = false;
				}
			}

			if (doContinue) {
				// Remove from client side
				var theTasks = [...tasks];
				let idx = theTasks.findIndex(i => i['jobGuid'] === task['jobGuid']);
				// let date = moment(theTasks[idx].date).toDate();
				theTasks.splice(idx, 1);

				setTasks(theTasks);
			}
		}
	}

	const confirmDeleteTask = (task) => {
		confirmDialog({
			message: t('Scheduler.ConfirmDelete'),
			header: t('Scheduler.ConfirmDeleteHeader'),
			acceptLabel: t('Scheduler.ConfirmDialogYes'),
			rejectLabel: t('Scheduler.ConfirmDialogNo'),
			className: 'scheduler-confirm-panel',
			icon: 'pi pi-exclamation-triangle',
			accept: () => deleteTask(task)
		});
	}

	const confirmSave = async () => {
		confirmDialog({
			message: t('Scheduler.ConfirmOverwriteMessage'),
			header: t('Scheduler.ConfirmOverwriteHeader'),
			acceptLabel: t('Scheduler.ConfirmDialogYes'),
			rejectLabel: t('Scheduler.ConfirmDialogNo'),
			icon: 'pi pi-exclamation-triangle',
			accept: async () => {

				let res = await pricingService.schedulePricing(tasks);

				if (res === null) {
					messagesService.showSuccess(t('Scheduler.SchedulerRefreshedTitle'), t('Scheduler.SchedulerRefreshedMessage'));
				} else {
					messagesService.showError(t('Scheduler.SchedulerRefreshedErrorTitle'), res);
				}
				await reloadTable();
			}
		});
	}

	const deleteBodyTemplate = (rowData) => {
		return (
			<React.Fragment>
				<Button icon="pi pi-trash" className="p-link p-row-editor-init" onClick={() => confirmDeleteTask(rowData)} />
			</React.Fragment>
		);
	}

	const confirmReset = () => {
		reloadTable();
	}

	useEffect(() => {
		reloadTable();

		async function getDefaults() {
			// Initialize default values
			let schdulingConfig = await pricingService.retrieveSchedulingConfiguration();
			setDaysInterval(schdulingConfig.defaultDaysBetweenRepricings);
			setMaxScheduledRecalculations(schdulingConfig.maxScheduledRecalculations);
			setMaxRecalculationsPerWeek(schdulingConfig.maxRecalculationsPerWeek);
		}

		getDefaults();

	}, []);  // eslint-disable-line react-hooks/exhaustive-deps

	if (allowed !== true) {
		return (<></>);
	} else {
		return (
			<>
				<h3 className="text-primary pt-3 pb-3 pl-3">
					{t('SchedulerDialog.Header')}
					<Button label="" title={t('Scheduler.HelpButton')} icon="pi pi-question-circle" className="p-button-config-help p-button-rounded p-button-info" onClick={() => showHelp('Scheduler.ImportHelpTitle', 'Scheduler.ImportHelpMessage')} />
				</h3>
				<div className="field grid mt-3 pl-3 pr-3">
					<div className="col-12 md:col-12 scrollpanel-container">
						<ScrollPanel style={{ width: '100%', height: '350px' }} className="datatable datatable-responsive">
							<DataTable value={tasks} dataKey="jobGuid"
								className="p-datatable-config"
								paginator={false}
								footer={false}
								editMode="row" editingRows={editingRows}
								onRowEditChange={onRowEditChange} onRowEditInit={onRowEditInit} onRowEditCancel={onRowEditCancel}
								columnResizeMode="fit">
								<Column field="description" header={t('Scheduler.DescriptionColumn')} editor={descriptionEditor} style={{ width: '9rem' }}></Column>
								<Column field="date" header={t('Scheduler.DateColumn')} body={dateBody} editor={dateEditor} style={{ width: '8rem' }}></Column>
								<Column field="recurrent" header={t('Scheduler.RecurrentColumn')} bodyClassName="center-column" headerClassName="center-column" body={recurrentColumnBody} editor={recurrentColumnEditor} style={{ width: '3rem' }}></Column>
								<Column field="daysInterval" header={t('Scheduler.DaysIntervalColumn')} editor={daysIntervalEditor} body={daysIntervalBody} style={{ width: '3rem' }}></Column>
								<Column rowEditor headerStyle={{ width: '6rem' }} bodyStyle={{ textAlign: 'center' }} ></Column>
								<Column body={deleteBodyTemplate} style={{ width: '1rem' }}></Column>
							</DataTable>
						</ScrollPanel>
					</div>
				</div>

				<div className="flex justify-content-end mr-3 bottom-buttons">
					<ConfigActionBtn onClick={(e) => runNow(e)} buttonText={t('Scheduler.RunButton')} />
					<ConfigActionBtn onClick={(e) => addTaskElement(e)} buttonText={t('Scheduler.AddTaskButton')} />
					<ConfigActionBtn onClick={(e) => confirmSave()} buttonText={t('ConfigurationDialog.SaveButton')} disabled={!dataChanged} />
					<ConfigActionBtn onClick={(e) => confirmReset()} buttonText={t('ConfigurationDialog.CancelButton')} disabled={!dataChanged} />
				</div>
			</>
		);
	}
}

export default withTranslation()(Scheduler)
