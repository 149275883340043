import axios from 'axios'
import authService from '../../components/api-authorization/AuthorizeService';

/**
 * Service to signal the subscribers when the unread user message list has been changed
 * */
export class RealTimeMessagesService {
	_callbacks = [];
	_nextSubscriptionId = 0;

	static REMOVED_MESSAGE = "removed";
	static NEW_MESSAGE = "new";
	static UPDATE_SIGNAL = "update";
	static REMOVE_ALL = "all_removed"

	_subscription = null;
	_subscribeTokenExpiration = null;
	_token = null;
	_eventSource = null;

	constructor() {
		this._subscription = authService.subscribe(() => {
			this.authorize();
		});

		this._subscribeTokenExpiration = authService.subscribeTokenExpiration((newToken) => {
			if (newToken !== this._token) {
				// console.log('upgrading REAL TIME MESSAGING token', newToken);
				this._token = newToken;

				// Call initialize again here, to close actual event source connection and create a new one with the new token
				this.initialize();
			}
		});

		this.authorize();
	}

	authorize() {
		authService.isAuthenticated().then((isAuthenticated) => {
			// console.debug("RealTimeMessagesService, isAuthenticated: ", isAuthenticated);
			if (isAuthenticated) {
				authService.getAccessToken().then((token) => {
					this._token = token;
					// console.debug("RealTimeMessagesService, received token: ", token);

					this.initialize();
				});
			} else {
				this._token = null;
			}
		});

		// console.debug("PricingDataService: ", this._token);
	}

	async initialize() {
		if (this._token != null) {
			if (this._eventSource) {
				this._eventSource.close();
				this._eventSource = null;
			}

			var messagesToken = null;
			const config = {
				headers: { Authorization: `Bearer ${this._token}` }
			};

			try {
				messagesToken = await axios.get('registry/userMessages/eventsToken', config);

				if (messagesToken && messagesToken.status === 200) {
					var sseToken = await messagesToken.data;

					this._eventSource = new EventSource("registry/usermessages/events?ssetoken=" + sseToken, { withCredentials: true });

					// Use this EventSource (old fashioned) instead of new EventSource, because newer doesn't supports headers
					// const options = { headers: { 'Authorization': `Bearer ${token}` } };
					// this.eventSource = new EventSourcePolyfill("registry/usermessages/events", options);

					// handle messages if not handled by the "addEventListener" (see below)
					this._eventSource.onmessage = function (event) {
						// Do something with the data:
						// console.log(event); // event.data;
					};

					this._eventSource.onopen = function (event) {
						// Happens when receiving the first message.
						// console.debug(event);
					};

					this._eventSource.onerror = function (e) {
						// Happens when server close or issues.
						if (e.readyState === EventSource.CLOSED) {
							// Connection was closed.
							console.log("MessagesService: connection closed");

							// Initialize listener again
							this.initialize();
						}
					}

					this._eventSource.addEventListener('toastmessage', (event) => {
						// console.log('toastmessage');
						this.notifyNewMessageToShow(JSON.parse(event.data));
					});

					this._eventSource.addEventListener('updatemessage', (event) => {
						// console.log('updatemessage');

						// this is saying us that there are new messages for this user, but they should not be shown as toast
						this.notifyMessagesUpdate();
					});
				}
			} catch (e) {
				console.error(e);
			}
		}
	}

	unInitialize() {
		authService.unsubscribe(this._subscription);
		if (this._eventSource) {
			this._eventSource.close();
		}
	}

	notifyAllMessagesRemoved() {
		this.notifySubscribers(RealTimeMessagesService.REMOVE_ALL, null);
	}

	notifyMessageRemoved() {
		this.notifySubscribers(RealTimeMessagesService.REMOVED_MESSAGE, null);
	}

	notifyNewMessageToShow(msg) {
		this.notifySubscribers(RealTimeMessagesService.NEW_MESSAGE, msg);
	}

	notifyMessagesUpdate() {
		this.notifySubscribers(RealTimeMessagesService.UPDATE_SIGNAL, null);
	}

	async getMessagesCount() {
		let ret = 0;

		if (this._token != null) {
			const config = {
				headers: { Authorization: `Bearer ${this._token}` }
			};

			let response = await axios.get('registry/userMessages/pending', config);

			if (response.status === 200) {
				ret = response.data;
				// console.debug(`getMessagesCount() OK, res=${ret}`);
			} else {
				var error = response.data;
				console.error(error);
			}
		}

		return ret;
	}

	async getNewMessages(from, pageSize) {
		let ret = [];

		if (this._token != null) {
			const config = {
				headers: { Authorization: `Bearer ${this._token}` }
			};

			let response = await axios.get('registry/userMessages/?from=' + from + '&count=' + pageSize, config);

			if (response.status === 200) {
				ret = response.data;
				// console.debug(`getMessagesCount() OK, res=${ret}`);
			} else {
				var error = response.data;
				console.error(error);
			}
		}

		return ret;
	}

	async deleteAllMessages() {
		let ret = [];

		if (this._token != null) {
			const config = {
				headers: {
					Authorization: `Bearer ${this._token}`,
					"X-XSRF-TOKEN": this.getAntiForgeryToken()
				}
			};

			let response = await axios.delete('registry/userMessages/', config);

			if (response.status === 200) {
				ret = response.data;

				this.notifyAllMessagesRemoved();
				// console.debug(`getMessagesCount() OK, res=${ret}`);
			} else {
				var error = response.data;
				console.error(error);
			}
		}

		return ret;
	}

	async deleteMessage(msgId) {
		let ret = [];

		if (this._token != null) {
			const config = {
				headers: {
					Authorization: `Bearer ${this._token}`,
					"X-XSRF-TOKEN": this.getAntiForgeryToken()
				}
			};

			let response = await axios.delete('registry/userMessages/' + msgId, config);

			if (response.status === 200) {
				ret = response.data;

				this.notifyMessageRemoved();
				// console.debug(`getMessagesCount() OK, res=${ret}`);
			} else {
				var error = response.data;
				console.error(error);
			}
		}

		return ret;
	}


	subscribe(callback) {
		this._callbacks.push({ callback, subscription: this._nextSubscriptionId++ });
		return this._nextSubscriptionId - 1;
	}

	unsubscribe(subscriptionId) {
		const subscriptionIndex = this._callbacks
			.map((element, index) => element.subscription === subscriptionId ? { found: true, index } : { found: false })
			.filter(element => element.found === true);
		if (subscriptionIndex.length !== 1) {
			throw new Error(`Found an invalid number of subscriptions ${subscriptionIndex.length}`);
		}

		this._callbacks.splice(subscriptionIndex[0].index, 1);
	}

	notifySubscribers(action, args) {
		for (let i = 0; i < this._callbacks.length; i++) {
			const callback = this._callbacks[i].callback;
			callback(action, args);
		}
	}

	getAntiForgeryToken() {
		const xsrfToken = document.cookie
			.split("; ")
			.find(row => row.startsWith("XSRF-TOKEN="))
			.split("=")[1];

		return xsrfToken;
	}

	static get instance() { return realTimeMessagingServices; }
}

/**
 * Expose user messages service
 * */
const realTimeMessagingServices = new RealTimeMessagesService();

export default realTimeMessagingServices;