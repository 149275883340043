import React from 'react'

function UserAgreementPage(props) {
	return (
		<div className="grid p-legal-content">
			<div className="col-12" style={{ "whiteSpace": "pre-line"}}>
				<h2 className="text-primary">Pricimetrics SAAS User Agreement - Version 1.0 (updated 07/12/2022)</h2>
				<h3>IMPORTANT NOTICE: DISPUTES ABOUT THIS AGREEMENT AND THE SERVICES PROVIDED BY PRICIMETRICS ARE SUBJECT TO BINDING ARBITRATION AND A WAIVER OF CLASS ACTION RIGHTS AS DETAILED IN THE &#8220;MANDATORY ARBITRATION AND CLASS ACTION WAIVER&#8221; SECTION BELOW.</h3>
				<p>
					This Agreement (the &#8220;Agreement&#8221;) is between Pricimetrics Inc. (&#8220;Pricimetrics&#8221;) and the individual accepting these terms (&#8220;User&#8221;). This Agreement controls User's access to Pricimetrics' services and its associated or affiliated products, applications, portals, reporting, and websites, etc. (the &#8220;Service&#8221;).
				</p>
				<p>
					By clicking &#8220;I Agree,&#8221; or by accessing or using the Service and its associated or affiliated, products, applications, portals, reporting, and websites, etc., as User, you agree that in consideration of the mutual covenants, terms, and conditions set forth herein, and for other good and valuable consideration, the receipt and sufficiency of which are hereby acknowledged, you have read, understand, and agree to accept and be bound by the terms of this Pricimetrics SaaS Subscriber Terms, Pricimetrics SaaS End User Terms, Pricimetrics Website Terms of Use, and Pricimetrics Website Privacy Policy, (together the &#8220;User Terms of Use&#8221; or &#8220;Terms&#8221; or &#8220;Agreement&#8221;) current copies of which can be found at www.Pricimetrics.com and www.PricimetricsMax.com and that the foregoing creates a legal agreement between you and Pricimetrics.
				</p>
				<p>
					To use the Services, you must be, and represent and warrant, that you are competent to agree to these User Terms of Use. If you are agreeing to these User Terms of Use on behalf of an organization or entity, you represent and warrant that you are authorized to agree to these User Terms of Use on that organization or entity's behalf and bind them to these User Terms of Use (in which case, the references to &#8220;you&#8221; and &#8220;your&#8221; in these User Terms of Use, except for in this sentence, refer to that organization or entity). If Pricimetrics has previously prohibited you from accessing or using the Services, you are not permitted to access or use the Services.
				</p>

				<h3>IF USERS REGISTER FOR A FREE TRIAL OR ARE OTHERWISE GIVEN EVALUATION ACCESS OF PRICIMETRICS' SERVICES, THIS AGREEMENT WILL ALSO APPLY.</h3>

				<h3><u>Beta Testing.</u> From time to time, beta or trial versions of Services or a portion thereof may be made available (&#8220;Beta&#8221;).  If and the extent such Beta offerings are provided, they will be marked as &#8220;Beta&#8221; and will be provided without warranty of any kind to Customer and User and on an AS IS basis.  Use of Beta offerings are entirely voluntarily, and unless otherwise specified in writing, are free of charge.  Pricimetrics provides Beta elements to variously assist Pricimetrics with testing functionality, determining their utility, and gathering general feedback on certain features of the Services.   However, by their nature User understands that Beta offerings are highly likely to contain defects, and that should Customer or User elect to work with Beta offerings, that they may encounter performance problems and/or loss of functionality.   Pricimetrics may discontinue Beta offerings at any time in our sole discretion.  We will have no liability for any harm or damage arising out of or in connection with use of a Beta offering.</h3>

				<h3>If you do not agree to these User Terms of Use, then you have no right to access or use the Services.  In the event of a conflict between the terms of this Pricimetrics SaaS Subscriber Terms, Pricimetrics SaaS End User Terms, and the Website Terms of Use, or the Privacy Policy, the terms of this Pricimetrics SaaS Subscriber Terms shall govern and prevail.</h3>

				<p>Organizations or other third parties that have paid for a subscription to Pricimetrics for use by their team (&#8220;Customer&#8221;) must separately submit an Order Form and accept the terms of PRICIMETRICS SAAS Subscriber Agreement and Pricimetrics' Website Terms of Use, and Pricimetrics' Privacy Policy (together the &#8220;Subscriber Agreement&#8221;). That Subscriber Agreement permits Customers to create and configure teams and invite other users of the Service under Customer's account (&#8220;Users&#8221;) to join. If you have been invited to a team created by a Customer, you acknowledge and agree that the Customer owns all content that you submit or upload to the Services and controls your use of the Services in accordance with applicable local laws, including but not limited to adding or removing you from a team, enabling or disabling third-party integrations, managing permissions, and accessing, modifying, or removing content that you submit or upload to the Services.</p>

				<p>Pricimetrics may, from time to time, modify these User Terms of Use. Please check this page periodically for updates. If you do not agree to, or cannot comply with, the modified User Terms of Use, you must stop using the Services. The updated User Terms of Use will take effect upon their posting and will apply on a go-forward basis, unless otherwise provided in a notice to you, and except as provided in the Mandatory Arbitration and Class Action Waiver section of these User Terms of Use. Your continued use of the Services after any such update constitutes your binding acceptance of such changes.</p>

				<p>This Agreement commences on the date User first accepts it (&#8220;Effective Date&#8221;) and, unless terminated earlier pursuant any of the Agreement's express provisions, will continue on an annual basis in effect as stated in the relevant Order Form (the &#8220;Term&#8221;) unless earlier terminated pursuant to this Agreement's express provisions or either party gives the other party thirty (30) days written notice of non-renewal in keeping with the terms of this Agreement.</p>

				<h3>1. <u>Definitions.</u></h3>

				<ul className="list-none-with-padding">
						<li><b>&#8220;Access Credentials&#8221;</b> means any username, identification number, password, license or security key, security token, PIN, or other security code, method, technology, or device used, alone or in combination, to verify an individual's identity and authorization to access and use the Services.</li>
						<li><b>&#8220;Action&#8221;</b> means any claim, action, cause of action, demand, lawsuit, arbitration, inquiry, audit, notice of violation, proceeding, litigation, citation, summons, subpoena, or investigation of any nature, civil, criminal, administrative, regulatory, or other, whether at law, in equity, or otherwise.</li>
						<li><b>&#8220;Affiliate&#8221;</b> of a Person means any other Person that directly or indirectly, through one or more intermediaries, controls, is controlled by, or is under common control with, such Person.</li>
						<li><b>&#8220;Customer Data&#8221;</b> means information, data, and other content, in any form or medium, that is collected, downloaded, uploaded, or otherwise received, directly or indirectly from User by or through the Services and/or that incorporates or is derived from the Processing of such information, data, or content by or through the Services and reports generated for Customer. For the avoidance of doubt, Customer Data does not include Resultant Data or any other information reflecting the access or use of the Services by or on behalf of any User.</li>
						<li><b>&#8220;Customer Systems&#8221;</b> means the User's information technology infrastructure, including computers, software, hardware, databases, electronic systems (including database management systems), and networks, whether operated directly by User or through the use of third-party services.</li>
						<li><b>&#8220;Documentation&#8221;</b> means any manuals, instructions, or other documents or materials that the Pricimetrics provides or makes available to User in any form or medium and which describe the functionality, components, features, or requirements of the Services or Pricimetrics Materials, including any aspect of the installation, configuration, integration, operation, use, support, or maintenance thereof.</li>
						<li><b>&#8220;Harmful Code&#8221;</b> means any software, hardware, or other technology, device, or means, including any virus, worm, malware, or other malicious computer code, the purpose or effect of which is to (a) permit unauthorized access to, or to destroy, disrupt, disable, distort, or otherwise harm or impede in any manner any (i) computer, software, firmware, hardware, system, or network; or (ii) any application or function of any of the foregoing or the security, integrity, confidentiality, or use of any data Processed thereby; or (b) prevent Customer or any User from accessing or using the Services or Pricimetrics Systems as intended by this Agreement. Harmful Code does not include any Pricimetrics Disabling Device.</li>
						<li><b>&#8220;Intellectual Property Rights&#8221;</b> means any and all registered and unregistered rights granted, applied for, or otherwise now or hereafter in existence under or related to any patent, copyright, trademark, trade secret, database protection, or other intellectual property rights laws, and all similar or equivalent rights or forms of protection, in any part of the world.</li>
						<li><b>&#8220;Law&#8221;</b> means any statute, law, ordinance, regulation, rule, code, order, constitution, treaty, common law, judgment, decree, or other requirement of any federal, state, local, or foreign government or political subdivision thereof, or any arbitrator, court, or tribunal of competent jurisdiction.</li >
						<li><b>&#8220;Losses&#8221;</b> means any and all losses, damages, deficiencies, claims, actions, judgments, settlements, interest, awards, penalties, fines, costs, or expenses of whatever kind, including reasonable attorneys' fees and the costs of enforcing any right to indemnification hereunder and the cost of pursuing any insurance providers.</li>
						<li><b>&#8220;Permitted Use&#8221;</b> means any use of the Services by a User for the benefit of User in the ordinary course of its internal business operations.</li >
						<li><b>&#8220;Person&#8221;</b> means an individual, corporation, partnership, joint venture, limited liability entity, governmental authority, unincorporated organization, trust, association, or other entity.</li >
						<li><b>&#8220;Personal Information&#8221;</b> means information that User provides or for which User provides access to Pricimetrics, or information which Pricimetrics creates or obtains on behalf of User, in accordance with this Agreement that: (i) directly or indirectly identifies an individual(including, for example, names, signatures, addresses, telephone numbers, email addresses, and other unique identifiers); or(ii) can be used to authenticate an individual(including, without limitation, employee identification numbers, government - issued identification numbers, passwords or PINs, user identification and account access credentials or passwords, financial account numbers, credit report information, student information, answers to security questions, and other personal identifiers).User's business contact information is not by itself Personal Information.</li>
						<li><b>&#8220;Process&#8221;</b> means to take any action or perform any operation or set of operations that the SaaS Services are capable of taking or performing on any data, information, or other content, including to collect, receive, input, upload, download, record, reproduce, store, organize, compile, combine, log, catalog, cross - reference, manage, maintain, copy, adapt, alter, translate, or make other derivative works or improvements, process, retrieve, output, consult, use, perform, display, disseminate, transmit, submit, post, transfer, disclose, or otherwise provide or make available, or block, erase, or destroy. &#8220;Processing&#8221; and &#8220;Processed&#8221; have correlative meanings.</li >
						<li><b>&#8220;Pricimetrics Disabling Device&#8221;</b> means any software, hardware, or other technology, device, or means(including any back door, time bomb, time out, drop dead device, software routine, or other disabling device) used by Pricimetrics or its designee to disable Customer's or any User's access to or use of the Services automatically with the passage of time or under the positive control of Pricimetrics or its designee.</li >
						<li><b>&#8220;Pricimetrics Materials&#8221;</b> means the Services, specifications, Documentation, and Pricimetrics Systems and any and all other information, data, documents, materials, works, and other content, devices, methods, processes, hardware, software, and other technologies and inventions, including any deliverables, technical or functional descriptions, requirements, or plans, that are provided or used by Pricimetrics or any Subcontractor in connection with the Services or otherwise comprise or relate to the Services or Pricimetrics Systems.For the avoidance of doubt, Pricimetrics Materials include Resultant Data and any information, data, or other content derived from Pricimetrics' monitoring of User's access to or use of the Services, but do not include User Data.</li >
						<li><b>&#8220;Pricimetrics Personnel&#8221;</b> means all individuals involved in the performance of Services as employees, agents, or independent contractors of Pricimetrics or any Subcontractor.</li >
						<li><b>&#8220;Pricimetrics Systems&#8221;</b> means the information technology infrastructure used by or on behalf of Pricimetrics in performing the Services, including all computers, software, hardware, databases, electronic systems(including database management systems), and networks, whether operated directly by Pricimetrics or through the use of third - party services.</li >
						<li><b>&#8220;Representatives&#8221;</b> means, with respect to a party, that party's and its Affiliates' employees, officers, directors, consultants, agents, independent contractors, service providers, sublicensees, subcontractors, and legal advisors.</li >
						<li><b>&#8220;Resultant Data&#8221;</b> means data and information related to User's use of the Services that is used by Pricimetrics in an aggregate and anonymized manner, including to compile statistical and performance information related to the provision and operation of the Services.</li>
						<li><b>&#8220;Third-Party Materials&#8221;</b> means materials and information, in any form or medium, including any open - source or other software, documents, data, content, specifications, products, equipment, or components of or relating to the Services that are not proprietary to Pricimetrics.</li >
						<li><b>&#8220;Specifications&#8221;</b> means the specifications for the Services set forth in the Documentation and the Order Form.</li >
						<li><b>&#8220;User&#8221;</b> or &#8220;User&#8221; means Customer's employees, consultants, contractors, and agents (a) who are authorized by Customer to access and use the Services under the rights granted to Customer pursuant to this Agreement; and (b) for whom access to the Services has been purchased hereunder.</li>
						<li><b>&#8220;User Data&#8221;</b> means information, data, and other content, in any form or medium, that is collected, downloaded, uploaded, or otherwise received, directly or indirectly from User by or through the Services and/or that incorporates or is derived from the Processing of such information, data, or content by or through the Services. For the avoidance of doubt, User Data does not include Resultant Data or any other information reflecting the access or use of the Services by or on behalf of any User.</li>
						<li><b>&#8220;User Systems&#8221;</b> means the User's information technology infrastructure, including computers, software, hardware, databases, electronic systems (including database management systems), and networks, whether operated directly by User or through the use of third-party services.</li>
				</ul>

				<h3>2. Services.</h3>
				<ul className="list-none-with-padding">
					<li><b>2.1 <u>Administration of User's Account.</u></b> User must manage its account. User's can access, monitor, use, export and disclose all content posted by Users in accordance with applicable local laws. User is responsible for: (i) maintaining the confidentiality of passwords and Administrator accounts; (ii) managing access to User's account; and (iii) ensuring that User's use of the Service complies with this Agreement. Pricimetrics shall not be held liable for any actions on the part of User.</li>
					<li><b>2.2	<u>User Conduct; Compliance.</u></b> User is responsible for use of the Service and for compliance with Pricimetrics' User Terms of Use. User is also responsible for providing any notice and obtaining any consents and authorizations necessary: (i) to allow the User to access, monitor, use, and disclose the content posted by the User on the Service. The Service is not authorized for use by persons under the age of 18 and User will not use the Service is User is under 18 years of age.<br />
						User will promptly notify Pricimetrics if it becomes aware of any unauthorized access to User's account or the Service. User is also responsible and liable for ensuring that it: obtains the relevant permissions required by law to text or email anyone via the Service; does not use misleading header information; clearly and conspicuously identify texts and emails as an advertisement if such is the case; includes a valid postal address for their business in their text and email campaigns; include a legally compliant method for recipients to opt-out of receiving future texts and emails; honor any such opt-request promptly and efficiently but no later than 10 business days within receipt of such (or earlier if required by law); remain fully liable for the texts and email campaigns even if they are sending it out on behalf of someone else.
					</li>
					<li><b>2.3 <u>Access and Use.</u></b> Subject to and conditioned on User's ongoing compliance with the terms and conditions of this Agreement, Pricimetrics hereby grants User a non-exclusive, non-transferable (except in compliance with Section 15.9) right to access and use the Services during the term, solely for use by Users in accordance with the terms and conditions herein. Such use is limited to User's internal use. The term of this Agreement shall begin on, the earlier of, the date User's Order Form is submitted to Pricimetrics or the date User accesses the Service, and end upon termination pursuant to this Agreement.</li>
					<li><b>2.4 <u>Documentation License.</u></b> Pricimetrics hereby grants to User a non-exclusive, non-sublicensable, non-transferable (except in compliance with Section 15.9) license to use the Documentation during the Term solely for User's internal business purposes in connection with its use of the Services.</li>
					<li><b>2.5 <u>Service and System Control.</u></b> Except as otherwise expressly provided in this Agreement, as between the parties:
						<ul className="list-none">
							<li>(a)	Pricimetrics has and will retain sole control over the operation, provision, maintenance, and management of the Pricimetrics Materials; and</li>
							<li>(b)	User has and will retain sole control over the operation, maintenance, and management of, and all access to and use of, the User Systems, and sole responsibility for all access to and use of the Pricimetrics Materials by any Person by or through the User Systems or any other means controlled by User, including any: (i) information, instructions, or materials provided by any of them to the Services or Pricimetrics; (ii) results obtained from any use of the Services or Pricimetrics Materials; and (iii) conclusions, decisions, or actions based on such use.</li>
						</ul>
					</li>
					<li><b>2.6 <u>Reservation of Rights.</u></b> Nothing in this Agreement grants any right, title, or interest in or to (including any license under) any Intellectual Property Rights in or relating to, the Services, Pricimetrics Materials, or Third-Party Materials, whether expressly, by implication, estoppel, or otherwise. All right, title, and interest in and to the Services, the Pricimetrics Materials, and the Third-Party Materials are and will remain with Pricimetrics and the respective rights holders in the Third-Party Materials.</li>
					<li><b>2.7 <u>Changes.</u></b> Pricimetrics reserves the right, in its sole discretion, to make any changes to the Services and Pricimetrics Materials that it deems necessary or useful to: (a) maintain or enhance: (i) the quality or delivery of Pricimetrics' services to its Users; (ii) the competitive strength of or market for Pricimetrics' services; or (iii) the Services' cost efficiency or performance; or (b) to comply with applicable Law.</li>
					<li><b>2.8 <u>Subcontractors.</u></b> Unless otherwise specifically agreed to in signed writing, Pricimetrics may from time to time in its discretion engage third parties to perform Services (each, a &#8220;<b>Subcontractor</b>&#8221;).</li>
					<li><b>2.9 <u>Suspension or Termination of Services.</u></b> Pricimetrics may, directly or indirectly, and by use of a Pricimetrics Disabling Device or any other lawful means, suspend, terminate, or otherwise deny User's, or any other Person's access to or use of all or any part of the Services or Pricimetrics Materials, without incurring any resulting obligation or liability, if: (a) Pricimetrics receives a judicial order, subpoena, or law enforcement request that expressly or by reasonable implication requires Pricimetrics to do so; or (b) Pricimetrics believes, in its good faith and sole discretion, that: (i) User has failed to comply with any material term of this Agreement, or accessed or used the Services beyond the scope of the rights granted or for a purpose not authorized under this Agreement or in any manner that does not comply with any material instruction or requirement of the specifications; (ii) User is, has been, or is likely to be involved in any fraudulent, misleading, or unlawful activities; or (iii) this Agreement expires or is terminated. This Section 2.9 does not limit any of Pricimetrics' other rights or remedies, whether at law, in equity, or under this Agreement.</li>
				</ul>
						

				<h3>3. <u>Use Restrictions; Service Usage and Data Storage.</u></h3>
				<ul className="list-none-with-padding">
					<li><b>3.1 <u>Use Restrictions.</u></b> User shall not, and shall not permit any other Person to, access or use the Services or Pricimetrics Materials except as expressly permitted by this Agreement and, in the case of Third-Party Materials, the applicable third-party license agreement. For purposes of clarity and without limiting the generality of the foregoing, User shall not, except as this Agreement expressly permits:
						<ul className="list-none">
							<li>(a)	copy, modify, or create derivative works or improvements of the Services or Pricimetrics Materials;</li>
							<li>(b)	rent, lease, lend, sell, sublicense, assign, distribute, publish, transfer, or otherwise make available any Services or Pricimetrics Materials to any Person, including on or in connection with the internet or any time-sharing, service bureau, software as a service, cloud, or other technology or service;</li>
							<li>(c)	reverse engineer, disassemble, decompile, decode, adapt, or otherwise attempt to derive or gain access to the source code of the Services or Pricimetrics Materials, in whole or in part;</li>
							<li>(d)	bypass or breach any security device or protection used by the Services or Pricimetrics Materials or access or use the Services or Pricimetrics Materials other than by a User through the use of his or her own then valid Access Credentials;</li>
							<li>(e)	input, upload, transmit, or otherwise provide to or through the Services or Pricimetrics Systems, any information or materials that are unlawful or injurious, or contain, transmit, or activate any Harmful Code;</li>
							<li>(f)	damage, destroy, disrupt, disable, impair, interfere with, or otherwise impede or harm in any manner the Services, Pricimetrics Systems, or Pricimetrics' provision of services to any third party, in whole or in part;</li>
							<li>(g)	remove, delete, alter, or obscure any trademarks, specifications, Documentation, warranties, or disclaimers, or any copyright, trademark, patent, or other intellectual property or proprietary rights notices from any Services or Pricimetrics Materials, including any copy thereof;</li>
							<li>(h)	access or use the Services or Pricimetrics Materials in any manner or for any purpose that infringes, misappropriates, or otherwise violates any Intellectual Property Right or other right of any third party (including by any unauthorized access to, misappropriation, use, alteration, destruction, or disclosure of the data of any other Pricimetrics User), or that violates any applicable Law;</li>
							<li>(i)	access or use the Services or Pricimetrics Materials for purposes of competitive analysis of the Services or Pricimetrics Materials, the development, provision, or use of a competing software service or product or any other purpose that is to the Pricimetrics' detriment or commercial disadvantage;</li>
							<li>(j)	access or use the Services or Pricimetrics Materials in, or in association with, the design, construction, maintenance, or operation of any hazardous environments, systems, or applications, any safety response systems or other safety-critical applications, or any other use or application in which the use or failure of the Services could lead to personal injury or severe physical or property damage; or</li>
							<li>(k)	otherwise access or use the Services or Pricimetrics Materials beyond the scope of the authorization granted under this Section 3.1.</li>
							<li>(l)	share usernames and passwords to others.</li>
						</ul>
					</li>
					<li>
						<b>3.2 <u>Service Usage.</u></b> The <b>Order Form</b> sets forth relevant subscription and other fees in effect as of the Effective Date.
					</li>
				</ul>

				<h3>4. <u>User Obligations.</u></h3>
				<ul className="list-none-with-padding">
					<li><b>4.1 <u>Systems and Cooperation.</u></b> User shall at all times during the Term: (a) set up, maintain, and operate in good repair and in accordance with the specifications all User Systems on or through which the Services are accessed or used; (b) provide Pricimetrics Personnel with such access to User's Data and User Systems as is necessary for Pricimetrics to implement and perform the Services in accordance with the Services to be provided and the selections on the Order Form; and (c) provide all cooperation and assistance as Pricimetrics may reasonably request to enable Pricimetrics to exercise its rights and perform its obligations (including but not limited to, providing Pricimetrics access to User dashboards) under and in connection with this Agreement.</li>
					<li><b>4.2 <u>Effect of User Failure or Delay.</u></b> Pricimetrics is not responsible or liable for any delay or failure of performance caused in whole or in part by User's delay in performing, or failure to perform, any of its obligations under this Agreement (each, a &#8220;<b>User Failure</b>&#8221;).</li>
					<li><b>4.3 <u>Corrective Action and Notice.</u></b> If User becomes aware of any actual or threatened activity prohibited by Section 3.1, User shall immediately: (a) take all reasonable and lawful measures within their respective control that are necessary to stop the activity or threatened activity and to mitigate its effects (including, where applicable, by discontinuing and preventing any unauthorized access to the Services and Pricimetrics Materials and permanently erasing from their systems and destroying any data to which any of them have gained unauthorized access); and (b) immediately notify Pricimetrics of any such actual or threatened activity.</li>
				</ul>

				<h3>5. Service Levels.</h3>
				<ul className="list-none-with-padding">
					<li><b>5.1 <u>Service Levels.</u></b> Subject to the terms and conditions of this Agreement and the service level commitments of any third party service providers or vendors that are utilized by Pricimetrics, Pricimetrics will use commercially reasonable efforts to make the Services Available during each calendar month during the Term (each such calendar month, a &#8220;<b>Service Period</b>&#8221;), excluding unavailability as a result of any of the Exceptions described below in this Section 5.1 (the &#8220;<b>Availability Requirement</b>&#8221;). &#8220;<b>Service Level Failure</b>&#8221; means a material failure of the Services to meet the Availability Requirement. &#8220;<b>Available</b>&#8221; means the Services are available for access and use by User over the Internet and operating in material accordance with the Specifications. For purposes of calculating the Availability Requirement, the following are &#8220;<b>Exceptions</b>&#8221; to the Availability Requirement, and neither the Services will be considered un-Available nor any Service Level Failure be deemed to occur in connection with any failure to meet the Availability Requirement or impaired ability of Users to access or use the Services that is due, in whole or in part, to any: (a) access to or use of the Services by User's Access Credentials, that does not strictly comply with this Agreement and the specifications; (b) User Failure; (c) User's Internet connectivity; (d) Force Majeure Event or the failure of the Service is due to the acts of third (3) parties; (e) failure, interruption, outage, or other problem with any software, hardware, system, network, facility, or other matter not supplied by Pricimetrics pursuant to this Agreement; (f) Scheduled Downtime or when User is provided with advance notice by Pricimetrics; or (g) disabling, suspension, or termination of the Services pursuant to Section 2.9.</li>
					<li><b>5.2 <u>Service Level Failures and Remedies.</u></b> In the event of a Service Level Failure, User must notify Pricimetrics of the occurrence of such via email at support@PricimetricsMax.com within five (5) days of the occurrence of such.</li>
					<li><b>5.3 <u>Scheduled Downtime.</u></b> Pricimetrics explicitly reserves the sole right to: (a) schedule downtime for routine maintenance of the Services in a standard maintenance window between the hours of 12 a.m. and 6 a.m., Eastern Standard Time (Tuesdays and Sundays); (b) provide emergency fixes, as defined and deemed necessary in Pricimetrics' sole discretion; and (c) give at least 48 hours prior notice of all other scheduled outages of the Services (&#8220;<b>Scheduled Downtime</b>&#8221;) outside of the standard maintenance window.</li>
					<li><b>5.4 <u>Service Support.</u></b> The Services include Pricimetrics' standard User support services (&#8220;<b>Support Services</b>&#8221;) at the support levels in accordance with the Pricimetrics service support schedule then in effect. Pricimetrics may amend the Support Services from time to time in its sole discretion. Customer may purchase enhanced support services separately at Pricimetrics' then current rates.</li>
				</ul>

				<h3>6. <u>Data Backup.</u></h3>
				<p>
					The Pricimetrics Systems are programmed to perform routine data backups as set out in Pricimetrics' backup policy in effect from time to time. In the event of any loss, destruction, damage, or corruption of User Data caused by the Pricimetrics Systems or Services, Pricimetrics will, as its sole obligation and liability and as User's sole remedy, use commercially reasonable efforts to restore the User Data from Pricimetrics' then most current backup of such User Data in accordance with the then current backup policy.
				</p>

				<h3>7. <u>Security.</u></h3>
				<ul className="list-none-with-padding">
					<li><b>7.1 <u>Pricimetrics Systems, Accessibility, and Security Obligations.</u></b> Pricimetrics will employ measures in accordance with Pricimetrics' data privacy, accessibility, and security policy as may be amended from time to time.</li>
					<li><b>7.2 <u>Data Breach Procedures.</u></b> Pricimetrics maintains a data breach plan and shall implement the procedures required under such data breach plan on the occurrence of a data breach.</li>
					<li><b>7.3 <u>Prohibited Data.</u></b> User acknowledges that the Services are not designed with security and access management for Processing the following categories of information: (a) data that is classified and or used on the U.S. Munitions list, including software and technical data; (b) articles, services, and related technical data designated as defense articles or defense services; and (c) ITAR (International Traffic in Arms Regulations) related data, (each of the foregoing, &#8220;<b>Prohibited Data</b>&#8221;). User shall not, and shall not permit, any other Person to, provide any Prohibited Data to, or Process any Prohibited Data through, the Services, the Pricimetrics Systems, or any Pricimetrics Personnel. User is solely responsible for reviewing all User Data and shall ensure that no User Data constitutes or contains any Prohibited Data.</li>
					<li><b>7.4 <u>User Control and Responsibility.</u></b> User has and will retain sole responsibility for: (a) all User Data, including its content and use; (b) all information, instructions, and materials provided by or on behalf of User or any User in connection with the Services; (c) User's information technology infrastructure, including computers, software, databases, electronic systems (including database management systems), and networks, whether operated directly by User or through the use of third-party services (&#8220;<b>User Systems</b>&#8221;); (d) the security and use of User's Access Credentials; and (e) all access to and use of the Services and Pricimetrics Materials directly or indirectly by or through the User Systems or its or its Users' Access Credentials, with or without User's knowledge or consent, including all results obtained from, and all conclusions, decisions, and actions based on, such access or use.</li>
					<li><b>7.5<u>Access and Security.</u></b> User shall employ all physical, administrative, and technical controls, screening, and security procedures and other safeguards necessary to: (a) securely administer the distribution and use of all Access Credentials and protect against any unauthorized access to or use of the Services; and (b) control the content and use of User Data, including the uploading or other provision of User Data for Processing by the Services.</li>
				</ul>

				<h3>8. <u>Fees and Payment.</u></h3>
				<ul className="list-none-with-padding">
					<li><b>8.1 <u>Fees.</u></b> Customer shall pay Pricimetrics the fees, including those for its Users, as set forth in the relevant <b>Order Form</b>.</li>
					<li><b>8.2 <u>Late Payment.</u></b> Pricimetrics may suspend performance of the Services until all past due amounts and interest thereon have been paid, without incurring any obligation or liability to Customer or User or any other Person by reason of such suspension. For purposes of this Section, notice shall be deemed sufficient is such is in the form of an email or message notification through the Service, e.g. a message on the screen of the User.</li>
					<li><b>8.3 <u>Audits.</u></b> Pricimetrics or its nominee (including its accountants and auditors) may, in Pricimetrics' sole discretion, inspect and audit User's use of the Services under this Agreement at any time during the Term and for 5 years following the termination or earlier expiration of this Agreement. All audits will be conducted during regular business hours, and no more frequently than once in any 12 month period, and in a manner that does not unreasonably interfere with User's business operations. User shall make available all such books, records, equipment, information, and personnel, and provide all such cooperation and assistance, as may be requested by or on behalf of Pricimetrics with respect to such audit. Pricimetrics shall only examine information directly related to User's use of the Software.</li>
				</ul>

					<h3>9. <u>Confidentiality.</u></h3>
					<ul className="list-none-with-padding">
						<li><b>9.1 <u>Confidential Information.</u></b> In connection with this Agreement each party (as the &#8220;<b>Disclosing Party</b>&#8221;) may disclose or make available Confidential Information to the other party (as the &#8220;<b>Receiving Party</b>&#8221;). Subject to Section 9.2, &#8220;<b>Confidential Information</b>&#8221; means information in any form or medium (whether oral, written, electronic, or other) that the Disclosing Party considers confidential or proprietary, including information consisting of or relating to the Disclosing Party's technology, trade secrets, know-how, business operations, plans, strategies, customers, users, and pricing, and information with respect to which the Disclosing Party has contractual or other confidentiality obligations, in each case whether or not marked, designated, or otherwise identified as &#8220;confidential&#8221;. Without limiting the foregoing: all Pricimetrics Materials are the Confidential Information of Pricimetrics and the financial terms and existence of this Agreement are the Confidential Information of Pricimetrics.</li>
						<li><b>9.2 <u>Exclusions.</u></b> Confidential Information does not include information that the Receiving Party can demonstrate by written or other documentary records: (a) was rightfully known to the Receiving Party without restriction on use or disclosure prior to such information's being disclosed or made available to the Receiving Party in connection with this Agreement; (b) was or becomes generally known by the public other than by the Receiving Party's or any of its Representatives' noncompliance with this Agreement; (c) was or is received by the Receiving Party on a non-confidential basis from a third party that, to the Receiving Party's knowledge, was not or is not, at the time of such receipt, under any obligation to maintain its confidentiality; or (d) the Receiving Party can demonstrate by written or other documentary records was or is independently developed by the Receiving Party without reference to or use of any Confidential Information.</li>
						<li><b>9.3 <u>Protection of Confidential Information.</u></b> As a condition to being provided with any disclosure of or access to Confidential Information, the Receiving Party shall for three (3) years:
							<ul className="list-none-with-padding">
								<li>(a)	not access or use Confidential Information other than as necessary to exercise its rights or perform its obligations under and in accordance with this Agreement;</li>
								<li>(b)	except as may be permitted by and subject to its compliance with Section 9.4, not disclose or permit access to Confidential Information other than to its Representatives who: (i) need to know such Confidential Information for purposes of the Receiving Party's exercise of its rights or performance of its obligations under and in accordance with this Agreement; (ii) have been informed of the confidential nature of the Confidential Information and the Receiving Party's obligations under this Section 9.3; and (iii) are bound by written confidentiality and restricted use obligations at least as protective of the Confidential Information as the terms set forth in this Section 9;</li>
								<li>(c)	safeguard the Confidential Information from unauthorized use, access, or disclosure using at least the degree of care it uses to protect its similarly sensitive information and in no event less than a reasonable degree of care; and</li>
								<li>(d)	promptly notify the Disclosing Party of any unauthorized use or disclosure of Confidential Information and use commercially reasonable efforts to prevent further unauthorized use or disclosure; and</li>
								<li>(e)	ensure its Representatives' compliance with, and be responsible and liable for, any of its Representatives' non-compliance with, the terms of this Section 9.</li>
								<li>(f)	Notwithstanding any other provisions of this Agreement, the Receiving Party's obligations under this Section 9 with respect to any Confidential Information that constitutes a trade secret under any applicable Law will continue until such time, if ever, as such Confidential Information ceases to qualify for trade secret protection under one or more such applicable Laws other than as a result of any act or omission of the Receiving Party or any of its Representatives.</li>
							</ul>
						</li>
						<li><b>9.4 <u>Compelled Disclosures.</u></b> If the Receiving Party or any of its Representatives is compelled by a court or applicable Law to disclose any Confidential Information then, to the extent permitted by applicable Law, the Receiving Party shall:
							<ul className="list-none-with-padding">
								<li>(a) promptly, and prior to such disclosure, notify the Disclosing Party in writing of such requirement so that the Disclosing Party can seek a protective order or other remedy or waive its rights under Section 9.3; and</li>
								<li>(b) provide reasonable assistance to the Disclosing Party in opposing such disclosure or seeking a protective order or other limitations on disclosure. If the Disclosing Party waives compliance or, after providing the notice and assistance required under this Section 9.4, the Receiving Party remains required by Law to disclose any Confidential Information, the Receiving Party shall disclose only that portion of the Confidential Information that, on the advice of the Receiving Party's legal counsel, the Receiving Party is legally required to disclose and, on the Disclosing Party's request, shall use commercially reasonable efforts to obtain assurances from the applicable court or other presiding authority that such Confidential Information will be afforded confidential treatment.</li>
							</ul>
						</li>
					</ul>

				<h3>10. <u>Intellectual Property Rights.</u></h3>
				<ul className="list-none-with-padding">
					<li><b>10.1 <u>Pricimetrics Materials.</u></b> All right, title, and interest in and to the Pricimetrics Materials, including all Intellectual Property Rights therein, are and will remain with Pricimetrics and, with respect to Third-Party Materials, the applicable third-party providers own all right, title, and interest, including all Intellectual Property Rights, in and to the Third-Party Materials. User has no right, license, or authorization with respect to any of the Pricimetrics Materials except as expressly set forth in Section 2.1 or the applicable third-party license, in each case subject to Section 3.1. All other rights in and to the Pricimetrics Materials are expressly reserved by Pricimetrics. In furtherance of the foregoing, User hereby unconditionally and irrevocably grants to Pricimetrics an assignment of all right, title, and interest in and to the Resultant Data, including all Intellectual Property Rights relating thereto.</li>
					<li><b>10.2 <u>User Data.</u></b> As between User and Pricimetrics, User is and will remain the sole and exclusive owner of all right, title, and interest in and to all User Data, including all Intellectual Property Rights relating thereto, subject to the rights and permissions granted in Section 10.3.</li>
					<li><b>10.3 <u>Consent to Use User Data.</u></b> User hereby irrevocably grants all such rights and permissions in or relating to User Data as are necessary or useful to Pricimetrics, its Subcontractors, and the Pricimetrics Personnel to enforce this Agreement and exercise Pricimetrics', its Subcontractors', and the Pricimetrics Personnel's rights and perform Pricimetrics', its Subcontractors', and the Pricimetrics Personnel's obligations hereunder.</li>
					<li><b>10.4	<u>Consent to Use User Data to Offer Additional Opportunities.</u></b> User hereby irrevocably grant all such rights and permissions in or relating to User Data for Pricimetrics, and for those third parties that Pricimetrics, in its sole discretion, deems potentially of interest to User and/or Users,  to enable the offering and provision of additional services or opportunities (including third (3) party services and/or opportunities) to User and/or Users.  This Section shall not be subject to Section 14.</li>
				</ul>

				<h3>11.	Representations and Warranties.</h3>
				<ul className="list-none-with-padding">
					<li><b><u>11.1 Mutual Representations and Warranties.</u></b> Each party represents and warrants to the other party that:
						<ul className="list-none-with-padding">
							<li>(a)	it is duly organized, validly existing, and in good standing as a corporation or other entity under the Laws of the jurisdiction of its incorporation or other organization;</li>
							<li>(b)	it has the full right, power, and authority to enter into and perform its obligations and grant the rights, licenses, consents, and authorizations it grants or is required to grant under this Agreement;</li>
							<li>(c)	the execution of this Agreement by its representative whose signature is set forth at the end of this Agreement has been duly authorized by all necessary corporate or organizational action of such party; and</li>
							<li>(d)	when executed and delivered by both parties, this Agreement will constitute the legal, valid, and binding obligation of such party, enforceable against such party in accordance with its terms.</li>
						</ul>
					</li>
					<li><b>11.2 <u>Additional Pricimetrics Representations, Warranties, and Covenants.</u></b> Pricimetrics represents, warrants, and covenants to User that Pricimetrics will perform the Services using personnel of required skill, experience, and qualifications and in a professional and workmanlike manner in accordance with generally recognized industry standards for similar services and will devote adequate resources to meet its obligations under this Agreement.</li>
					<li><b>11.3 <u>Additional User Representations, Warranties, and Covenants.</u></b> User represents, warrants, and covenants to Pricimetrics that User owns or otherwise has and will have the necessary rights and consents in and relating to the User Data so that, as received by Pricimetrics and Processed in accordance with this Agreement, they do not and will not infringe, misappropriate, or otherwise violate any Intellectual Property Rights, or any privacy or other rights of any third party or violate any applicable Law.  Additionally User represents, warrants, and covenants to Pricimetrics that all User Data uploaded or provided to Pricimetrics by User shall be accurate and that User understands that any <b>pricing recommendations produced</b> by the Services shall be only as reliable as the data provided by User.</li>
					<li><b>11.4 <u>DISCLAIMER OF WARRANTIES.</u></b> EXCEPT FOR THE EXPRESS WARRANTIES SET FORTH IN SECTION 11.1 AND SECTION 11.2, ALL SERVICES AND PRICIMETRICS MATERIALS ARE PROVIDED &#8220;AS IS.&#8221; PRICIMETRICS SPECIFICALLY DISCLAIMS ALL IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, TITLE, AND NON-INFRINGEMENT, AND ALL WARRANTIES ARISING FROM COURSE OF DEALING, USAGE, OR TRADE PRACTICE. WITHOUT LIMITING THE FOREGOING, PRICIMETRICS MAKES NO WARRANTY OF ANY KIND THAT THE SERVICES, REPORTING, OR PRICIMETRICS MATERIALS, OR ANY PRODUCTS OR RESULTS OF THE USE THEREOF, WILL MEET CUSTOMER OR USER OR ANY OTHER PERSON'S REQUIREMENTS, OPERATE WITHOUT INTERRUPTION, ACHIEVE ANY INTENDED RESULT, BE COMPATIBLE OR WORK WITH ANY SOFTWARE, SYSTEM, OR OTHER SERVICES, OR BE SECURE, RELIABLE, ACCURATE, COMPLETE, FREE OF HARMFUL CODE, OR ERROR FREE. ALL THIRD-PARTY MATERIALS ARE PROVIDED &#8220;AS IS&#8221; AND ANY REPRESENTATION OR WARRANTY OF OR CONCERNING ANY THIRD-PARTY MATERIALS IS STRICTLY BETWEEN USER AND THE THIRD-PARTY OWNER OR DISTRIBUTOR OF THE THIRD-PARTY MATERIALS.</li>
				</ul>

				<h3>12.	Indemnification.</h3>
				<ul className="list-none-with-padding">
					<li><b>12.1 <u>Pricimetrics Indemnification.</u></b> Pricimetrics shall indemnify, defend, and hold harmless User and User's officers, directors, employees, agents, permitted successors, and permitted assigns (each, a &#8220;<b>User Indemnitee</b>&#8221;) from and against any and all Losses incurred by User/User Indemnitee resulting from any Action by a third party (other than an Affiliate of User) that User's use of the Services (excluding User Data and Third-Party Materials) in accordance with this Agreement (including the specifications) infringes or misappropriates such third party's US patents, copyrights, or trade secrets. The foregoing obligation does not apply to the extent that the alleged infringement arises from:
						<ul className="list-none-with-padding">
							<li>(a)	Third-Party Materials or User Data;</li>
							<li>(b)	access to or use of the Pricimetrics Materials in combination with any hardware, system, software, network, or other materials or service not provided by Pricimetrics or specified for User's use in the Documentation;</li>
							<li>(c)	modification of the Pricimetrics Materials other than: (i) by or on behalf of Pricimetrics; or (ii) with Pricimetrics' written approval in accordance with Pricimetrics' written specification;</li>
							<li>(d)	failure to timely implement any modifications, upgrades, replacements, or enhancements made available to User by or on behalf of Pricimetrics; or</li>
							<li>(e)	act, omission, or other matter described in Section 12.2(a), Section 12.2(b), Section 12.2(c), or Section 12.2(d), whether or not the same results in any Action against or Losses by any Pricimetrics Indemnitee.</li>
						</ul>
					</li>
					<li><b>12.2 <u>User Indemnification.</u></b> User shall indemnify, defend, and hold harmless Pricimetrics and its Subcontractors and Affiliates, and each of its and their respective officers, directors, employees, agents, successors, and assigns (each, a &#8220;<b>Pricimetrics Indemnitee</b>&#8221;) from and against any and all Losses incurred by such Pricimetrics Indemnitee resulting from any Action by a third party (other than an Affiliate of a Pricimetrics Indemnitee) to the extent that such Losses arise out of or result from, or are alleged to arise out of or result from:
						<ul className="list-none-with-padding">
							<li>(a)	User Data, including any Processing of User Data by or on behalf of Pricimetrics in accordance with this Agreement;</li>
							<li>(b)	any other materials or information (including any documents, data, specifications, software, content, or technology) provided by or on behalf of User, including Pricimetrics' compliance with any specifications or directions provided by or on behalf of User to the extent prepared without any contribution by Pricimetrics;</li>
							<li>(c)	allegation of facts that, if true, would constitute User's breach of any of its representations, warranties, covenants, or obligations under this Agreement; or</li>
							<li>(d)	negligence or more culpable act or omission (including recklessness or willful misconduct) by User, or any third party on behalf of User, in connection with this Agreement.</li>
						</ul>
					</li>
					<li><b>12.3	<u>Indemnification Procedure.</u></b> Each party shall promptly notify the other party in writing of any Action for which such party believes it is entitled to be indemnified pursuant to Section 12.1 or Section 12.2, as the case may be. The party seeking indemnification (the &#8220;<b>Indemnitee</b>&#8221;) shall cooperate with the other party (the &#8220;<b>Indemnitor</b>&#8221;) at the Indemnitor's sole cost and expense. The Indemnitor shall promptly assume control of the defense and shall employ counsel of its choice to handle and defend the same, at the Indemnitor's sole cost and expense. The Indemnitee may participate in and observe the proceedings at its own cost and expense with counsel of its own choosing. The Indemnitor shall not settle any Action on any terms or in any manner that adversely affects the rights of any Indemnitee without the Indemnitee's prior written consent, which shall not be unreasonably withheld or delayed. If the Indemnitor fails or refuses to assume control of the defense of such Action, the Indemnitee shall have the right, but no obligation, to defend against such Action, including settling such Action after giving notice to the Indemnitor, in each case in such manner and on such terms as the Indemnitee may deem appropriate. The Indemnitee's failure to perform any obligations under this Section 12.3 will not relieve the Indemnitor of its obligations under this Section 12, except to the extent that the Indemnitor can demonstrate that it has been materially prejudiced as a result of such failure.</li>
					<li><b>12.4 <u>Mitigation.</u></b> If any of the Services or Pricimetrics Materials are, or in Pricimetrics' opinion are likely to be, claimed to infringe, misappropriate, or otherwise violate any third-party Intellectual Property Right, or if User's use of the Services or Pricimetrics Materials is enjoined or threatened to be enjoined, Pricimetrics may, at its option and sole cost and expense:
						<ul className="list-none-with-padding">
							<li>(a)	obtain the right for User to continue to use the Services and Pricimetrics Materials materially as contemplated by this Agreement;</li>
							<li>(b)	modify or replace the Services and Pricimetrics Materials, in whole or in part, to seek to make the Services and Pricimetrics Materials (as so modified or replaced) non-infringing, while providing materially equivalent features and functionality, in which case such modifications or replacements will constitute Services and Pricimetrics Materials, as applicable, under this Agreement; or</li>
							<li>(c)	by written notice to User, terminate this Agreement with respect to all or part of the Services and Pricimetrics Materials, and require User to immediately cease any use of the Services and Pricimetrics Materials or any specified part or feature thereof.</li>
						</ul>
					</li>
					<li><b>12.5	<u>Sole Remedy.</u></b> THIS SECTION 12 SETS FORTH USER'S SOLE REMEDIES AND PRICIMETRICS' SOLE LIABILITY AND OBLIGATION FOR ANY ACTUAL, THREATENED, OR ALLEGED CLAIMS THAT THE SERVICES AND PRICIMETRICS MATERIALS OR ANY SUBJECT MATTER OF THIS AGREEMENT INFRINGES, MISAPPROPRIATES, OR OTHERWISE VIOLATES ANY INTELLECTUAL PROPERTY RIGHTS OF ANY THIRD PARTY.</li>
				</ul>

				<h3>13.<u>Limitations of Liability.</u></h3>
				<ul className="list-none-with-padding">
					<li><b>13.1 <u>EXCLUSION OF DAMAGES.</u></b> IN NO EVENT WILL PRICIMETRICS OR ANY OF ITS AFFILIATES, SUBSIDIARIES, LICENSORS, SERVICE PROVIDERS, OR SUPPLIERS BE LIABLE UNDER OR IN CONNECTION WITH THIS AGREEMENT OR ITS SUBJECT MATTER UNDER ANY LEGAL OR EQUITABLE THEORY, INCLUDING BREACH OF CONTRACT, TORT (INCLUDING NEGLIGENCE), STRICT LIABILITY, AND OTHERWISE, FOR ANY: (a) LOSS OF PRODUCTION, USE, BUSINESS, REVENUE, OR PROFIT OR DIMINUTION IN VALUE; (b) IMPAIRMENT, INABILITY TO USE OR LOSS, INTERRUPTION OR DELAY OF THE SERVICES, OTHER THAN FOR THE ISSUANCE OF ANY APPLICABLE SERVICE FEE REDUCTION PURSUANT TO SECTION 5.2; (c) LOSS, DAMAGE, CORRUPTION OR RECOVERY OF DATA, OR BREACH OF DATA OR SYSTEM SECURITY; (d) COST OF REPLACEMENT GOODS OR SERVICES; (e) LOSS OF GOODWILL OR REPUTATION; OR (f) CONSEQUENTIAL, INCIDENTAL, INDIRECT, EXEMPLARY, SPECIAL, ENHANCED, OR PUNITIVE DAMAGES, REGARDLESS OF WHETHER SUCH PERSONS WERE ADVISED OF THE POSSIBILITY OF SUCH LOSSES OR DAMAGES OR SUCH LOSSES OR DAMAGES WERE OTHERWISE FORESEEABLE, AND NOTWITHSTANDING THE FAILURE OF ANY AGREED OR OTHER REMEDY OF ITS ESSENTIAL PURPOSE.</li>
					<li><b>13.2 <u>CAP ON MONETARY LIABILITY.</u>IN NO EVENT WILL THE COLLECTIVE AGGREGATE LIABILITY OF PRICIMETRICS AND ITS AFFILIATES, SUBSIDIARIES, LICENSORS, SERVICE PROVIDERS, AND SUPPLIERS ARISING OUT OF OR RELATED TO THIS AGREEMENT, WHETHER ARISING UNDER OR RELATED TO BREACH OF CONTRACT, TORT (INCLUDING NEGLIGENCE), STRICT LIABILITY, OR ANY OTHER LEGAL OR EQUITABLE THEORY, EXCEED THE TOTAL AMOUNTS PAID TO LICENSOR UNDER THIS AGREEMENT IN THE TWELVE MONTH PERIOD PRECEDING THE EVENT GIVING RISE TO THE CLAIM. THE FOREGOING LIMITATIONS APPLY EVEN IF ANY REMEDY FAILS OF ITS ESSENTIAL PURPOSE.</b></li>
					<li><b>13.3	<u>MANDATORY BINDING ARBITRATION AND CLASS ACTION WAIVER.</u></b> PLEASE READ THIS SECTION CAREFULLY. IT AFFECTS YOUR LEGAL RIGHTS, INCLUDING YOUR RIGHT TO FILE A LAWSUIT IN COURT. This Agreement is governed by and construed in accordance with the internal laws of the State of Florida without giving effect to any choice or conflict of law provision or rule that would require or permit the application of the laws of any jurisdiction other than those of the State of Florida. The application of the United Nations Convention on Contracts for the International Sale of Goods is expressly excluded.<br /><br />
					Claims relating to this Agreement or the Service will be resolved through final and binding arbitration, except as set forth below. The parties agree that the Agreement affects interstate commerce and that the Federal Arbitration Act governs the interpretation and enforcement of these arbitration provisions. Initial Dispute Resolution: The parties agree that most disputes can be resolved without resort to litigation. The parties agree to use their best efforts to settle any dispute, claim, question, or disagreement directly through consultation with each other, and good faith negotiations shall be a condition to either party initiating a lawsuit or arbitration. Accordingly, before initiating a lawsuit or arbitration, User Agrees to contact Pricimetrics to attempt to resolve the dispute in good faith.<br /><br />
					Binding Arbitration & Class Action Waiver: If the parties do not reach an agreed-upon solution within a period of thirty (30) days from the time the informal dispute resolution is initiated under the Initial Dispute Resolution provision above, then either party may initiate binding arbitration as the sole means to resolve claims, subject to the terms set forth below. Specifically, all claims arising out of or relating to the Agreement (including its formation, performance and breach), the parties' relationship with each other and/or your use of the Services shall be finally settled by binding arbitration administered by the American Arbitration Association under its Commercial Arbitration Rules, excluding any rules or procedures governing or permitting class actions.<br /><br />
					Filing a Demand. To start an arbitration, you must do the following: (a) Write a Demand for Arbitration (&#8220;Demand&#8221;) that (i) briefly explains the dispute, (ii) lists your and Pricimetrics' names and addresses, (iii) specify the amount of money in dispute, if applicable, (iv) identify the requested location for a hearing if an in-person hearing is requested, and (v) state what you want in the dispute; (b) Send one copy of the Demand to the AAA, along with a copy of these Terms and the filing fee required by the AAA; and (c) Send one copy of the Demand for Arbitration to us at Support@PricimetricsMax.com.<br /><br />
					The parties understand that, absent this mandatory arbitration provision, they would have the right to sue in court and have a jury trial. They further understand that, in some instances, the costs of arbitration could exceed the costs of litigation and the right to discovery may be more limited in arbitration than in court. If you are a resident of the United States, arbitration may take place in the county where you reside at the time of filing, unless you and we both agree to another location or telephonic arbitration. For individuals residing outside the United States, arbitration shall be initiated in Broward County, Florida, United States, and you and Pricimetrics agree to submit to the personal jurisdiction of any federal or state court in Broward County, Florida, United States, in order to compel arbitration, stay proceedings pending arbitration, or to confirm, modify, vacate, or enter judgment on the award entered by the arbitrator.<br /><br />
					THE PARTIES AGREE THAT EACH MAY BRING CLAIMS AGAINST THE OTHER ONLY IN ITS INDIVIDUAL CAPACITY, AND NOT AS A PLAINTIFF OR CLASS MEMBER IN ANY PURPORTED CLASS OR REPRESENTATIVE PROCEEDING. If any court or arbitrator determines that the class action waiver set forth in this paragraph is void or unenforceable for any reason or that arbitration can proceed on a class basis, then the disputes, claims or controversies will not be subject to arbitration and must be litigated in state or federal court located in Broward County, Florida. The arbitrator, and not any federal, state or local court or agency, shall have exclusive authority to resolve all disputes arising out of or relating to the interpretation, applicability, enforceability or formation of the Agreement, including, but not limited to any claim that all or any part of the Agreement is void or voidable, or whether a claim is subject to arbitration. The arbitrator shall be empowered to grant whatever relief would be available in a court under law or in equity. The arbitrator's award shall be written, and binding on the Parties and judgment on the award rendered by the arbitrator(s) may be entered in any court having jurisdiction thereof. Arbitration will be held in Broward County, Florida. If any court or arbitrator determines that this arbitration provision is void or unenforceable for any reason or that the parties are not bound to arbitrate their claims, then the disputes, claims or controversies deemed not to be subject to arbitration must be litigated in state or federal court located in Broward County, Florida. Exception: Litigation of Intellectual Property Claims: Notwithstanding the foregoing, disputes, claims, or controversies concerning (1) either party's patents, copyrights, moral rights, trademarks, and trade secrets or (2) claims of piracy or unauthorized use of the Services (collectively, &#8220;IP Claims&#8221;) shall not be subject to arbitration.</li>
				</ul>

				<h3>14. <u>Termination.</u></h3>
				<ul className="list-none-with-padding">
					<li><b>14.1 <u>Termination.</u></b> In addition to any other express termination right set forth elsewhere in this Agreement:
						<ul className="list-none-with-padding">
							<li>(a)	Pricimetrics may terminate this Agreement and lock User out of the Service and stop providing support services, effective on written notice to User, if Customer:
								<ul className="list-none-with-padding">
									<li>(i) fails to pay any amount when due hereunder, and such failure continues more than fifteen (15) days after Pricimetrics' delivery of written notice thereof; or immediately if Customer or User breaches any of its obligations under Section 3.1, Section 7.4, or Section 9;</li>
								</ul>
							</li>
							<li>(b)	either party may terminate this Agreement, effective on written notice to the other party, if the other party materially breaches this Agreement, and such breach:
								<ul className="list-none-with-padding">
									<li>(i) is incapable of cure; or</li>
									<li>(ii) being capable of cure, remains uncured ninety (90) days after the non-breaching party provides the breaching party with written notice of such breach; and</li>
								</ul>
							</li>
							<li>(c)	either party may terminate this Agreement, effective immediately upon written notice to the other party, if the other party:</li>
							<li>
								<ul className="list-none-with-padding">
									<li>(i) becomes insolvent or is generally unable to pay, or fails to pay, its debts as they become due;</li>
									<li>(ii) files or has filed against it, a petition for voluntary or involuntary bankruptcy or otherwise becomes subject, voluntarily or involuntarily, to any proceeding under any domestic or foreign bankruptcy or insolvency Law;</li>
									<li>(iii) makes or seeks to make a general assignment for the benefit of its creditors; or (iv) applies for or has appointed a receiver, trustee, custodian, or similar agent appointed by order of any court of competent jurisdiction to take charge of or sell any material portion of its property or business.</li>
								</ul>
							</li>
							<li>(d)	Pricimetrics may terminate this Agreement, effective immediately upon written notice to the other party, for any or no reason.</li>
						</ul>
					</li>
					<li><b>14.2 <u>Effect of Termination or Expiration.</u></b> Upon any expiration or termination of this Agreement, except as expressly otherwise provided in this Agreement:
						<ul className="list-none-with-padding">
							<li>(a)	Other than as specifically stated in this Agreement, all rights, licenses, consents, and authorizations granted by either party to the other hereunder will immediately terminate;</li>
							<li>(b)	Upon any expiration or termination of this Agreement, except as expressly otherwise provided in this Agreement and except as necessary to fulfill ongoing obligations to Customers, Pricimetrics will return, destroy, or continue to extend protections to and limit the use and disclosure of Customer Data, provided that the Parties agree that it is not feasible in light of reasonable business requirements, regulatory compliance requirements, and the rights and obligations under the Agreement for Pricimetrics to return or destroy its business records and transaction databases, including, but not limited to, records and databases of transactions for which User has used Service or in which Pricimetrics has engaged on behalf of User or records and databases that reflect the use of Service and information that User or Pricimetrics has entered in Service in the course of the Agreement to enable or perform Service Services;</li>
							<li>(c)	Except as otherwise permitted in this Agreement, Pricimetrics shall have the right to permanently erase all User Data and User's Confidential Information from all systems Pricimetrics directly or indirectly controls, provided that, for clarity, Pricimetrics' obligations under this Section 14.4(c) do not apply to any Resultant Data;</li>
							<li>(d)	User shall immediately cease all use of any Services or Pricimetrics Materials and (i) promptly return to Pricimetrics, or at Pricimetrics' written request destroy, all documents and tangible materials containing, reflecting, incorporating, or based on any Pricimetrics Materials or Pricimetrics' Confidential Information; and (ii) permanently erase all Pricimetrics Materials and Pricimetrics' Confidential Information from all systems User directly or indirectly controls; and (iii) certify to Pricimetrics in a signed written instrument that it has complied with the requirements of this Section 14.2(d);</li>
							<li>(e)	Notwithstanding anything to the contrary in this Agreement, with respect to information and materials then in its possession or control: (i) the Receiving Party may retain the Disclosing Party's Confidential Information; and (ii) Pricimetrics may retain User Data; (iii) User may retain Pricimetrics Materials, in the case of each of subclause (i) and (ii) and (iii), in its then current state and solely to the extent and for so long as required by applicable Law; (iv) Pricimetrics may also retain User Data in its backups, archives, and disaster recovery systems until such User Data is deleted in the ordinary course; and (v) all information and materials described in this Section 14.2(e) will remain subject to all confidentiality, security, and other applicable requirements of this Agreement; and</li>
							<li>(f)	Pricimetrics may disable all User access to the Pricimetrics Materials. For clarity, Pricimetrics shall have no obligation to transfer Customer Data upon expiration or termination.</li>
						</ul>
					</li>
					<li><b>14.3 <u>Surviving Terms.</u></b>
						The provisions set forth in the following sections, and any other right or obligation of the parties in this Agreement that, by its nature, should survive termination or expiration of this Agreement, will survive any expiration or termination of this Agreement: Section 3.1, Section 9, Section 11.4, Section 12, Section 13, Section 14.2, this Section 14.3, and Section 15.
					</li>
				</ul>

				<h3>15.	<u>Miscellaneous.</u></h3>
				<ul className="list-none-with-padding">
					<li><b>15.1	<u>Hosting of the Service; Export Restrictions.</u></b> The Service is controlled and operated from facilities in the United States. Pricimetrics makes no representations that the Service is appropriate or available for use in other locations. Users who access or use the Service from other jurisdictions do so of their own volition and are responsible for compliance with all applicable United States and local laws and regulations, including but not limited to export and import regulations. If User is located outside of the United States, User agrees that Pricimetrics may transfer, store and process User Data in locations other than User's country. The export and re-export of content via the Service may be controlled by the United States Export Administration Regulations or other applicable export restrictions or embargo. The Service may not be used in any country that is subject to an embargo by the United States and User may not use the Service in violation of any export restriction or embargo by the United States or any other applicable jurisdiction. In addition, User must ensure that the Service is not made available for use by persons or entities blocked or denied by the United States government.</li>
					<li><b>15.2	<u>Further Assurances.</u></b> On a party's reasonable request, the other party shall, at the requesting party's sole cost and expense, execute and deliver all such documents and instruments, and take all such further actions, as may be necessary to give full effect to this Agreement.</li>
					<li><b>15.3	<u>Relationship of the Parties.</u></b> The relationship between the parties is that of independent contractors. Nothing contained in this Agreement shall be construed as creating any agency, partnership, joint venture, or other form of joint enterprise, employment, or fiduciary relationship between the parties, and neither party shall have authority to contract for or bind the other party in any manner whatsoever.</li>
					<li><b>15.4	<u>Public Announcements.</u></b> Neither party shall issue or release any announcement, statement, press release, or other publicity or marketing materials relating to this Agreement or, unless expressly permitted under this Agreement, otherwise use the other party's trademarks, service marks, trade names, logos, domain names, or other indicia of source, association, or sponsorship, in each case, without the prior written consent of the other party, which consent shall not be unreasonably withheld, provided, however, that Pricimetrics may, without User's consent, include User's name and other indicia in its lists of Pricimetrics' current or former Users of Pricimetrics in promotional and marketing materials.</li>
					<li><b>15.5	<u>Notices.</u></b> Any notice, request, consent, claim, demand, waiver, or other communications under this Agreement have legal effect only if in writing and addressed to each party as provided in the relevant Order Form (or to such other address or such other person that such party may designate from time to time in accordance with this Section 15.5).<br/><br/>
						Notices sent in accordance with this Section 15.5 will be deemed effectively given: (a) when received, if delivered by hand, with signed confirmation of receipt; (b) when received, if sent by a nationally recognized overnight courier, signature required; (c) when sent, if by facsimile or email, (in each case, with confirmation of transmission), if sent during the addressee's normal business hours, and on the next business day, if sent after the addressee's normal business hours; and (d) on the fifth (5th) business day after the date mailed by certified or registered mail, return receipt requested, postage prepaid.</li>
					<li><b>15.6	<u>Interpretation.</u></b> For purposes of this Agreement: (a) the words &#8220;include,&#8221; &#8220;includes,&#8221; and &#8220;including&#8221; are deemed to be followed by the words &#8220;without limitation&#8221;; (b) the word &#8220;or&#8221; is not exclusive; (c) the words &#8220;herein,&#8221; &#8220;hereof,&#8221; &#8220;hereby,&#8221; &#8220;hereto,&#8221; and &#8220;hereunder&#8221; refer to this Agreement as a whole; (d) words denoting the singular have a comparable meaning when used in the plural, and vice-versa; and (e) words denoting any gender include all genders. Unless the context otherwise requires, references in this Agreement: (x) to sections, exhibits, schedules, attachments, and appendices mean the sections of, and exhibits, schedules, attachments, and appendices attached to, this Agreement; (y) to an agreement, instrument, or other document means such agreement, instrument, or other document as amended, supplemented, and modified from time to time to the extent permitted by the provisions thereof; and (z) to a statute means such statute as amended from time to time and includes any successor legislation thereto and any regulations promulgated thereunder. The parties intend this Agreement to be construed without regard to any presumption or rule requiring construction or interpretation against the party drafting an instrument or causing any instrument to be drafted. The exhibits, schedules, attachments, and appendices referred to herein are an integral part of this Agreement to the same extent as if they were set forth verbatim herein.</li>
					<li><b>15.7	<u>Headings.</u></b> The headings in this Agreement are for reference only and do not affect the interpretation of this Agreement.</li>
					<li><b>15.8	<u>Entire Agreement.</u></b> This Agreement, together with the Order Form, constitutes the sole and entire agreement of the parties with respect to the subject matter of this Agreement and supersedes all prior and contemporaneous understandings, agreements, representations, and warranties, both written and oral, with respect to such subject matter. In the event of any inconsistency between the statements made in the body of this Agreement, the related exhibits, schedules, attachments, and appendices (other than an exception expressly set forth as such therein), the following order of precedence governs: (a) first, this Agreement, excluding its exhibits, schedules, attachments, and appendices; (b) second, the exhibits, schedules, attachments, and appendices to this Agreement as of the Effective Date; and (c) third, any other documents incorporated herein by reference.</li>
					<li><b>15.9	<u>Assignment.</u></b> User shall not assign or otherwise transfer any of its rights, or delegate or otherwise transfer any of its obligations or performance under this Agreement, in each case whether voluntarily, involuntarily, by operation of law, or otherwise, without Pricimetrics' prior written consent, which consent shall not be unreasonably withheld, conditioned, or delayed. For purposes of the preceding sentence, and without limiting its generality, any merger, consolidation, or reorganization involving User (regardless of whether User is a surviving or disappearing entity) will be deemed to be a transfer of rights, obligations, or performance under this Agreement for which Pricimetrics' prior written consent is required. No assignment, delegation, or transfer will relieve User of any of its obligations or performance under this Agreement. Any purported assignment, delegation, or transfer in violation of this Section 15.8 is void. This Agreement is binding upon and inures to the benefit of the parties hereto and their respective successors and permitted assigns.</li>
					<li><b>15.10 <u>Force Majeure.</u></b>
						<ul className="list-none-with-padding">
							<li><b>(a) <u>No Breach or Default.</u></b> In no event will either party be liable or responsible to the other party, or be deemed to have defaulted under or breached this Agreement, for any failure or delay in fulfilling or performing any term of this Agreement, (except for any obligations to make payments), when and to the extent such failure or delay is caused by any circumstances beyond such party's reasonable control (a &#8220;<b>Force Majeure Event</b>&#8221;), including acts of God, flood, fire, earthquake or explosion, war, terrorism, invasion, riot or other civil unrest, embargoes or blockades in effect on or after the date of this Agreement, national or regional emergency, strikes, labor stoppages or slowdowns or other industrial disturbances, passage of Law or any action taken by a governmental or public authority, including imposing an embargo, export or import restriction, quota, or other restriction or prohibition or any complete or partial government shutdown, or national or regional shortage of adequate power or telecommunications or transportation. Either party may terminate this Agreement if a Force Majeure Event affecting the other party continues substantially uninterrupted for a period of 60 days or more.</li>
							<li><b>(b) <u>Affected Party Obligations.</u></b> In the event of any failure or delay caused by a Force Majeure Event, the affected party shall give prompt written notice to the other party stating the period of time the occurrence is expected to continue and use commercially reasonable efforts to end the failure or delay and minimize the effects of such Force Majeure Event.</li>
						</ul>
					</li>
					<li><b>15.11 <u>No Third-Party Beneficiaries.</u></b> This Agreement is for the sole benefit of the parties hereto and their respective successors and permitted assigns and nothing herein, express or implied, is intended to or shall confer upon any other Person any legal or equitable right, benefit, or remedy of any nature whatsoever under or by reason of this Agreement.</li>
					<li><b>15.12 <u>Amendment and Modification; Waiver.</u></b> No amendment to or modification of or rescission, termination, or discharge of this Agreement is effective unless it is in writing, identified as an amendment to or rescission, termination, or discharge of this Agreement and signed by an authorized representative of each party. No waiver by any party of any of the provisions hereof shall be effective unless explicitly set forth in writing and signed by the party so waiving. Except as otherwise set forth in this Agreement, no failure to exercise, or delay in exercising, any rights, remedy, power, or privilege arising from this Agreement will operate or be construed as a waiver thereof; nor shall any single or partial exercise of any right, remedy, power, or privilege hereunder preclude any other or further exercise thereof or the exercise of any other right, remedy, power, or privilege.</li>
					<li><b>15.13 <u>Severability.</u></b> If any term or provision of this Agreement is invalid, illegal, or unenforceable in any jurisdiction, such invalidity, illegality, or unenforceability shall not affect any other term or provision of this Agreement or invalidate or render unenforceable such term or provision in any other jurisdiction. Upon such determination that any term or other provision is invalid, illegal, or unenforceable, the parties hereto shall negotiate in good faith to modify this Agreement so as to effect the original intent of the parties as closely as possible in a mutually acceptable manner in order that the transactions contemplated hereby be consummated as originally contemplated to the greatest extent possible.</li>
					<li><b>15.14 <u>Submission to Jurisdiction.</u></b> The parties
						<ul className="list-none-with-padding">
							<li>(i) hereby irrevocably and unconditionally submit to the exclusive jurisdiction of the state courts of Broward, Florida and to the jurisdiction of the United States District Court for the Southern District of Florida for the purpose of any action arising out of or based upon this Agreement;</li>
							<li>(ii) agree not to commence any action out of or based upon this Agreement except in the state courts of Broward, Florida or the United States District Court for the Southern District of Florida; and</li>
							<li>(iii) hereby waive, and agree to not assert, by way of motion, as a defense, or otherwise, in any such Action, any claim that they are not subject personally to the jurisdiction of the above-named courts, that their property is exempt or immune from attachment or execution, that the action is brought in an inconvenient forum, that the venue of the action is improper or that this Agreement or the subject matter hereof may not be enforced in or by such court.</li>
						</ul>
					</li>
					<li><b>15.15 <u>Waiver of Jury Trial.</u></b> Each party irrevocably and unconditionally waives any right it may have to a trial by jury in respect of any legal action arising out of or relating to this Agreement or the transactions contemplated hereby.</li>
					<li><b>15.16 <u>Equitable Relief.</u></b> User acknowledges and agrees that a breach or threatened breach by User of any of its obligations under Section 9 or, in the case of User, Section 3.1, Section 4.4,or Section 7.4, would cause Pricimetrics irreparable harm for which monetary damages would not be an adequate remedy and that, in the event of such breach or threatened breach, Pricimetrics will be entitled to equitable relief, including a restraining order, an injunction, specific performance, and any other relief that may be available from any court, without any requirement to post a bond or other security, or to prove actual damages or that monetary damages are not an adequate remedy. Such remedies are not exclusive and are in addition to all other remedies that may be available at law, in equity, or otherwise.</li>
					<li><b>15.17 <u>Attorneys' Fees.</u></b> In the event that any action, suit, or other legal or administrative proceeding is instituted or commenced by either party against the other party arising out of or related to this Agreement, the prevailing party is entitled to recover its actual attorneys' fees and court costs from the non-prevailing party.</li>
				</ul>
			</div>
		</div>
	)
}

export default UserAgreementPage
