import React, { useState, useEffect } from "react";
import { withTranslation } from "react-i18next";
import MultiTabWidget from "../components/Dashboard/MultiTabWidget/MultiTabWidget";
import MultiTab from "../components/Dashboard/Charts/Bar-Line/MultiTab";
import PerformanceChart from "../components/Dashboard/KpiPerformance/KpiPerformance";
import SpotLight from "../components/Dashboard/SpotLight/SpotLight";
import ProfitTab from "../components/Dashboard/ProfitOpportunity/ProfitTab";
import RepricingOverPeriod from "../components/Dashboard/RepricingOverPeriod/RepricingOverPeriod";
import StateOfBusiness from "../components/Dashboard/StateOfBusiness/StateOfBusiness";
import authService from '../components/api-authorization/AuthorizeService';
// import ItemDemandCurve from "../components/Dashboard/ItemDemandCurve/ItemDemandCurve";

import dashboardService from '../Api/requests/dashboard';
import configurationService from '../Api/requests/config';

function DashboardPage(props) {
	const { t, tReady, i18n } = props;
	const [loaded, setLoaded] = useState(false);
	const [loading, setLoading] = useState(false);
	const [currencySymbol, setCurrencySymbol] = useState(null);

	const [multiTabWidgetData, setMultiTabWidgetData] = useState(null);
	const [multiChartData, setMultiChartData] = useState(null);
	const [kpiPerformanceData, setKpiPerformanceData] = useState(null);
	const [spotLightData, setSpotLightData] = useState(null);
	const [stateOfBusinessData, setStateOfBusinessData] = useState(null);
	const [companyName, setCompanyName] = useState("");
	// const [demandCurve, setDemandCurve] = useState();

	// const [repricingPerformance, setRepricingPerformanceData] = useState();
	// const [pricingMovementOverTime, setPricingMovementOverTimeData] = useState();
	const [hideSpotLightPanel, setHideSpotLightPanel] = useState(true);
	const [hideLowerGrid, setHideLowerGrid] = useState(true);

	const [profitOpportunityTab, setProfitOpportunityDataTab] = useState();
	const [performancePeriodOverPeriod, setPerformancePeriodOverPeriodData] = useState();

	const loadData = async () => {
		if (!loading && !loaded) {
			setLoading(true);

			let customerConfig = await configurationService.getGeneralConfiguration();
			if (customerConfig.selectedDashboardSections != null) {
				setHideSpotLightPanel(customerConfig.selectedDashboardSections.indexOf('dummiesSpotlight') < 0);
				setHideLowerGrid(customerConfig.selectedDashboardSections.indexOf('lowerGridCharts') < 0);
			} else {
				setHideSpotLightPanel(true);
				setHideLowerGrid(true);
			}

			let multiTabData = await dashboardService.getMultiTabWidgetData(i18n);
			setMultiTabWidgetData(multiTabData);

			let multiChartData = await dashboardService.getMultiChartData(i18n);
			setMultiChartData(multiChartData);
			// console.log(multiChartData);

			let kpiData = await dashboardService.getKpiPerformance(i18n);
			setKpiPerformanceData(kpiData);
			// console.log(kpiData);

			let spotlightData = await dashboardService.getSpotLight(i18n);
			setSpotLightData(spotlightData);

			let stateOfBusiness = await dashboardService.getStateOfBusiness(i18n);
			setStateOfBusinessData(stateOfBusiness);

			// let repricingPerformanceData = await dashboardService.getRepricingPerformanceLastPeriod(i18n);
			// setRepricingPerformanceData(repricingPerformanceData);

			// let pricingMovementOverTimeData = await dashboardService.getPricingMovementOverTime(i18n);
			// setPricingMovementOverTimeData(pricingMovementOverTimeData);

			let profitOpportunityCategoryData = await dashboardService.getProfitOpportunityCategory(i18n);
			// console.log(profitOpportunityCategoryData);
			setProfitOpportunityDataTab(profitOpportunityCategoryData);

			let performancePeriodOverPeriod = await dashboardService.getPerformancePeriodOverPeriod(i18n);
			setPerformancePeriodOverPeriodData(performancePeriodOverPeriod);

			// let demandCurveData = await dashboardService.getDemandCurve(i18n);
			// setDemandCurve(demandCurveData);
			// console.log(demandCurveData);

			setLoaded(true);
			setLoading(false);
		}
	}

	useEffect(() => {
		const setUserData = async () => {
			let user = await authService.getUser();
			setCurrencySymbol(user.currencySymbol);
			setCompanyName(user.company);
		}

		setUserData();		
	}, []); // eslint-disable-line react-hooks/exhaustive-deps

	useEffect(() => {
		if (tReady === true) {
			loadData();
		}
	}, [tReady]); // eslint-disable-line react-hooks/exhaustive-deps

	return (
		<>
			<div id="overlay_panel" style={{ display: loaded ? "none" : "" }} className="p-overlaypanel-wrapper p-component-overlay p-general-loading-overlay">
				<i className="p-datatable-loading-icon pi-spin pi pi-spinner"></i>
			</div>
			<div className="grid">
				<div className="col-12 lg:col-6">
					<h3 className="text-primary">{companyName}</h3>
					<h1 className="text-primary">{t("Dashboard.Header")}</h1>
				</div>
			</div>
			<div className="grid" style={ { display: loaded ? "" : "none" } } >
				<div
					className="col-12 sm:col-12 lg:col-4 xl:col-2"
				>
					<StateOfBusiness data={stateOfBusinessData} currencySymbol={currencySymbol} />
					<SpotLight
						hidden={hideSpotLightPanel}
						data={spotLightData ? spotLightData : { tabs: [] }} />
				</div>
				<div className="lg:col-8 xl:col-10">
					<div className="grid">
						<div className="col-12 sm:col-12 lg:col-12 xl:col-8">
							<MultiTab data={multiChartData ? multiChartData : []} />
						</div>
						<div className="col-12 lg:col-12 xl:col-4">
							<ProfitTab
								data={profitOpportunityTab ? profitOpportunityTab : []}
							/>
						</div>
					</div>
					<div className="grid" style={ { display: hideLowerGrid ? "none" : "" } } >
						<div className="col-12 lg:col-12 xl:col-4">
							<PerformanceChart
								data={kpiPerformanceData ? kpiPerformanceData : { tabs: [] }}
							/>
						</div>
						<div className="col-12 lg:col-12 xl:col-4">
							<RepricingOverPeriod
								data={
									performancePeriodOverPeriod
										? performancePeriodOverPeriod
										: { tabs: [] }
								}
							/>
							{/* <PricingMovementOverTimeTab
							data={
								PricingMovementOverTime
								? PricingMovementOverTime
								: { tabs: [] }
							}
							/> */}
						</div>
						<div className="col-12 lg:col-12 xl:col-4">
							<MultiTabWidget
								data={multiTabWidgetData ? multiTabWidgetData : { tabs: [] }}
							/>
						</div>
					</div>
					{/* <div className="grid">
						<div className="col-12 lg:col-12 xl:col-4">
							<ItemDemandCurve
							data={demandCurve ? demandCurve : { tabs: [] }}
							/>
						</div>
						</div> */}
				</div>
			</div>
		</>
	);
}

export default withTranslation()(DashboardPage);

