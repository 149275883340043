export class Messages {
    static _toastComponent = null;

    attachToast(toastComponent) {
        this._toastComponent = toastComponent;
    }

    showError(summary, detail, life, sticky) {
        this._toastComponent.show({ severity: 'error', summary: summary, detail: detail, life: (life ? life : 5000), sticky: (sticky ? sticky : false) });
    }

    showWarning(summary, detail, life, sticky) {
        this._toastComponent.show({ severity: 'warn', summary: summary, detail: detail, life: (life ? life : 5000), sticky: (sticky ? sticky : false) });
    }

    showInfo(summary, detail, life, sticky) {
        this._toastComponent.show({ severity: 'info', summary: summary, detail: detail, life: (life ? life : 5000), sticky: (sticky ? sticky : false) });
    }

    showSuccess(summary, detail, life, sticky) {
        this._toastComponent.show({ severity: 'success', summary: summary, detail: detail, life: (life ? life : 5000), sticky: (sticky ? sticky : false) });
    }

    static get instance() { return messagesService; }
}

const messagesService = new Messages();

export default messagesService;
