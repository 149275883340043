import { React } from 'react';
import { withTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';
import packageInfo from '../../package.json';

const ContentFooter = (props) => {
	const { t } = props;

	return (
		<div className="grid p-footer">
			<div className="col-4">
			</div>
			<div className="col-4 p-terms-privacy">
				<NavLink to="/terms" exact="true">{t('ContentFooter.TermsOfService')}</NavLink>
				<span>|</span>
				<NavLink to="/privacy" exact="true">{t('ContentFooter.PrivacyPolicy')}</NavLink>
			</div>
			<div className="col-4 p-build-number">
				<span className="text-sixth">Build {packageInfo.version}</span>
			</div>
		</div>
	)
}
export default withTranslation()(ContentFooter)
