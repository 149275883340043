import { React, useEffect } from 'react'

function TermsPage(props) {

	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	return (
		<div className="grid p-legal-content">
			<div className="col-12" style={{ "whiteSpace": "pre-line"}}>
				<h2 className="text-primary">Website Terms of Use (Version 1.0 - updated 06-14-2022)</h2>
				<h3>Please read these terms and conditions carefully.</h3>
				<p>
					Pricimetrics, Inc. (&#8220;Pricimetrics,&#8221; &#8220;we,&#8221; or &#8220;us&#8221;) operates the websites currently located at www.pricimetrics.com and www.pricimetricsmax.com (&#8220;Website&#8221;) to help facilitate the provision of Pricimetrics' services (the &#8220;Services&#8221;) to its customers, and makes the Website available to individuals (&#8220;Users&#8221; or &#8220;You&#8221;) for informational purposes. (The term &#8220;site&#8221; or &#8220;website&#8221; as used in these Terms of Use includes all versions these internet pages accessed via any electronic device.) If You use Pricimetrics in any manner, You accept these Terms of Service (&#8220;Agreement&#8221; or &#8220;Terms&#8221;). If You do not agree with any provision of this Agreement or do not wish to be bound by this Agreement, do not use Pricimetrics' Service.<br />
				</p>
				<p>
					BY ACCESSING OR USING THE PRICIMETRICS WEBSITE YOU AGREE TO BE BOUND BY THESE TERMS OF SERVICE, THE PRIVACY POLICY, AND ALL OTHER ADDITIONAL TERMS INCORPORATED BY REFERENCE HEREIN. YOU ARE AUTHORIZED TO USE THE WEBSITE ONLY IF YOU AGREE TO ABIDE BY ALL APPLICABLE LAWS, THESE TERMS OF SERVICE, INCLUDING THOSE INCORPORATED BY REFERENCE, AND THE PRIVACY POLICY. PLEASE READ THESE TERMS OF SERVICE AND THE PRIVACY POLICY CAREFULLY. IF YOU DO NOT AGREE WITH THESE TERMS OR THE PRIVACY POLICY, YOU SHOULD IMMEDIATELY DISCONTINUE YOUR USE OF THE WEBSITE AND THE PRICIMETRICS' SERVICE.<br/>
				</p>
				<h3>1. Modifications to Terms of Service.</h3>
				<p>
					Pricimetrics may modify these Terms of Service and its Privacy Policy from time to time, and any such modifications shall be effective upon their posting on the Website. You agree to be bound by any changes to the Terms of Service and Privacy Policy when You use the Website after any such modification is posted on the Website. It is therefore important that You review these Terms of Service each time You access the Website to ensure that You are aware of any changes or modifications to the Terms of Service.
				</p>
				<h3>2. Eligibility.</h3>
				<p>
					You must be eighteen (18) years old or older to use Pricimetrics' Service. By using Pricimetrics' Service, You represent and assume that You have the authority and capacity to enter into this Agreement and to abide by all the terms listed in this Agreement.
				</p>
				<h3>3. Access, Alerts, and Downloads.</h3>
				<p>
					Pricimetrics reserves the right to reject and to terminate your use of the Website at any time, for any reason or for no reason, without notice to You. Pricimetrics also reserves the right to provide notices and alerts to Users from time to time about use of the Website and information on feature updates and changes.
				</p>
				<h3>4. Intellectual Property Protection.</h3>
				<p>
					All the content and information displayed or accessible on or through the Website, including without limitation, text, artwork, graphics, logos, button icons, images, audio clips, video clips, digital downloads, prices, products, product and service descriptions, and data compilations, is the property of Pricimetrics. Such content is protected by U.S. and international trademark, copyright, and other intellectual property laws. You shall not copy, distribute, alter, display, perform, publish, or create derivative works from such materials, other than as permitted in these Terms of Service. Systematic retrieval of data or other information from the Website to prepare any collection, compilation, database, or directory is strictly prohibited.<br />
					<br/>
					The names and logos for Pricimetrics, and any other Pricimetrics graphics, logos, designs, page headers, button icons, scripts and service names are trademarks or trade dress of Pricimetrics. Pricimetrics' trademarks and trade dress may not be used, including as part of trademarks or as part of domain names, in connection with any other product or service in any manner that is likely to cause consumer confusion and may not be copied, imitated, or used, in whole or in part, without the prior written permission of Pricimetrics. You may not frame or utilize framing techniques to enclose any trademark, logo, or other proprietary information (including images, text, page layout, or form) of Pricimetrics or any Pricimetrics affiliates without Pricimetrics' express written consent. In addition, You agree to not remove, obscure or otherwise alter any proprietary notices appearing on any content, including copyright, trademark and other intellectual property notices.<br />
					<br />
					EXCEPT AS EXPRESSLY PROVIDED IN THE TERMS OF SERVICE, NEITHER PRICIMETRICS NOR ANY THIRD PARTY HAS CONFERRED UPON YOU BY IMPLICATION, ESTOPPEL, OR OTHERWISE, ANY LICENSE OR RIGHT UNDER ANY PATENT, TRADEMARK, COPYRIGHT, OR OTHER PROPRIETARY RIGHTS TO USE THE WEBSITE. NO OWNERSHIP RIGHTS ARE OR WILL BE ASSIGNED TO YOU BY REASON OF YOUR ACCEPTANCE OF THIS TERMS OF SERVICE.<br />
					<br />
					You acknowledge and agree that a breach or threatened breach by you of any of your obligations under this Section would cause Pricimetrics irreparable harm for which monetary damages would not be an adequate remedy and that, in the event of such breach or threatened breach, Pricimetrics will be entitled to equitable relief, including a restraining order, an injunction, specific performance, and any other relief that may be available from any court, without any requirement to post a bond or other security, or to prove actual damages or that monetary damages are not an adequate remedy. Such remedies are not exclusive and are in addition to all other remedies that may be available at law, in equity, or otherwise.<br />
				</p>
				<h3>5. License Grant and Restrictions.</h3>
				<p>
					Pricimetrics hereby grants You a limited, non-exclusive, non-transferable, and revocable right to access and use the Website to (i) utilize the features, content and tools that Pricimetrics makes available to You through the Website or otherwise and (ii) receive information relating to the Services. You shall use the Website, and any Pricimetrics content solely for Your personal use, and for no other purpose whatsoever without the express written consent of Pricimetrics. You shall not modify, copy, distribute, transmit, display, perform, reproduce, publish, license, broadcast, create derivative works from, transfer, or sell any of the Pricimetrics content on the Website, including without limitation any data, text, artwork, graphics, logos, button icons, images, audio clips, digital downloads, product and service descriptions, and data compilations. The license granted in the Terms of Service does not include any resale or commercial use of the Website, and such use is prohibited. You are also prohibited from creating any derivative works from the Website, or downloading or copying of any information for the benefit of another person or entity other than contemplated in these Terms of Service. Pricimetrics reserves the right to suspend or deny, in its sole discretion, Your access to the Website, without notice to You.<br />
					<br />
					ANY RIGHTS IN THE WEBSITE NOT EXPRESSLY GRANTED TO YOU IN THESE TERMS OF SERVICE ARE RESERVED TO PRICIMETRICS.
				</p>
				<h3>6. Privacy Policy.</h3>
				<p>
					Pricimetrics respects your right to privacy and understands that visitors want to be in control of their personal information. Accordingly, Pricimetrics has developed a Privacy Policy located at: (www.pricimetrics.com/privacy-policy), that governs your use of the Website, and You should review it carefully. By becoming a User, you understand that we will obtain and share your information, and that your information may be shared with third parties. We will use, store, and disclose your personal information in accordance with our Privacy Policy, and your use of the Website constitutes your consent to the terms set forth in the Privacy Policy. If you disagree with any aspect of the Privacy Policy, then you should cease using the Website.
				</p>
				<h3>7. Terms and Termination of Access.</h3>
				<p>
					This Agreement is effective starting on the date you use the Website for the first time and continues until terminated in accordance with this Agreement.<br />
					<br />
					You understand and agree that Pricimetrics, in its sole discretion, may terminate your right to use the Website, direct You to cease using the Website, and discontinue or restrict your access to the Website, all without notice to You and for any reason. You agree that Pricimetrics shall not be liable to You or to any third party for any modification, suspension, or discontinuance of the Website, or any parts thereof.<br />
					<br />
					While Pricimetrics may restrict your use of the Website for any reason or for no reason at all, we will suspend, disable, delete, or otherwise limit or restrict your access if Pricimetrics determines that You have violated any provision of this Agreement or that your conduct or content would tend to damage Pricimetrics' reputation or goodwill. If Pricimetrics deactivates or otherwise restricts your access due to your misconduct, you shall not use the Website under a different name or different email address.<br />
					<br />
					Upon termination of this Agreement, all licenses granted by Pricimetrics to You will terminate. In the event of access restriction for any reason, whether on your behalf or ours, content that You submitted may no longer be available. Pricimetrics is not responsible for the loss of such content.<br />
				</p>
				<h3>8. Limitation of Liability.</h3>
				<p>
					You specifically agree that Pricimetrics is not liable for any of your conduct while using the Website. Pricimetrics is also not responsible for any problems or technical malfunction of any telephone network or lines, computer online systems, servers or providers, computer equipment, software, or failure of any email due to technical problems or traffic congestion on the internet or on the Website, including any injury or damage to your or any other person's computer related to or resulting from use of the Website.<br />
					<br />
					In no event shall Pricimetrics be liable for any damages, including but not limited to any direct, indirect, incidental, special, or consequential damages resulting from your use or the inability to use the Website, any Pricimetrics content, any information accessible on the Website, from any messages received through the Website, or resulting from unauthorized access to or alteration of transmissions or data, including but not limited to, damages for loss of profits, use, data, or other intangible property, whether based on contract, tort, strict liability or otherwise, even if Pricimetrics has been advised of the possibility of such damages, to the fullest extent permitted by applicable law.<br />
					<br />
					You agree that regardless of any statute or law to the contrary, You will file any claim or cause of action arising out of or related to your use of the Website, or to interpret or enforce the Terms of Service within one (1) year of receiving the Terms of Service or be forever barred.<br />
					<br />
					Pricimetrics is not responsible for the conduct of any user. In no event shall Pricimetrics, its affiliates or its partners be directly or indirectly liable for any losses or damages whatsoever, including but not limited to direct, indirect, general, special, compensatory, consequential, and/or incidental damages, arising out of or relating to the conduct of You or anyone else in connection with Your use of the Website and Pricimetrics' Services including, without limitation, death, bodily injury, emotional distress, and/or any other damages.
				</p>
				<h3>9. Disclaimers.</h3>
				<p>
					YOU EXPRESSLY ACKNOWLEDGE AND AGREE THAT YOUR USE OF THE INFORMATION ON THE WEBSITE, AND ANY PRICIMETRICS CONTENT, IS AT YOUR SOLE RISK. THE PRICIMETRICS CONTENT IS PROVIDED ON AN &#8220;AS IS&#8221; AND &#8220;AS AVAILABLE&#8221; BASIS. PRICIMETRICS HAS NO OBLIGATION TO SCREEN OR MONITOR ANY CONTENT AND DOES NOT GUARANTEE THAT ANY CONTENT AVAILABLE ON PRICIMETRICS COMPLIES WITH THIS AGREEMENT OR IS SUITABLE FOR ALL USERS.<br />
					<br />
					PRICIMETRICS EXPRESSLY DISCLAIMS ALL WARRANTIES OF ANY KIND, WHETHER EXPRESS OR IMPLIED, INCLUDING BUT NOT LIMITED TO THE IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, AND NON-INFRINGEMENT.<br />
					<br />
					PRICIMETRICS DOES NOT MAKE ANY WARRANTY THAT THE PRICIMETRICS CONTENT WILL MEET YOUR REQUIREMENTS OR THAT ACCESS TO THE WEBSITE WILL BE UNINTERRUPTED, TIMELY, SECURE, ACCURATE, VIRUS-FREE OR ERROR FREE. EXCEPT AS OTHERWISE SEPARATELY EXTENDED TO YOU AT THE TIME YOU RECEIVE ANY PRICIMETRICS CONTENT PROVIDED TO YOU, PRICIMETRICS DOES NOT MAKE ANY WARRANTY CONCERNING THE INFORMATION AND RESULTS THAT MAY BE OBTAINED FROM THE USE OF THE PRICIMETRICS CONTENT PROVIDED ON OR THROUGH THE WEBSITE, OR CONCERNING THE ACCURACY OR RELIABILITY OF ANY INFORMATION DISPLAYED THEREON. PRICIMETRICS DOES NOT MAKE ANY WARRANTY REGARDING ANY INFORMATION OBTAINED FROM ANY HYPERLINKED THIRD PARTY WEBSITE OR WEBSITE, INCLUDING ANY ADVERTISER'S WEBSITE OR WEBSITE. PRICIMETRICS DOES NOT MAKE ANY WARRANTY REGARDING THE RELATIONSHIPS BETWEEN ANY ADVERTISER ON THE WEBSITE AND OTHER USERS OF THE WEBSITE. INFORMATION OBTAINED BY YOU FROM THE WEBSITE SHALL NOT CREATE ANY WARRANTY NOT EXPRESSLY OR IMPLIEDLY MADE HEREIN TO THE EXTENT PERMITTED BY APPLICABLE LAW.<br />
					<br />
					To the extent any disclaimer or limitation of liability does not apply, all applicable express, implied, and statutory warranties will be limited in duration to a period of thirty (30) days after the date on which You first used Pricimetrics, and no warranties shall apply after such period.<br />
				</p>
				<h3>10. Your Conduct and Prohibited Activities.</h3>
				<p>
					Users are required to be civil and respectful at all times and in all interactions with Pricimetrics and with any other User. In addition, You shall not:<br />
				</p>
				<ul>
					<li>Attempt to submit information to the Website with more than one email address;</li>
					<li>Harass or stalk any other person;</li>
					<li>Harm or exploit minors;</li>
					<li>Act in a deceptive manner by, among other things, impersonating any person or entity;</li>
					<li>Solicit money from Pricimetrics or other Users;</li>
					<li>Post any content that is prohibited by Section 13 herein;</li>
					<li>Express or imply that any statements you make are endorsed by Us without our specific prior written consent;</li>
					<li>Use the Website or our Services in an illegal manner or to commit an illegal act;</li>
					<li>Access the Website in a jurisdiction in which it is illegal or unauthorized;</li>
					<li>Ask or use other Users to conceal the identity, source, or destination of any illegally gained money or products;</li>
					<li>Use any robot, spider, site search/retrieval application, or other manual or automatic device or process to retrieve, index, &#8220;data mine&#8221;, or in any way reproduce or circumvent the navigational structure or presentation of the Service or its contents;</li>
					<li>Collect others' personal information by electronic or other means for the purpose of sending unsolicited email or unauthorized framing of or linking to the Website;</li>
					<li>Interfere with or disrupt the Website or the servers or networks connected to the Website;</li>
					<li>Email or otherwise transmit any material that contains software viruses or any other computer code, files or programs designed to interrupt, destroy or limit the functionality of any computer software, computer hardware, or telecommunications equipment;</li>
					<li>Forge headers or otherwise manipulate identifiers in order to disguise the origin of any information transmitted to or through the Website (either directly or indirectly through use of third party software);</li>
					<li>&#8220;Frame&#8221; or &#8220;mirror&#8221; any part of the Website, without Pricimetrics' prior written authorization;</li>
					<li>Use meta tags or code or other devices containing any reference to Us or the Website (or any trademark, trade name, service mark, logo or slogan of Pricimetrics) to direct any person to any other website for any purpose;</li>
					<li>Post, distribute, or reproduce in any way any copyrighted material, trademarks, service marks, trade names, logos, slogans, or other proprietary information without obtaining the prior consent of the owner of such proprietary rights;</li>
					<li>Modify, adapt, sublicense, translate, sell, reverse engineer, decipher, decompile or otherwise disassemble any portion of the Service any software used on or for the Service, or cause others to do so;</li>
					<li>Post, use, transmit or distribute, directly or indirectly, (e.g. screen scrape) in any manner or media any content or information obtained from the Service other than solely in connection with your use of the Service in accordance with this Agreement.</li>
				</ul>
				<h3>11. </h3>
				<ul className="list-none">
					<li><h3>(a) Content Posted by You on Pricimetrics.</h3>
						<p>
							You are solely responsible for the content and information that you post, upload, publish, link to, transmit, record, display or otherwise make available for use on the Website (collectively, &#8220;post&#8221;) to Pricimetrics, including but not limited to text messages, chats, videos (including streaming videos), and photographs, whether publicly posted or privately transmitted (collectively, &#8220;Content&#8221;). You agree that any Content you place or provide access to for use on the Website may be viewed by Pricimetrics as applicable for Pricimetrics' provision of its Services to You.<br />
							<br />
							You are solely responsible for all activities that you perform. You agree to immediately notify Pricimetrics of any disclosure or unauthorized use, or any other breach of security, at <a href="mailto:support@pricimetricsmax.com">support@pricimetricsmax.com</a>.<br />
							<br />
							You may not post or transmit to Pricimetrics or any other User any offensive, inaccurate, incomplete, abusive, obscene, profane, threatening, intimidating, harassing, racially offensive, or illegal material, or any material that infringes or violates another person's rights (including intellectual property rights, and rights of privacy and publicity), via the Website. You represent and warrant that (i) all information that You submit is accurate and truthful and that You will promptly update any information provided by You that subsequently becomes inaccurate, incomplete, misleading or false and (ii) you have the right to post the Content on Pricimetrics and grant the licenses set forth below.<br />
							<br />
							You understand and agree that Pricimetrics may, but is not obligated to, monitor or review any Content you post as part of the Website. We may delete any Content, in whole or in part, that in our sole judgment violates this Agreement or may harm the reputation of Pricimetrics or the Pricimetrics Website.<br />
						</p>
					</li>
					<li><h3>(b) Advertising</h3>
						<p>
							Pricimetrics' business may be partly funded through advertising. You understand and agree that the Pricimetrics website and your use thereof may include advertisements, and that these are, in the sole discretion of Pricimetrics, necessary to support the website and the services provided by Pricimetrics. To help make the advertisements relevant and useful to you, Pricimetrics may allow advertisements based on the information we collect from you or in relation to your interaction on our site.
						</p>
					</li>
					<li><h3>(c) Automated Queries</h3>
						<p>
							Automated queries (including screen and database scraping, spiders, robots, crawlers and any other automated activity with the purpose of obtaining information from the Pricimetrics website) are strictly prohibited, unless you have received express written permission from Pricimetrics. As a limited exception, publicly available search engines and similar Internet navigation tools (&#8220;Search Engines&#8221;) may query the Pricimetrics website and provide an index with links to the Pricimetrics website, only to the extent such unlicensed &#8220;fair use&#8221; is allowed by applicable copyright law. Search Engines are not permitted to query or search information protected by a security verification system (&#8220;captcha&#8221;) which limits access to human users.
						</p>
					</li>
					
					<li><h3>(d) Links to Third Party Sites</h3>
						<p>
							Pricimetrics' website and services may include links to third-party products, services, websites, hyperlinks to other websites, and materials provided by third parties. Pricimetrics does not endorse, and takes no responsibility for such products, services, websites, and/or materials. Pricimetrics makes no representations or warranties regarding the legality or appropriateness of any third party products, services, websites, hyperlinks or materials. You understand that Pricimetrics has no obligation to, and does not, review, evaluate, approve or monitor materials provided by third parties. Your dealings with any third party arising in connection with your use of Pricimetrics' website are solely between you and such third party, and Pricimetrics takes no responsibility for any damages or costs of any type arising out of or in any way connected with your dealings with these third parties.
						</p>
					</li>
				</ul>
				<h3>12. Licenses Granted by You to Us.</h3>
				<p>
					By posting Content as part of the Service, you automatically grant to Pricimetrics, its affiliates, licensees and successors, an irrevocable, perpetual, non-exclusive, transferable, sub-licensable, royalty-free, worldwide right and license to (i) use, copy, store, perform, display, reproduce, record, play, adapt, modify and distribute the Content, (ii) prepare derivative works of the Content or incorporate the Content into other works, and (iii) grant and authorize sublicenses of the foregoing in any media now known or hereafter created. In addition, you waive any so-called &#8220;moral rights&#8221; in your Content. If you suggest to Pricimetrics any improvements or new features for Pricimetrics or for its Website, software or services, Pricimetrics shall have the right to implement such suggestions without any compensation to you.<br/>
					<br />
					Government Sales.  If User is a branch or agency of the United States Government or a contractor thereto, the following provision applies. As defined in FAR section 2.101, the Site and Services and related documentation are &#8220;commercial items&#8221; and according to DFAR section 252.227 7014(a)(1) and (5) are deemed to be &#8220;commercial computer software&#8221; and &#8220;commercial computer software documentation.&#8221;  Consistent with DFAR section 227.7202 and FAR section 12.212, any use modification, reproduction, release, performance, display, or disclosure of such commercial software or commercial software documentation by the U.S. Government will be governed solely by the terms of these Terms of Use and will be prohibited except to the extent expressly permitted by the terms of these Terms of Use.
				</p>
				<h3>13. Prohibited Content.</h3>
				<p>
					Your use of Pricimetrics, including all Content You post, must comply with all applicable laws and regulations. In addition to the types of Content described in Section 10 above, the following is a partial list of Content which You are prohibited from posting to Pricimetrics. You shall not post, upload, display or otherwise make available Content that:
				</p>
				<ul>
					<li>Promotes racism, bigotry, hatred or physical harm of any kind against any group or individual;</li>
					<li>Advocates harassment or intimidation of another person;</li>
					<li>Requests money from, or is intended to otherwise defraud, Pricimetrics or other Users;</li>
					<li>Involves the transmission of &#8220;junk mail&#8221;, &#8220;chain letters&#8221; or unsolicited mass mailing or &#8220;spamming&#8221; (or &#8220;spimming&#8221;, &#8220;phishing&#8221;, &#8220;trolling&#8221; or similar activities);</li>
					<li>Promotes information that is false or misleading, or promotes illegal activities or conduct that is defamatory, libelous or otherwise objectionable;</li>
					<li>Promotes an illegal or unauthorized copy of another person's copyrighted work, such as providing pirated computer programs or links to them, providing information to circumvent manufacturer installed copy-protect devices, or providing pirated images, audio or video, or links to pirated images, audio or video files;</li>
					<li>Contains video, audio photographs, or images of another person without his or her permission (or in the case of a minor, the minor's legal guardian);</li>
					<li>Contains restricted or password only access pages, or hidden pages or images (those not linked to or from another accessible page);</li>
					<li>Provides material that exploits people in a sexual, violent or other illegal manner, or solicits personal information from anyone under the age of 18;</li>
					<li>Provides instructional information about illegal activities such as making or buying illegal weapons or drugs, violating someone's privacy, or providing, disseminating or creating computer viruses;</li>
					<li>Contains viruses, time bombs, trojan horses, cancelbots, worms or other harmful, or disruptive codes, components or devices;</li>
					<li>Impersonates, or otherwise misrepresents affiliation, connection or association with, any person or entity;</li>
					<li>Provides information or data you do not have a right to make available under law or under contractual or fiduciary relationships (such as inside information, proprietary and confidential information);</li>
					<li>Disrupts the normal flow of dialogue, causes a screen to &#8220;scroll&#8221; faster than other users are able to type, or otherwise negatively affects other users' ability to use the Website;</li>
					<li>Solicits passwords or personal identifying information of other Users for commercial or unlawful purposes;</li>
					<li>Contains false or misleading statements or reviews.</li>
					<li>Disseminates another person's personal information without his or her permission; or</li>
					<li>Publicizes or promotes commercial activities and/or sales, including but not limited to contests, sweepstakes, barter, advertising, and pyramid schemes, without our prior written consent.</li>
				</ul>
				<p>
					Pricimetrics reserves the right, in its sole discretion, to investigate and take any legal action against anyone who violates this provision, including removing the offending communication from the Website and terminating or suspending access by such violators.
				</p>
				<h3>14. Your Representations and Warranties.</h3>
				<p>
					For each item of Content that you submit, you represent and warrant that: (i) you have the right to submit the Content to Pricimetrics and grant the licenses set forth above; (ii) Pricimetrics will not need to obtain licenses from any third party or pay royalties to any third party; (iii) the Content does not infringe any third party's rights, including intellectual property rights and privacy rights; and (iv) the Content complies with this Agreement and all applicable laws.
				</p>
				<h3>15. Indemnification.</h3>
				<p>
					You agree to indemnify, defend, and hold Pricimetrics, its subsidiaries, affiliates, officers, agents, partners and employees, harmless from any loss, liability, claim, or demand, including reasonable attorneys' fees, made by any third party due to or arising out of your breach of or failure to comply with this Agreement (including any breach of your representations and warranties contained herein), any postings or Content you provide to Pricimetrics, and the violation of any law or regulation by You. Pricimetrics reserves the right to assume the exclusive defense and control of any matter otherwise subject to indemnification by you, in which event you will fully cooperate with Us in connection therewith.
				</p>
				<h3>16. Copyright Policy, Notice and Procedure for Making Claims of Copyright Infringement.</h3>
				<p>
					You may not post, distribute, or reproduce in any way any copyrighted material, trademarks, or other proprietary information without obtaining the prior written consent of the owner of such proprietary rights. Without limiting the foregoing, if you believe that your work has been copied and posted on the Website in a way that constitutes copyright infringement, please notify our Copyright Agent at the following address:<br />
					Copyright Agent<br />
					The Concept Law Group, P.A.<br />
					6400 North Andrews Avenue,<br />
					Fort Lauderdale, FL 33309<br />
					<br />
					To facilitate the processing of your claim, you will need to provide the Copyright Agent with the following:<br />
				</p>
				<ul className="list-none">
					<li>1.	(i) A physical or electronic signature of a person authorized to act on behalf of the owner of an exclusive right that is allegedly infringed.</li>
					<li>2.	(ii) Identification of the copyrighted work claimed to have been infringed, or, if multiple copyrighted works at a single online website are covered by a single notification, a representative list of such works at that site.</li>
					<li>3.	(iii) Identification of the material that is claimed to be infringing or to be the subject of infringing activity and that is to be removed or access to which is to be disabled, and information reasonably sufficient to permit the service provider to locate the material.</li>
					<li>4.	(iv) Information reasonably sufficient to permit the service provider to contact the complaining party, such as an address, telephone number, and, an email address at which the complaining party may be contacted.</li>
					<li>5.	(v) A statement that the complaining party has a good faith belief that use of the material in the manner complained of is not authorized by the copyright owner, its agent, or the law.</li>
					<li>6.	(vi) A statement that the information in the notification is accurate, and under penalty of perjury, that the complaining party is authorized to act on behalf of the owner of an exclusive right that is allegedly infringed.</li>
				</ul>
				<p>
					Pricimetrics reserves the right to terminate or restrict access by repeat infringers.
				</p>
				<h3>17. Our Use of Your Information.</h3>
				<p>
					You agree that Pricimetrics may access, preserve and disclose your information and Content if required to do so by law or in a good faith belief that such access, preservation or disclosure is reasonably necessary, such as to:
				</p>
				<ul className="list-none">
					<li>(i) Comply with legal process;</li>
					<li>(ii) Enforce these Terms of Service;</li>
					<li>(iii) Respond to claims that any Content violates the rights of third parties;</li>
					<li>(iv) Respond to your requests for customer service;</li>
					<li>(v) Allow you to use the Service in the future; or</li>
					<li>(vi) Protect the rights, property or personal safety of Pricimetrics or any other person or entity.</li>
				</ul>
				<h3>18. Reliance on Information Posted</h3>
				<p>
					The information presented on or through the Website is made available solely for general information purposes. We do not warrant the accuracy, completeness or usefulness of this information. Any reliance you place on such information is strictly at your own risk. We disclaim all liability and responsibility arising from any reliance placed on such materials by you or any other visitor to the Website, or by anyone who may be informed of any of its contents.<br />
					<br/>
					This Website may include content provided by third parties, including materials provided by other users, bloggers and third-party licensors, syndicators, aggregators and/or reporting services. All statements and/or opinions expressed in these materials, and all articles and responses to questions and other content, other than the content provided by the Pricimetrics, are solely the opinions and the responsibility of the person or entity providing those materials. These materials do not necessarily reflect the opinion of the Pricimetrics. We are not responsible, or liable to you or any third party, for the content or accuracy of any materials provided by any third parties.
				</p>
				<h3>19. MANDATORY BINDING ARBITRATION AND CLASS ACTION WAIVER. PLEASE READ THIS SECTION CAREFULLY. IT AFFECTS YOUR LEGAL RIGHTS, INCLUDING YOUR RIGHT TO FILE A LAWSUIT IN COURT.</h3>
				<p>
					Claims relating to this Agreement or the Service will be resolved through final and binding arbitration, except as set forth below. The parties agree that the Agreement affects interstate commerce and that the Federal Arbitration Act governs the interpretation and enforcement of these arbitration provisions.
				</p>
				<p>
					Initial Dispute Resolution: The parties agree that most disputes can be resolved without resort to litigation. The parties agree to use their best efforts to settle any dispute, claim, question, or disagreement directly through consultation with each other, and good faith negotiations shall be a condition to either party initiating a lawsuit or arbitration. Accordingly, before initiating a lawsuit or arbitration, you agree to contact Pricimetrics to attempt to resolve the dispute in good faith.
				</p>
				<p>
					Binding Arbitration & Class Action Waiver: If the parties do not reach an agreed-upon solution within a period of thirty (30) days from the time the informal dispute resolution is initiated under the Initial Dispute Resolution provision above, then either party may initiate binding arbitration as the sole means to resolve claims, subject to the terms set forth below. Specifically, all claims arising out of or relating to the Agreement (including its formation, performance and breach), the parties' relationship with each other and/or your use of the Website and/or Services shall be finally settled by binding arbitration administered by the American Arbitration Association under its Commercial Arbitration Rules, excluding any rules or procedures governing or permitting class actions.
				</p>
				<p>
					Filing a Demand. To start an arbitration, you must do the following: (a) Write a Demand for Arbitration (&#8220;Demand&#8221;) that (i) briefly explains the dispute, (ii) lists your and Pricimetrics' names and addresses, (iii) specify the amount of money in dispute, if applicable, (iv) identify the requested location for a hearing if an in-person hearing is requested, and (v) state what you want in the dispute; (b) Send one copy of the Demand to the AAA, along with a copy of these Terms and the filing fee required by the AAA; and (c) Send one copy of the Demand for Arbitration to us at <a href="mailto:support@pricimetrics.com">support@pricimetrics.com</a> or <a href="mailto:support@pricimetricsmax.com">support@pricimetricsmax.com</a>.
				</p>
				<p>
					The parties understand that, absent this mandatory arbitration provision, they would have the right to sue in court. They further understand that, in some instances, the costs of arbitration could exceed the costs of litigation and the right to discovery may be more limited in arbitration than in court. If you are a resident of the United States, arbitration may take place in the county where you reside at the time of filing, unless you and we both agree to another location or telephonic arbitration. For individuals residing outside the United States, arbitration shall be initiated in Broward County, Florida, United States, and you and Pricimetrics agree to submit to the personal jurisdiction of any federal or state court in Broward County, Florida, United States, in order to compel arbitration, stay proceedings pending arbitration, or to confirm, modify, vacate, or enter judgment on the award entered by the arbitrator.
				</p>
				<p>
					THE PARTIES AGREE THAT EACH MAY BRING CLAIMS AGAINST THE OTHER ONLY IN ITS INDIVIDUAL CAPACITY, AND NOT AS A PLAINTIFF OR CLASS MEMBER IN ANY PURPORTED CLASS OR REPRESENTATIVE PROCEEDING. If any court or arbitrator determines that the class action waiver set forth in this paragraph is void or unenforceable for any reason or that arbitration can proceed on a class basis, then the disputes, claims or controversies will not be subject to arbitration and must be litigated in state or federal court located in Broward County, Florida, United States. The arbitrator, and not any federal, state or local court or agency, shall have exclusive authority to resolve all disputes arising out of or relating to the interpretation, applicability, enforceability or formation of the Agreement, including, but not limited to any claim that all or any part of the Agreement is void or voidable, or whether a claim is subject to arbitration. The arbitrator shall be empowered to grant whatever relief would be available in a court under law or in equity. The arbitrator's award shall be written, and binding on the Parties and judgment on the award rendered by the arbitrator(s) may be entered in any court having jurisdiction thereof. Arbitration will be held in Broward County, Florida, United States. If any court or arbitrator determines that this arbitration provision is void or unenforceable for any reason or that the parties are not bound to arbitrate their claims, then the disputes, claims or controversies deemed not to be subject to arbitration must be litigated in state or federal court located in. Broward County, Florida, United States.
				</p>
				<p>
					Exception: Litigation of Intellectual Property Claims: Notwithstanding the foregoing, disputes, claims, or controversies concerning (1) either party's patents, copyrights, moral rights, trademarks, and trade secrets or (2) claims of piracy or unauthorized use of the Services (collectively, &#8220;IP Claims&#8221;) shall not be subject to arbitration.
				</p>
				<h3>20. Miscellaneous Provisions.</h3>
				<ul className="list-none">
					<li><h3>A. Applicable Law</h3>
						<p>You acknowledge that the Content contained in the Pricimetrics Website is controlled in and originates from the United States. Pricimetrics does not make any representation that any of the Content is appropriate or available for use in other locations. Any claim relating to the use of the Website and any Content displayed thereon, shall be governed by the internal substantive laws of the State of Florida, without regard to its conflicts of laws rules. The application of the United Nations Convention on Contracts for the International Sale of Goods is expressly excluded. You expressly consent to the personal and exclusive jurisdiction of the state and federal courts located in, or having jurisdiction over, Broward County, Florida, United States for any such claim. You further agree that in the event Pricimetrics prevails in any litigation or arbitration proceeding to interpret or enforce a party's rights under these Terms of Service, the court or arbitration panel shall have the right and duty, in addition to awarding any relief deemed appropriate in the circumstances, to award Pricimetrics its reasonable attorneys' fees, costs, and litigation expenses incurred in prosecuting or defending such action or proceeding, at trial, at any arbitration proceeding, on appeal, or in any proceeding to enforce any final judgment or arbitration award. Each party irrevocably and unconditionally waives any right it may have to a trial by jury in respect of any legal action arising out of or relating to this Agreement.</p>
					</li>
					<li><h3>B. Assignment</h3>
						<p>This Agreement shall bind and inure to the benefit of Pricimetrics' successors, assigns and licensees. Pricimetrics shall have the right to assign or otherwise transfer its rights or obligations under this Agreement whether by contract, merger, sale of all or substantially all of Pricimetrics' assets, or operation of law without your consent, or notice to You. Any attempted assignment by You shall be null and void, and not have any legal force or effect.</p>
					</li>
						<li><h3>C. Waiver</h3>
						<p>The failure of Pricimetrics to exercise or enforce any right or provision of the Terms of Service shall not constitute a waiver of such right or provision.</p>
					</li>
					<li><h3>D. Severability</h3>
						<p>If any provision of the Terms of Service is found by a court of competent jurisdiction to be invalid, the parties nevertheless agree that the court should endeavor to give effect to the parties' intentions as reflected in the provision, and the other provisions of the Terms of Service remain in full force and effect.</p>
					</li>
					<li><h3>E.Entire Agreement</h3>
						<p>These Terms of Service, and your consent thereto as demonstrated by your use of the Website, constitute the entire agreement between You and Pricimetrics relating to the subject matter of this Agreement.</p>
					</li>
					<li>
						<h3>F.Descriptive Headings</h3>
						<p>The headings of the several sections of this Agreement are intended for convenience of reference only and are not intended to be a part of or affect the meaning or interpretation of this Agreement.</p>
					</li>
					<li><h3>G. Hosting of the Service; Export Restrictions.</h3>
						<p>The Website is controlled and operated from facilities in the United States. Pricimetrics makes no representations that the Website is appropriate or available for use in other locations. Anyone who accesses or uses the Website from other jurisdictions (or who allow their Authorized Users to do so) do so of their own volition and are responsible for compliance with all applicable United States and local laws and regulations, including but not limited to export and import regulations. If you are located outside of the United States, you agree that we may transfer, store and process your data in locations other than your country. The export and re-export of content via the website may be controlled by the United States Export Administration Regulations or other applicable export restrictions or embargo. The website may not be used in any country that is subject to an embargo by the United States and you may not use the website in violation of any export restriction or embargo by the United States or any other applicable jurisdiction. In addition, you must ensure that the Website is not made available by you for use by persons or entities blocked or denied by the United States government.</p>
					</li>
					<li><h3>H.Information Providing On The Website</h3>
						<p>The information provided on the Website may mirror information from publicly available sites or information that was obtained by the Pricimetrics and/or the Website. If the respective information on the Website belongs to you and you have any complaints about the use of either your intellectual property or personal contact information, please contact <a href="mailto:support@pricimetrics.com">support@pricimetrics.com</a> or <a href="mailto:support@pricimetricsmax.com">support@pricimetricsmax.com</a> immediately.</p>
					</li>
						
				</ul>
			</div>
		</div>
	)
}

export default TermsPage
