import React, { useEffect, useState } from 'react'
import { Chart } from 'primereact/chart'
import { withTranslation } from 'react-i18next'
import moment from 'moment'
import 'chartjs-adapter-moment'

function UnitPriceHistory(prop) {
	const { selectedItem, productInfo, t } = prop
	const [hasData, setHasData] = useState(false)
	const [chartData, setChartData] = useState(null)
	const [basicOptions, setBasicOptions] = useState({
		maintainAspectRatio: false,
		aspectRatio: 1.5,
		fill: false,
		responsive: true,
		elements: {
			point: {
				radius: 1,
				hitRadius: 4,
				hoverRadius: 3
			}
		},
		hover: {
			mode: 'nearest'
		},
		animation: {
			duration: 0
		},
		plugins: {
			legend: {
				display: false,
				labels: {
					color: '#495057'
				}
			},
			tooltip: {
				backgroundColor: '#ffffff',
				bodyColor: '#004547',
				borderColor: '#004547',
				titleColor: '#004547',
				borderWidth: 1,
				titleFont: {
					size: 14
				},
				bodyFont: {
					size: 14
				},
				callbacks: {
					title: function (item) {
						return moment(item[0].raw.x).format("DD MMM yy");
					}
				}
			}
		},
		scales: {
			x: {
				type: 'time',
				title: {
					display: true,
					text: t('UnitPriceHistoryTile.DateAxisLabel')
				},
				time: {
					unit: 'month',
					displayFormats: {
						month: 'MMM YY',
						week: 'DD MMM'
					},
					source: 'data'
				},
				ticks: {
					color: '#495057'
				},
				grid: {
					color: '#ebedef'
				}
			},
			y: {
				title: {
					display: true,
					text: t('UnitPriceHistoryTile.PriceAxisLabel'),
					align: 'start'
				},
				ticks: {
					color: '#495057',
					callback: function (value, index) {
						if (value >= 0) return value;

					}
				},
				grid: {
					color: '#ebedef'
				}
			},
			y1: {
				position: 'right',
				title: {
					display: true,
					text: t('UnitPriceHistoryTile.UnitsAxisLabel'),
					// align: 'end'
				},
				ticks: {
					color: '#495057',
					callback: function (value, index) {
						if (value >= 0) return value;
					}
				},
				grid: {
					color: '#ebedef'
				}
			}
		}
	});

	const timeSeriesPriceBind = (productData) => {
		var data = [];

		for (var i = 0; i < productData.timeSeriesDate.length; i++) {
			data.push({
				x: productData.timeSeriesDate[i],
				y: productData.timeSeriesPrices[i]
			});
		}

		return data;
	}

	const timeSeriesUnitBarsBind = (productData) => {
		var data = [];

		for (var i = 0; i < productData.timeSeriesDate.length; i++) {
			data.push({
				x: productData.timeSeriesDate[i],
				y: productData.timeSeriesUnits[i]
			});
		}

		return data;
	}

	const fillChart = async () => {
		if (selectedItem.historyObservationDates.length > 0) {
			const basicData = {
				datasets: [
					{
						label: t('UnitPriceHistoryTile.PriceHistoryLegend'),
						data: timeSeriesPriceBind(productInfo),
						fill: false,
						borderColor: '#2e999e',
						backgroundColor: '#2e999e',
						tension: 0.4,
						//lineTension: 0.9,
						//bezierCurve: true,
						showLine: true,
						borderWidth: 2,
						yAxisID: 'y'
					},
					{
						label: t('UnitPriceHistoryTile.UnitsHistoryLegend'),
						data: timeSeriesUnitBarsBind(productInfo),
						fill: false,
						borderColor: '#dd7d07',
						backgroundColor: '#dd7d07',
						tension: 0.4,
						//lineTension: 0.9,
						//bezierCurve: true,
						showLine: true,
						borderWidth: 2,
						yAxisID: 'y1'
					}
				]
			}

			let sortedPrices = [...basicData.datasets[0].data].sort((a, b) => b.y - a.y);
			let sortedUnits = [...basicData.datasets[1].data].sort((a, b) => b.y - a.y);

			let highestPrice = sortedPrices[0].y;
			let highestUnits = sortedUnits[0].y;
			let minPrice = sortedPrices[sortedPrices.length - 1].y;
			//let minUnits = sortedUnits[sortedUnits.length - 1].y;

			basicOptions.scales.y1.max = Math.round(highestUnits * 1.1);
			basicOptions.scales.y1.min = -1 * basicOptions.scales.y1.max;

			//console.log('max', basicOptions.scales.y1.max);
			//console.log('min', basicOptions.scales.y1.min);

			basicOptions.scales.y.max = Math.round(highestPrice * 2) + ((minPrice > 0) ? 0 : 0.5);
			basicOptions.scales.y.min = minPrice > 0 ? (minPrice * 0.95).toFixed(2) : -0.25;

			basicOptions.scales.y.ticks.callback = function (value, index) {
				if (value >= 0 && value <= highestPrice) return value;
			}

			setBasicOptions(basicOptions);
			setChartData(basicData);
			setHasData(true);
		} else {
			setHasData(false);
		}
	};

	useEffect(() => {
		if (selectedItem != null) {
			fillChart();
		} else {
			setHasData(false);
		}
	}, [productInfo]) // eslint-disable-line react-hooks/exhaustive-deps

	if (hasData) {
		return (
			<div className="pricingPaneTileChart">
				<Chart
					type="line"
					key={"unitPriceHistoryChart_" + selectedItem.observationId}
					id={"unitPriceHistoryChart_" + selectedItem.observationId}
					data={chartData}
					options={basicOptions}
					style={{ position: 'relative', width: '100%' }} />
			</div>
				);
	} if (selectedItem === null) {
		return (<p className="text-md text-primary">{t('UnitPriceHistoryTile.PleaseSelectARow')}</p>);
	} else {
		return (<p className="text-md text-primary">{t('UnitPriceHistoryTile.NoHistoryData')}</p>);
	}
}

export default withTranslation()(UnitPriceHistory)