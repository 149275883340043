import axios from 'axios'
import Moment from 'moment';
import authService from '../../components/api-authorization/AuthorizeService';

export class PricingDataService {
	_subscription = null;
	_token = null;
	_subscribeTokenExpiration = null;

	constructor() {
		this._subscription = authService.subscribe(() => {
			this.authorize();
		});

		this._subscribeTokenExpiration = authService.subscribeTokenExpiration((newToken) => {
			if (newToken !== this._token) {
				// console.log('upgrading PRICING token', newToken);
				this._token = newToken;
			}
		});

		this.authorize();
	}

	authorize() {
		authService.isAuthenticated().then((isAuthenticated) => {
			// console.debug("PricingDataService, isAuthenticated: ", isAuthenticated);
			if (isAuthenticated) {
				authService.getAccessToken().then((token) => {
					this._token = token;
					// console.debug("PricingDataService, received token: ", token);
				});
			} else {
				this._token = null;
			}
		});

		// console.debug("PricingDataService: ", this._token);
	}

	async getPricingMaxSellingPrice(filters, searchTerm, searchTermOutsideFilters, includeRelationships) {
		let ret = 0;

		if (this._token != null) {
			const config = {
				headers: { Authorization: `Bearer ${this._token}` }
			};

			const parms = {
				from: -1,
				count: -1,
				sortField: null,
				sortOrder: -1,
				filters: filters,
				includeRelationships: includeRelationships !== undefined ? includeRelationships : true,	// By default, include relationships
				searchTerm: searchTerm,
				searchTermOutsideFilters: searchTermOutsideFilters
			};

			let response = await axios.post('pricedata/pricingTable/maxsellingprice/', parms, config);

			if (response.status === 200) {
				ret = response.data;
				// console.debug(`getPricingItemsCount() OK, res=${ret}`);
			} else {
				var error = response.data;
				console.error(error);
			}
		}

		return ret;
	}

	async getPricingMaxProfit(filters, searchTerm, searchTermOutsideFilters, includeRelationships) {
		let ret = 0;

		if (this._token != null) {
			const config = {
				headers: { Authorization: `Bearer ${this._token}` }
			};

			const parms = {
				from: -1,
				count: -1,
				sortField: null,
				sortOrder: -1,
				filters: filters,
				includeRelationships: includeRelationships !== undefined ? includeRelationships : true,	// By default, include relationships
				searchTerm: searchTerm,
				searchTermOutsideFilters: searchTermOutsideFilters
			};

			let response = await axios.post('pricedata/pricingTable/maxprofit/', parms, config);

			if (response.status === 200) {
				ret = response.data;
				// console.debug(`getPricingItemsCount() OK, res=${ret}`);
			} else {
				var error = response.data;
				console.error(error);
			}
		}

		return ret;
	}

	async getPricingItemsCount(filters, searchTerm, searchTermOutsideFilters, checkedItems, includeRelationships) {
		let ret = 0;

		if (this._token != null) {
			const config = {
				headers: { Authorization: `Bearer ${this._token}` }
			};

			const parms = {
				from: -1,
				count: -1,
				sortField: null,
				sortOrder: -1,
				filters: filters,
				includeRelationships: includeRelationships !== undefined ? includeRelationships : true,	// By default, include relationships
				searchTerm: searchTerm,
				searchTermOutsideFilters: searchTermOutsideFilters,
				selectedProducts: checkedItems == null ? null : checkedItems.map(obs => ({ sku: obs.sku, storeNumber: obs.storeNumber }))
			};

			let response = await axios.post('pricedata/pricingTable/count/', parms, config);

			if (response.status === 200) {
				ret = response.data;
				// console.debug(`getPricingItemsCount() OK, res=${ret}`);
			} else {
				var error = response.data;
				console.error(error);
			}
		}

		return ret;
	}

	async getPricingItems(parameters, filters, searchTerm, searchTermOutsideFilters, checkedItems) {
		let ret = [];
		if (this._token != null) {
			const config = {
				headers: { Authorization: `Bearer ${this._token}` }
			};

			const parms = {
				from: parameters.first ? parameters.first : 0,
				count: parameters.rows,
				sortField: parameters.sortField,
				sortOrder: parameters.sortOrder ? parameters.sortOrder : 0,
				includeRelationships: parameters.includeRelationships !== undefined ? parameters.includeRelationships : true,	// By default, include relationships
				filters: filters,
				searchTerm: searchTerm,
				searchTermOutsideFilters: searchTermOutsideFilters ? searchTermOutsideFilters : false,
				selectedProducts: checkedItems == null ? null : checkedItems.map(obs => ({ sku: obs.sku, storeNumber: obs.storeNumber }))
			};

			try {
				let response = await axios.post('pricedata/pricingTable/', parms, config);

				if (response.status === 200) {
					ret = response.data;
					// console.debug(`getPricingItems() OK, res=${ret}`);
				} else {
					var error = response.data;
					console.error(error);
				}
			} catch (e) {
				console.error(e);
			}
		}

		return ret;
	}

	async getPricingFilterItems(parameters, filters, searchTerm, searchTermOutsideFilters) {
		let ret = [];
		if (this._token != null) {
			const config = {
				headers: { Authorization: `Bearer ${this._token}` }
			};

			const parms = {
				from: parameters.first,
				count: parameters.rows,
				sortField: parameters.sortField,
				sortOrder: parameters.sortOrder ? parameters.sortOrder : 0,
				includeRelationships: parameters.includeRelationships !== undefined ? parameters.includeRelationships : true,	// By default, include relationships
				filters: filters,
				searchTerm: searchTerm,
				searchTermOutsideFilters: searchTermOutsideFilters
			};

			let response = await axios.post('pricedata/pricingTableFilterData/', parms, config);

			if (response.status === 200) {
				ret = response.data;
				// console.debug(`getPricingFilterItems() OK, res=${ret}`);
			} else {
				var error = response.data;
				console.error(error);
			}
		}

		return ret;
	}

	async exportToCSV(parameters, filters, searchTerm, searchTermOutsideFilters, columns, checkedItems) {
		let ret = null;

		if (this._token != null) {
			const config = {
				headers: {
					Authorization: `Bearer ${this._token}`,
					"X-XSRF-TOKEN": this.getAntiForgeryToken()
				}
			};

			const parms = {
				from: parameters.first,
				count: parameters.rows,
				sortField: parameters.sortField,
				sortOrder: parameters.sortOrder ? parameters.sortOrder : 0,
				includeRelationships: parameters.includeRelationships !== undefined ? parameters.includeRelationships : true,	// By default, include relationships
				filters: filters,
				searchTerm: searchTerm,
				searchTermOutsideFilters: searchTermOutsideFilters,
				selectedProducts: checkedItems == null ? null : checkedItems.map(obs => ({ sku: obs.sku, storeNumber: obs.storeNumber })),
				columns: columns.map(a =>
				({
					field: a.field,
					header: a.header
				}))
			};

			try {
				let response = await axios.post('pricedata/pricingTable/exportToCSV/', parms, config);

				if (response.status === 200) {
					if (response.data) {
						var binaryData = [];
						binaryData.push(response.data); //My blob
						var url = URL.createObjectURL(new Blob(binaryData, { type: "text/csv" }));
						let a = document.createElement('a');
						a.href = url;
						a.download = 'export.csv';
						a.click();
					}
					// console.debug(`exportToCSV() OK`);
				} else {
					var error = response.data;
					console.error(error);
					ret = error;
				}
			} catch (e) {
				let error = e.response.data
				console.error(error);
				ret = error;
			}
		}

		return ret;
	}

	async saveCurrentPrices(items) {
		let res = null;

		if (this._token != null) {
			const config = {
				headers: { Authorization: `Bearer ${this._token}` }
			};

			let changedItems = [];

			let i = 0;
			for (i = 0; i < items.length; i++) {
				changedItems.push({
					sku: items[i].sku,
					storeNumber: items[i].storeNumber,
					newPrice: items[i].recommendedSellingPrice,
					units: items[i].unitsPeriodT1,
					lastObservationId: items[i].observationId
				});
			}

			let response = await axios.post('pricedata/setCurrentPrice/', changedItems, config);

			if (response.status === 200) {
				let ret = response.data;
				if (ret === true) {
					// console.debug(`saveCurrentPrices() OK, res=${ret}`);
				} else {
					console.error(`saveCurrentPrices() FAIL, res=${ret}`);
					res = ret;
				}
			} else {
				var error = response.data;
				console.error(error);
				res = error;
			}
		}

		return res;
	}

	async getProductInfo(observationId, allStores) {
		let ret = [];

		if (this._token != null) {
			const config = {
				headers: { Authorization: `Bearer ${this._token}` }
			};

			let response = await axios.get('pricedata/productdata/' + observationId + '/?allStores=' + allStores, config);

			if (response.status === 200) {
				ret = response.data;
				// console.debug(`getProductInfo() OK, res=${ret}`);
			} else {
				var error = response.data
				console.error(error);
			}
		}

		return ret;
		
	}

	async getSchedulerTasks() {
		var ret = null;

		if (this._token != null) {
			const config = {
				headers: { Authorization: `Bearer ${this._token}` }
			};

			let response = await axios.get('pricing/jobs', config);

			if (response.status === 200) {
				// console.debug("getSchedulerTasks() OK");
				ret = response.data;
				// console.log(response.data);
			} else {
				let error = response.data;
				console.error(error);
			}
		}

		return ret;
	}

	async deleteSchedulerTask(task) {
		var ret = null;

		if (this._token != null) {
			const config = {
				headers: {
					Authorization: `Bearer ${this._token}`,
					"X-XSRF-TOKEN": this.getAntiForgeryToken()
				}
			};

			let response = await axios.delete('pricing/job/' + task.jobGuid, config);

			if (response.status === 200) {
				// console.debug("deleteSchedulerTask() OK");
				// ret = response.data;
				// console.log(response.data);
			} else {
				let error = response.data;
				console.error(error);
			}
		}

		return ret;
	}

	async runPricingNow() {
		var res = '';

		if (this._token != null) {
			const config = {
				headers: {
					Authorization: `Bearer ${this._token}`,
					"X-XSRF-TOKEN": this.getAntiForgeryToken()
				}
			};

			try {
				let response = await axios.post('pricing/runnow', null, config);

				if (response.status === 200) {
					// console.debug("runPricingNow() OK");
					res = null;
				} else {
					let error = response.data;
					console.error(error);
					res = error;
				}
			} catch (e) {
				let error = e.response.data
				console.error(error);
				res = error;
			}
		}

		return res;
	}

	async retrieveSchedulingConfiguration() {
		var ret = null;    // Default value

		if (this._token != null) {
			const config = {
				headers: { Authorization: `Bearer ${this._token}` }
			};

			let response = await axios.get('pricing/schedulingConfiguration', config);

			if (response.status === 200) {
				ret = response.data;
			} else {
				var error = response.data;
				console.error(error);
			}
		}

		return ret;
	}

	async schedulePricing(parms) {
		var ret = '';

		if (this._token != null) {
			const config = {
				headers: {
					Authorization: `Bearer ${this._token}`,
					"X-XSRF-TOKEN": this.getAntiForgeryToken()
				}
			};

			try {
				let response = await axios.post('pricing/schedulerun', parms, config);

				if (response.status === 200) {
					// console.debug("schedulePricing() OK");
					ret = null;
				} else {
					let error = response.data;
					console.error(error);
					ret = error;
				}
			} catch (e) {
				let error = e.response.data
				console.error(error);
				ret = error;
			}
		}

		return ret;
	}

	async getLastRepricingDateTime() {
		var ret = '';

		if (this._token != null) {
			const config = {
				headers: { Authorization: `Bearer ${this._token}` }
			};

			try {
				let response = await axios.get('pricing/lastrun', config);

				if (response.status === 200) {
					// console.debug("getLastRepricingDateTime() OK");
					let value = response.data;
					// Server date comes in UNIVERSAL TIME (GMT+0), so let's convert to local time
					ret = Moment.utc(value).local().format('yyyy-MM-DD HH:mm:ss');
					// ret = Moment(value).format('yyyy-MM-DD HH:mm:ss');
				} else {
					let error = response.data;
					console.error(error);
					ret = 'N/A';
				}
			} catch (e) {
				let error = e.response.data
				console.error(error);
				ret = 'N/A';
			}
		}

		return ret;
	}

	async saveRelationship(relationshipData) {
		let ret = false;

		if (this._token != null) {
			const config = {
				headers: {
					Authorization: `Bearer ${this._token}`,
					"X-XSRF-TOKEN": this.getAntiForgeryToken()
				}
			};

			try {
				let response = await axios.post('relationshipsdata', relationshipData, config);

				if (response.status === 200) {
					ret = response.data;
					// console.debug(`saveRelationship() OK, res=${ret}`);
				} else {
					ret = false;
				}
			} catch (e) {
				console.error(e);
				ret = false;
			}
		}

		return ret;
	}

	async getRelationships() {
		let ret = null;

		if (this._token != null) {
			const config = {
				headers: {
					Authorization: `Bearer ${this._token}`,
					"X-XSRF-TOKEN": this.getAntiForgeryToken()
				}
			};

			try {
				let response = await axios.get('relationshipsdata', config);

				if (response.status === 200) {
					ret = response.data;
					// console.debug(`getPricingItemsCount() OK, res=${ret}`);
				} else {
					ret = null;
				}
			} catch (e) {
				console.error(e);
				ret = null;
			}
		}

		return ret;
	}

	async deleteGroup(relationshipId) {
		let ret = null;

		if (this._token != null) {
			const config = {
				headers: {
					Authorization: `Bearer ${this._token}`,
					"X-XSRF-TOKEN": this.getAntiForgeryToken()
				}
			};

			try {
				let response = await axios.delete(`relationshipsdata/?relationshipId=${relationshipId}`, config);

				if (response.status === 200) {
					ret = true;
					// console.debug(`getPricingItemsCount() OK, res=${ret}`);
				} else {
					ret = false;
				}
			} catch (e) {
				console.error(e);
				ret = false;
			}
		}

		return ret;
	}

	getAntiForgeryToken() {
		const xsrfToken = document.cookie
			.split("; ")
			.find(row => row.startsWith("XSRF-TOKEN="))
			.split("=")[1];

		return xsrfToken;
	}

	static get instance() { return pricingService; }
}

const pricingService = new PricingDataService();

export default pricingService;