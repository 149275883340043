import React from "react";

const PricingProgressBar = (props) => {
	const { percentage, value, currencySymbol } = props;

	var thePerc = percentage;

	if (thePerc > 0) {
		thePerc -= 5;	// Set 5% less of the full width to fit the price
	}

	const fillerStyles = {
		width: `${thePerc.toFixed(2)}%`,
		display: 'block'
	}

	return (
		<div role="progressbar" className="p-progressbar p-component p-progressbar-determinate p-progressbar-height p-progressbar-nobackground" aria-valuemin={value.toFixed(2)} aria-valuenow={value.toFixed(2)} aria-valuemax={value.toFixed(0)} aria-label={value.toFixed(2)}>
			<div style={{ width: '75%' }}>
				<div className="p-progressbar-value p-progressbar-value-animate" style={fillerStyles}></div>
			</div>
			<div className="p-progressbar-label" style={{ width: '25%' }}>{currencySymbol}{value.toFixed(2)}</div>
		</div>
	);
};

export default PricingProgressBar;
