import React, { useEffect, useState } from 'react'
import ConfigActionBtn from '../Config/ConfigActionBtn'
import { withTranslation } from 'react-i18next';
import { InputText } from 'primereact/inputtext';
import { DataTable } from 'primereact/datatable';
import { Dropdown } from 'primereact/dropdown';
import { InputNumber } from 'primereact/inputnumber';
import { Checkbox } from 'primereact/checkbox';
import { confirmDialog } from 'primereact/confirmdialog';
import { Column } from 'primereact/column';
import { ScrollPanel } from 'primereact/scrollpanel';
import { Button } from 'primereact/button';
import importService from '../../Api/requests/import'
import messagesService from '../../Api/client-side/messages'

function FieldMappings(props) {
	const { t } = props;
	const { setLoading } = props;
	const { allowed } = props;
	const { showHelp } = props;

	const [dataChanged, setDataChanged] = useState(false)
	const [tableType, setTableType] = useState(0)
	const [mappings, setMappings] = useState(null)

	const tableTypes = [
		{ value: 0, label: "Transaction Data" },
		{ value: 1, label: "Products" }
	];

	const confirmLostChanges = (callback) => {
		if (dataChanged) {
			confirmDialog({
				message: t('FieldMappings.ConfirmRefresh'),
				header: t('FieldMappings.ConfirmOverwriteHeader'),
				acceptLabel: t('FieldMappings.ConfirmDialogYes'),
				rejectLabel: t('FieldMappings.ConfirmDialogNo'),
				icon: 'pi pi-exclamation-triangle',
				accept: () => callback()
			});
		}
	}

	const confirmSave = async () => {
		confirmDialog({
			message: t('FieldMappings.ConfirmOverwriteMessage'),
			header: t('FieldMappings.ConfirmOverwriteHeader'),
			acceptLabel: t('FieldMappings.ConfirmDialogYes'),
			rejectLabel: t('FieldMappings.ConfirmDialogNo'),
			icon: 'pi pi-exclamation-triangle',
			accept: async () => {

				let res = await importService.saveMappings(mappings);

				if (res === null) {
					messagesService.showSuccess(t('FieldMappings.MappingsRefreshedTitle'), t('FieldMappings.MappingsRefreshedMessage'));
					await reloadTable(tableType);
				} else {
					messagesService.showError(t('FieldMappings.MappingsRefreshedErrorTitle'), t('FieldMappings.MappingsRefreshedErrorMessage'));
				}
			}
		});
	}

	const reloadTable = async (theTableType) => {
		setLoading(true);
		let mappings = await importService.getMappingItems(theTableType);
		setMappings(mappings);
		setDataChanged(false);
		setLoading(false);
	}

	const onTableTypeChange = async (val) => {
		if (dataChanged) {
			confirmLostChanges(async () => {
				setTableType(val);
				await reloadTable(val);
			});
		} else {
			setTableType(val);
			await reloadTable(val);
		}
	}

	const confirmReset = () => {
		onTableTypeChange(tableType);
	}

	useEffect(() => {
		reloadTable(tableType);
	}, []); // eslint-disable-line react-hooks/exhaustive-deps

	const treatBlanksViewTemplate = (rowData) => {
		const statuses = [
			{ value: '', label: 'None' },
			{ value: 'Zero', label: t('FieldMappings.TreatAsBlank_Zero') },
			{ value: 'AverageOfSurrounding', label: t('FieldMappings.TreatAsBlank_AverageOfSurrounding') }
		];

		var text = "";
		var found = false;

		for (var i = 0; i < statuses.length && !found; i++) {
			if (statuses[i].value === rowData['treatBlanks']) {
				text = statuses[i].label;
				found = true;
			}
		}

		return (<span>{text}</span>);
	}

	const treatBlanksAsValueChange = (props, field) => {
		const statuses = [
			{ value: '', label: 'None' },
			{ value: 'Zero', label: t('FieldMappings.TreatAsBlank_Zero') },
			{ value: 'AverageOfSurrounding', label: t('FieldMappings.TreatAsBlank_AverageOfSurrounding') }
		];

		return (
			<Dropdown value={props.rowData['treatBlanks']} options={statuses} optionLabel="label" optionValue="value"
				onChange={(e) => onEditorValueChange(props, e.target.value)} placeholder={t('FieldMappings.SelectTreatAsBlank')}
				itemTemplate={(option) => {
					return <span>{option.label}</span>
				}} />
		);
	}

	const eliminateIfLessThanEditor = (props) => {
		var value = (props.rowData['eliminateIfLessThan']) ? props.rowData['eliminateIfLessThan'] : 0.0;

		return <InputNumber value={value} mode="decimal" inputClassName="inputfield textbox" minFractionDigits={1} maxFractionDigits={2} onValueChange={(e) => onEditorValueChange(props, e.value)} />;
	}

	const onEditorValueChange = (config, value) => {
		let updatedMappings = [...config.props.value]
		let item = updatedMappings[config.rowIndex]
		item[config.field] = value
		item['changed'] = true;

		if (config.rowData) {
			config.rowData[config.field] = value;
		}

		setMappings(updatedMappings)

		setDataChanged(true);
	}

	const displayColumnEditor = (rowData, props) => {
		return (
			<Checkbox checked={rowData['displayColumn']} onChange={(e) => onEditorValueChange(props, e.checked)} />
		);
	}

	const relationshipScreenEditor = (rowData, props) => {
		// console.log(rowData);
		return (
			<Checkbox checked={rowData['relationshipScreen']} onChange={(e) => onEditorValueChange(props, e.checked)} />
		);
	}

	const cannotBeBlankEditor = (rowData, props) => {
		return (
			<Checkbox checked={rowData['cannotBeBlank']} onChange={(e) => onEditorValueChange(props, e.checked)} />
		);
	}

	const nameEditor = (props) => {
		return <InputText type="text" className="inputfield textbox" value={props.rowData['name']} onChange={(e) => { onEditorValueChange(props, e.target.value); }} />;
	}

	const columnHeaderEditor = (props) => {
		let theValue = props.rowData['columnHeader'];
		return <InputText type="text" className="inputfield textbox" value={theValue ? theValue : ''} onChange={(e) => { onEditorValueChange(props, e.target.value); }} />;
	}

	if (allowed !== true) {
		return (<></>);
	} else {
		return (
			<>
				<h3 className="text-primary pt-3 pb-3 pl-3">
					{t('FieldMappings.Header')}
					<Button label="" title={t('FieldMappings.HelpButton')} icon="pi pi-question-circle" className="p-button-config-help p-button-rounded p-button-info" onClick={() => showHelp('FieldMappings.ImportHelpTitle', 'FieldMappings.ImportHelpMessage')} />
				</h3>
				<div className="field grid mt-3 pl-3 pr-3">
					<div className="col-12 xl:col-5">
						<Dropdown id="language" name="language" value={tableType} options={tableTypes} optionLabel="label" optionValue="value" onChange={(e) => onTableTypeChange(e.value)} />
					</div>
				</div>

				<div className="field grid mt-3 pl-3 pr-3">
					<div className="col-12 md:col-12 scrollpanel-container">
						<ScrollPanel style={{ width: '100%', height: '400px' }} className="datatable datatable-responsive">
							<DataTable value={mappings} dataKey="mapping"
								className="p-datatable-config"
								paginator={false}
								footer={false}
								responsiveLayout="scroll"
								columnResizeMode="fit">
								<Column field="name" header={t('FieldMappings.FieldTableNameColumn')} bodyClassName="left-align-column" headerClassName="left-align-column" editor={nameEditor} style={{ width: '20%' }}></Column>
								<Column field="mapping" header={t('FieldMappings.FieldTableMappingColumn')} bodyClassName="left-align-column" headerClassName="left-align-column" style={{ width: '20%' }}></Column>
								<Column field="cannotBeBlank" header={t('FieldMappings.FieldTableCannotBeBlankColumn')} bodyClassName="center-column" headerClassName="center-column" body={cannotBeBlankEditor}></Column>
								<Column field="displayColumn" header={t('FieldMappings.FieldTableDisplayColumn')} bodyClassName="center-column" headerClassName="center-column" body={displayColumnEditor}></Column>
								<Column field="relationshipScreen" header={t('FieldMappings.FieldTableRelationshipColumn')} bodyClassName="center-column" headerClassName="center-column" body={relationshipScreenEditor}></Column>
								<Column field="columnHeader" header={t('FieldMappings.FieldTableColumnHeaderColumn')} bodyClassName="center-column" headerClassName="center-column" editor={columnHeaderEditor} style={{ width: '12%' }}></Column>
								<Column field="eliminateIfLessThan" header={t('FieldMappings.FieldTableEliminateIfLessThanColumn')} bodyClassName="center-column" headerClassName="center-column" editor={eliminateIfLessThanEditor} style={{ width: '15%' }}></Column>
								<Column field="treatBlanks" header={t('FieldMappings.FieldTableTreatBlanksColumn')} bodyClassName="left-align-column" headerClassName="left-align-column" body={treatBlanksViewTemplate} editor={treatBlanksAsValueChange} style={{ width: '15%' }}></Column>
							</DataTable>
						</ScrollPanel>
					</div>
				</div>

				<div className="flex justify-content-end mr-3 bottom-buttons">
					<ConfigActionBtn onClick={(e) => confirmSave()} buttonText={t('ConfigurationDialog.SaveButton')} disabled={!dataChanged} />
					<ConfigActionBtn onClick={(e) => confirmReset()} buttonText={t('ConfigurationDialog.CancelButton')} disabled={!dataChanged} />
				</div>
			</>
		)
	}
}

export default withTranslation()(FieldMappings)
