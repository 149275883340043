import React, { useEffect, useState } from 'react'
import { Chart } from 'primereact/chart'
import { withTranslation } from 'react-i18next'
import { Calculate } from "../../../Api/client-side/calculate";
import { ChartUtils } from "../../../Api/client-side/chartsUtils";

function DemandCurve(prop) {
	const { selectedItem, t } = prop
	const [hasData, setHasData] = useState(false)
	const [chartData, setChartData] = useState(null)
	let calculate = new Calculate();
	let chartUtils = new ChartUtils();

	const [basicOptions, setBasicOptions] = useState({
		maintainAspectRatio: false,
		aspectRatio: 1.20,
		responsive: true,
		fill: false,
		elements: {
			point: {
				pointStyle: 'rect',
				radius: 1
			}
		},
		animation: {
			duration: 0
		},
		plugins: {
			legend: {
				display: true,
				position: 'bottom',
				labels: {
					color: '#495057',
					boxWidth: 10,
					boxHeight: 10,
				}
			},
			tooltip: {
				// Disable the on-canvas tooltip
				enabled: false,

				callbacks: {
					title: (item) => {
						return chartUtils.createTitleDemandCurvesPricingPaneTooltips(item);
					}
				},
				external: (context) => { chartUtils.customDemandCurvesPricingPaneTooltips(context); }
			}
		},
		scales: {
			y: {
				title: {
					display: true,
					text: t('DemandCurveTile.UnitsAxisLabel')
				},
				ticks: {
					color: '#495057'
				},
				grid: {
					color: '#ebedef'
				}
			},
			x: {
				title: {
					display: true,
					text: t('DemandCurveTile.PriceAxisLabel')
				},
				ticks: {
					color: '#495057',
					callback: function (val, index) {
						if (val < 100) {
							return calculate.roundPrice(val).toFixed(2);
						} else {
							return calculate.roundPrice(val).toFixed(0);
						}
					}
				},
				grid: {
					color: '#ebedef'
				}
			}
		}
	});

	useEffect(() => {
		if (selectedItem != null) {
			if (selectedItem.demandCurve) {
				var historic = [];
				var outliers = [];
				var minHistoric = Number.POSITIVE_INFINITY;
				var maxHistoric = Number.NEGATIVE_INFINITY;
				var addedHistoricPoints = 0;

				var theIdx;
				for (theIdx = selectedItem.history.length - 1; theIdx >= 0 && addedHistoricPoints < selectedItem.demandCurveHistoricPoints; theIdx--) {
					if (calculate.includeHistoricPoint(theIdx, selectedItem)) {
						var historicPoint = {
							x: calculate.roundPrice(selectedItem.history[theIdx]),
							y: calculate.roundPrice(selectedItem.historyUnits[theIdx]),
							label: selectedItem.historyObservationDates[theIdx]
						};

						if (calculate.isOutlier(theIdx, selectedItem)) {
							outliers.push(historicPoint);
						} else {
							historic.push(historicPoint);
						}

						if (historicPoint.x > maxHistoric) {
							maxHistoric = historicPoint.x;
						}

						if (historicPoint.x < minHistoric) {
							minHistoric = historicPoint.x;
						}

						addedHistoricPoints++;
					}
				}

				var theData = calculate.retrieveDemandCurveData(selectedItem, null, true, minHistoric, maxHistoric)
					.map(d => ({ x: calculate.roundPrice(d.price), y: d.units }));

				const basicData = {
					datasets: [
						{
							label: t('DemandCurveTile.ChartCurveLegend'),
							data: theData,
							fill: false,
							borderColor: '#2e999e',
							backgroundColor: '#2e999e',
							showLine: true,
							cubicInterpolationMode: 'monotone',
							tension: 0.4,
							// bezierCurve: true,
							// lineTension: 0.6,
							borderWidth: 2,
						},
						{
							label: t('DemandCurveTile.ChartCurveLegendOutliers'),
							type: 'scatter',
							data: outliers,
							borderColor: '#8d350e',
							backgroundColor: '#8d350e',
							pointRadius: 3,
							pointStyle: 'circle'
						}
					]
				}
				var useWindowSizes = false;

				if (!useWindowSizes) {
					basicData.datasets.push({
						type: 'scatter',
						label: t('DemandCurveTile.Observations'),
						labels: historic.map(obs => obs.label),
						data: historic,
						pointRadius: 3,
						pointStyle: 'circle',
						borderColor: '#004547',
						backgroundColor: '#004547'
					});
				} else {
					var sizesColors = [
						'#004547',
						'#c05220',
						'#f0a583',
						'#bfd9dd',
						'#8fb9bf',
						'#2e999e',
						'#dd7d07',
						'#effafb',
						'#bc4712'
					];

					var windowSizes = selectedItem.windowSizes; //[12, 16, 20, 24];
					var lastSize = 0;

					for (var i = 0; i < windowSizes.length; i++) {
						var subset = historic.slice(lastSize, windowSizes[i]);
						var color = sizesColors[i % sizesColors.length];
						var from = (i === 0) ? 0 : windowSizes[i - 1];
						var to = windowSizes[i];

						lastSize = windowSizes[i];

						basicData.datasets.push({
							type: 'scatter',
							label: t('DemandCurveTile.ChartCurveHistoricPointsLegend', { pointsNumberFrom: from, pointsNumberTo: to }),
							labels: subset.map(obs => obs.label),
							data: subset,
							pointRadius: 3,
							pointStyle: 'circle',
							borderColor: color,
							backgroundColor: color
						});
					}
				}

				let minMax = calculate.minMaxDemandCurveChartValue(selectedItem, null, { min: minHistoric, max: maxHistoric }, 0.05);

				var basicOptionsCopy = { ...basicOptions };
				basicOptionsCopy.scales.x.min = minMax.min;
				basicOptionsCopy.scales.x.max = minMax.max;
				setBasicOptions(basicOptionsCopy);
				setChartData(basicData);
				setHasData(true);
			} else {
				setHasData(false);
			}
		} else {
			setHasData(false);
		}
	}, [selectedItem]) // eslint-disable-line react-hooks/exhaustive-deps

	if (hasData) {
		return (
			<div className="pricingPaneTileChart">
				<Chart
				type="scatter"
				key={"demandCurveChart_" + selectedItem.observationId}
				id={"demandCurveChart_" + selectedItem.observationId}
				data={chartData}
				options={basicOptions}
				style={{ position: 'relative', width: '100%' }} />
			</div>
				);
	} if (selectedItem === null) {
		return (<p className="text-md text-primary">{t('DemandCurveTile.PleaseSelectARow')}</p>);
	} else {
		return (<p className="text-md text-primary">{t('DemandCurveTile.NoDemandCurveMessage')}</p>);
	}
}

export default withTranslation()(DemandCurve)