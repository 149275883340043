import axios from 'axios'
import authService from '../../components/api-authorization/AuthorizeService';

export class UsersDataService {
	_subscription = null;
	_token = null;
	_subscribeTokenExpiration = null;

	constructor() {
		this._subscription = authService.subscribe(() => {
			this.authorize();
		});

		this._subscribeTokenExpiration = authService.subscribeTokenExpiration((newToken) => {
			if (newToken !== this._token) {
				// console.log('upgrading USERS DATA token', newToken);
				this._token = newToken;
			}
		});

		this.authorize();
	}

	authorize() {
		authService.isAuthenticated().then((isAuthenticated) => {
			// console.debug("UsersDataService, isAuthenticated: ", isAuthenticated);
			if (isAuthenticated) {
				authService.getAccessToken().then((token) => {
					this._token = token;
					// console.debug("UsersDataService, received token: ", token);
				});
			} else {
				this._token = null;
			}
		});

		// console.debug("UsersDataService: ", this._token);
	}

	async getUsersItemsCount() {
		let ret = 0;

		if (this._token != null) {
			const config = {
				headers: { Authorization: `Bearer ${this._token}` }
			};

			const parms = {
				sortField: null,
				sortOrder: -1
			};

			let response = await axios.post('users/count', parms, config);

			if (response.status === 200) {
				ret = response.data;
				// console.debug(`getUsersItemsCount() OK, res=${ret}`);
			} else {
				var error = response.data;
				console.error(error);
			}
		}

		return ret;
	}

	async getUsersItems(parameters) {
		let ret = [];
		if (this._token != null) {
			const config = {
				headers: { Authorization: `Bearer ${this._token}` }
			};

			const parms = {
				from: parameters.first,
				count: parameters.rows,
				sortField: parameters.sortField,
				sortOrder: parameters.sortOrder ? parameters.sortOrder : 0
			};

			let response = await axios.post('users', parms, config);

			if (response.status === 200) {
				ret = response.data;
				// console.debug(`getPricingItems() OK, res=${ret}`);
			} else {
				var error = response.data;
				console.error(error);
			}
		}

		return ret;
	}

	async saveUser(user) {
		let res = null;

		if (this._token != null) {
			const config = {
				headers: {
					Authorization: `Bearer ${this._token}`,
					"X-XSRF-TOKEN": this.getAntiForgeryToken()
				}
			};

			try {
				let response = await axios.put('users', user, config);

				if (response.status === 200) {
					// let ret = response.data;
					// console.debug(`saveUser() OK, res=${ret}`);
				} else {
					var error = response.data;
					console.error(error);
					res = error;
				}
			} catch (e) {
				res = e;
			}
		}

		return res;
	}

	async checkMailAllowed(id, mail) {
		let ret = 0;

		if (this._token != null) {
			const config = {
				headers: {
					Authorization: `Bearer ${this._token}`,
					"X-XSRF-TOKEN": this.getAntiForgeryToken()
				}
			};

			const parms = {
				id: id,
				email: mail
			};

			let response = await axios.post('users/checkemail', parms, config);

			if (response.status === 200) {
				ret = response.data;
				// console.debug(`getUsersItemsCount() OK, res=${ret}`);
			} else {
				var error = response.data;
				console.error(error);
			}
		}

		return ret;
	}

	async agreementUpToDate(theUserId) {
		var ret = false;

		if (this._token != null) {
			const config = {
				headers: {
					Authorization: `Bearer ${this._token}`
				}
			};

			let response = await axios.get('users/acceptAgreement?userId=' + theUserId, config);

			if (response.status === 200) {
				// console.debug("agreementUpToDate() OK");
				ret = response.data;
			} else if (response.status === 400) {
				ret = 400;
				console.error(ret);
			} else {
				let error = response.data;
				ret = error;
				console.error(error);
			}
		}

		return ret;
	}

	async aceptAgreement(theUserId, signatureData) {
		var ret = null;

		if (this._token != null) {
			const config = {
				headers: {
					Authorization: `Bearer ${this._token}`,
					"X-XSRF-TOKEN": this.getAntiForgeryToken()
				}
			};

			const parms = {
				userId: theUserId,
				signature: signatureData
			};

			let response = await axios.post('users/acceptAgreement', parms, config);

			if (response.status === 200) {
				// console.debug("deleteUser() OK");
				ret = null;
			} else if (response.status === 400) {
				ret = 400;
				console.error(ret);
			} else {
				let error = response.data;
				ret = error;
				console.error(error);
			}
		}

		return ret;
	}

	async deleteUser(user) {
		var ret = null;

		if (this._token != null) {
			const config = {
				headers: {
					Authorization: `Bearer ${this._token}`,
					"X-XSRF-TOKEN": this.getAntiForgeryToken()
				}
			};

			let response = await axios.delete('users/' + user.id, config);

			if (response.status === 200) {
				// console.debug("deleteUser() OK");
				ret = null;
			} else if (response.status === 400) {
				ret = 400;
				console.error(ret);
			} else {
				let error = response.data;
				ret = error;
				console.error(error);
			}
		}

		return ret;
	}

	getAntiForgeryToken() {
		const xsrfToken = document.cookie
			.split("; ")
			.find(row => row.startsWith("XSRF-TOKEN="))
			.split("=")[1];

		return xsrfToken;
	}

	static get instance() { return usersdataService; }
}

const usersdataService = new UsersDataService();

export default usersdataService;