import React from "react";
import { Chart } from "primereact/chart";

function PieChart(props) {
	const chartData = props?.data;

	// const chartData = {
	//   datasets: props?.data,
	// };
	let basicOptions = {
		maintainAspectRatio: false,
		aspectRatio: 1.4,
		plugins: {
			legend: props.legend,
			tooltip: props.tooltip
		},
		scales: props.scales,
	};

	return (
		<>
			<div className="card flex justify-content-center">
				<Chart
					type={props.typeChart}
					data={chartData}
					options={basicOptions}
					style={{ position: "relative", width: "40%" }}
				/>
			</div>
		</>
	);
}

export default PieChart;
