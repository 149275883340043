import React, { useState } from "react";
import { TabView, TabPanel } from "primereact/tabview";
import KpiPerformanceChart from "../Charts/KpiPerformanceChart/KpiPerformanceChart";

const PerformanceChart = (props) => {
  const [activeTab, setActiveTab] = useState(0);
	
	const generateKpiPerformanceChartData = (rawData, maxBarThickness, lables) => {
	return {
	  labels: lables,
	  datasets: rawData.map((obj) => {
		return {
		  label: obj.label,
		  // maxBarThickness: maxBarThickness,
			accumulated: obj.accumulated,
		  data: obj.categories.map((item) => item.value),
		  backgroundColor: obj.color,
		};
	  }),
	};
  };

  return (
	<div className="dashboard-widget">
	  <div className="widget-inner-box">
		<div className="dashboard-tab">
		  <TabView
			activeIndex={activeTab}
			onTabChange={(e) => setActiveTab(e.index)}
			className="tabview-custom"
		  >
			{props.data.tabs.map((tab, i) => {
			  return (
				<TabPanel header={tab.name} key={i}>
				  {/* bar chart */}
				  {tab.type === "bar" && (
                    <div className="grid">
					  <div className="col-12 lg:col-12">
						<KpiPerformanceChart
						  typeChart={tab.type}
						  legend={tab.legend}
						  title={tab.title}
					      scales={tab.scales}
						  tooltip={tab.tooltip}
						  data={generateKpiPerformanceChartData(
							tab.bar,
							tab.maxBarThickness,
							tab.labels
						  )}
						/>
					  </div>
					</div>
				  )}
				</TabPanel>
			  );
			})}
		  </TabView>
		  <i className="widget-option-btn pi pi-chevron-circle-down"></i>
		</div>
	  </div>
	</div>
  );
};

export default PerformanceChart;
