import axios from 'axios'
// import { isValidElement } from 'react';
import authService from '../../components/api-authorization/AuthorizeService';
import moment from 'moment';

export class PatternsDataService {
	_subscription = null;
	_token = null;
	_subscribeTokenExpiration = null;

	_backgroundColors = [
		'#044a4d',
		'#0e5c5f',
		'#186e72',
		'#2f8c90',
		'#389397',
		'#48a1a5',
		'#55aaae',
		'#6bb6b9',
		'#7cbec1',
		'#91c9cb',
		'#a8d3d5',
		'#c5dfe0',
		'#dae8e9',
		'#f2f1ef',
		'#f5e8d9',
		'#f9dcbe',
		'#fcd2a6',
		'#ffc586',
		'#ffb566',
		'#ffa445',
		'#ff9425',
		'#f9841b',
		'#f17418',
		'#e96314',
		'#db5511',
		'#bf4810',
		'#a63d0e',
		'#8e320d'
	];

	constructor() {
		this._subscription = authService.subscribe(() => {
			this.authorize();
		});

		this._subscribeTokenExpiration = authService.subscribeTokenExpiration((newToken) => {
			if (newToken !== this._token) {
				// console.log('upgrading PatternsDataService token', newToken);
				this._token = newToken;
			}
		});

		this.authorize();
	}

	authorize() {
		authService.isAuthenticated().then((isAuthenticated) => {
			// console.debug("PricingDataService, isAuthenticated: ", isAuthenticated);
			if (isAuthenticated) {
				authService.getAccessToken().then((token) => {
					this._token = token;
					// console.debug("PricingDataService, received token: ", token);
				});
			} else {
				this._token = null;
			}
		});

		// console.debug("PatternsDataService: ", this._token);
	}

	retrieveMappingHeader(mapping, failbackName) {
		if (mapping.columnHeader) {
			return mapping.columnHeader;
		} else {
			return failbackName;
		}
	}

	addProductMapping(m) {
		return (m.mapping === 'Manufacturer' ||
			m.mapping === 'Category' ||
			m.mapping === 'Sub_Category' ||
			m.mapping === 'Size');
	}

	isCategorical(factor) {
		switch (factor) {
			case 0:		// Date
			case 1:		// StoreNumber
			case 2:		// SKU
			case 3:		// Description
			case 8:		// PromoFlag1
			case 9:		// PromoFlag2
			case 10:	// PromoFlag3
			case 15:	// StockOut
			case 18:	// Product_Manufacturer,
			case 19:	// Product_Category,
			case 20:	// Product_SubCategory,
			case 21:	// Product_Size
			case 22:	// Dummy4
			case 23:	// Dummy5
			case 24:	// Dummy6
			case 25:	// Dummy7
			case 26:	// Dummy8
			case 27:	// Dummy9
			case 28:	// Dummy10
			case 29:	// Dummy11
			case 30:	// Dummy12
			case 31:	// Dummy13
			case 32:	// Dummy14
			case 33:	// Dummy15
			case 34:	// Dummy16
			case 203:	// GuardrailsApplied
				return true;
			default:
				return false;
		}
	}

	factorOperationIsAllowed(factor, operation) {
		// Only NUMERICAL factors are checked, for categorical it's only COUNT operation allowed
		switch (factor) {
			case 4: // Units
				return true;	// All operations allowed
			case 5: // Revenue
				return true;	// All operations allowed
			case 6: // SellingPrice
				return (operation === 1 || operation === 2);	// Only AVG and MED allowed
			case 7: // ListPrice
				return (operation === 1 || operation === 2);	// Only AVG and MED allowed
			case 11:  // UnitCost
				return (operation === 1 || operation === 2);	// Only AVG and MED allowed
			case 12:  // Inventory
				return true;	// All operations allowed
			case 13:  // InventoryStarting
				return true;	// All operations allowed
			case 14: // InventoryEnding 
				return true;	// All operations allowed

			// Derived fields
			case 200: // SuggestedPrice
				return (operation === 1 || operation === 2);	// Only AVG and MED allowed
			case 201: // ImplementedPrice
				return (operation === 1 || operation === 2);	// Only AVG and MED allowed
			case 202: // ProfitPotential
			case 204: // ActualProfit
				return true;	// All operations allowed
			case 1000: // SKUsSold
				return false;	// No operation here, only COUNT
			default:
				return false;
		}
	}

	async deletePreset(preset) {
		if (this._token != null) {
			const config = {
				headers: {
					Authorization: `Bearer ${this._token}`,
					"X-XSRF-TOKEN": this.getAntiForgeryToken()
				}
			};

			try {
				var response = await axios.delete(`patterns/presets?id=${preset.id}`, config);

				if (response.status === 200) {
					return null;
				} else {
					let error = response.data;
					console.error(error);
					return error;
				}
			} catch (e) {
				console.error(e);
				return e;
			}
		}
	}

	async savePresets(presets) {
		if (this._token != null) {
			const config = {
				headers: {
					Authorization: `Bearer ${this._token}`,
					"X-XSRF-TOKEN": this.getAntiForgeryToken()
				}
			};

			var changedPresets = presets.filter((e) => { return e.dirty === true; });

			try {
				var response = await axios.put(`patterns/presets`, changedPresets, config);

				if (response.status === 200) {
					return null;
				} else {
					let error = response.data;
					console.error(error);
					return error;
				}
			} catch (e) {
				console.error(e);
				return e;
			}
		}
	}

	sortFactors(a, b) {
		const labelA = a.label.toUpperCase(); // ignore upper and lowercase
		const labelB = b.label.toUpperCase(); // ignore upper and lowercase
		if (labelA < labelB) {
			return -1;
		}
		if (labelA > labelB) {
			return 1;
		}

		// names must be equal
		return 0;
	}

	isScalarMetric(metric) {
		switch (metric) {
			case 4: // Units
			case 5: // Revenue
			case 6: // SellingPrice
			case 7: // ListPrice
			case 11:  // UnitCost
			case 12:  // Inventory
			case 13:  // InventoryStarting
			case 14: // InventoryEnding 
			case 200: // SuggestedPrice
			case 201: // ImplementedPrice
			case 202: // ProfitPotential
			case 204: // ActualProfit
			case 1000: // SKUsSold
				return true;	// All operations allowed
			default:
				return false;
		}
	}

	async getPatternsConfig() {
		var response;

		if (this._token != null) {
			const config = {
				headers: {
					Authorization: `Bearer ${this._token}`,
					"X-XSRF-TOKEN": this.getAntiForgeryToken()
				}
			};

			try {
				response = await axios.post(`patterns/config`, null, config);

				if (response.status !== 200) {
					return {};
				} else {
					return response.data;
				}
			} catch (e) {
				console.error(e);
				return {};
			}
		}
	}

	async savePatternsConfig(cfgData) {
		if (this._token != null) {
			const config = {
				headers: {
					Authorization: `Bearer ${this._token}`,
					"X-XSRF-TOKEN": this.getAntiForgeryToken()
				}
			};

			try {
				var response = await axios.put(`patterns/config`, cfgData, config);

				if (response.status === 200) {
					return null;
				} else {
					let error = response.data;
					console.error(error);
					return error;
				}
			} catch (e) {
				console.error(e);
				return e;
			}
		}
	}

	async getFactorsAndMetrics(importService, t) {
		var userSpecified = [];
		var derived = [];

		// Retrieve all mappings
		let mappings = await importService.getMappingItems(0);
		let mappingsProducts = await importService.getMappingItems(1);

		mappings.forEach((m) => {
			if (m.displayColumn === true) {
				userSpecified.push({
					label: this.retrieveMappingHeader(m, t(`Patterns.Factor_${m.mapping}`)),
					id: m.internalNameId,
					isDerivedField: false
				});
			}
		});

		mappingsProducts.forEach((m) => {
			if (m.displayColumn === true) {
				if (this.addProductMapping(m)) {
					userSpecified.push({
						label: this.retrieveMappingHeader(m, t(`Patterns.Factor_${m.mapping}`)),
						id: m.internalNameId,
						isDerivedField: false
					});
				}
			}
		});

		// Add derived factors
		let derivedFactors = [
			{
				mapping: 'SuggestedPrice',
				id: 200
			},
			//{
			//	mapping: 'ImplementedPrice',
			//	id: 201
			//},
			{
				mapping: 'ProfitPotential',
				id: 202
			},
			{
				mapping: 'GuardrailsApplied',
				id: 203
			},
			{
				mapping: 'ActualProfit',
				id: 204
			}
		];

		derivedFactors.forEach((m) => {
			derived.push({
				label: this.retrieveMappingHeader(m, t(`Patterns.Factor_${m.mapping}`)),
				id: m.id,
				isDerivedField: true,
			});
		});

		var theFactors = [
			{
				groupLabel: t('Patterns.DerivedFactorsGroup'),
				items: derived.sort(this.sortFactors)
			},
			{
				groupLabel: t('Patterns.UserSpecifiedFactorsGroup'),
				items: userSpecified.sort(this.sortFactors)
			}
		];

		var theMetrics = [];

		mappings.forEach((m) => {
			if (m.displayColumn === true) {
				if (this.isScalarMetric(m.internalNameId)) {
					theMetrics.push({
						label: this.retrieveMappingHeader(m, t(`Patterns.Factor_${m.mapping}`)),
						id: m.internalNameId,
						isDerivedField: false
					});
				}
			}
		});

		derived.forEach((m) => {
			if (this.isScalarMetric(m.id)) {
				theMetrics.push({
					label: m.label,
					id: m.id,
					isDerivedField: true
				});
			}
		});

		// Special Metrics
		let specialMetrics = [
			{
				mapping: 'SKUsSold',
				id: 1000
			}
		];

		specialMetrics.forEach((m) => {
			if (this.isScalarMetric(m.id)) {
				theMetrics.push({
					label: this.retrieveMappingHeader(m, t(`Patterns.Factor_${m.mapping}`)),
					id: m.id,
					isDerivedField: true,
					onlyCategorical: true
				});
			}
		});
		
		return {
			factors: theFactors,
			metricsForCategoricalFactors: theMetrics
		};
	}

	async getPresets() {
		var response;

		if (this._token != null) {
			const config = {
				headers: {
					Authorization: `Bearer ${this._token}`,
					"X-XSRF-TOKEN": this.getAntiForgeryToken()
				}
			};

			try {
				response = await axios.post(`patterns/presets`, null, config);

				if (response.status !== 200) {
					return {};
				} else {
					return response.data;
				}
			} catch (e) {
				console.error(e);
				return {};
			}
		}
	}

	getNumberFormatForLabels(value) {
		if (value < 1000) {
			return value;
		} else {
			var inK = (value / 1000);

			if ((inK - Math.trunc(inK)) > 0.1) {
				if (inK < 1000) {
					// Lower than 1000k, use 2 decimal points
					return `${inK.toFixed(2)}K`;
				} else {
					// Greater than 1000k, use 1 decimal point
					return `${inK.toFixed(1)}K`;
				}
			} else {
				return `${inK.toFixed(0)}K`;
			}
		}
	}

	calculateRange3RateOfChangeColor(value, ranges) {
		// rangesFactor3ForRateOfChange
		var colorIdx = 0;
		if (value <= ranges[0]) {
			// it's the MINIMUM range, so the comparison using < will not match!
			// Keep colorIdx = 0
			return this._backgroundColors[0];
		} else {
			while (ranges[colorIdx] < value) {
				colorIdx++;
			}
		}

		colorIdx = (colorIdx - 1) % this._backgroundColors.length;
		return this._backgroundColors[colorIdx];
	}

	calculateRange3Color(range3Index, factor3RangeCount) {
		var colorIdx = 0;
		if (range3Index === 0) {
			// First item
			colorIdx = 0;
		} else if (range3Index === factor3RangeCount - 1) {
			// Last item
			colorIdx = this._backgroundColors.length - 1;
		} else if (factor3RangeCount > this._backgroundColors.length) {
			// We have no enough colors for assignment, so we need to repeat
			colorIdx = range3Index % this._backgroundColors.length;			
		} else {
			// Middle item when we have more than enough colors
			var assignmentStep = this._backgroundColors.length / factor3RangeCount;

			var calculatedIdx = range3Index * assignmentStep;

			if ((calculatedIdx - assignmentStep) > 0.5) {
				colorIdx = Math.min(this._backgroundColors.length - 2, Math.trunc(calculatedIdx) + 1);
			} else {
				colorIdx = Math.trunc(calculatedIdx);
			}
		}

		return this._backgroundColors[colorIdx];		
	}

	async getPatternsDataForFactors(selectedFactors, currencySymbol, t, factor3Name, operation3Name, factor2Name, operation2Name, factor1Name, operation1Name, metric1Name,
		metric2Name, filter) {
		var response;

		if (this._token != null) {
			const config = {
				headers: {
					Authorization: `Bearer ${this._token}`,
					// "X-XSRF-TOKEN": this.getAntiForgeryToken()
				}
			};

			try {

				if (filter != null) {
					let theParams = {
						...selectedFactors,
						...{
							filters: filter.filters,
							searchTerm: filter.searchText,
							searchTermOutsideFilters: filter.searchOutside
						}
					};

					response = await axios.post(`patterns/mekkodata`, theParams, config);
				} else {
					response = await axios.post(`patterns/mekkodata`, selectedFactors, config);
				}

				if (response.status !== 200) {
					return {};
				}
			} catch (e) {
				console.error(e);
				return {};
			}
		}

		var theData = response.data;
		var theDatasets = theData.datasets;

		var range3Index = 0;

		theDatasets.forEach((item, idx) => {
			var itemOriginalLabel = item.label;

			// COMMENT THIS OUT: It's for testing negative y values
			// for (var ix = 0; ix < item.data.length; ix++) {
			//	if (ix % 2 == 0) item.data[ix] *= -1;
			//}

			item.backgroundColor = this.calculateRange3Color(0, 0);	// Default green color when no range 3 is defined
			item.borderColor = 'white';
			item.label = t(`${item.label}`);
			item.twoFactorsLabel = this.isValidFactorValue(selectedFactors.factor2) ?  t('Patterns.Factor2TooltipFormat', { factor: factor2Name, operation: operation2Name }) : null;
			item.twoFactorsName = this.isValidFactorValue(selectedFactors.factor2) ? factor2Name : null;
			item.firstFactorLabel = t('Patterns.Factor2WithMetricTooltipFormat', { factor: factor1Name, metric: metric1Name, operation: operation1Name });
			item.thirdFactorName = this.isValidFactorValue(selectedFactors.factor3) ? factor3Name : null;
			item.currencySymbol = currencySymbol;
			item.factor1IsCurrency = theData.rangesFactor1AreCurrency;
			item.factor2IsCurrency = theData.rangesFactor2AreCurrency;
			item.factor3IsCurrency = theData.rangesFactor3AreCurrency;
			item.metric1IsCurrency = theData.metric1IsCurrency;
			item.metric2IsCurrency = theData.metric2IsCurrency;
			item.metric3IsCurrency = theData.metric3IsCurrency;	
			item.metric1IsRateOfChange = theData.rangesFactor1AreRateOfChange;	
			item.metric2IsRateOfChange = theData.rangesFactor2AreRateOfChange;	
			item.metric3IsRateOfChange = theData.rangesFactor3AreRateOfChange;

			if (this.isValidFactorValue(selectedFactors.factor2)) {
				if (!this.isValidFactorValue(selectedFactors.factor3)) {
					// Only factor 1 and 2 available, swap labels for tooltip
					item.twoFactorsLabel = item.twoFactorsName;
					item.twoFactorsName = this.isValidFactorValue(selectedFactors.factor2) ? t('Patterns.Factor2WithMetricTooltipFormat', { factor: factor2Name, metric: metric2Name, operation: operation2Name }) : null;
				} else {
					item.twoFactorsLabel = item.twoFactorsName;
					item.twoFactorsLabel = this.isValidFactorValue(selectedFactors.factor2) ? t('Patterns.Factor2WithMetricTooltipFormat', { factor: factor2Name, metric: metric2Name, operation: operation2Name }) : null;
				}
			}

			let factor3Precission = 0; //; theData.rangesFactor3AreCurrency ? 2 : 0;

			let numberOfRange3 = 0;

			if (theData.rangesFactor3 && theData.rangesFactor3.length > 0) {
				range3Index = range3Index % (theData.rangesFactor3.length - 1);
				numberOfRange3 = theData.rangesFactor3.length - 1;
			} else {
				range3Index = range3Index % (theData.numberOfRanges3);
				numberOfRange3 = theData.numberOfRanges3;
			}

			if (this.isValidFactorValue(selectedFactors.factor3) || (this.isValidFactorValue(selectedFactors.factor2) && selectedFactors.rateOfChangeActivated === true)) {

				if (selectedFactors.rateOfChangeActivated) {
					var colorByRateFactor3 = [];
					item.aggregatedByFactor3RateOfChange.forEach((value, idx) => {
						colorByRateFactor3.push(this.calculateRange3RateOfChangeColor(value, theData.rangesFactor3ForRateOfChange));
					});

					item.backgroundColor = colorByRateFactor3;
				} else {
					item.backgroundColor = this.calculateRange3Color(range3Index, numberOfRange3);
				}

				if (this.isCategorical(selectedFactors.factor3)) {
					// label is kept in this case, only try to get a translation if available
					item.label = t(itemOriginalLabel.split(' ~ ')[1]);

					var tooltipByBarCategorical = [];
					var tooltipValueByBarCategorical = [];

					// console.debug(operation3Name);
					// console.debug(factor3Name);

					if (theData.factor3AggregatedByCategory) {
						theData.factor3AggregatedByCategory.forEach((value, idx) => {
							if (operation3Name && factor3Name) {
								var aggregationFactor3Name = t("Patterns.Factor3TooltipFormat", {
									operation: operation3Name,
									factor: factor3Name
								})

								tooltipByBarCategorical.push(aggregationFactor3Name);
								tooltipValueByBarCategorical.push(item.aggregatedByFactor3[idx]);
							} else {
								tooltipByBarCategorical.push('');
								tooltipValueByBarCategorical.push(item.aggregatedByFactor3[idx]);
							}
						});
					}

					item.tooltipFactor3AggregtedLegends = tooltipByBarCategorical;
					item.tooltipFactor3AggregatedValues = tooltipValueByBarCategorical;
				} else {
					if (theData.rangesFactor3 && theData.rangesFactor3.length > 0) {
						if (theData.rangesFactor2AreRateOfChange) {
							item.tooltipFactor3AggregtedLegends = null;
							item.tooltipFactor3AggregatedValues = null;
						} else {
							var tooltipByBar = [];
							var tooltipValueByBar = [];

							if (theData.rangesFactor3AreCurrency) {
								item.label = t("Patterns.PriceVsProfitMekko_ColorRangeLegend", {
									factor: factor3Name,
									operation: operation3Name,
									from: `${currencySymbol}${this.getNumberFormatForLabels(theData.rangesFactor3[range3Index])}`,
									to: `${currencySymbol}${this.getNumberFormatForLabels(theData.rangesFactor3[range3Index + 1])}`
								});
								if (item.aggregatedByFactor3) {
									item.aggregatedByFactor3.forEach((value, idx) => {
										var precission = factor3Precission;
										if ((value - Math.trunc(value)) < Number.EPSILON) {
											precission = 0;
										}
										if (operation3Name && factor3Name) {
											let aggregationFactor3Name = t("Patterns.Factor3TooltipFormat", {
												operation: operation3Name,
												factor: factor3Name
											});

											tooltipByBar.push(aggregationFactor3Name);
											tooltipValueByBar.push(item.aggregatedByFactor3[idx].toLocaleString(undefined, { maximumFractionDigits: precission }));
										} else {
											tooltipByBar.push('');
											tooltipValueByBar.push(item.aggregatedByFactor3[idx].toLocaleString(undefined, { maximumFractionDigits: precission }));
										}
									});
								}

							} else {
								item.label = t("Patterns.PriceVsProfitMekko_ColorRangeLegend", {
									factor: factor3Name,
									operation: operation3Name,
									from: `${this.getNumberFormatForLabels(theData.rangesFactor3[range3Index])}`,
									to: `${this.getNumberFormatForLabels(theData.rangesFactor3[range3Index + 1])}`
								});

								if (theData.factor3AggregatedByCategory) {
									theData.factor3AggregatedByCategory.forEach((value, idx) => {
										if (operation3Name && factor3Name) {
											let aggregationFactor3Name = t("Patterns.Factor3TooltipFormat", {
												operation: operation3Name,
												factor: factor3Name
											});

											tooltipByBar.push(aggregationFactor3Name);
											tooltipValueByBar.push(item.aggregatedByFactor3[idx].toLocaleString(undefined, { maximumFractionDigits: factor3Precission }));
										} else {
											tooltipByBar.push('');
											tooltipValueByBar.push(item.aggregatedByFactor3[idx].toLocaleString(undefined, { maximumFractionDigits: factor3Precission }));
										}
									});
								}

								//if (theData.factor3AggregatedByCategory) {
								//	theData.factor3AggregatedByCategory.forEach((value, idx) => {
								//		if (operation3Name && factor3Name) {
								//			let aggregationFactor3Name = t("Patterns.Factor3TooltipFormat", {
								//				operation: operation3Name,
								//				factor: factor3Name
								//			})

								//			tooltipByBar.push(aggregationFactor3Name);
								//			tooltipValueByBar.push(value.toLocaleString(undefined, { maximumFractionDigits: factor3Precission }));
								//		} else {
								//			tooltipByBar.push('');
								//			tooltipValueByBar.push(value.toLocaleString(undefined, { maximumFractionDigits: factor3Precission }));
								//		}
								//	});
								//}
							}

							item.tooltipFactor3AggregtedLegends = tooltipByBar;
							item.tooltipFactor3AggregatedValues = tooltipValueByBar;
						}
					}
				}

				if (!this.isCategorical(selectedFactors.factor2)) {
					// Note: for ELSE case, use length - 1 because we have 1 element more than the real amount of available ranges for factor 3.
					const factor3Items = theData.numberOfRanges3 > 0 ? theData.numberOfRanges3 : (theData.rangesFactor3.length - 1);

					var idxRangeFactor2 = Math.floor(idx / factor3Items);	// Each "rangesFactor3" items it will switch to next factor 2

					if (theData.rangesFactor2AreRateOfChange) {
						item.label = t('Patterns.PriceVsProfitMekko_ColorRangeLegend', {
							from: this.getNumberFormatForLabels(theData.rangesFactor2[idxRangeFactor2]),
							to: this.getNumberFormatForLabels(theData.rangesFactor2[idxRangeFactor2 + 1])
						});
					} else {

						if (theData.rangesFactor2AreCurrency) {
							item.factor2RangeText = t('Patterns.PriceVsProfitMekko_ColorRangeLegend', {
								from: `${currencySymbol}${this.getNumberFormatForLabels(theData.rangesFactor2[idxRangeFactor2])}`,
								to: `${currencySymbol}${this.getNumberFormatForLabels(theData.rangesFactor2[idxRangeFactor2 + 1])}`
							});
						} else {
							item.factor2RangeText = t('Patterns.PriceVsProfitMekko_ColorRangeLegend', {
								from: this.getNumberFormatForLabels(theData.rangesFactor2[idxRangeFactor2]),
								to: this.getNumberFormatForLabels(theData.rangesFactor2[idxRangeFactor2 + 1])
							});
						}
					}
				} else {
					// CATEGORICAL: fill factor2Categories array
					item.factor2Category = t(itemOriginalLabel.split(' ~ ')[0]);

					//var aux = item.twoFactorsLabel;
					//item.twoFactorsLabel = item.twoFactorsName;
					//item.twoFactorsName = aux;
				}
			}
			else if (this.isValidFactorValue(selectedFactors.factor2)) {
				if (theData.rangesFactor2 && !this.isCategorical(selectedFactors.factor2)) {
					if (theData.rangesFactor2AreCurrency) {
						item.label = t('Patterns.PriceVsProfitMekko_ColorRangeLegend', {
							from: `${currencySymbol}${this.getNumberFormatForLabels(theData.rangesFactor2[idx])}`,
							to: `${currencySymbol}${this.getNumberFormatForLabels(theData.rangesFactor2[idx + 1])}`
						});
					} else {
						item.label = t('Patterns.PriceVsProfitMekko_ColorRangeLegend', {
							from: this.getNumberFormatForLabels(theData.rangesFactor2[idx]),
							to: this.getNumberFormatForLabels(theData.rangesFactor2[idx + 1])
						});
					}
				} else {
					// CATEGORICAL: Swap labels and name
					var aux2 = item.twoFactorsLabel;
					item.twoFactorsLabel = item.twoFactorsName;
					item.twoFactorsName = aux2;
				}
			}

			range3Index++;
		});

		var xCategoryLabels = [];
		var totalCount = theData.barWidths.reduce((partialSum, a) => partialSum + a, 0);

		for (var iFactor1 = 0; iFactor1 < theData.barWidths.length; iFactor1++) {
			let perc = (theData.barWidths[iFactor1] / totalCount) * 100.0;

			if (theData.rangesFactor1) {
				let labelFormat = (this.isValidFactorValue(selectedFactors.factor2) || this.isValidFactorValue(selectedFactors.factor3)) ? 'Patterns.PriceVsProfitMekko_XLegend_NumericRange' : 'Patterns.PriceVsProfitMekko_XLegend_NumericRange_NoFactor2Or3';

				let theOriginalLabel = t(theData.xCats[iFactor1]);

				// WORKAROUND: Cannot use rangesFactor1 because the 0-width columns have no data in "barWidths"
				var iRange = iFactor1;
				while (!theOriginalLabel.startsWith(`${theData.rangesFactor1[iRange]} `)) {
					iRange++;
				}

				if (theData.rangesFactor1AreCurrency) {
					xCategoryLabels.push(t(labelFormat,
						{
							from: `${currencySymbol}${this.getNumberFormatForLabels(theData.rangesFactor1[iRange])}`,
							to: `${currencySymbol}${this.getNumberFormatForLabels(theData.rangesFactor1[iRange + 1])}`,
							perc: `${perc.toFixed(2)}`
						}));
				} else {
					xCategoryLabels.push(t(labelFormat,
						{
							from: this.getNumberFormatForLabels(theData.rangesFactor1[iRange]),
							to: this.getNumberFormatForLabels(theData.rangesFactor1[iRange + 1]),
							perc: `${perc.toFixed(2)}`
						}));
				}
			}
			else {
				let labelFormat = (this.isValidFactorValue(selectedFactors.factor2) || this.isValidFactorValue(selectedFactors.factor3)) ? 'Patterns.PriceVsProfitMekko_XLegend' : 'Patterns.PriceVsProfitMekko_XLegend_NoFactor2Or3';

				xCategoryLabels.push(t(labelFormat,
					{
						label: t(theData.xCats[iFactor1]),	// Use T in case we have a translation key here
						perc: `${perc.toFixed(2)}`
					}));
			}
		}

		var ret = {
			xLabels: xCategoryLabels,
			xCats: theData.barWidths,
			firstFactorValues: theData.firstFactorValues,
			datasets: theDatasets,
			stacked: this.isValidFactorValue(selectedFactors.factor2) || this.isValidFactorValue(selectedFactors.factor3),
			numberOfLabelsFactor3: (theData.rangesFactor3 && theData.rangesFactor3.length > 0) ? (theData.rangesFactor3.length - 1) : range3Index
		};

		if (selectedFactors.rateOfChangeActivated) {
			ret.rangesFactor3ForRateOfChange = theData.rangesFactor3ForRateOfChange;
			ret.rateOfChangeColors = this._backgroundColors;

			var rateOfChangeTooltipText = '';

			var dateFrom = moment(selectedFactors.startingPeriodFrom).format('MM/DD/yyyy');
			var dateTo = moment(selectedFactors.endingPeriodTo).format('MM/DD/yyyy');

			if (theData.rangesFactor1AreRateOfChange) {
				rateOfChangeTooltipText = t('Patterns.RateOfChangeTooltipFormat', { factor: metric1Name, periodFrom: dateFrom, periodTo: dateTo });
			} else if (theData.rangesFactor2AreRateOfChange) {
				rateOfChangeTooltipText = t('Patterns.RateOfChangeTooltipFormat', { factor: metric2Name, periodFrom: dateFrom, periodTo: dateTo });
			} else {
				rateOfChangeTooltipText = t('Patterns.RateOfChangeTooltipFormat', { factor: factor3Name, periodFrom: dateFrom, periodTo: dateTo });
			}

			ret.rateOfChangeTooltipText = rateOfChangeTooltipText;
		}

		return ret;
	}

	async retrieveDatesInMonth(month, year) {
		var response;

		if (this._token != null) {
			const config = {
				headers: {
					Authorization: `Bearer ${this._token}`,
					"X-XSRF-TOKEN": this.getAntiForgeryToken()
				}
			};

			try {
				response = await axios.post(`patterns/availableDates`, { month: month, year: year }, config);

				if (response.status !== 200) {
					return {};
				} else {
					return response.data;
				}
			} catch (e) {
				console.error(e);
				return {};
			}
		}
	}

	isValidFactorValue(factor) {
		return (typeof factor !== 'undefined' && factor != null && factor >= 0);
	}

	getAntiForgeryToken() {
		const xsrfToken = document.cookie
			.split("; ")
			.find(row => row.startsWith("XSRF-TOKEN="))
			.split("=")[1];

		return xsrfToken;
	}


	static get instance() { return patternsDataService; }
}

const patternsDataService = new PatternsDataService();

export default patternsDataService;