import React, { useState } from "react";
import SpotLightCard from "./SpotLightCard";

import { TabView, TabPanel } from "primereact/tabview";

const SpotLight = ({ data, hidden }) => {
  const [activeTab, setActiveTab] = useState(0);
  return (
    <>
          <div className="SpotLightCard" style={{ display: hidden ? "none" : "" }}>
        <TabView
          activeIndex={activeTab}
          onTabChange={(e) => setActiveTab(e.index)}
        >
          {data.tabs.map((tab, i) => {
            return (
              <TabPanel header={tab.name} key={i}>
                {tab.categories.map((data, index) => {
                  return <SpotLightCard data={data} key={index} />;
                })}
              </TabPanel>
            );
          })}
        </TabView>
      </div>
    </>
  );
};

export default SpotLight;
