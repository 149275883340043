import React from "react";
import { withTranslation } from "react-i18next";


const SpotLightCard = (props) => {
	const { label, percentage, isProfit, numberOfItems } = props.data;
	const { t } = props;

	return (
		<>
			<div className="grid SpotLightCard-Container">
				<div className="col-12 sm:col-12">
					<div className="card mb-0">
						<div className="flex justify-content-between">
							<div
								className="col-6 left-text flex bg-blue-100"
							>
								<span className="icon">
									<i className="pi pi-window-maximize" />
								</span>

								<div
									className="text-sm advertising"
									style={{ color: "#014548" }}
								>
									{label}
								</div>
							</div>

							<div className="col-6 right-text">
								<div
									className="font-medium text-xl"
									style={{ color: isProfit ? "#014548" : "#FF911E" }}
								>
									<span className="circle-icon">
										<i
											className={`widget-btn pi ${isProfit
													? "pi-chevron-circle-up"
													: "pi-chevron-circle-down"
												}  `}
										/>
									</span>
									{percentage}%
								</div>
								<div className="font-medium text-down">
									<div className="text-sm" style={{ color: "#014548" }}>
										{t('Dashboard_Spotlight.Spotlight_AverageUpliftLabel')}
									</div>
									<div className="text-sm" style={{ color: "#2F989C" }}>
										{t('Dashboard_Spotlight.Spotlight_BasedOnLabel', { numberOfItems: numberOfItems })}
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default withTranslation()(SpotLightCard);
