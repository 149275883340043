import React from "react";
import { Chart } from "primereact/chart";

function KpiPerformanceChart(props) {
	const barChart = props.data;

	let KpiPerformanceChart = {
		responsive: true,
		maintainAspectRatio: false,
		aspectRatio: 1.28,
		plugins: {
			legend: props.legend,
			title: props.title,
			tooltip: props.tooltip
		},

		scales: props.scales,
	};

	return (
		<>
			<div className="">
				<Chart
					type={props.typeChart}
					data={barChart}
					options={KpiPerformanceChart}
					style={{ position: "relative", width: "100%" }}
				/>
			</div>
		</>
	);
}

export default KpiPerformanceChart;
