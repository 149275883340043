export class PatternsState {
	startingPeriodFrom = null;
	startingPeriodTo = null;
	endingPeriodFrom = null;
	endingPeriodTo = null;

	setRateOfChangeDates(startingPeriodFrom, startingPeriodTo, endingPeriodFrom, endingPeriodTo) {
		this.startingPeriodFrom = startingPeriodFrom;
		this.startingPeriodTo = startingPeriodTo;
		this.endingPeriodFrom = endingPeriodFrom;
		this.endingPeriodTo = endingPeriodTo;
	}
	static get instance() { return patternsState; }
}

const patternsState = new PatternsState();

export default patternsState;
