import React, { useEffect, useRef, useState } from 'react'
import { withTranslation } from 'react-i18next';
import { DataTable } from 'primereact/datatable';
import { Button } from 'primereact/button';
import { Column } from 'primereact/column';
import { ConfirmDialog } from 'primereact/confirmdialog';
import { confirmDialog } from 'primereact/confirmdialog';
import { Badge } from 'primereact/badge';
import Moment from 'moment';
import realTimeMessagingServices, { RealTimeMessagesService } from '../../Api/requests/realtimemessaging'

function ViewMessages(props) {
	const { t } = props;
	const { OnMessagesLoaded } = props;
	const messagesSubscription = useRef(null);
	const [messages, setMessages] = useState([]);
	const [totalRecords, setTotalRecords] = useState(0);
	const [loading, setLoading] = useState(false);
	const [lazyParams, setLazyParams] = useState({
		first: 0,
		rows: 20,
		page: 1
	});

	useEffect(() => {
		loadLazyData();
	}, [lazyParams]) // eslint-disable-line react-hooks/exhaustive-deps

	const loadLazyData = async () => {
		setLoading(true);

		let itemsCount = await realTimeMessagingServices.getMessagesCount();
		// console.log(itemsCount);
		setTotalRecords(itemsCount);

		var newMessages = await realTimeMessagingServices.getNewMessages(lazyParams.first, lazyParams.rows);
		setMessages(newMessages);

		if (OnMessagesLoaded) {
			OnMessagesLoaded(itemsCount);
		}

		setLoading(false);
	}

	useEffect(() => {
		loadLazyData();

		messagesSubscription.current = realTimeMessagingServices.subscribe((action, msg) => {
			if (action === RealTimeMessagesService.NEW_MESSAGE ||
				action === RealTimeMessagesService.UPDATE_SIGNAL ||
				action === RealTimeMessagesService.REMOVED_MESSAGE ||
				action === RealTimeMessagesService.REMOVE_ALL) {
				loadLazyData();
			}
		});

		// Use this for "component unmount"
		return () => {
			realTimeMessagingServices.unsubscribe(messagesSubscription.current);
		}
	}, []); // eslint-disable-line react-hooks/exhaustive-deps

	const dateBody = (rowData, props) => {
		var strDate = Moment(rowData['date']).format('DD MMM yyyy - HH:mm:ss');
		return (<>{strDate}</>);
	}

	const textBody = (rowData, props) => {
		return (<span className="p-message-summary">{rowData['messageText']}</span>);
	}

	const severityBody = (rowData, props) => {
		var icon = 'pi-check';
		let message = "info";
		let severity = "info";

		switch (rowData.severity) {
			case 0:
				icon = "pi-info-circle";
				message = t('UserMessages.InfoTooltip');
				severity = "info";
				break;
			case 1:
				icon = "pi-check";
				message = t('UserMessages.SuccessTooltip');
				severity = "success";
				break;
			case 2:
				icon = "pi-exclamation-triangle";
				message = t('UserMessages.WarningTooltip');
				severity = "warning";
				break;
			case 3:
				icon = "pi-times-circle";
				message = t('UserMessages.ErrorTooltip');
				severity = "danger";
				break;
			default:
				icon = "pi-info-circle";
				message = t('UserMessages.InfoTooltip');
				severity = "info";
				break;
		}

		let text = <span className={"p-message-icon pi " + icon} title={message}></span>;

		return (
			<Badge value={text} size="large" severity={severity} style={ { borderRadius: "20px" } }>
			</Badge>
		);
	}

	const onPage = (event) => {
		let _lazyParams = { ...lazyParams, ...event };
		setLazyParams(_lazyParams);
	}

	const confirmDeleteMessage = (rowData) => {
		confirmDialog({
			message: t('UserMessages.ConfirmDeleteMessage'),
			header: t('UserMessages.ConfirmDeleteHeader'),
			acceptLabel: t('UserMessages.ConfirmDialogYes'),
			rejectLabel: t('UserMessages.ConfirmDialogNo'),
			className: 'confirm-panel',
			icon: 'pi pi-exclamation-triangle',
			accept: async () => {
				await realTimeMessagingServices.deleteMessage(rowData.id);
			}
		});
	}

	const commandsTemplate = (rowData) => {
		return (
			<React.Fragment>
				<Button icon="pi pi-trash" className="p-link p-row-editor-init" onClick={() => confirmDeleteMessage(rowData)} />
			</React.Fragment>
		);
	}

	return (
		<>
			<ConfirmDialog />
			<div className="field grid mt-3 pl-3 pr-3" style={ { position: "relative" } }>
				<div id="overlay_panel_messages" style={{ width: "100%", height: "100%", display: loading ? "" : "none" }} className="p-overlaypanel-wrapper p-general-loading-overlay">
					<i className="p-datatable-loading-icon pi-spin pi pi-spinner"></i>
				</div>
				<div className="col-12 md:col-12">
					<div className="datatable datatable-rounded datatable-responsive">
						<DataTable value={messages}
							dataKey="id"
							className="p-datatable-customers p-datatable-simple"
							emptyMessage={t('UserMessages.NoMessages')} lazy
							paginatorPosition="top" paginator first={lazyParams.first} rows={20} totalRecords={totalRecords} onPage={onPage}
							footer={false}
							columnResizeMode="fit">
							<Column body={severityBody} style={{width: '2rem'}}></Column>
							<Column field="date" header={ t('UserMessages.DateColumn') } style={{ width: '12rem' }} body={dateBody}></Column>
							<Column field="messageText" header={ t('UserMessages.MessageColumn') } body={textBody}></Column>
							<Column body={commandsTemplate} style={{ width: '4rem' }}></Column>
						</DataTable>
					</div>
				</div>
			</div>
		</>
	);
}

export default withTranslation()(ViewMessages);