import React, { useEffect, useState } from 'react'
import moment from 'moment'
import { withTranslation } from 'react-i18next'

function SKUDetails(props) {
	const { selectedItem, currencySymbol, t, tReady } = props
	const [currency, setCurrency] = useState('$');

	useEffect(() => {
		if (selectedItem != null) {
			// console.log(selectedItem);
		}
	}, [selectedItem]);

	useEffect(() => {
		if (currencySymbol !== null) {
			setCurrency(currencySymbol);
		}
	}, [currencySymbol]);

	if (selectedItem === null && tReady) {
		return (<p className="text-md text-primary">{t('SKUDetailsTile.PleaseSelectARow')}</p>);
	} else if (tReady) {
		return (
			<>
				{selectedItem.isRelationshipItem && (
				<>
					<p className="text-md text-primary">[[{selectedItem.description}]]</p>
				</>)}

				{!selectedItem.isRelationshipItem && (
				<>
					<p className="text-md text-primary">{selectedItem.description}</p>
					<div className="field grid mt-3 pl-3 pr-3">
						<label className="col-12 md:col-8">{t('SKUDetailsTile.SKU')}</label>
						<div className="col-12 md:col-4">
							{selectedItem.sku}
						</div>
					</div>

					<div className="field grid mt-3 pl-3 pr-3">
						<label className="col-12 md:col-8">{t('SKUDetailsTile.StoreNumber')}</label>
						<div className="col-12 md:col-4">
							{selectedItem.storeNumber}
						</div>
					</div>
				</>)}

				<div className="field grid mt-3 pl-3 pr-3">
					<label className="col-12 md:col-8">{t('SKUDetailsTile.RecommendedPrice')}</label>
					<div className="col-12 md:col-4">
						{currency} {selectedItem.recommendedSellingPrice}
					</div>
				</div>

				<div className="field grid mt-3 pl-3 pr-3">
					<label className="col-12 md:col-8">{t('SKUDetailsTile.LastPrice')}</label>
					<div className="col-12 md:col-4">
						{currency} {selectedItem.pricePeriodT.toFixed(2)}
					</div>
				</div>

				<div className="field grid mt-3 pl-3 pr-3">
					<label className="col-12 md:col-8">{t('SKUDetailsTile.ProjectedProfit')}</label>
					<div className="col-12 md:col-4">
						{currency} {selectedItem.profitThisPeriod.toFixed(2)}
					</div>
				</div>

				<div className="field grid mt-3 pl-3 pr-3">
					<label className="col-12 md:col-8">{t('SKUDetailsTile.LatestObservation')}</label>
					<div className="col-12 md:col-4">
						{moment(selectedItem.date).format('DD MMM yyyy')}
					</div>
				</div>
			</>
		);
	} else {
		return null;
	}
}

export default withTranslation()(SKUDetails)