import React, { useState } from "react";
import { TabView, TabPanel } from "primereact/tabview";
import ProfitOpportunityChart from "../Charts/ProfitOpportunityChart/ProfitOpportunityChart";

const generateDoughnutChartData = (rawData, labels) => {
	return {
		labels: labels ? labels : [],
		datasets: [
			{
				label: "",
				data: rawData.map((item) => item.value),
				backgroundColor: rawData.map((item) => item.color),
			},
		],
	};
};

const ProfitOpportunity = (props) => {
	const [activeTab, setActiveTab] = useState(0);

	return (
		<div className="dashboard-widget">
			<div className="widget-inner-box">
				<div className="dashboard-tab">
					<TabView
						activeIndex={activeTab}
						onTabChange={(e) => setActiveTab(e.index)}
						className="tabview-custom"
					>
						{props.data.tabs.map((tab, i) => {
							return (
								<TabPanel header={tab.name} key={i}>
									{tab.type === "doughnut" && (
										<div className="grid">
											<div className="col-12 lg:col-12">
												{tab.categories && tab.categories.length ? (
													<ProfitOpportunityChart
														typeChart={tab.type}
														legend={tab.legend}
														data={generateDoughnutChartData(
															tab.categories,
															tab.labels
														)}
													/>
												) : (
													<h6>Unavailable</h6>
												)}
											</div>
										</div>
									)}
								</TabPanel>
							);
						})}
					</TabView>
					<i className="widget-option-btn pi pi-chevron-circle-down"></i>
				</div>
			</div>
		</div>
	);
};

export default ProfitOpportunity;