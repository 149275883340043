import React, { useState } from "react";
import { TabView, TabPanel } from "primereact/tabview";
import BarLineChart from "../Bar-Line Chart/BarLineChart";

const BarLine = (props) => {
	const [activeTab, setActiveTab] = useState(0);

	const generateBarChartData = (rawData, color, maxBarThickness, labels, barName) => {
		if (rawData[0]?.barCategory) {
			return {
				labels: labels,
				datasets: rawData.map((obj) => {
					return {
						label: obj.label,
						maxBarThickness: maxBarThickness,
						data: obj.barCategory.map((item) => item.value),
						backgroundColor: obj.color,
					};
				}),
			};
		} else {
			return {
				labels: labels,
				datasets: [
					{
						label: barName,
						maxBarThickness: maxBarThickness,
						data: rawData.map((item) => item.value),
						backgroundColor: color,
					},
				],
			};
		}
	};

	return (
		<div className="dashboard-widget">
			<div className="widget-inner-box">
				<div className="dashboard-tab">
					<TabView
						activeIndex={activeTab}
						onTabChange={(e) => setActiveTab(e.index)}
						className="tabview-custom"
					>
						{props.data.tabs.map((tab, i) => {
							return (
								<TabPanel header={tab.name} key={i}>
									{/* bar chart */}
									{tab.type === "bar" && (
										<div className="grid">
											<div className="col-12  lg:col-12">
												<BarLineChart
													typeChart={tab.type}
													legend={tab.legend}
													scales={tab.scales}
													tooltip={tab.tooltip}
													data={generateBarChartData(
														tab.categories,
														tab.color,
														tab.maxBarThickness,
														tab.labels,
														tab.barName
													)}
												/>
											</div>
										</div>
									)}
								</TabPanel>
							);
						})}
					</TabView>
					<i className="widget-option-btn pi pi-chevron-circle-down"></i>
				</div>
			</div>
		</div>
	);
};

export default BarLine;
