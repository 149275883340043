import React, {useEffect, useState} from 'react'

function Collapse(props) {
	const { onToogled, closeOnClick } = props
	const [active, setActive] = useState(props.collapse);

	useEffect(() => {
		onToogled(props.title, active);
	}, [active]); // eslint-disable-line react-hooks/exhaustive-deps

	const expandCollapse = (force) => {
		if (force						// we're forcing to execute the action
			|| closeOnClick === true	// closeOnClick is true so we don't matter what happens
			|| active === "")			// we're disabled, and we're going to open the tile
		{
			setActive(active === "" ? "active" : "");
		}
	}

	return (
		<div className={`collapse-wrapper ${props.className} ${active}`} onClick={(e) => {
			expandCollapse(false)
		}}>
			<span className="collapse-button icon-collapse" onClick={(e) => { expandCollapse(true) }}>
			</span>
			<div className="collapse-header">
				{props.title}
			</div>
			<div className="collapse-body">
				{props.children}
			</div>
		</div>
	)
}

export default Collapse
