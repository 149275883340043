import React from "react";
import { Chart } from "primereact/chart";

function RepricingOverPeriodChart(props) {
  let basicOptions = {
    responsive: true,
    maintainAspectRatio: false,
    aspectRatio: 1.28,
    radius: 0,
    plugins: {
		legend: props.legend,
		tooltip: props.tooltip
    },
    scales: props.scales,
  };

  return (
    <>
      <div className="">
	   <Chart
          type={props.typeChart}
          data={props.data}
          options={basicOptions}
          style={{ position: "relative", width: "100%" }}
        />
      </div>
    </>
  );
}

export default RepricingOverPeriodChart;
