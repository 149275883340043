import React, {useCallback, useEffect, useMemo, useRef, useState} from 'react'
import { debounce } from 'lodash'
import {OverlayPanel} from "primereact/overlaypanel";
import { Column } from "primereact/column";
import { Dropdown } from "primereact/dropdown";
import { DataTable } from "primereact/datatable";
import { ScrollPanel } from "primereact/scrollpanel";
import { Calendar } from "primereact/calendar";
import { Slider } from "primereact/slider";
import { withTranslation } from 'react-i18next';

function FilterPricing(props) {
	const { onSearched } = props
	const { itemsFiltered } = props
	const { filters, setFilters } = props
	const { loadLazyFilters } = props
	const { onFilter } = props
	const { clearFiltersFlag } = props
	const { dateFilterEnabled } = props
	const { t, i18n } = props
	const [search, setSearch] = useState('')
	const [searchOutside, setSearchOutside] = useState(false)
	const [currentFilter, setCurrentFilter] = useState(null)
	const [filterDates, setFilterDates] = useState(null);
	const [items, setItems] = useState([]);
	const [allowDateFilter, setAllowDateFilter] = useState(dateFilterEnabled)

	const filterOverlay = useRef(null)
	var filterPill = []
	const filterPills = (element) => {
		if (element !== null) {
			filterPill[element.id] = element;
		}
	}

	const debouncedSearch = useMemo(
		() =>
			debounce((newFilters, value, isApplied) => {
				if (isApplied) {
					onFilter({ filters: newFilters.filter(item => item.value !== '') }) // Including filters in lazyParams
					filterOverlay.current.hide()
				}
				loadLazyFilters({ filters: newFilters.filter(item => item.value !== '') })
			}, 500),
		[] // eslint-disable-line react-hooks/exhaustive-deps
	)

	const handleChange = useCallback(
		(e, filter, isClearFilter) => {
			let newFilters = [...filters]
			let index = newFilters.findIndex(item => item.field === filter.field)

			var value = '';
			var theValue = '';
			var toValue = '';

			if (isClearFilter) {
				value = '';
				theValue = '';
				toValue = undefined;

				if (filter.field === 'date') {
					setFilterDates(null);
				}
			} else {
				value = e.originalEvent && e.originalEvent._reactName === "onClick" ? e.value[filter.field] : (e.target ? e.target.value : e.value);

				if (filter.numericRangeFilter === true && e.value && e.value.length === 2) {
					theValue = e.value[0].toString();
					toValue = e.value[1].toString();
				} else if (e.target) {
					if (filter.booleanFilter === true) {
						theValue = (newFilters[index].value === '') ? "true" : ""; // Switch state
					} else {
						//if (e.target.value instanceof Date) {
						if (e.target.value instanceof Array) {
							if (e.target.value.length > 0 && e.target.value[0] !== null) {
								theValue = e.target.value[0].toISOString();
							}

							if (e.target.value.length > 1 && e.target.value[1] !== null) {
								toValue = e.target.value[1].toISOString();
							}
						} else if (e.target.value instanceof Date) {
							theValue = e.target.value.toISOString();
						} else {
							if (value !== null) {
								theValue = value.toString();
							}
						}
					}
				} else {
					theValue = value.toString();
				}
			}

			newFilters[index].value = theValue;
			newFilters[index].toValue = toValue;
			setFilters(newFilters);

			let isApplied = false;

			if (isClearFilter) {
				isApplied = true;
				filterPill[filter.field].checked = false;
			} else {
				// onBlur and OnKeyPress in the inputText from filterOverlay
				// onClick when click on row
				if (e._reactName === "onBlur"
					|| e._reactName === "onKeyPress"
					|| (e.originalEvent && e.originalEvent._reactName === "onClick")
					|| filter.booleanFilter) {
					isApplied = true;
				}
				if (filter.booleanFilter) {
					filterPill[filter.field].checked = (newFilters[index].value === 'true');

					if (filter.exclusiveWith) {
						for (var iExc = 0; iExc < filter.exclusiveWith.length; iExc++) {
							let otherFieldName = filter.exclusiveWith[iExc];
							// console.log(iExc, otherFieldName);
							let theOtherIndex = newFilters.findIndex(item => item.field === otherFieldName)

							if (theOtherIndex >= 0) {
								newFilters[theOtherIndex].value = '';
								filterPill[otherFieldName].checked = false;
							}
						}
					}
				} else {
					if (newFilters[index].value !== '') {
						filterPill[filter.field].checked = true;
					} else {
						filterPill[filter.field].checked = false;
					}
				}
			}

			debouncedSearch(newFilters, value, isApplied)
		},
		[debouncedSearch, filters] // eslint-disable-line react-hooks/exhaustive-deps
	)

	useEffect(() => {
		if (filterDates !== null) {
			// Simulate a "onClick" when calendar range has been selected
			if (filterDates[0] !== null && filterDates[1] !== null) {

				let e = {
					target:
					{
						value: filterDates
					},
					_reactName: 'onBlur'
				};

				let currentFilter = filters.find(item => item.field === 'date');

				handleChange(e, currentFilter);
			}
		}
	}, [filterDates]); // eslint-disable-line react-hooks/exhaustive-deps

	useEffect(() => {
		if (currentFilter !== null) {
			// Remove repeated items and sort
			let distinctItems = itemsFiltered.filter(distinctFilter).sort(sortCompare);
			setItems(distinctItems);
			// console.log(distinctItems);
		}

	}, [currentFilter, itemsFiltered]);  // eslint-disable-line react-hooks/exhaustive-deps

	const sortCompare = (first, second) => {
		if (first[currentFilter.field] < second[currentFilter.field]) {
			return -1;
		}

		if (first[currentFilter.field] > second[currentFilter.field]) {
			return 1;
		}

		return 0;
	};

	const distinctFilter = (value, index, self) => {
		return (self.findIndex(e => e[currentFilter.field] === value[currentFilter.field]) === index);
	}

	const yearNavigatorTemplate = (e) => {
		return (
			<div className="inline-block">
				<Dropdown value={e.value} options={e.options} onChange={(event) => e.onChange(event.originalEvent, event.value)}
					className="ml-2" style={{ lineHeight: 1 }} />
			</div>);
	}

	const handleDateFilterChange = (e) => {
		setFilterDates(e.value);
	}

	const isFilterPillChecked = (item) => {
		if (item.booleanFilter) {
			if (item.value === 'true') {
				return true;
			} else {
				return false;
			}
		} else {
			return false;
		}
	}

	useEffect(() => {
		if (clearFiltersFlag === true) {
			clearAllFilters();
		}
	}, [clearFiltersFlag]);	// eslint-disable-line react-hooks/exhaustive-deps

	useEffect(() => {
		if (dateFilterEnabled === false) {
			// Simulate a "clear filter" on date
			let currentFilter = filters.find(item => item.field === 'date');
			handleChange({}, currentFilter, true);
		}

		setAllowDateFilter(dateFilterEnabled);
	}, [dateFilterEnabled]);

	const clearAllFilters = (e) => {
		let newFilters = [...filters];
		newFilters.forEach(f => {
			if (f.numericRangeFilter === true) {
				f.value = f.numericInitialRange[0];
				f.toValue = f.numericInitialRange[1];
			} else {
				f.value = '';
				f.toValue = '';
			}

			filterPill[f.field].checked = false;
		});

		debouncedSearch(newFilters, '', true);
	}

	const retrieveSliderValue = (currentFilter) => {
		if (currentFilter == null) {
			return currentFilter.numericInitialRange;
		} else {
			var theFilterItem = filters.find(item => item.field === currentFilter.field);
			var ret = [...currentFilter.numericInitialRange];

			if (theFilterItem) {
				if (theFilterItem.value !== '') {
					ret[0] = parseInt(theFilterItem.value);
				}
				if (theFilterItem.toValue !== undefined) {
					ret[1] = parseInt(theFilterItem.toValue);
				}
			}

			return ret;
		}
	}

	var elementsTable = (<DataTable
							value={items}
							className="p-datatable-customers"
							columnResizeMode="fit"
							selectionMode="single"
							onSelectionChange={e => {
								handleChange(e, currentFilter)
							}}
							dataKey="id"
						>
							<Column field={currentFilter !== null ? currentFilter.field : ''}></Column>
						</DataTable>);

	return (
		<form className="filter-wrapper">
			{/* Filter By */}
			<h4 className="text-primary text-md mb-3">{t('PricingFilters.FilterByTitle')}</h4>
			<div className="check-pill-wrapper">
				{filters !== null && filters.map((item, index) =>
					<div key={index} className="check-pill check-pill-tertiary check-pill-md check-pill-rounded-full mr-2">
						<input ref={filterPills} type="checkbox" id={item.field} value={item.field} defaultChecked={isFilterPillChecked(item)} name="filter-by" onClick={(e) => {
							if (item.customFilterHandler) {
								e.preventDefault();
								setCurrentFilter(item);
								item.customFilterHandler(e, item);
                            } else if (!item.booleanFilter) {
								e.preventDefault();
								setCurrentFilter(item);
								filterOverlay.current.toggle(e);
							} else {
								// Treat boolean filters in a special way
								setCurrentFilter(item);
								handleChange(e, item);
							}
						}} />
						<label htmlFor={item.field}>{item.header}</label>
					</div>
				)}
			</div>
			<button className="btn btn-none text-primary mb-1 p-0" type="reset" onClick={(e) => {
				let newFilters = [...filters]
				newFilters.map(item => item.value = '')
				onFilter({ filters: newFilters.filter(item => item.value !== ''), clearAll: true })
				setFilters(newFilters)
				setFilterDates(null);
				// debouncedSearch(newFilters, undefined, true);
			}
			}>{t('PricingFilters.ClearAll')}</button>
			<hr className="hr hr-primary mb-3" />
			{/* end Filter By */}
			{/* Search */}
			<h4 className="text-primary text-md mb-2">{t('PricingFilters.SearchTitle')}</h4>
			<div className="filter-search mb-2">
				<input
					type="text"
					id="filter-search"
					className="mr-2"
					placeholder={t('PricingFilters.SearchTextboxPlaceholder')}
					aria-label="Search through table"
					value={search}
					onKeyPress={(e) => {
						if (e.key === 'Enter') {
							e.preventDefault();
							onSearched(e.target.value, searchOutside, true)
						}
					}}
					onChange={(e) => {
						setSearch(e.target.value)
					}}
				/>
				<button type="button" className="btn btn-none btn-search" onClick={(e) => onSearched(search, searchOutside, true)} >
				</button>
			</div>
			<label className="form-checkbox form-checkbox-tertiary form-checkbox-md mb-3 text-sm text-primary">
				<input type="checkbox"
					defaultChecked={searchOutside}
					onChange={(e) => {
						setSearchOutside(e.target.checked)
						onSearched(search, e.target.checked, false)
					}} />
				<span className="form-checkmark mr-2"></span>{t('PricingFilters.SearchOutsideCurrentCheckbox')}</label>
			<hr className="hr hr-primary mb-3" />
			{/* end Search */}
			<OverlayPanel
				ref={filterOverlay}
				id="overlay_panel"
				className="p-overlaypanel-wrapper p-overlaypanel-rounded-lg"
			>
				<div className="filter-search mb-2">
					{
						(currentFilter && currentFilter.field === 'date') &&
						(<Calendar
							id="dateFilterCalendar"
							locale={i18n.language}
							yearNavigator yearRange="2010:2050"
							yearNavigatorTemplate={yearNavigatorTemplate}
							dateFormat="yy-mm-dd"
							placeholder={t('PricingFilters.FilterDateRangePlaceholder')}
							selectionMode="range"
							readOnlyInput
							value={filterDates}
							disabled={!allowDateFilter}
							onChange={(e) => handleDateFilterChange(e)}
						/>)
					}

					{
						(currentFilter && currentFilter.field !== 'date' && currentFilter.numericRangeFilter !== true) &&
						(<input
							type="text"
							id="dateFilterInput"
							placeholder={t('PricingFilters.FilterContentPlaceholder')}
							aria-label="Search through table"
							value={currentFilter !== null ? filters.find(item => item.field === currentFilter.field).value : ''}
							onChange={e => handleChange(e, currentFilter)}
							onBlur={(e) => handleChange(e, currentFilter)}
							onKeyPress={(e) => {
								if (e.key === 'Enter') {
									handleChange(e, currentFilter)
								}
							}}
						/>)
					}

					{
						(currentFilter && currentFilter.field !== 'date' && currentFilter.numericRangeFilter === true) &&
						(<div className="pricimetrics-slider">
							<Slider
								id={currentFilter.field}
								name={currentFilter.field}
								value={retrieveSliderValue(currentFilter)}
								min={0}
								max={currentFilter.numericInitialRange[1]}
								onChange={(e) => {
									handleChange(e, currentFilter);
								}}
								className="w-14rem" range />
							<span className="label" style={{ float: "left" }}>{retrieveSliderValue(currentFilter)[0]}</span>
							<span className="label" style={{ float: "right" }}>{retrieveSliderValue(currentFilter)[1]}</span>
						</div>)
					}
					
				</div>
				{currentFilter && (
					<div style={ { textAlign: 'right' } }>
						<div
							className="p-mx-auto clear-filter"
							onClick={(e) => handleChange(e, currentFilter, true)}
						>
							{t('PricingFilters.ClearFilter')}
						</div>
					</div>
				)}
				<div className="grid filter mt-2">
					{
						currentFilter && (currentFilter.field === 'date' || currentFilter.numericRangeFilter === true) ? null : (
							<div
							className="datatable datatable-responsive filter"
							header="Responsive"
						>
							{(items.length > 10) ?
								(<ScrollPanel className="scroll-panel-filters">
									{elementsTable}
									</ScrollPanel>) : elementsTable
							}

						</div>)
					}
				</div>
			</OverlayPanel>
		</form>
	)
}

export default withTranslation()(FilterPricing)
