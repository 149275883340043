import React, { useCallback, useEffect, useState } from "react"
import { withTranslation } from 'react-i18next'
import authService from "../components/api-authorization/AuthorizeService";
import pricingService from "../Api/requests/pricing"
import patternsDataService from "../Api/requests/patterns"
import MarimekkoChart from "../components/Patterns/Charts/Marimekko/MarimekkoChart";
import FilterPricing from "../components/Pricing/FilterPricing"
import importService from '../Api/requests/import'
import { Dropdown } from 'primereact/dropdown';
import { Panel } from 'primereact/panel';
import { Ripple } from 'primereact/ripple';
import { InputNumber } from 'primereact/inputnumber';
import { Calendar } from "primereact/calendar";
import { confirmDialog, ConfirmDialog } from 'primereact/confirmdialog';
import { classNames } from 'primereact/utils'
import patternsState from '../Api/client-side/patternsState'
import moment from 'moment';

function PatternsPage(props) {
	const { t, i18n, tReady } = props
	const [initializing, setInitializing] = useState(true);
	const [companyName, setCompanyName] = useState("");
	const [currentMaxSellingPrice, setCurrentMaxSellingPrice] = useState(null);
	const [currencySymbol, setCurrencySymbol] = useState(null);
	const [lastRepricing, setLastRepricing] = useState(null);
	const [xAxisLabels, setXAxisLabels] = useState([]);
	const [xCatWidths, setXCatsWidths] = useState([]);
	const [firstFactorValues, setFirstFactorValues] = useState([]);
	const [labelsForFactor3, setLabelsForFactor3] = useState(0);
	const [datasets, setDatasets] = useState([]);
	const [stacked, setStacked] = useState(true);
	const [presets, setPresets] = useState([]);
	const [factors, setFactors] = useState([]);
	const [metrics, setMetrics] = useState([]);
	const [selectedPreset, setSelectedPreset] = useState(0);
	const [selectedFirstFactor, setSelectedFirstFactor] = useState(-1);
	const [selectedSecondFactor, setSelectedSecondFactor] = useState(-1);
	const [selectedThirdFactor, setSelectedThirdFactor] = useState(-1);
	const [rawData, setRawData] = useState(null);

	const [defaultCategoryMetric, setDefaultCategoryMetric] = useState(null);
	const [selectedFirstMetric, setSelectedFirstMetric] = useState(4);
	const [selectedSecondMetric, setSelectedSecondMetric] = useState(4);

	const [selectedFirstMetricBins, setSelectedFirstMetricBins] = useState(8);
	const [selectedSecondMetricBins, setSelectedSecondMetricBins] = useState(8);
	const [selectedThirdMetricBins, setSelectedThirdMetricBins] = useState(25);

	const [operation1, setOperation1] = useState(-1);
	const [operation2, setOperation2] = useState(-1);
	const [operation3, setOperation3] = useState(-1);
	const [factor3Resolution, setFactor3Resolution] = useState(0);
	const [loading, setLoading] = useState(false);

	const [filterParams, setFilterParams] = useState(null);
	const [itemsFiltered, setItemsFiltered] = useState(null);
	const [search, setSearch] = useState("");
	const [searchOutside, setSearchOutside] = useState(false);
	const [filters, setFilters] = useState([]);

	const [operation1Enabled, setOperation1Enabled] = useState(false);
	const [operation2Enabled, setOperation2Enabled] = useState(false);
	const [operation3Enabled, setOperation3Enabled] = useState(false);

	const [presetUpdated, setPresetUpdated] = useState(null);
	const [chartUpdated, setChartUpdated] = useState(null);

	// Rate of Change
	const [dateFilterEnabled, setDateFilterEnabled] = useState(true);
	const [showRateOfChangePerc, setShowRateOfChangePerc] = useState(false);
	const [startingPeriodFrom, setStartingPeriodFrom] = useState(null);
	const [startingPeriodTo, setStartingPeriodTo] = useState(null);
	const [endingPeriodFrom, setEndingPeriodFrom] = useState(null);
	const [endingPeriodTo, setEndingPeriodTo] = useState(null);
	const [rateOfChangeActivated, setRateOfChangeActivated] = useState(false);
	const [enabledDates, setEnabledDates] = useState([]);
	const [viewDate, setViewDate] = useState(null);

	const loadData = async () => {
		let user = await authService.getUser();
		setCurrencySymbol(user.currencySymbol);
		setCompanyName(user.company);

		loadFiltersData();

		let factorsAndMetrics = await patternsDataService.getFactorsAndMetrics(importService, t);
		setFactors(factorsAndMetrics.factors);
		setMetrics(factorsAndMetrics.metricsForCategoricalFactors);

		let presets = await patternsDataService.getPresets();
		// console.log(presets);
		setPresets(presets);

		let defaultPreset = presets.find(p => p.isDefaultPreset === true);

		if (defaultPreset != null) {
			setSelectedPreset(defaultPreset.id);
		}

		setInitializing(false);
	};

	const loadFiltersData = async () => {
		// Load auto-complete in filter section
		let _filterParams = { ...filterParams, ...{ rows: 100 } } // rows attribute limits the maximum amount of filter items we're going to retrieve
		let theFilterdata = await pricingService.getPricingFilterItems(
			_filterParams,
			filters,
			search,
			searchOutside);
		setItemsFiltered(theFilterdata);
	}

	const loadChartData = async () => {
		var mekkoData = null;
		setLoading(true);

		//console.log('factor1=', selectedFirstFactor);
		//console.log('factor2=', selectedSecondFactor);
		//console.log('factor3=', selectedThirdFactor);
		//console.log('operation1=', operation1);
		//console.log('operation2=', operation2);
		//console.log('operation3=', operation3);

		var operation3Name = getOperation3Name();
		var operation2Name = getOperation2Name();
		var operation1Name = getOperation1Name();

		var metric1Name = getMetricName(selectedFirstMetric);
		var metric2Name = getMetricName(selectedSecondMetric);

		mekkoData = await patternsDataService.getPatternsDataForFactors({
			factor1: selectedFirstFactor,
			factor2: selectedSecondFactor,
			factor3: selectedThirdFactor,
			operation1: operation1,
			operation2: operation2,
			operation3: operation3,
			factor3Resolution: factor3Resolution,
			firstMetric: selectedFirstMetric,
			firstMetricBins: selectedFirstMetricBins,
			secondMetric: selectedSecondMetric,
			secondMetricBins: selectedSecondMetricBins,
			thirdMetricBins: selectedThirdMetricBins,
			rateOfChangeActivated: rateOfChangeActivated,
			startingPeriodFrom: startingPeriodFrom,
			startingPeriodTo: startingPeriodTo,
			endingPeriodFrom: endingPeriodFrom,
			endingPeriodTo: endingPeriodTo
		}, currencySymbol, t, getFactor3Name(), operation3Name, getFactor2Name(), operation2Name, getFactor1Name(), operation1Name, metric1Name, metric2Name,
			{
				filters: (filterParams != null) ? filterParams.filters : null,
				searchText: search,
				searchOutside: searchOutside
			});

		setXAxisLabels(mekkoData.xLabels);
		setXCatsWidths(mekkoData.xCats);
		setFirstFactorValues(mekkoData.firstFactorValues)
		setDatasets(mekkoData.datasets);
		setStacked(mekkoData.stacked);
		setLabelsForFactor3(mekkoData.numberOfLabelsFactor3);
		setRawData(mekkoData);

		setChartUpdated(false);
		setLoading(false);
	}

	useEffect(() => {
		const initialLoad = async () => {
			if (currencySymbol !== null) {
				let theLastRepricing = await pricingService.getLastRepricingDateTime();

				if (theLastRepricing != null) {
					setLastRepricing(theLastRepricing);
				} else {
					setLastRepricing(null);
				}

				let patternsConfig = await patternsDataService.getPatternsConfig();
				setDefaultCategoryMetric(patternsConfig.defaultMetric);
				setSelectedFirstMetric(patternsConfig.defaultMetric);
				setSelectedSecondMetric(patternsConfig.defaultMetric);
			}
		}

		initialLoad();
	}, [currencySymbol]);	// eslint-disable-line react-hooks/exhaustive-deps

	useEffect(() => {
		if (selectedPreset > 0) {
			setPresetUpdated(true);
		}
	}, [selectedPreset]);	// eslint-disable-line react-hooks/exhaustive-deps

	useEffect(() => {
		if (chartUpdated === true) {
			setPresetUpdated(false);
			loadChartData();
		}
	}, [chartUpdated]);	// eslint-disable-line react-hooks/exhaustive-deps

	useEffect(() => {
		if (presetUpdated === true) {
			let theSelectedPreset = presets.find(p => p.id === selectedPreset);

			if (theSelectedPreset) {
				setSelectedFirstFactor(theSelectedPreset.factor1);
				setSelectedSecondFactor(theSelectedPreset.factor2);
				setSelectedThirdFactor(theSelectedPreset.factor3);

				if (!patternsDataService.isCategorical(theSelectedPreset.factor1)) {
					setSelectedFirstMetric(theSelectedPreset.factor1);
				} else {
					if (defaultCategoryMetric != null) {
						setSelectedFirstMetric(defaultCategoryMetric);
					}
				}

				if (!patternsDataService.isCategorical(theSelectedPreset.factor2)) {
					setSelectedSecondMetric(theSelectedPreset.factor2);
				} else {
					if (defaultCategoryMetric != null) {
						setSelectedSecondMetric(defaultCategoryMetric);
					}
				}

				setOperation1(theSelectedPreset.operation1);
				setOperation2(theSelectedPreset.operation2);
				setOperation3(theSelectedPreset.operation3);
			}

			setChartUpdated(true);
		} else {
			setChartUpdated(false);
		}
	}, [presetUpdated]);	// eslint-disable-line react-hooks/exhaustive-deps

	const operationUpdated = () => {
		if (presetUpdated === false) {
			setChartUpdated(true);
		}
	}

	useEffect(() => {
		operationUpdated();
	}, [operation1, operation2, operation3, factor3Resolution]);	// eslint-disable-line react-hooks/exhaustive-deps

	const factorUpdated = (factor, operation, setOperation, selectedMetric, setOperationEnabled, useFactor) => {
		if (typeof factor === 'undefined' || factor <= 0) {
			setOperationEnabled(false);
			setOperation(-1);
		} else {
			setOperationEnabled(true);
			// if (operation === -1) {
			var newOperation = -1;

			for (var op = 0; op < 3; op++) {
				if (factorAllowsOperation(factor, op, selectedMetric, useFactor)) {
					newOperation = op;
					break;
				}
			}

			if (newOperation >= 0) {
				setOperation(newOperation);
			} else {
				setOperationEnabled(false);
				setOperation(-1);
			}

			//}
		}

		if (presetUpdated === false) {
			setChartUpdated(true);
		}
	};

	useEffect(() => {
		if (selectedFirstFactor == null || selectedFirstFactor > -1) {
			var setFactorUpdated = true;

			if (selectedFirstFactor > -1) {
				if (!patternsDataService.isCategorical(selectedFirstFactor)) {
					// When we change from one categorical to one numeric, "setSelectedFirstMetric" could be equals to selectedFirstFactor and it will not trigger the firstMetric change effect
					// So we need to force it
					if (selectedFirstMetric !== selectedFirstFactor) {
						setSelectedFirstMetric(selectedFirstFactor);
						setFactorUpdated = false;	// firstMetric effect will call factorUpdated method
					}
					
				} else {
					if (defaultCategoryMetric != null) {
						// When we change from one categorical to another the "setSelectedFirstMetric" will not trigger the firstMetric change effect
						// So we need to force it anyway
						if (defaultCategoryMetric !== selectedFirstMetric) {
							setSelectedFirstMetric(defaultCategoryMetric);
							setFactorUpdated = false;	// firstMetric effect will call factorUpdated method
						}
					}
				}
			}

			if (setFactorUpdated === true) {
				factorUpdated(selectedFirstFactor, operation1, setOperation1, selectedFirstMetric, setOperation1Enabled, false);
			}
		}
		
	}, [selectedFirstFactor]);	// eslint-disable-line react-hooks/exhaustive-deps

	useEffect(() => {
		if (selectedSecondFactor == null || selectedSecondFactor > -1) {
			var setFactorUpdated = true;

			if (selectedSecondFactor > -1) {
				if (!patternsDataService.isCategorical(selectedSecondFactor)) {
					// When we change from one categorical to one numeric, "setSelectedSecondMetric" could be equals to selectedSecondFactor and it will not trigger the secondMetric change effect
					// So we need to force it
					if (selectedSecondMetric !== selectedSecondFactor) {
						setSelectedSecondMetric(selectedSecondFactor);
						setFactorUpdated = false;	// secondMetric effect will call factorUpdated method
					}
				} else {
					if (defaultCategoryMetric != null) {
						// When we change from one categorical to another the "setSelectedSecondMetric" will not trigger the secondMetric change effect
						// So we need to force it
						if (defaultCategoryMetric !== selectedFirstMetric) {
							setSelectedSecondMetric(defaultCategoryMetric);
							setFactorUpdated = false;	// firstMetric effect will call factorUpdated method
						}
					}
				}
			}

			if (setFactorUpdated === true) {
				factorUpdated(selectedSecondFactor, operation2, setOperation2, selectedSecondMetric, setOperation2Enabled, false);
			}
		}
	}, [selectedSecondFactor]);	// eslint-disable-line react-hooks/exhaustive-deps

	useEffect(() => {
		if (selectedThirdFactor == null || selectedThirdFactor > -1) {
			factorUpdated(selectedThirdFactor, operation3, setOperation3, null, setOperation3Enabled, true);
		}
	}, [selectedThirdFactor]);	// eslint-disable-line react-hooks/exhaustive-deps

	useEffect(() => {
		if (!initializing) {
			loadChartData();
		}
	}, [selectedFirstMetricBins, selectedSecondMetricBins, selectedThirdMetricBins]);	// eslint-disable-line react-hooks/exhaustive-deps

	useEffect(() => {
		// Use this trick to avoid triggering in first render
		if (!initializing && !presetUpdated) {
			factorUpdated(selectedFirstFactor, operation1, setOperation1, selectedFirstMetric, setOperation1Enabled, false);
		};
	}, [selectedFirstMetric]); 	// eslint-disable-line react-hooks/exhaustive-deps

	useEffect(() => {
		// Use this trick to avoid triggering in first render
		if (!initializing && !presetUpdated) {
			factorUpdated(selectedSecondFactor, operation2, setOperation2, selectedSecondMetric, setOperation2Enabled, false);
		};
	}, [selectedSecondMetric]); 	// eslint-disable-line react-hooks/exhaustive-deps

	useEffect(() => {
		if (currentMaxSellingPrice != null) {
			var theFilters = [
				{
					field: "sku",
					header: t("PricingFilters.SKUFilterButton"),
					value: "",
					booleanFilter: false,
					numericRangeFilter: false,
				},
				{
					field: "date",
					header: t("PricingFilters.DateFilterButton"),
					value: "",
					booleanFilter: false,
					numericRangeFilter: false,
				},
				{
					field: "storeNumber",
					header: t("PricingFilters.StoreFilterButton"),
					value: "",
					booleanFilter: false,
					numericRangeFilter: false,
				},
				{
					field: "description",
					header: t("PricingFilters.DescriptionFilterButton"),
					value: "",
					booleanFilter: false,
					numericRangeFilter: false,
				},
				{
					field: "pdu",
					header: t("PricingFilters.PDUFilterButton"),
					value: "",
					booleanFilter: true,
					numericRangeFilter: false,
					exclusiveWith: ["demandCurve"],
				},
				{
					field: "demandCurve",
					header: t("PricingFilters.DCUFilterButton"),
					value: "",
					booleanFilter: true,
					numericRangeFilter: false,
					exclusiveWith: ["pdu"],
				},
				{
					field: "guardrailed",
					header: t("PricingFilters.GuardrailFilterButton"),
					value: "",
					booleanFilter: true,
					numericRangeFilter: false,
					exclusiveWith: [],
				},
				{
					field: "sellingPrice",
					header: t("PricingFilters.SellingPriceButton"),
					booleanFilter: false,
					numericRangeFilter: true,
					numericInitialRange: [0, Math.ceil(currentMaxSellingPrice)],
					value: "0",
					toValue: Math.ceil(currentMaxSellingPrice).toString(),
					exclusiveWith: []
				}
			];

			setFilters(theFilters);

			setFilterParams({});
		}
	}, [currentMaxSellingPrice]) 	// eslint-disable-line react-hooks/exhaustive-deps

	useEffect(() => {
		if (tReady) {
			fillMaxPrice([]);
		}			
	}, [tReady]); // eslint-disable-line react-hooks/exhaustive-deps

	useEffect(() => {

		// Restore state (if available)
		setEndingPeriodFrom(patternsState.endingPeriodFrom);
		setEndingPeriodTo(patternsState.endingPeriodTo);
		setStartingPeriodFrom(patternsState.startingPeriodFrom);
		setStartingPeriodTo(patternsState.startingPeriodTo);

		loadData();

		return () => {
			
		}
	}, []);	// eslint-disable-line react-hooks/exhaustive-deps

	const renderOperation = (factor, operationValue, operationSetter, groupName, selectedMetric, enabled, useFactor) => {
		let sumEnabled = enabled && factorAllowsOperation(factor, 0, selectedMetric, useFactor);
		let avgEnabled = enabled && factorAllowsOperation(factor, 1, selectedMetric, useFactor);
		let medianEnabled = enabled && factorAllowsOperation(factor, 2, selectedMetric, useFactor);

		return (
			<div className="grid w-full pl-6 mt-1 mb-1">
				<div className="col-4 pl-0 pr-0">
					<label className={classNames("form-checkbox", "form-checkbox-tertiary", "form-checkbox-md", "mb-0", "ml-0", "text-sm", "text-primary", { "disabled": !sumEnabled })}>
						<input type="radio" name={groupName}
							checked={operationValue === 0}
							disabled={!sumEnabled}
							onChange={(e) => {
							operationSetter(0);
						}} />
						<span className="form-checkmark mr-2"></span>{t('Patterns.SumOperation')}
					</label>
				</div>
				<div className="col-4 pl-0 pr-0">
					<label className={classNames("form-checkbox", "form-checkbox-tertiary", "form-checkbox-md", "mb-0", "ml-0", "text-sm", "text-primary", { "disabled": !avgEnabled })}>
						<input type="radio" name={groupName}
								checked={operationValue === 1}
								disabled={!avgEnabled}
								onChange={(e) => {
								operationSetter(1);
							}} />
						<span className="form-checkmark mr-2"></span>{t('Patterns.AverageOperation')}
					</label>
				</div>
				<div className="col-4 pl-0 pr-0">
					<label className={classNames("form-checkbox", "form-checkbox-tertiary", "form-checkbox-md", "mb-0", "ml-0", "text-sm", "text-primary", { "disabled": !medianEnabled })}>
						<input type="radio" name={groupName}
								checked={operationValue === 2}
								disabled={!medianEnabled}
								onChange={(e) => {
								operationSetter(2);
							}} />
						<span className="form-checkmark mr-2"></span>{t('Patterns.MedianOperation')}
					</label>
				</div>
		</div>);
	}

	const getFactor1Name = () => {
		if (patternsDataService.isValidFactorValue(selectedFirstFactor)) {
			for (var idx = 0; idx < factors.length; idx++) {
				var selectedFactor = factors[idx].items.find(f => f.id === selectedFirstFactor);
				if (selectedFactor) {
					return selectedFactor.label;
				}
			}
		}

		return '';
	}

	const getOperation1Name = () => {
		if (patternsDataService.isValidFactorValue(selectedFirstFactor)) {
			if (patternsDataService.isCategorical(selectedFirstFactor)) {
				switch (operation1) {
					case 0: return t('Patterns.SumOperation');
					case 1: return t('Patterns.AverageOperation');
					case 2: return t('Patterns.MedianOperation');
					default: return t('Patterns.CountOperation');
				}
			} else {
				switch (operation1) {
					case 0: return t('Patterns.SumOperation');
					case 1: return t('Patterns.AverageOperation');
					case 2: return t('Patterns.MedianOperation');
					default: return 'Wrong operation!';
				}
			}
		} else {
			return 'No factor 1 selected';
		}
	}

	const getMetricName = (metric) => {
		for (var idx = 0; idx < metrics.length; idx++) {
			if (metrics[idx].id === metric) {
				return metrics[idx].label;
			}
		}

		return '';
	}

	const factor2IsSelected = () => {
		return (patternsDataService.isValidFactorValue(selectedSecondFactor));
	}

	const getFactor2Name = () => {
		if (patternsDataService.isValidFactorValue(selectedSecondFactor)) {
			for (var idx = 0; idx < factors.length; idx++) {
				var selectedFactor = factors[idx].items.find(f => f.id === selectedSecondFactor);
				if (selectedFactor) {
					return selectedFactor.label;
				}
			}
		}

		return '';
	}

	const getOperation2Name = () => {
		if (patternsDataService.isValidFactorValue(selectedSecondFactor)) {
			if (patternsDataService.isCategorical(selectedSecondFactor)) {
				switch (operation2) {
					case 0: return t('Patterns.SumOperation');
					case 1: return t('Patterns.AverageOperation');
					case 2: return t('Patterns.MedianOperation');
					default: return t('Patterns.CountOperation');
				}
			} else {
				switch (operation2) {
					case 0: return t('Patterns.SumOperation');
					case 1: return t('Patterns.AverageOperation');
					case 2: return t('Patterns.MedianOperation');
					default: return 'Wrong operation!';
				}
			}
		} else {
			return 'No factor 2 selected';
		}
	}

	const getOperation3Name = () => {
		if (patternsDataService.isValidFactorValue(selectedThirdFactor)) {
			if (patternsDataService.isCategorical(selectedThirdFactor)) {
				switch (operation3) {
					case 0: return t('Patterns.SumOperation');
					case 1: return t('Patterns.AverageOperation');
					case 2: return t('Patterns.MedianOperation');
					default: return t('Patterns.CountOperation');
				}
			} else {
				switch (operation3) {
					case 0: return t('Patterns.SumOperation');
					case 1: return t('Patterns.AverageOperation');
					case 2: return t('Patterns.MedianOperation');
					default: return 'Wrong operation!';
				}
			}
		} else {
			return 'No factor 3 selected';
		}
	}

	const factorAllowsOperation = (factor, operation, selectedMetric, useFactor) => {
		if (patternsDataService.isValidFactorValue(factor)) {
			// Operations are handled by selected METRIC
			if (useFactor === true) {
				return patternsDataService.factorOperationIsAllowed(factor, operation);
			} else {
				return patternsDataService.factorOperationIsAllowed(selectedMetric, operation);
			}
		} else {
			return false;
		}
	}


	const factor3IsSelected = () => {
		return (patternsDataService.isValidFactorValue(selectedThirdFactor));
	}

	const factor3IsCategorical = () => {
		return (factor3IsSelected() && patternsDataService.isCategorical(selectedThirdFactor));
	}

	const getFactor3Name = () => {
		if (patternsDataService.isValidFactorValue(selectedThirdFactor)) {
			for (var idx = 0; idx < factors.length; idx++) {
				var selectedFactor = factors[idx].items.find(f => f.id === selectedThirdFactor);
				if (selectedFactor) {
					return selectedFactor.label;
				}
			}
		}

		return 'No factor 3 selected';
	}

	const getYTitle = () => {
		if (!factor2IsSelected() && !factor3IsSelected()) {
			if (patternsDataService.isCategorical(selectedFirstMetric)) {
				return t("Patterns.PriceVsProfitMekko_YLabel_OnlyFactor1_Numeric", {
					operation: getOperation1Name(),
					metric: getMetricName(selectedFirstMetric)
				});
			} else {
				if (rateOfChangeActivated) {
					return t("Patterns.PriceVsProfitMekko_YLabel_OnlyFactor1_RateOfChange", {
						operation: getOperation1Name(),
						metric: getMetricName(selectedFirstMetric)
					});
				} else {
					return t("Patterns.PriceVsProfitMekko_YLabel_OnlyFactor1_Numeric", {
						operation: getOperation1Name(),
						metric: getMetricName(selectedFirstMetric)
					});
				}
			}
		} else {
			return t("Patterns.PriceVsProfitMekko_YLabel_Share", {
				operation: patternsDataService.isValidFactorValue(selectedSecondFactor) ? getOperation2Name() : getOperation1Name(),
				factor: patternsDataService.isValidFactorValue(selectedSecondFactor) ? getFactor2Name() : getFactor1Name(),
				metric: patternsDataService.isCategorical(selectedSecondFactor) ? getMetricName(selectedSecondMetric) : t('Patterns.PriceVsProfitMekko_YLabel_Share_Items_Label')
			});
		}
	}

	useEffect(() => {
		if (search !== "") {
			setChartUpdated(true);
		}
	}, [search, searchOutside]); // eslint-disable-line react-hooks/exhaustive-deps

	const fillMaxPrice = async (filters) => {
		let theFilters = filters;
		let theMaxSellingPrice = await pricingService.getPricingMaxSellingPrice(
			theFilters,
			search,
			searchOutside,
			false);
		if (theMaxSellingPrice === 0) {
			theMaxSellingPrice = 100;
		} else {
			theMaxSellingPrice = Math.abs(theMaxSellingPrice);
		}

		setCurrentMaxSellingPrice(theMaxSellingPrice);
	};

	const loadLazyFilters = async (filters) => {
		// Reload the chart with new filters
		setFilterParams(filters);
		setChartUpdated(true);
	};

	const onFilter = (event) => {
		// console.debug(event);
		let _filterParams = { ...filterParams, ...event } // rows attribute required for keeping rows number on filter
		setFilterParams(_filterParams);

		if (event.clearAll === true) {
			// ClearAll will not invoke loadLazyFilters
			setChartUpdated(true);
		}
	};

	const onSearched = async (searchTerm, searchOutside, textChanged) => {
		// console.log("term: ", searchTerm, "outside: ", searchOutside);
		setSearchOutside(searchOutside)
		if (textChanged || searchTerm !== "") {
			setSearch(searchTerm)
		}
	};

	const filterRateOfChangeTemplate = (options) => {
		const toggleIcon = options.collapsed ? 'pi pi-chevron-down' : 'pi pi-chevron-up';
		const className = `${options.className} justify-content-start`;
		const titleClassName = `${options.titleClassName} ml-2 text-md text-primary`;
		const style = { fontWeight: 'bold' };

		options.togglerClassName += ' patterns-panel-toggle';

		return (
			<div className={className}>
				<button className={options.togglerClassName} onClick={options.onTogglerClick}>
					<span className={toggleIcon}></span>
					<Ripple />
				</button>
				<span className={titleClassName} style={style}>{t('Patterns.FilterRateOfChangeTitle')} </span>
			</div>
		);
	}

	const filterHeaderTemplate = (options) => {
		const toggleIcon = options.collapsed ? 'pi pi-chevron-down' : 'pi pi-chevron-up';
		const className = `${options.className} justify-content-start mb-3`;
		const titleClassName = `${options.titleClassName} ml-2 text-md text-primary`;
		const style = { fontWeight: 'bold' };

		options.togglerClassName += ' patterns-panel-toggle';

		return (
			<div className={className}>
				<button className={options.togglerClassName} onClick={options.onTogglerClick}>
					<span className={toggleIcon}></span>
					<Ripple />
				</button>
				<span className={titleClassName} style={style}>{t('Patterns.FilterRateOfChangeFilterTitle')}</span>
			</div>
		);
	}
			
	const calendarOnShow = async (currentDate, endingPeriodTo) => {
		setViewDate(currentDate);

		var date = (currentDate == null) ? new Date() : currentDate;

		let dates = await patternsDataService.retrieveDatesInMonth(date.getMonth() + 1, date.getFullYear());
		dates = dates.map((x) => { return moment(x).toDate(); });
		setEnabledDates(dates);
	}

	const calendarMonthNavigatorTemplate = (e) => {
		return (
			<div className="inline-block">
				<Dropdown value={e.value} options={e.options} onChange={(event) => e.onChange(event.originalEvent, event.value)}
					className="mr-2" style={{ lineHeight: 1 }} />
			</div>);
	}

	const calendarViewDateChange = async (event) => {
		setViewDate(event.value);

		let date = event.value;
		let dates = await patternsDataService.retrieveDatesInMonth(date.getMonth() + 1, date.getFullYear());
		dates = dates.map((x) => { return moment(x).toDate(); });
		setEnabledDates(dates);
	}

	const yearNavigatorTemplate = (e) => {
		return (
			<div className="inline-block">
				<Dropdown value={e.value} options={e.options} onChange={(event) => e.onChange(event.originalEvent, event.value)}
					className="ml-2" style={{ lineHeight: 1 }} />
			</div>);
	}


	const handleDateRateOfChangeFilterChange = (e, isFromDate, isStartingPeriod) => {
		var currentFrom;

		if (isStartingPeriod) {
			if (isFromDate) {
				if (startingPeriodTo != null && (e.value != null && e.value > startingPeriodTo)) {
					setStartingPeriodFrom(startingPeriodTo);
					setStartingPeriodTo(e.value);
				} else {
					setStartingPeriodFrom(e.value);

					if (startingPeriodTo == null) {
						setStartingPeriodTo(e.value);
					}
				}
			} else {
				if (startingPeriodFrom != null && (e.value != null && e.value < startingPeriodFrom)) {
					currentFrom = new Date(startingPeriodFrom);
					setStartingPeriodFrom(e.value);
					setStartingPeriodTo(currentFrom);
				} else {
					setStartingPeriodTo(e.value);
				}
			}
		} else {
			if (isFromDate) {
				if (endingPeriodTo != null && (e.value != null && e.value > endingPeriodTo)) {
					setEndingPeriodFrom(endingPeriodTo);
					setEndingPeriodTo(e.value);
				} else {
					setEndingPeriodFrom(e.value);

					if (endingPeriodTo == null) {
						setEndingPeriodTo(e.value);
					}
				}
			} else {
				if (endingPeriodFrom != null && (e.value != null && e.value < endingPeriodFrom)) {
					currentFrom = new Date(endingPeriodFrom);
					setEndingPeriodFrom(e.value);
					setEndingPeriodTo(currentFrom);
				} else {
					setEndingPeriodTo(e.value);
				}
			}
		}
	}

	const updateDateFilterActivation = () => {
		if (showRateOfChangePerc) {
			if (startingPeriodFrom != null && startingPeriodTo != null && endingPeriodFrom != null && endingPeriodTo != null) {
				setDateFilterEnabled(false);
			} else {
				setDateFilterEnabled(true);
			}
		} else {
			setDateFilterEnabled(true);
		}
	}

	const bottomMostOperationIsNumericFunction = () => {
		var res = false;

		if (factor3IsSelected()) {
			res = !patternsDataService.isCategorical(selectedThirdFactor);
		} else if (factor2IsSelected()) {
			res = !patternsDataService.isCategorical(selectedSecondMetric);
		} else {
			res = !patternsDataService.isCategorical(selectedFirstMetric);
		}

		return res;
	}

	const bottomMostOperationIsNumeric = useCallback(() => {
		return bottomMostOperationIsNumericFunction();
	}, [selectedFirstFactor, selectedSecondFactor, selectedThirdFactor]);	// eslint-disable-line react-hooks/exhaustive-deps

	useEffect(() => {
		updateDateFilterActivation();

		if (showRateOfChangePerc === true
			&& startingPeriodFrom != null && startingPeriodTo != null && endingPeriodFrom != null && endingPeriodTo != null
			&& bottomMostOperationIsNumeric()) {
			if (rateOfChangeActivated === true) {
				// If rate of change is already activated, and we have all the dates set, then update chart
				setChartUpdated(true);
			} else {
				setRateOfChangeActivated(true);
			}
		} else {
			if (rateOfChangeActivated === true) {
				// Only trigger update when it was TRUE
				setRateOfChangeActivated(false);
			}
		}

		patternsState.setRateOfChangeDates(startingPeriodFrom, startingPeriodTo, endingPeriodFrom, endingPeriodTo);

	}, [startingPeriodFrom, startingPeriodTo, endingPeriodFrom, endingPeriodTo, showRateOfChangePerc, selectedFirstFactor, selectedSecondFactor, selectedThirdFactor]);	// eslint-disable-line react-hooks/exhaustive-deps
	
	useEffect(() => {
		if (!initializing && !loading) {
			if (rateOfChangeActivated === false && !bottomMostOperationIsNumeric()) {
				confirmDialog({
					header: t('Patterns.RateOfChangeDisabledNotNumericFactorTitle'),
					message: t('Patterns.RateOfChangeDisabledNotNumericFactorMessage'),
					acceptLabel: t('Patterns.ConfirmDialogOk'),
					className: 'confirm-panel',
					rejectClassName: 'hidden',
					icon: 'pi pi-info-circle',
					accept: async () => {
						// setChartUpdated(true);
					}
				});
			} else {
				setChartUpdated(true);
			}
		}
	}, [rateOfChangeActivated])	// eslint-disable-line react-hooks/exhaustive-deps

	const panelHeaderTemplate = (options) => {
		const toggleIcon = options.collapsed ? 'pi pi-chevron-down' : 'pi pi-chevron-up';
		const className = `${options.className} justify-content-start`;
		const titleClassName = `${options.titleClassName} ml-2 text-primary`;
		const style = { fontSize: '1.25rem' };

		options.togglerClassName += ' patterns-panel-toggle';

		return (
			<div className={className}>
				<button className={options.togglerClassName} onClick={options.onTogglerClick}>
					<span className={toggleIcon}></span>
					<Ripple />
				</button>
				<span className={titleClassName} style={style}> </span>
			</div>
		);
	};

	const filterMetrics = (factor) => {
		if (patternsDataService.isCategorical(factor)) {
			return metrics;
		} else {
			return metrics.filter(v => v.onlyCategorical !== true);
		}
	}

	const getChartColorLegendTitle = () => {
		if (rateOfChangeActivated === true) {
			if (patternsDataService.isValidFactorValue(selectedThirdFactor)) {
				return t('Patterns.RateOfChangeTitleFormat', { factor: getFactor3Name(), operation: getOperation3Name() });
			} else {
				return t('Patterns.RateOfChangeTitleFormat', { factor: getMetricName(selectedSecondMetric), operation: getOperation2Name() });
			}
		} else {
			return getFactor3Name();
		}
	}

	return (
		<>
			<ConfirmDialog />
			<div id="overlay_panel" style={{ display: loading ? "" : "none" }} className="p-overlaypanel-wrapper p-component-overlay p-general-loading-overlay">
				<i className="p-datatable-loading-icon pi-spin pi pi-spinner"></i>
			</div>
			<div className="grid">
				<div className="col-12 mb-0 pb-0">
					<h3 className="text-primary">{companyName}</h3>
					<h1 className="text-primary">{t("Patterns.Header")}</h1>
					<h4 className="text-primary text-subtitle" style={{ display: (lastRepricing == null) ? "none" : "" }}>{t('PricingPage.LastRepricing', { date: lastRepricing })}</h4>
				</div>
				<div className="col-12 mt-0 pt-0 print-fullwidth">
					<div className="grid">
						<div className="col-12 lg:col-3 pl-lg-5 mt-0 pt-0 z-2 no-print">
							<div className="filter-wrapper pricing-field">
								<h4 className="text-primary text-md mb-3">{t('Patterns.PresetsLabel')}</h4>
								<div className='check-pill-wrapper'>
									<div className="check-pill check-pill-tertiary check-pill-md check-pill-rounded-full mr-1 w-full">
										<Dropdown value={selectedPreset} onChange={(e) => setSelectedPreset(e.value)} options={presets} optionLabel="name" optionValue="id"
											placeholder={t('Patterns.FactorNoOption')}
											dropdownIcon="pi pi-chevron-circle-down"
											panelClassName="pricimetrics-dropdown-panel"
											className="pricimetrics-dropdown w-full" />
									</div>
								</div>
								<div className="p-pill-separator"></div>

								<h4 className="text-primary text-md mt-4 mb-3 ">{t('Patterns.Factor1Label')}</h4>
								<div className='check-pill-wrapper'>
									<div className="check-pill check-pill-tertiary check-pill-md check-pill-rounded-full mr-1 w-full">
										<Dropdown value={selectedFirstFactor} onChange={(e) => setSelectedFirstFactor(e.value)} options={factors}
											optionGroupLabel="groupLabel"
											optionGroupChildren="items"
											dropdownIcon="pi pi-chevron-circle-down"
											optionLabel="label"
											optionValue="id"
											showClear={false}
											placeholder={t('Patterns.Factor1NoOption')}
											panelClassName="pricimetrics-dropdown-panel"
											className="pricimetrics-dropdown w-full" />
									</div>
									<div className="patterns-factor-panel-container text-sm">
										<Panel headerTemplate={panelHeaderTemplate} collapsed={true} toggleable>
											<div className={classNames("patterns-metric-container")}>
												<label htmlFor="firstMetric">{t('Patterns.MetricLabel')}</label>
												<Dropdown
													value={selectedFirstMetric}
													id="firstMetric"
													name="firstMetric"
													onChange={(e) => setSelectedFirstMetric(e.value)}
													options={filterMetrics(selectedFirstFactor)}
													dropdownIcon="pi pi-chevron-circle-down"
													optionLabel="label"
													optionValue="id"
													placeholder={t('Patterns.Factor1NoOption')}
													panelClassName="pricimetrics-dropdown-panel"
													className="pricimetrics-dropdown patterns-factor-panel-dropdown" />
											</div>
											{renderOperation(selectedFirstFactor, operation1, setOperation1, "operation1", selectedFirstMetric, operation1Enabled, false)}
											<div className={classNames("patterns-metric-container", "patterns-metric-bins-container")}>
												<label htmlFor="firstMetricLimitTo">{t('Patterns.LimitToLabel')}</label>
												<InputNumber value={selectedFirstMetricBins}
													onValueChange={(e) => setSelectedFirstMetricBins(e.value)}
													id="firstMetricLimitTo"
													name="firstMetricLimitTo"
													inputClassName="inputfield textbox"
													className="patterns-numeric-input"
													showButtons={true} required minFractionDigits={0} maxFractionDigits={0} min={2}
													max={25}
													mode="decimal" />
												<label>{t('Patterns.LimitToBinsLabel')}</label>
											</div>
										</Panel>
									</div>
								</div>

								<h4 className="text-primary text-md mt-4 mb-3">{t('Patterns.Factor2Label')}</h4>
								<div className='check-pill-wrapper'>
									<div className="check-pill check-pill-tertiary check-pill-md check-pill-rounded-full mr-1 w-full">
										<Dropdown value={selectedSecondFactor} onChange={(e) => setSelectedSecondFactor(e.value)} options={factors}
											optionGroupLabel="groupLabel"
											optionGroupChildren="items"
											dropdownIcon="pi pi-chevron-circle-down"
											optionLabel="label"
											optionValue="id"
											showClear={selectedThirdFactor == null || selectedThirdFactor === -1}
											placeholder={t('Patterns.Factor2NoOption')}
											panelClassName="pricimetrics-dropdown-panel"
											className="pricimetrics-dropdown w-full" />
									</div>
									<div className="patterns-factor-panel-container text-sm">
										<Panel headerTemplate={panelHeaderTemplate} collapsed={true} toggleable>
											<div className={classNames("patterns-metric-container", factor2IsSelected() ? "" : "metric-disabled")}>
												<label htmlFor="second">{t('Patterns.MetricLabel')}</label>
												<Dropdown
													value={selectedSecondMetric}
													id="secondMetric"
													name="secondMetric"
													onChange={(e) => setSelectedSecondMetric(e.value)}
													options={filterMetrics(selectedSecondFactor)}
													dropdownIcon="pi pi-chevron-circle-down"
													optionLabel="label"
													optionValue="id"
													placeholder={t('Patterns.Factor2NoOption')}
													panelClassName="pricimetrics-dropdown-panel"
													className="pricimetrics-dropdown patterns-factor-panel-dropdown" />
											</div>
											{renderOperation(selectedSecondFactor, operation2, setOperation2, "operation2", selectedSecondMetric, operation2Enabled, false)}
											<div className={classNames("patterns-metric-container", "patterns-metric-bins-container", factor2IsSelected() ? "" : "metric-disabled")}>
												<label htmlFor="secondMetricLimitToBins">{t('Patterns.LimitToLabel')}</label>
												<InputNumber value={selectedSecondMetricBins}
													onValueChange={(e) => setSelectedSecondMetricBins(e.value)}
													id="secondMetricLimitToBins"
													name="secondMetricLimitToBins"
													inputClassName="inputfield textbox"
													className="patterns-numeric-input"
													showButtons={true} required minFractionDigits={0} maxFractionDigits={0} min={2}
													max={25}
													mode="decimal" />
												<label>{t('Patterns.LimitToBinsLabel')}</label>
											</div>
										</Panel>
									</div>
								</div>

								<h4 className="text-primary text-md mt-4 mb-3">{t('Patterns.Factor3Label')}</h4>
									<div className='check-pill-wrapper'>
										<div className="check-pill check-pill-tertiary check-pill-md check-pill-rounded-full mr-1 w-full">
										<Dropdown value={selectedThirdFactor} onChange={(e) => setSelectedThirdFactor(e.value)} options={factors}
											optionGroupLabel="groupLabel"
											optionGroupChildren="items"
											dropdownIcon="pi pi-chevron-circle-down"
											optionLabel="label"
											optionValue="id"
											showClear
											placeholder={t('Patterns.Factor3NoOption')}
											panelClassName="pricimetrics-dropdown-panel"
											className="pricimetrics-dropdown w-full" />
									</div>
									<div className="patterns-factor-panel-container text-sm">
										<Panel headerTemplate={panelHeaderTemplate} collapsed={true} toggleable>
											{renderOperation(selectedThirdFactor, operation3, setOperation3, "operation3", null, operation3Enabled, true)}

											<div className='w-full grid pl-3'>
												<div className="col-2">&nbsp;</div>
												<div className="col-4 pr-0 pl-0 pt-3" >
													<label className={classNames("form-checkbox", "form-checkbox-tertiary", "form-checkbox-md", "mb-3", "text-sm", "text-primary", { "disabled": !operation3Enabled || factor3IsCategorical() })}>
														<input type="checkbox"
															name="ByFactor3Ranges"
															checked={factor3Resolution === 1}
															disabled={!operation3Enabled || factor3IsCategorical()}
															onChange={(e) => {
																setFactor3Resolution(e.target.checked ? 1 : 0);
															}} />
														<span className="form-checkmark mr-2"></span>{t('Patterns.Factor3Switch_ByFactor3Ranges')}
													</label>
												</div>
												<div className="col-6" style={{ paddingTop: "11px" }} >
													<div className={classNames("patterns-metric-container", "patterns-metric-bins-container", factor3IsSelected() ? "" : "metric-disabled")}>
														<label htmlFor="thirdMetricLimitToBins">{t('Patterns.LimitToLabel')}</label>
														<InputNumber value={selectedThirdMetricBins}
															onValueChange={(e) => setSelectedThirdMetricBins(e.value)}
															id="thirdMetricLimitToBins"
															name="thirdMetricLimitToBins"
															inputClassName="inputfield textbox"
															className="patterns-numeric-input"
															showButtons={true} required minFractionDigits={0} maxFractionDigits={0} min={2}
															max={25}
															mode="decimal" />
														<label>{t('Patterns.LimitToBinsLabel')}</label>
													</div>
												</div>
											</div>
										</Panel>
									</div>
								</div>
								<div className="text-sm">
									<Panel headerTemplate={filterHeaderTemplate} collapsed={true} toggleable>
										<div>
											<FilterPricing
												itemsFiltered={itemsFiltered}
												onFilter={onFilter}
												onSearched={onSearched}
												setFilters={setFilters}
												filters={filters}
												loadLazyFilters={loadLazyFilters}
												dateFilterEnabled={dateFilterEnabled}
											/>
										</div>
									</Panel>
									<Panel headerTemplate={filterRateOfChangeTemplate} collapsed={true} toggleable>		
										<div className="mt-3">
											<label className={classNames("form-checkbox", "form-checkbox-tertiary", "form-checkbox-md", "mt-3", "mb-3", "text-sm", "text-primary", bottomMostOperationIsNumeric() ? "" : "opacity-50")}>
												<input type="checkbox" name="showRateOfChangePerc"
													disabled={!bottomMostOperationIsNumeric()}
													checked={showRateOfChangePerc}
													onChange={(e) => {
														setShowRateOfChangePerc(e.target.checked);
													}} />
												<span className="form-checkmark mr-2"></span>{t('Patterns.FilterRateOfRangeMessage')}
											</label>
											<div className={(showRateOfChangePerc && bottomMostOperationIsNumeric()) ? "" : "opacity-50"}>
												<h5 className="text-primary text-md font-bold">{t('Patterns.FilterRateOfChangeStartingPeriod')}</h5>
												<div className="grid mt-3 mb-3">
													<div className="col-6">
														<span className="text-primary p-from-to-calendar-label">{t('Patterns.FilterRateOfChangeFrom')}</span>
														<Calendar
															id="starting_period_from"
															className="p-from-to-calendar"
															panelClassName="p-from-to-datepicker"
															locale={i18n.language}
															monthNavigator yearNavigator yearRange="2010:2050"
															yearNavigatorTemplate={yearNavigatorTemplate}
															monthNavigatorTemplate={calendarMonthNavigatorTemplate}
															onViewDateChange={calendarViewDateChange}
															onShow={() => calendarOnShow(startingPeriodFrom, setStartingPeriodFrom)}
															todayButtonClassName="hidden"
															viewDate={viewDate}
															enabledDates={enabledDates}
															dateFormat="yy-mm-dd"
															placeholder={t('Patterns.FilterRateOfChangeFrom')}
															readOnlyInput
															showButtonBar 
															value={startingPeriodFrom}
															disabled={!showRateOfChangePerc || !bottomMostOperationIsNumeric()}
															onChange={(e) => handleDateRateOfChangeFilterChange(e, true, true)}
														/>
													</div>
													<div className="col-6">
														<span className="text-primary p-from-to-calendar-label">{t('Patterns.FilterRateOfChangeTo')}</span>
														<Calendar
															id="starting_period_to"
															className="p-from-to-calendar"
															panelClassName="p-from-to-datepicker"
															locale={i18n.language}
															monthNavigator yearNavigator yearRange="2010:2050"
															yearNavigatorTemplate={yearNavigatorTemplate}
															monthNavigatorTemplate={calendarMonthNavigatorTemplate}
															onViewDateChange={calendarViewDateChange}
															onShow={() => calendarOnShow(startingPeriodTo, setStartingPeriodTo)}
															todayButtonClassName="hidden"
															viewDate={viewDate}
															enabledDates={enabledDates}
															dateFormat="yy-mm-dd"
															placeholder={t('Patterns.FilterRateOfChangeTo')}
															readOnlyInput
															showButtonBar
															value={startingPeriodTo}
															disabled={!showRateOfChangePerc || !bottomMostOperationIsNumeric()}
															onChange={(e) => handleDateRateOfChangeFilterChange(e, false, true)}
														/>
														<span className="pi pi-info-circle p-from-to-calendar-info-icon" title={t('Patterns.FilterRateOfChangeInfo') }></span>
													</div>
												</div>
												<h5 className="text-primary text-md font-bold">{t('Patterns.FilterRateOfChangeEndingPeriod') }</h5>
												<div className="grid mt-3 mb-3">
													<div className="col-6">
														<span className="text-primary p-from-to-calendar-label">{t('Patterns.FilterRateOfChangeFrom')}</span>
														<Calendar
															id="ending_period_from"
															className="p-from-to-calendar"
															panelClassName="p-from-to-datepicker"
															locale={i18n.language}
															monthNavigator yearNavigator yearRange="2010:2050"
															yearNavigatorTemplate={yearNavigatorTemplate}
															monthNavigatorTemplate={calendarMonthNavigatorTemplate}
															onViewDateChange={calendarViewDateChange}
															onShow={() => calendarOnShow(endingPeriodFrom, setEndingPeriodFrom)}
															todayButtonClassName="hidden"
															viewDate={viewDate}
															enabledDates={enabledDates}
															dateFormat="yy-mm-dd"
															placeholder={t('Patterns.FilterRateOfChangeFrom')}
															readOnlyInput
															showButtonBar
															value={endingPeriodFrom}
															disabled={!showRateOfChangePerc || !bottomMostOperationIsNumeric()}
															onChange={(e) => handleDateRateOfChangeFilterChange(e, true, false)}
														/>
													</div>
													<div className="col-6">
														<span className="text-primary p-from-to-calendar-label">{t('Patterns.FilterRateOfChangeTo')}</span>
														<Calendar
															id="ending_period_to"
															className="p-from-to-calendar"
															panelClassName="p-from-to-datepicker"
															locale={i18n.language}
															monthNavigator yearNavigator yearRange="2010:2050"
															yearNavigatorTemplate={yearNavigatorTemplate}
															monthNavigatorTemplate={calendarMonthNavigatorTemplate}
															onViewDateChange={calendarViewDateChange}
															onShow={() => calendarOnShow(endingPeriodTo, setEndingPeriodTo)}
															todayButtonClassName="hidden"
															viewDate={viewDate}
															enabledDates={enabledDates}
															dateFormat="yy-mm-dd"
															placeholder={t('Patterns.FilterRateOfChangeTo')}
															readOnlyInput
															showButtonBar
															value={endingPeriodTo}
															disabled={!showRateOfChangePerc || !bottomMostOperationIsNumeric()}
															onChange={(e) => handleDateRateOfChangeFilterChange(e, false, false)}
														/>
														<span className="pi pi-info-circle p-from-to-calendar-info-icon" title={t('Patterns.FilterRateOfChangeInfo')}></span>
													</div>
												</div>
											</div>
										</div>
									</Panel>
								</div>								
							</div>
						</div>
						<div className="col-12 lg:col-9 pl-lg-5 mt-0 pt-0 z-1">
							<MarimekkoChart
								xTitle={t("Patterns.PriceVsProfitMekko_XLabel")}
								yTitle={getYTitle()}
								rateOfChangeActivated={rateOfChangeActivated}
								showFactor3Tooltip={(factor3Resolution === 1 && rateOfChangeActivated) || !rateOfChangeActivated}
								rawData={rawData}
								showTooltip={true}
								t={t}
								showLabels={factor2IsSelected()}
								legend={
									{
										display: factor3IsSelected() && factor3IsCategorical(),
										customNumericDisplay: (factor3IsSelected() && !factor3IsCategorical()) || (factor2IsSelected() && rateOfChangeActivated),
										title: {
											display: factor3IsSelected() && factor3IsCategorical(),
											text: getChartColorLegendTitle(),
											color: '#014548'
										},
										labels: {
											color: '#014548',
											filter: (legendItem, data) => {
												// console.debug(labelsForFactor3);
												let label = data.datasets[legendItem.datasetIndex].label || '';
												if (typeof (label) !== 'undefined') {
													if (legendItem.datasetIndex >= labelsForFactor3) {
														return false;
													}
												}
												return label;
											}										
										}									
									}
								}
								data={
									{
										labels: xAxisLabels,
										mekkoThickness: xCatWidths,
										firstFactorValues: firstFactorValues,
										datasets: datasets,
										stacked: stacked
									}
								}>
							</MarimekkoChart>
						</div>
					</div>
				</div>
			</div>
		</>
	)
}

export default withTranslation()(PatternsPage)
