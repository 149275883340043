import React, { useState, useEffect } from 'react'
import { withTranslation } from 'react-i18next';
import ConfigActionBtn from '../Config/ConfigActionBtn'
import { confirmDialog } from 'primereact/confirmdialog';
import { Checkbox } from 'primereact/checkbox';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import configurationService from '../../Api/requests/config'
import messagesService from '../../Api/client-side/messages'

function PricingExportSettings(props) {
	const { t } = props;
	const { setLoading } = props;
	const { allowed } = props;
	const { showHelp } = props;

	const [dataChanged, setDataChanged] = useState(false)
	const [columns, setColumns] = useState([])
	const [originalRows, setOriginalRows] = useState([])
	const [editingRows, setEditingRows] = useState({})

	// const actualActiveIndex = useRef(-1);

	const reloadTable = async () => {
		setLoading(true);

		let selectedFields = await configurationService.loadPricingExportConfig();

		// console.log(selectedFields);

		selectedFields = selectedFields.includedFields;		

		var theFilters = [
			{
				field: 'sku',
				header: t('PricingTable.SKU_Header'),
				selected: selectedFields.includes('sku')
			},
			{
				field: 'storeNumber',
				header: t('PricingTable.Store_Header'),
				selected: selectedFields.includes('storeNumber')
			},
			{
				field: 'projectedTotalProfit',
				header: t('PricingTable.ProjectedTotalProfit_Header'),
				selected: selectedFields.includes('projectedTotalProfit')
			},
			{
				field: 'changeInProfit',
				header: t('PricingTable.ChangeInProfit_Header'),
				selected: selectedFields.includes('changeInProfit')
			},
			{
				field: 'projectedTotalGrossProfit',
				header: t('PricingTable.ProjectedTotalGrossProfit_Header'),
				selected: selectedFields.includes('projectedTotalGrossProfit')
			},
			{
				field: 'changeInGrossProfit',
				header: t('PricingTable.ChangeInGrossProfit_Header'),
				selected: selectedFields.includes('changeInGrossProfit')
			},
			{
				field: 'sellingPrice',
				header: t('PricingTable.LastWeekPrice_Header'),
				selected: selectedFields.includes('sellingPrice')
			},
			{
				field: 'recommendedSellingPrice',
				header: t('PricingTable.RecommendedPrice_Header'),
				selected: selectedFields.includes('recommendedSellingPrice')
			},
			//{
			//	field: 'info',
			//	header: t('PricingTable.InfoColumn_Header'),
			//	selected: selectedFields.includes('info')
			//},
			{
				field: 'confidence',
				header: t('PricingTable.Confidence_Header'),
				selected: selectedFields.includes('confidence')
			},
			{
				field: 'lastPriceChangeDate',
				header: t('PricingTable.DaysSinceLastPriceChange_Header'),
				selected: selectedFields.includes('lastPriceChangeDate')
			},
			{
				field: 'priceImpactOnProfit',
				header: t('PricingTable.PriceImpactOnProfit_Header'),
				selected: selectedFields.includes('priceImpactOnProfit')
			},
			{
				field: 'unitsChange',
				header: t('PricingTable.Units_Change'),
				selected: selectedFields.includes('unitsChange')
			},
			{
				field: 'unitsChangePercentage',
				header: t('PricingTable.Units_Change_Percentage'),
				selected: selectedFields.includes('unitsChangePercentage')
			},
			{
				field: 'profitChangePercentage',
				header: t('PricingTable.Profit_Change_Percentage'),
				selected: selectedFields.includes('profitChangePercentage')
			}
		];

		setColumns(theFilters);
		setDataChanged(false);

		setLoading(false);
	}

	const onEditorValueChange = (config, value) => {
		let updatedFields = [...config.props.value]
		let item = updatedFields[config.rowIndex]
		item[config.field] = value
		item['changed'] = true;

		setColumns(updatedFields)

		setDataChanged(true);
	}

	const onRowEditInit = (event) => {
		var theOriginalRows = originalRows;
		theOriginalRows[event.index] = { ...columns[event.index] };
		setOriginalRows(theOriginalRows);
	}

	const onRowEditCancel = (event) =>  {
		let theColumns = [...columns];
		theColumns[event.index] = originalRows[event.index];

		let theOriginalRows = [...originalRows];
		delete theOriginalRows[event.index];

		setOriginalRows(theOriginalRows);
		setColumns(theColumns);
	}

	const onRowEditChange = (event)  => {
		setEditingRows(event.data);
	}

	const confirmReset = async () => {
		await reloadTable();
	}

	const confirmSave = async () => {
		confirmDialog({
			message: t('PricingExportSettings.ConfirmOverwriteMessage'),
			header: t('PricingExportSettings.ConfirmOverwriteHeader'),
			acceptLabel: t('PricingExportSettings.ConfirmDialogYes'),
			rejectLabel: t('PricingExportSettings.ConfirmDialogNo'),
			icon: 'pi pi-exclamation-triangle',
			accept: async () => {
				const selected = columns.filter(c => c.selected === true).map(function (item) { return item.field; })
				console.log(selected);

				let config = {
					IncludedFields: selected
				};

				let res = await configurationService.savePricingExportConfig(config);

				if (res === null) {
					messagesService.showSuccess(t('PricingExportSettings.RefreshedTitle'), t('PricingExportSettings.RefreshedMessage'));
				} else {
					messagesService.showError(t('PricingExportSettings.RefreshedErrorTitle'), t('PricingExportSettings.RefreshedErrorMessage'));
				}
			}
		});
	}

	const fieldNameTemplate = (rowData, props) => {
		return (<>{t(rowData.header)}</>);
	}

	const selectColumnEditor = (rowData, props) => {
		return (
			<Checkbox checked={rowData['selected']} onChange={(e) => onEditorValueChange(props, e.checked)} />
		);
	}

	useEffect(() => {
		reloadTable();
	}, []);  // eslint-disable-line react-hooks/exhaustive-deps

	if (allowed !== true) {
		return (<></>);
	} else {
		return (
			<>
				<h3 className="text-primary pt-3 pb-3 pl-3" id="pricingExportSettings">
					{t('PricingExportSettings.Header')}
					<Button label="" title={t('PricingExportSettings.HelpButton')} icon="pi pi-question-circle" className="p-button-config-help p-button-rounded p-button-info" onClick={() => showHelp('PricingExportSettings.ImportHelpTitle', 'PricingExportSettings.ImportHelpMessage')} />
				</h3>
				

				<div className="field grid mt-3 pl-3 pr-3">
					<div className="col-12 md:col-12 datatable datatable-responsive">
						<DataTable value={columns} dataKey="field" responsiveLayout="scroll"
							className="p-datatable-config"
							paginator={false}
							footer={false}
							editMode="row" editingRows={editingRows}
							onRowEditChange={onRowEditChange} onRowEditInit={onRowEditInit} onRowEditCancel={onRowEditCancel}
							columnResizeMode="fit">
							<Column field="field" header={t('PricingExportSettings.FieldNameColumn')} style={{ maxWidth: '75%' }} body={fieldNameTemplate}></Column>
							<Column field="selected" header={t('PricingExportSettings.ExportColumn')} bodyClassName="center-column" headerClassName="center-column" body={selectColumnEditor} style={{ maxWidth: '25%' }}></Column>
						</DataTable>
					</div>
				</div>

				<div className="flex justify-content-end mr-3 bottom-buttons">
					<ConfigActionBtn onClick={(e) => confirmSave()} buttonText={t('ConfigurationDialog.SaveButton')} disabled={!dataChanged} />
					<ConfigActionBtn onClick={(e) => confirmReset()} buttonText={t('ConfigurationDialog.CancelButton')} disabled={!dataChanged} />
				</div>
			</>
		);
	}
}

export default withTranslation()(PricingExportSettings)
